import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { WithAuth } from '@totem/auth';
import { User } from '@totem/roles';
import { Routes, Navigate, Route, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/fr';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useIntercom } from 'react-use-intercom';
import { Toaster } from 'react-hot-toast';

import { UserContext } from 'constants/user-context';
import { SPYEE_TOKEN, TOKEN } from 'constants';

import { GET_ORGANIZATION, GET_TOTEM_CLOSED_DAYS, GET_USER } from 'data/queries';

import { ErrorHandler } from 'utils/error-handler';

import { Navbar, CenterPanel } from 'wrappers/index';

import { Login } from 'pages/Login';
import { DownloadMobileApp } from 'pages/DownloadMobileApp';
import { SetPassword } from 'pages/SetPassword/SetPassword';
import { RequestResetPassword } from 'pages/ResetPassword/RequestResetPassword';
import { ResetPassword } from 'pages/ResetPassword/ResetPassword';
import { Sven } from 'pages/Sven';
import { SplitScreen } from 'pages/SplitScreen';
import { Loading } from 'components/Loading';

import { Spybox } from 'ui/Spybox';

import { SignupRouter } from 'pages/Signup/SignupRouter';
import { TotemLoadingStyle } from 'styles/pages/totem.styles';
import { Signup } from 'pages/Signup/Signup';
import { EmployeeEmailValidation } from 'pages/Settings/SettingsPage/SettingsTeam/EmployeeEmailValidation';
import { OrderDetails } from 'pages/Order/OrderDetails';

import { TotemContainer, Wrapper } from 'styles';
import { NavbarStyle } from 'styles/wrappers/navbar.styles';

import { ENV_PREPROD, ENV_PROD } from 'constants/environment-constants';

import { identify } from './tracking/segment';
import { ModalContext } from 'contexts/ModalContext';
import { TotemClosedDaysContext } from 'contexts/TotemClosedDaysContext';

dayjs.locale('fr');
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(customParseFormat);

export const App = () => {
    const getSkipValue = () => !window.TOTEM.storage.get(TOKEN);
    // eslint-disable-next-line
    const { boot: bootIntercom } = window.__TOTENV__.E2E ? { boot: () => {} } : useIntercom();
    const { pathname, state = {} } = useLocation();
    const { from } = state || {};
    const [skip, setSkip] = useState(getSkipValue());
    const [initialized, setInitialized] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modal, setModal] = useState(null);

    const showNavbar = !/(login|signup|set-password|recover-password|verify-email|sven)/.test(pathname) && !skip;

    const {
        loading: userLoading,
        error: userError,
        data: userData = {},
    } = useQuery(GET_USER, {
        skip,
    });
    const {
        loading: orgLoading,
        error: orgError,
        data: orgData = {},
    } = useQuery(GET_ORGANIZATION, {
        skip,
    });

    const {
        loading: totemDaysLoading,
        error: totemDaysError,
        data: totemDaysData = {},
    } = useQuery(GET_TOTEM_CLOSED_DAYS, {
        skip,
    });

    const loading = userLoading || orgLoading || totemDaysLoading;

    useEffect(() => {
        if (!initialized && !userLoading && userData.user) {
            if (!window.__TOTENV__.E2E && userData.user.profile) {
                const {
                    _id,
                    createdAt,
                    username,
                    profile: { firstname, lastname, organizationName, organizationId },
                    roles,
                    visitedSites,
                } = userData.user;
                identify({
                    createdAt: createdAt / 1000,
                    email: username,
                    id: _id,
                    name: `${firstname} ${lastname}`,
                });
                bootIntercom({
                    company: {
                        companyId: organizationId,
                        name: organizationName,
                    },
                    email: username,
                    name: `${firstname} ${lastname}`,
                    userId: _id,
                    customAttributes: {
                        isUserC: roles.global.includes('Employee') || !!visitedSites.length,
                        isUserB: !roles.global.includes('Employee'),
                        totemUserLink: `https://admin.totem.co/user/${_id}`,
                    },
                });
            }
            setInitialized(true);
        }

        // We want a different icon in PROD than in other environments
        if (window.__TOTENV__.ENV !== ENV_PROD) {
            // 1. Update icon
            const largeFavicon = document.getElementById('large-favicon');
            const smallFavicon = document.getElementById('small-favicon');

            largeFavicon.href = '/favicon-preprod-32x32.png';
            smallFavicon.href = '/favicon-preprod-16x16.png';

            // 2. Update page title
            const pageTitle = document.getElementById('page-title');
            pageTitle.innerHTML =
                (window.__TOTENV__.ENV === ENV_PREPROD ? '[preprod] ' : '[local] ') + 'TOTEM | Application Web';
        }
    }, [userLoading, initialized, userData, loading, bootIntercom]);

    if (loading) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    let error = null;

    if (
        (userError && !userError.message.includes('Not authenticated')) ||
        (orgError && !orgError.message.includes('Not authenticated')) ||
        (totemDaysError && !totemDaysError.message.includes('Not authenticated'))
    ) {
        error = userError || orgError || totemDaysError;
    }

    const { user = null } = userData || {};
    const { organizationOfUser: organization = null } = orgData || {};
    const { getTotemClosedDays: totemClosedDays = [] } = totemDaysData || {};

    if (user) {
        User.setUser(user);
    }

    const handleAuthCallback = () => {
        const skipValue = getSkipValue();
        setSkip(skipValue);
    };

    // This needs to be a function as a const depending on window is not automatically refreshed when window changes
    function getShouldDisplayExitSpy() {
        return !!window.TOTEM.storage.get(SPYEE_TOKEN);
    }

    const handleExitSpy = async () => {
        await window.TOTEM.storage.remove(SPYEE_TOKEN);
        !getShouldDisplayExitSpy() && window.location.replace('/spying');
    };

    return (
        <WithAuth callback={handleAuthCallback}>
            <UserContext.Provider
                value={{
                    organization: !skip ? organization : null,
                    user: !skip ? user : null,
                }}
            >
                <ModalContext.Provider
                    value={{
                        isModalOpen,
                        setIsModalOpen,
                        modal,
                        setModal,
                    }}
                >
                    {User.isGlobalEmployee() ? (
                        // need to put this page inside the UserContext.Provider to let the employee logout
                        <DownloadMobileApp />
                    ) : (
                        <>
                            <Toaster />
                            <ErrorHandler>
                                {error ? (
                                    <div>
                                        {(function () {
                                            throw error;
                                        })()}
                                    </div>
                                ) : (
                                    <TotemContainer>
                                        {showNavbar && (
                                            <NavbarStyle>
                                                <Navbar />
                                            </NavbarStyle>
                                        )}
                                        <Wrapper>
                                            {user ? (
                                                <TotemClosedDaysContext.Provider value={!skip ? totemClosedDays : []}>
                                                    <Routes>
                                                        <Route path="/signup/*" element={<SignupRouter />} />
                                                        <Route path="/sven" element={<Sven />} />
                                                        <Route path="/login" element={<Navigate to={from || '/'} />} />
                                                        <Route
                                                            path="/set-password/:stepId"
                                                            element={<SetPassword setSkip={setSkip} />}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/recover-password"
                                                            element={<RequestResetPassword />}
                                                        />
                                                        <Route
                                                            path="/recover-password/:token"
                                                            element={<ResetPassword setSkip={setSkip} />}
                                                        />
                                                        <Route
                                                            path="/validate-employee/:token"
                                                            element={<EmployeeEmailValidation setSkip={setSkip} />}
                                                        />
                                                        <Route path="/order/:orderId?" element={<OrderDetails />} />
                                                        <Route path="*" element={<CenterPanel />} />
                                                    </Routes>
                                                </TotemClosedDaysContext.Provider>
                                            ) : (
                                                <Routes>
                                                    <Route exact path="/" element={<SplitScreen />} />
                                                    <Route exact path="/login" element={<Login />} />
                                                    <Route exact path="/signup" element={<Signup />} />
                                                    <Route
                                                        path="/set-password/:stepId"
                                                        element={<SetPassword setSkip={setSkip} />}
                                                    />
                                                    <Route
                                                        path="/recover-password"
                                                        element={<RequestResetPassword />}
                                                    />
                                                    <Route
                                                        path="/recover-password/:token"
                                                        element={<ResetPassword setSkip={setSkip} />}
                                                    />
                                                    <Route
                                                        path="/validate-employee/:token"
                                                        element={<EmployeeEmailValidation setSkip={setSkip} />}
                                                    />
                                                    <Route path="*" element={<Navigate to="/login" />} />
                                                </Routes>
                                            )}
                                        </Wrapper>
                                        {user && getShouldDisplayExitSpy() && (
                                            <Spybox onClick={handleExitSpy} organizationName={organization.name} />
                                        )}
                                    </TotemContainer>
                                )}
                            </ErrorHandler>
                        </>
                    )}
                </ModalContext.Provider>
            </UserContext.Provider>
        </WithAuth>
    );
};
