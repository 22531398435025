import React from 'react';
import { IconFileDownload } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { IconButton, TotemButton } from 'ui/Button';

import { InvoicesTableStyle, InvoiceStatus, SettingsSubtitle } from 'styles/pages/settings.styles';
import { FORMAT } from 'constants/date-constants';

export const StripeInvoicesTable = ({ invoices, fetchMore, hasMore, organizationId, isLoading }) => {
    const paymentInfo = {
        deleted: ['Annulée', 'darkGrey'],
        draft: ['En cours', 'pantinGrey'],
        open: ['En attente', 'red'],
        paid: ['Réglée', 'green'],
        uncollectible: ['A compléter', 'orange'],
        void: ['Nulle', 'yellow'],
    };

    const sortedInvoices = [...invoices].sort((a, b) => b.created - a.created);

    if (sortedInvoices?.length === 0) {
        return <SettingsSubtitle $topMargin="15px">Pas de facture pour le moment</SettingsSubtitle>;
    }
    return (
        <>
            <InvoicesTableStyle>
                <thead>
                    <tr>
                        <th>Statut</th>
                        <th>Description</th>
                        <th>Date</th>
                        <th>Numéro</th>
                        <th>Prix HT</th>
                        <th>TVA</th>
                        <th>Prix TTC</th>
                        <th>Facture</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedInvoices
                        .filter((invoice) => invoice.status !== 'deleted')
                        .map((invoice) => {
                            const invoiceDate = dayjs(invoice.created * 1000).format(FORMAT);
                            const [status, color] = paymentInfo[invoice.status];
                            return (
                                <tr key={invoice.id}>
                                    <td>{invoice.status && <InvoiceStatus color={color}>{status}</InvoiceStatus>}</td>
                                    <td>{invoice.description}</td>
                                    <td>{invoiceDate}</td>
                                    <td>{invoice.number}</td>
                                    <td>{Boolean(invoice.subtotal) && `${invoice.subtotal / 100}€`}</td>
                                    <td>{Boolean(invoice.tax) && `${invoice.tax / 100}€`}</td>
                                    <td>{Boolean(invoice.total) && `${invoice.total / 100}€`}</td>
                                    <td width="5%">
                                        {invoice.hosted_invoice_url && (
                                            <IconButton
                                                as="a"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={invoice.hosted_invoice_url}
                                            >
                                                <IconFileDownload />
                                            </IconButton>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </InvoicesTableStyle>
            {hasMore && (
                <TotemButton
                    as="a"
                    $margins={[1, 0]}
                    $type="secondary"
                    $alignSelf="center"
                    $fontSize="0.75em"
                    onClick={() =>
                        fetchMore({
                            variables: {
                                startingAfterInvoice: invoices[invoices.length - 1].id,
                                organizationId,
                            },
                        })
                    }
                    disabled={isLoading}
                >
                    Voir plus
                </TotemButton>
            )}
        </>
    );
};
