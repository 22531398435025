import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconEditCircle } from '@tabler/icons-react';
import styled, { css } from 'styled-components';
import { padding } from 'polished';

import { TotemButton, IconButton } from 'ui/Button';

import { UPDATE_TOTEM_NAME } from 'data/mutations';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

export const DashboardHeaderInfo = ({ totem, openDeliveryInfoPopup }) => {
    const { name, number, _id: totemId, address, longitude, latitude, address_details, zipcode, city } = totem;
    const [isEditingName, setEditingName] = useState(false);
    const [totemName, setTotemName] = useState(name);
    const [updateTotemName] = useMutation(UPDATE_TOTEM_NAME);

    const handleTotemNameSubmit = async () => {
        await updateTotemName({ variables: { totemId, name: totemName } });
        track(analyticsEvents.UPDATE_TOTEM_NAME, {
            [analyticsPropertiesConstants.TOTEM.NUMBER]: number,
        });
        setEditingName(false);
    };

    return (
        <TotemInformation $isPositionAbsolute={latitude && longitude}>
            <TotemInfoLeftPanel>
                {isEditingName ? (
                    <EditionContainer>
                        <TotemNameInput
                            autoFocus
                            placeholder="Nom de votre totem"
                            name="email"
                            width="200px"
                            defaultValue={totemName}
                            onChange={(e) => setTotemName(e.target.value)}
                        />
                        <TotemButton $margins={[0, 1]} $fontSize="1em" onClick={handleTotemNameSubmit}>
                            Modifier
                        </TotemButton>
                    </EditionContainer>
                ) : (
                    <TotemDashboardTitle>
                        <DashboardMainTitle data-test="totem-name">
                            {name || `Mon TOTEM n°${number}`}
                        </DashboardMainTitle>
                        <IconButton onClick={() => setEditingName(true)}>
                            <IconEditCircle stroke={2.5} />
                        </IconButton>
                    </TotemDashboardTitle>
                )}
                <TotemDashboardAddress data-test="dashboard-address">
                    {address && city && zipcode && (
                        <AddressLine>
                            {address}, {zipcode} {city.toUpperCase()}
                        </AddressLine>
                    )}
                    {address_details && <AddressLine>{address_details}</AddressLine>}
                </TotemDashboardAddress>

                <EditDeliveryInfoButton data-test="go-to-address" onClick={openDeliveryInfoPopup}>
                    <LeftPart>Modifier les informations liées à la livraison</LeftPart>
                    {/* as='div' avoids having overlaping buttons */}
                    <IconButton as="div">
                        <IconEditCircle stroke={2.5} />
                    </IconButton>
                </EditDeliveryInfoButton>
            </TotemInfoLeftPanel>
        </TotemInformation>
    );
};

const TotemInformation = styled.div`
    width: 100%;
    ${({ theme: { variables } }) => padding(variables.padding * 3)};
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;

    ${({ $isPositionAbsolute }) =>
        $isPositionAbsolute &&
        css`
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        `}
`;

const TotemInfoLeftPanel = styled.div`
    flex: 1;
    max-width: 500px;
`;

const EditionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const TotemDashboardTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 47px;
    button {
        transform: translateY(-3px);
    }
`;

const DashboardMainTitle = styled.h1`
    display: flex;
    flex: 1 0 auto;
    font-size: 2.8em;
    color: ${({ theme: { colors } }) => colors.black};
    margin: 0 10px 0 0;
    font-weight: 800;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80vw;
`;

const AddressLine = styled.div`
    margin: 5px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    svg {
        margin-right: 6px;
        flex-shrink: 0;
    }
`;

const TotemDashboardAddress = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-bottom: 0.5em;
`;

const TotemNameInput = styled.input`
    margin: 0;
    height: 1.7em;
    border-radius: ${({ theme }) => theme.variables.borderRadius};
    border: none;
    padding: 0 0.9em;
    width: 11em;
    font-size: 2em;
`;

const EditDeliveryInfoButton = styled.button`
    border: none;
    background-color: white;
    font: inherit;
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${({ theme }) => `${theme.variables.padding}px ${theme.variables.padding * 2}px`};
    border-radius: ${({ theme }) => theme.variables.borderRadius};
`;

const LeftPart = styled.div`
    max-width: 175px;
    text-align: left;
`;
