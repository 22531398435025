import { PartialUserInterface, UserScope } from './interfaces';

function ConfigureUser(user: PartialUserInterface): UserScope {
    let userScope = {};

    if (user) {
        const {
            _id: id,
            emails: [{ address: email }],
            profile: { firstname, lastname, organizationId },
            username
        } = user;
        userScope = {
            ...userScope,
            id,
            organizationId,
            username,
            email,
            name: `${firstname} ${lastname}`
        };
    }

    return userScope;
}

export function ConfigureScope(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    severity: any,
    {
        user,
        errorInfo = {},
        errorContext = 'global',
        level
    }: {
        user: PartialUserInterface;
        errorInfo?: any;
        errorContext?: string;
        level?: string;
    },
    sentryFunction: Function,
    error: Error | string,
    callback?: Function
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (scope: any, environment: string) => Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async function scopeConfiguration(scope: any, environment: string): Promise<void> {
        scope.setUser(ConfigureUser(user));
        scope.setLevel(level ?? severity.Error);
        scope.setTag('username', user?.username);
        scope.setTag('error-context', errorContext);
        errorInfo = typeof errorInfo === 'object' ? errorInfo : { message: errorInfo };
        scope.setExtras({
            ...errorInfo
        });

        let errorId;

        if (environment !== 'development') {
            errorId = sentryFunction(error);
        } else {
            errorId = `test-${Math.random()
                .toString(36)
                .substr(2)}`;
        }

        callback && callback(errorId);

        return Promise.resolve(void 0);
    };
}
