import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { AUTHENTICATE_EMPLOYEE_VALIDATION } from 'data/mutations';

import { Loading } from 'components/Loading';
import { Logo } from 'components/Logo';

import { LoginStyle, LoginTopHalfStyle } from 'styles/pages/login.styles';
import { TotemLoadingStyle } from 'styles/pages/totem.styles';

export const EmployeeEmailValidation = () => {
    const { token } = useParams();
    const [displayMessage, setDisplayMessage] = useState(null);
    const [authenticateEmployeeValidation] = useMutation(AUTHENTICATE_EMPLOYEE_VALIDATION);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            const result = await authenticateEmployeeValidation({ variables: { token } }).catch((e) => {
                if (e.message === 'GraphQL error: invalid token') {
                    setDisplayMessage('Lien invalide.');
                } else {
                    setDisplayMessage('Un problème est survenu lors de la validation de cette adresse email.');
                }
            });

            if (result) {
                const {
                    data: { authenticateEmployeeValidation: response },
                } = result;

                if (response.success) {
                    setDisplayMessage("L'employé.e a été bien rattaché.e à votre entreprise");
                    toast.success("L'employé.e a été bien rattaché.e à votre entreprise", {
                        duration: 8000,
                    });
                    navigate('/settings/team', { relative: 'path' });
                } else {
                    setDisplayMessage(response.error);
                }
            }
        };
        if (!displayMessage) {
            verifyToken();
        }
    }, [displayMessage, token]);

    if (!displayMessage) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <EmployeeConfirmationContainer>{displayMessage}</EmployeeConfirmationContainer>
        </LoginStyle>
    );
};

const EmployeeConfirmationContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    padding: 0 5rem;
`;
