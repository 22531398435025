import styled from 'styled-components';
import { margin, padding } from 'polished';

export const NutritionFactsStyle = styled.section`
    flex-shrink: 0;
    width: 320px;
    ${(props) => margin(0, props.theme.variables.margin)};
    ${(props) =>
        padding(
            props.theme.variables.padding * 0.8,
            props.theme.variables.padding * 0.8,
            props.theme.variables.padding * 0.3,
            props.theme.variables.padding * 0.8,
        )};
    border: 1px solid ${(props) => props.theme.colors.black};
    user-select: none;
    zoom: 90%;

    table {
        border-collapse: collapse;
    }

    @media screen and (min-width: 1300px) {
        zoom: 100%;
    }
`;

export const NutritionFactsHeader = styled.header`
    ${(props) => padding(0, 0, props.theme.variables.padding * 0.5, 0)};
    ${(props) => margin(0, 0, props.theme.variables.margin * 0.5, 0)};
    border-bottom: 9px solid ${(props) => props.theme.colors.black};

    p {
        margin: 0;
    }
`;

export const NutritionFactsTitle = styled.h1`
    ${(props) => margin(0, 0, props.theme.variables.margin * 0.5, 0)};
    font-weight: 800;
    font-size: 1.5rem;
`;

export const NutritionFactsTable = styled.table`
    width: 100%;

    thead tr {
        th,
        td {
            border: 0;
        }
    }

    th,
    td {
        ${(props) => padding(props.theme.variables.padding * 0.2)};
        font-weight: 500;
        white-space: nowrap;
        text-align: left;
        border-top: 1px solid ${(props) => props.theme.colors.black};
    }

    tr {
        &:last-child {
            border-bottom: 8px solid ${(props) => props.theme.colors.black};
        }
    }

    td {
        &:last-child {
            text-align: right;
            border-bottom: 0.5px solid ${(props) => props.theme.colors.black};
        }
    }
`;

export const NutritionFactsSmallTable = styled(NutritionFactsTable)`
    ${(props) => margin(0, 0, props.theme.variables.margin * 0.5, 0)};
    border-bottom: 1px solid #999;

    thead {
        tr {
            border-bottom: 1px solid ${(props) => props.theme.colors.black};
        }
    }

    td {
        &:last-child {
            text-align: left;
        }
    }

    th,
    td {
        ${(props) =>
            padding(
                props.theme.variables.padding * 0.2,
                props.theme.variables.padding * 0,
                props.theme.variables.padding * 0,
                props.theme.variables.padding * 0.3,
            )};
        border: 0 !important;
    }
    font-size: 0.8rem;
`;

export const NutritionFactsTeneur = styled.th`
    font-size: 0.9rem;
    padding-top: 0 !important;
`;

export const NutritionFactsSmallPar = styled.p`
    font-size: 0.9rem;
`;

export const NutritionFactsSmallTd = styled.td`
    font-size: 0.9rem;
`;

export const NutritionFactsBlankTd = styled.td`
    width: 1rem;
    border-top: 0;
`;

export const NutritionFactsMediumBottomRow = styled.tr`
    border-bottom: 4px solid ${(props) => props.theme.colors.black};
`;
