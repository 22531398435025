import React, { useContext } from 'react';
import { IconEditCircle } from '@tabler/icons-react';
import styled from 'styled-components';

import { ModalContext } from 'contexts/ModalContext';

import { TotemButton, IconButton } from 'ui/Button';
import { BudgetSettingsPopup } from './BudgetSettingsPopup';

import { DashboardTitle, TotemCard } from 'styles/pages/totem.styles';
import { BudgetDetailInfo } from 'pages/Totem/TotemDashboard/Budget/BudgetDetailInfo';

import { analyticsEvents, track } from 'tracking/segment';

export const BudgetCard = ({ totemId, totemMonthlyBudget }) => {
    const { setIsModalOpen, setModal } = useContext(ModalContext);

    return (
        <TotemCard $marginMultiplier={[0, 3, 1.5, 3]}>
            <CardLine>
                <TotemDashboardTitle>
                    <DashboardTitle $marginMultiplier={[0, 1.5, 0, 0]}>Mon budget mensuel</DashboardTitle>
                    {totemMonthlyBudget && (
                        <IconButton
                            onClick={() => {
                                track(analyticsEvents.OPEN_TOTEM_BUDGET_MODAL);
                                setModal(
                                    <BudgetSettingsPopup
                                        totemId={totemId}
                                        totemMonthlyBudget={totemMonthlyBudget}
                                        setIsModalOpen={setIsModalOpen}
                                    />,
                                );
                                setIsModalOpen(true);
                            }}
                        >
                            <IconEditCircle stroke={2.5} />
                        </IconButton>
                    )}
                </TotemDashboardTitle>
                {!totemMonthlyBudget ? (
                    <TotemButton
                        $alignSelf="flex-end"
                        $flex="none"
                        onClick={() => {
                            track(analyticsEvents.OPEN_TOTEM_BUDGET_MODAL);
                            setModal(
                                <BudgetSettingsPopup
                                    totemId={totemId}
                                    totemMonthlyBudget={totemMonthlyBudget}
                                    setIsModalOpen={setIsModalOpen}
                                />,
                            );
                            setIsModalOpen(true);
                        }}
                    >
                        Définir mon budget
                    </TotemButton>
                ) : (
                    <BudgetDetailInfo date={new Date()} totemId={totemId} totemMonthlyBudget={totemMonthlyBudget} />
                )}
            </CardLine>
        </TotemCard>
    );
};

const CardLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const TotemDashboardTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    align-self: start;
    button {
        transform: translateY(-3px);
    }
`;
