import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Building } from '@styled-icons/boxicons-regular';
import { Flex } from 'deprecated-enkel';
import dayjs from 'dayjs';
import { ClipLoader } from 'react-spinners';
import styled, { css } from 'styled-components';

import { UserContext } from 'constants/user-context';

import { UPDATE_ORGANIZATION_FIELDS } from 'data/mutations/organization.mutations';

import { ORGANIZATIONS_IMAGE_BASE_URL } from 'constants/url-constants';

import { ImageDragAndDrop } from 'components/ImageDragAndDrop';
import { TotemButton } from 'ui/Button';

import { TotemLabel, TotemInput } from 'styles';
import {
    SettingsPageStyle,
    SettingsCard,
    SettingsTitleContainer,
    SettingsTitle,
    SettingsTitleContainerWrapper,
    SettingsForm,
    SettingsLeftHalf,
    SettingsRightHalf,
    OptionText,
} from 'styles/pages/settings.styles';

import { handleTextInput, objectIsInObject } from 'utils';

import { analyticsEvents, track } from 'tracking/segment';

export const SettingsPageOrganization = () => {
    const { organization } = useContext(UserContext);
    const [updateOrganizationFields, { loading }] = useMutation(UPDATE_ORGANIZATION_FIELDS);
    const [organizationValues, setOrganizationValues] = useState({
        attachUsersManually: organization.attachUsersManually,
    });

    const handleOrganizationChange = async (e) => {
        e.preventDefault();

        try {
            await updateOrganizationFields({
                variables: {
                    organizationId: organization._id,
                    data: { ...organizationValues },
                },
            });
            track(analyticsEvents.UPDATE_ORGANIZATION_INFO);
            if (typeof organizationValues.imageUrl === 'object') {
                organizationValues.imageUrl = ORGANIZATIONS_IMAGE_BASE_URL + organizationValues.image.name;
            }
        } catch (error) {
            window.localStorage.error(error);
        }
    };

    const hasChanged = Boolean(
        Object.keys(organizationValues).length > 0 &&
            !objectIsInObject(organization, organizationValues) &&
            organization.imageUrl !== ORGANIZATIONS_IMAGE_BASE_URL + organizationValues?.image?.name &&
            organizationValues.name !== '',
    );

    const handleAttachUsersUpdate = (e, value) => {
        e.preventDefault();
        setOrganizationValues({ ...organizationValues, attachUsersManually: value });
    };

    return (
        <SettingsPageStyle>
            <SettingsCard>
                <SettingsForm onSubmit={handleOrganizationChange}>
                    <SettingsTitleContainerWrapper>
                        <SettingsTitleContainer>
                            <Building />
                            <SettingsTitle>Mon entreprise</SettingsTitle>
                        </SettingsTitleContainer>
                        <TotemButton
                            $alignSelf="flex-end"
                            type="submit"
                            disabled={!hasChanged}
                            $isLoading={loading}
                            $fontSize="1em"
                        >
                            {loading ? <ClipLoader color="white" size="16px" /> : 'Mettre à jour'}
                        </TotemButton>
                    </SettingsTitleContainerWrapper>
                    <Flex $flow="row" $wrap="wrap" $alignItems="flex-start">
                        <SettingsLeftHalf $width="232px" $flexShrink={0}>
                            <ImageDragAndDrop
                                imageUrl={organization.imageUrl}
                                label="Logo"
                                values={organizationValues}
                                setValues={setOrganizationValues}
                            />
                        </SettingsLeftHalf>
                        <SettingsRightHalf $width="100%">
                            <TotemLabel>
                                Nom de l'entreprise
                                <TotemInput
                                    placeholder="Entrez le nom de l'entreprise"
                                    name="name"
                                    defaultValue={organization.name}
                                    onChange={(e) => handleTextInput(e, setOrganizationValues, organizationValues)}
                                    data-test="organization-name"
                                />
                            </TotemLabel>
                            {organization?.acceptedTerms?.areAccepted && (
                                <TermsAcceptanceDate>
                                    Date d'acceptation des conditions générales de vente :{' '}
                                    {dayjs(organization?.acceptedTerms.acceptanceDate).format('DD/MM/YYYY')}
                                </TermsAcceptanceDate>
                            )}
                            <Option>
                                <OptionText>
                                    Je souhaite valider manuellement les adresses mail des personnes inscrites avec{' '}
                                    {organization.domains.map((domain) => `'@${domain}'`).join(', ')} pour qu'ils
                                    bénéficient des subventions
                                </OptionText>
                                <Flex>
                                    <OptionButton
                                        $fontSize="0.75em"
                                        $margins={[0, 0.5, 0, 0]}
                                        $selected={organizationValues.attachUsersManually}
                                        onClick={(e) => handleAttachUsersUpdate(e, true)}
                                    >
                                        Oui
                                    </OptionButton>
                                    <OptionButton
                                        $type="secondary"
                                        $fontSize="0.75em"
                                        $selected={!organizationValues.attachUsersManually}
                                        onClick={(e) => handleAttachUsersUpdate(e, false)}
                                    >
                                        Non
                                    </OptionButton>
                                </Flex>
                            </Option>
                        </SettingsRightHalf>
                    </Flex>
                </SettingsForm>
            </SettingsCard>
        </SettingsPageStyle>
    );
};

const Option = styled.div`
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1300px) {
        margin: 0 0 ${({ theme }) => theme.variables.margin}px 0;
    }
`;

const OptionButton = styled(TotemButton)`
    ${({ $selected, theme: { colors } }) =>
        !$selected &&
        css`
            border: 1px solid ${colors.borderGrey};
            color: ${colors.pantinGrey};
            background: ${colors.white};

            &:hover {
                border: 1px solid ${colors.borderGrey};
                color: ${colors.pantinGrey};
                background: ${colors.white};
            }
        `}
`;

const TermsAcceptanceDate = styled.div`
    color: ${({ theme: { colors } }) => colors.pantinGrey};
`;
