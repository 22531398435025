import styled, { css } from 'styled-components';
import { margin, padding, transparentize } from 'polished';
import { Link } from 'react-router-dom';
import { Card as UiCard, Button, Flex } from 'deprecated-enkel';
import { Plus, Minus, X } from 'styled-icons/boxicons-regular';
import { ArrowUp, ArrowDown } from 'styled-icons/fa-solid';

import { breakpointMixin } from 'utils';

export const QuantityInputStyle = styled.input`
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-size: 1.75em;
    text-align: center;
    background: ${(props) => (props.transparent ? 'transparent' : 'white')};
    border: none;

    border: ${(props) => (props.transparent ? 'none' : `2px solid ${props.theme.colors.black}`)};

    min-width: 0px;
    width: 100%;
    height: 100%;
    border-radius: ${(props) => props.theme.variables.borderRadius};

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }

    ::placeholder {
        color: #e7e7ea;
    }
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: ${(props) => transparentize(0.85, props.theme.colors.black)};
`;

export const Card = styled(UiCard.Style)`
    border-radius: ${({ theme }) => theme.variables.borderRadius};
    position: relative;
    ${(props) =>
        margin(props.theme.variables.margin * 0.5, props.theme.variables.margin * 0.5, props.theme.variables.margin)};
    overflow: hidden;
    background: ${(props) => props.theme.colors.white};

    ${breakpointMixin('100%', 0, 850)};
    ${breakpointMixin('50%', 851, 1124)};
    ${breakpointMixin('33.333333333%', 1125, 1280)};
    ${breakpointMixin('25%', 1281, 2400)};
    ${breakpointMixin('20%', 2401)};

    .card-product__info {
        width: max-content;
        transition: all 0.5s ease;
    }
`;

export const CardBottom = styled(Flex.Style)`
    position: relative;
    height: 100%;
    flex-flow: column;
`;

export const CardInner = styled(Link)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: ${(props) => props.theme.colors.white};
    font-size: 1.35rem;
    font-weight: 800;
    background: ${(props) => (props.$hasBackground ? transparentize(0.5, props.theme.colors.black) : 'transparent')};
    opacity: 0;
    transition: all 0.5s ease;

    &:link,
    &:active,
    &:hover,
    &:visited {
        text-decoration: none;
    }

    > svg {
        ${(props) => margin(0, props.theme.variables.margin, 0, 0)}
    }
`;

export const CardContent = styled(UiCard.Body.Style)`
    position: relative;
    display: block;
    height: ${(props) => props.height || '100%'};
    cursor: ${({ $cursorProp }) => ($cursorProp ? $cursorProp : 'default')};

    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};
    ${({
        theme: {
            variables: { padding: pad },
        },
        $paddingMultiplier = [],
    }) =>
        padding(
            pad * ($paddingMultiplier[0] || 0),
            pad * ($paddingMultiplier[1] || 0),
            pad * ($paddingMultiplier[2] || 0),
            pad * ($paddingMultiplier[3] || 0),
        )};
    ${(props) => props.align && `text-align: ${props.align}`};
`;

export const CardTitle = styled(UiCard.Title.Style)`
    margin: 0;
    font-weight: 500;
    font-size: 1.2em;
    flex: 1;
    ${({ fontSize }) => `font-size: ${fontSize};`}
    ${({ fontWeight }) => `font-weight: ${fontWeight};`}
`;

export const CardSubtitle = styled(UiCard.Title.Style)`
    margin: 0;
    color: ${(props) => props.theme.colors.pantinGrey};
    font-weight: 500;
`;

export const CardPackagingDisplay = styled(UiCard.Title.Style)`
    margin: 0;
    color: ${(props) => props.theme.colors.pantinGrey};
    font-weight: 500;
    ${({ leftMargin }) => leftMargin && `margin-left: ${leftMargin}`}
`;

export const CardOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
        props.$transparent
            ? 'unset'
            : props.light
              ? transparentize(0.8, props.theme.colors.black)
              : transparentize(0.5, props.theme.colors.black)};

    &:hover {
        .card-product__link {
            ${(props) => !props.$shouldHideDetails && 'opacity: 1'};
        }

        .card-product__info {
            ${(props) => !props.$shouldHideDetails && 'opacity: 0'};
        }
    }
`;

export const CardOverlayTitle = styled.span`
    color: white;
    text-align: center;
    font-weight: 800;
    font-size: ${(props) => props.fontSize || '1.9em'};
`;

export const CardOverlayDisplayUnit = styled.span`
    color: white;
`;

export const DisplayUnit = styled.span`
    color: white;
    font-weight: 800;
    font-size: 2.75em;
`;

export const DisplaySubUnit = styled.span`
    color: white;
    font-weight: 500;
    font-size: 1.75em;
`;

export const CardOverlaySubtitle = styled.span`
    color: white;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: uppercase;
`;

export const CardPriceGroup = styled(Flex.Style)`
    ${(props) => padding(props.theme.variables.padding * 0.5)};
    font-size: 0.7em;
`;

export const CardPriceEuro = styled(Flex.Style)`
    color: ${({ $isCrossed, theme }) => ($isCrossed ? theme.colors.pantinGrey : theme.colors.black)};
    align-items: center;
    font-weight: 500;
    font-size: 1.7rem;
    text-transform: none;
    margin-right: ${({ $isCrossed }) => ($isCrossed ? 5 : 2)}px;
    text-decoration: ${({ $isCrossed }) => ($isCrossed ? 'line-through' : 'none')};
`;

export const CardPriceCents = styled(Flex.Style)`
    color: ${(props) => props.theme.colors.black};
    align-items: center;
    font-size: 0.6rem;
    position: relative;
    top: -1px;
    font-weight: normal;
    text-transform: none;
`;

export const CardDescription = styled(CardContent)`
    color: ${(props) => props.theme.colors.pantinGrey};
    font-weight: 500;
    font-size: 1rem;
    ${(props) => margin(props.theme.variables.margin * 0.25, props.theme.variables.margin * 0.5)};

    > span {
        color: ${(props) => props.theme.colors.black};
    }
    ${({ $cursorProp }) => $cursorProp && `cursor:${$cursorProp}`}
`;

export const CardActionButton = styled(Button.Style)`
    display: inline-flex;
    flex-shrink: ${(props) => Number(!props.noShrink)};
    width: ${(props) => (props.noShrink ? 'max-content' : '100%')};
    height: 100%;
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
    min-height: 32px;
    margin: 0;
    font-weight: 800;
    font-size: ${(props) => props.fontSize || '1em'};
    letter-spacing: 0.5px;
    text-transform: uppercase;
    background-color: ${({ backgroundColor, theme: { colors } }) => colors[backgroundColor] || colors.green};
    color: ${({ theme: { colors } }) => colors.black};
    border: 0;
    border-radius: ${(props) =>
        props.$borderRadius
            ? props.$borderRadius.map((o) => (o ? props.theme.variables.borderRadius : 0)).join(' ')
            : props.theme.variables.borderRadius};
    transition: all 0.25s ease;

    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};

    &:hover {
        border: 0;
    }

    ${({ theme: { colors } }) => css`
        &:hover {
            background: ${colors.greenDarker};
            color: ${colors.black};

            svg {
                color: ${colors.black};
            }
        }
    `}

    ${(props) =>
        props.selected &&
        css`
            background: ${props.theme.colors.greenDarker};
            color: ${props.theme.colors.black};
            svg {
                color: ${(props) => props.theme.colors.black};
            }
        `};

    :disabled {
        ${(props) =>
            (props.plan || !props.$isLoading) &&
            css`
                background: ${(props) => props.theme.colors.lightGrey};
                border: 1px solid ${(props) => props.theme.colors.lightGrey};
            `}
        pointer-events: none;
        cursor: not-allowed;
    }
`;

export const CardButtonGroup = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: ${(props) => props.$justifyContent || 'space-between'};
    height: ${(props) => props.height || '55px'};
    ${(props) => !props.$noPadding && padding(props.theme.variables.padding * 0.5)};
    border: ${(props) => (props.$noBorder ? 0 : `1px solid ${props.theme.colors.border}`)};
    border-radius: ${(props) =>
        props.$borderRadius
            ? props.$borderRadius.map((o) => (o ? props.theme.variables.borderRadius : 0)).join(' ')
            : props.theme.variables.borderRadius};

    > span {
        min-width: 15px;
        ${(props) => margin(0, props.theme.variables.margin * 0.5)};
        color: ${(props) => props.theme.colors.black};
        font-weight: 500;
        font-size: 1em;
        text-align: center;
    }
`;

export const CardBottomContent = styled(Flex.Style)`
    height: 100%;
`;

export const MinusIcon = styled(Minus)`
    color: white;
`;

export const PlusIcon = styled(Plus)`
    color: ${(props) => (props.dark ? props.theme.colors.black : 'white')};
`;

export const SuggestIconContainer = styled(Flex.Style)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.culturedGrey};
    transition: all 0.35s;

    &:hover {
        cursor: pointer;
        transform: scale(1.25);
    }
`;

export const SuggestIcon = styled(Plus)`
    color: white;
    background-color: ${(props) => props.theme.colors.black};
    border-radius: 999px;
`;

export const RemoveIcon = styled(X)`
    color: white;
    background-color: ${(props) => props.theme.colors.black};
    border-radius: 999px;
    cursor: pointer;
    transition: all 0.25s ease;

    &:hover {
        color: ${(props) => props.theme.colors.black};
        background-color: ${(props) => props.theme.colors.yellow};
    }
`;

export const CardTagsStyle = styled(CardContent)`
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    ${(props) => padding(props.theme.variables.padding * 0.5)};
    height: max-content;
    ${({ $cursorProp }) => $cursorProp && `cursor:${$cursorProp}`}
`;

export const CardStock = styled(Flex)`
    position: relative;
    overflow: hidden;
    min-height: max-content;
    ${(props) => margin(props.theme.variables.margin * -2, props.theme.variables.margin, props.theme.variables.margin)};
    ${(props) => padding(props.theme.variables.margin * 0.5)};
    background: ${(props) => props.theme.colors.white};
    border-radius: ${(props) => props.theme.variables.borderRadius};
    box-shadow: 0 2px 7px 1px ${(props) => transparentize(0.65, props.theme.colors.black)};
`;

export const CardOverlayContainer = styled(Flex.Style)`
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    ${(props) => padding(props.theme.variables.padding)};
`;

export const CardOverlayPortions = styled(Flex.Style)`
    position: absolute;
    bottom: -20px;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 500;
    align-items: center;
    letter-spacing: 0.2px;
    ${(props) => margin(props.theme.variables.margin * 0.5, 0, 0)};
    ${(props) =>
        padding(
            props.theme.variables.padding * 0.4,
            props.theme.variables.padding,
            props.theme.variables.padding * 0.4,
            props.theme.variables.padding * 0.5,
        )};
    color: white;
    background: ${(props) => transparentize(0.6, props.theme.colors.black)};
    border-radius: ${(props) => props.theme.variables.borderRadius};

    &:hover {
        cursor: pointer;
    }
`;

export const ArrowUpIcon = styled(ArrowUp)`
    color: ${(props) => props.theme.colors.green};
    margin-right: 3px;
    margin-bottom: 2px;
`;

export const ArrowDownIcon = styled(ArrowDown)`
    color: ${(props) => props.theme.colors.red};
    margin-right: 3px;
    margin-bottom: 2px;
`;

export const CardOverlayText = styled.div`
    font-size: 1.2em;
    font-weight: 500;
    color: white;
    letter-spacing: 0.2px;
    ${(props) =>
        props.title &&
        css`
            font-size: 1.8em;
            text-transform: uppercase;
        `};
`;

export const ComeBackButton = styled(Button.Style)`
    align-items: center;
    height: 25px;
    font-size: 0.7em;
    font-weight: 500;
    letter-spacing: -0.6px;
    text-transform: uppercase;
    z-index: 2;

    ${(props) => padding(props.theme.variables.padding * 0.2, props.theme.variables.padding * 0.8)};
    ${(props) => margin(props.theme.variables.margin * 0.5, 0, 0)};

    &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.black};
        background-color: ${(props) => props.theme.colors.yellow};
    }
`;
