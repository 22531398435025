//fruit names like raisin and blueberries
export const UNCOUNTABLE_PRODUCT_IDS = [
    'fX7PkpLxBHW3FJ2Gx', //raisin blanc
    'uBxGmSNDruYoPdf2L', //raisin noir
    'mjcy9pJrfQBw2xEfa', //myrtille
    'qHcznS7D9iR45D9xc', //framboise
    'GCNS9fmuCBdwwYoYc', //fraise
    '9BvsRAH6hdxy8h7cj', //fraise agricool
];

export const PRODUCT_FLOW = {
    DRY: 'dry',
    BAKERY: 'bakery',
    FRESH: 'fresh',
    FRUIT: 'fruit',
};

export const PRODUCT_FLOW_LABEL = {
    [PRODUCT_FLOW.DRY]: 'Épicerie',
    [PRODUCT_FLOW.BAKERY]: 'Matin',
    [PRODUCT_FLOW.FRESH]: 'Produits Frais',
    [PRODUCT_FLOW.FRUIT]: 'Fruits',
};
