import { colors } from 'styles/theme';

export const TOTEM_ICONS = [
    { id: '015', name: 'burger', color: colors.pink },
    { id: '02', name: 'cookie', color: colors.green },
    { id: '05', name: 'bagel', color: colors.pink },
    { id: '07', name: 'maki', color: colors.yellow },
    { id: '04', name: 'lemon', color: colors.green },
    { id: '09', name: 'apple', color: colors.pink },
];
