import { GET_ORDERS_OF_TOTEM } from 'data/queries';

export function orderDuplicatedCacheHandler({
    apolloClient,
    destinationTotemId,
    orderBeingReplaced,
    ordersOfDestinationTotem,
    newOrder,
}) {
    if (!ordersOfDestinationTotem) {
        // if the destination's totem orders aren't in the cache, no need to update the cache (will be updated)
        return;
    }
    let updatedRecentOrders;
    if (orderBeingReplaced) {
        updatedRecentOrders = ordersOfDestinationTotem.recentOrders.map((order) => {
            if (order._id === orderBeingReplaced._id) {
                return newOrder;
            }
            return order;
        });
    } else {
        updatedRecentOrders = [...ordersOfDestinationTotem.recentOrders, newOrder];
    }
    apolloClient.writeQuery({
        query: GET_ORDERS_OF_TOTEM,
        variables: { totemId: destinationTotemId },
        data: {
            ordersOfTotem: {
                ...ordersOfDestinationTotem,
                recentOrders: updatedRecentOrders,
            },
        },
    });
}
