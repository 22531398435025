import React, { useState, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { IconArrowLeft, IconBuildingBank, IconCreditCard, IconCirclePlus, IconCircleCheck } from '@tabler/icons-react';
import { useNavigate } from 'react-router';

import styled from 'styled-components';

import { AsideBody, AsideHeader, AsideSubTitle, AsideDescription } from 'pages/Totem/Aside/AsideComponents';
import { getStripeApiKey } from 'pages/Settings/SettingsPage/SettingsPayment/utils';
import { CreditCardForm } from 'pages/Totem/Aside/Billing/CreditCardForm';
import { IBANForm } from 'pages/Totem/Aside/Billing/IBANForm';
import { BillingInfoForm } from 'pages/Totem/Aside/Billing/BillingInfoForm';
import { BankTransferForm } from 'pages/Totem/Aside/Billing/BankTransferForm';

import { UserContext } from 'constants/user-context';
import { BANK_TRANSFER, STRIPE_CARD, STRIPE_SEPA_DEBIT } from 'constants/organization';

import { IconButton, TotemButton } from 'ui/Button';

import { colors } from 'styles/theme';

import { capitalize } from 'utils';

export const BillingContainer = ({ currentTotem, setIsBillingAsideOpen }) => {
    const ICON_SIZE = '18px';
    const navigate = useNavigate();
    const [selectedPaymentType, setselectedPaymentType] = useState(undefined);
    const { organization, user } = useContext(UserContext);
    // update this
    const { cards, billingInfo, paymentType } = organization;
    const defaultSourceObject = cards?.find((card) => card.id === billingInfo?.defaultPaymentMethod);
    const [stripePromise] = useState(() => loadStripe(getStripeApiKey(organization)));
    const isCreditCardActive = selectedPaymentType === 'creditCard';
    const isIBANActive = selectedPaymentType === 'IBAN';
    const isBankTransferActive = selectedPaymentType === 'bankTransfer';
    const billingRequiredInfo = [
        'invoiceEmail',
        'invoiceName',
        'invoiceAddress',
        'invoiceCity',
        'invoiceZipcode',
        'invoiceCountry',
    ];

    const hasBillingInfo =
        organization.billingInfo && billingRequiredInfo.every((prop) => !!organization.billingInfo[prop]);
    const [isModifyingBillingInfo, setIsModifyingBillingInfo] = useState(!hasBillingInfo);
    return (
        <>
            <AsideHeader>
                <IconButton data-test="aside-return-button" $white $large onClick={() => setIsBillingAsideOpen(false)}>
                    <IconArrowLeft size={25} />
                </IconButton>
            </AsideHeader>
            <AsideBody>
                <AsideSubTitle>
                    1 - Informations de facturation{' '}
                    {hasBillingInfo && <IconCircleCheck size="22px" color={colors.green} />}
                </AsideSubTitle>
                {isModifyingBillingInfo ? (
                    <BillingInfoContainer>
                        <BillingInfoForm
                            currentTotem={currentTotem}
                            currentUser={user}
                            organization={organization}
                            setIsModifyingBillingInfo={setIsModifyingBillingInfo}
                        />
                    </BillingInfoContainer>
                ) : (
                    <TotemButton
                        $type="secondary"
                        $margins={[0, 2]}
                        data-test="modify-billing-info"
                        $fontSize="1em"
                        onClick={() => setIsModifyingBillingInfo(true)}
                    >
                        Modifier les informations de facturation
                    </TotemButton>
                )}
                <AsideSubTitle>
                    2 - Moyen de paiement{' '}
                    {billingInfo?.defaultPaymentMethod && defaultSourceObject && (
                        <IconCircleCheck size="22px" color={colors.green} />
                    )}
                </AsideSubTitle>
                {!hasBillingInfo ? (
                    <AsideDescription>
                        Veuillez ajouter vos informations de facturation avant d'ajouter un moyen de paiement.
                    </AsideDescription>
                ) : (billingInfo?.defaultPaymentMethod && defaultSourceObject) || paymentType === BANK_TRANSFER ? (
                    <>
                        <PaymentTypeToggle>
                            <PaymentOption as="div" $clickable={false}>
                                <SelectLine>
                                    <NameAndIcon>
                                        {defaultSourceObject?.type === STRIPE_SEPA_DEBIT ||
                                        paymentType === BANK_TRANSFER ? (
                                            <IconBuildingBank size={ICON_SIZE} />
                                        ) : (
                                            <IconCreditCard size={ICON_SIZE} />
                                        )}
                                        {paymentType === BANK_TRANSFER
                                            ? 'Virement bancaire'
                                            : billingInfo?.defaultPaymentMethod
                                              ? defaultSourceObject?.type === STRIPE_SEPA_DEBIT
                                                  ? 'SEPA (Virement bancaire)'
                                                  : defaultSourceObject?.type === STRIPE_CARD &&
                                                    `${capitalize(
                                                        defaultSourceObject.card?.brand || defaultSourceObject.brand,
                                                    )}`
                                              : 'Informations de facturation'}
                                    </NameAndIcon>
                                    {defaultSourceObject?.type === STRIPE_CARD &&
                                        `• • • • • • • • • • • • ${
                                            defaultSourceObject.card?.last4 || defaultSourceObject.last4
                                        }`}
                                </SelectLine>
                            </PaymentOption>
                        </PaymentTypeToggle>
                        <TotemButton
                            $type="secondary"
                            $margins={[0, 2]}
                            $fontSize="1em"
                            onClick={() => navigate('/settings/payment', { relative: 'path' })}
                        >
                            Modifier le moyen de paiement utilisé
                        </TotemButton>
                    </>
                ) : (
                    <Elements stripe={stripePromise}>
                        <PaymentTypeToggle>
                            <PaymentOption
                                $clickable={!isCreditCardActive}
                                data-test="payment-methods-credit-card-button"
                                onClick={() => setselectedPaymentType('creditCard')}
                            >
                                <SelectLine>
                                    <NameAndIcon>
                                        <IconCreditCard size={ICON_SIZE} />
                                        Carte bancaire
                                    </NameAndIcon>
                                    {selectedPaymentType !== 'creditCard' && <IconCirclePlus size={ICON_SIZE} />}
                                </SelectLine>
                                {selectedPaymentType === 'creditCard' && (
                                    <CreditCardForm
                                        organization={organization}
                                        currentUser={user}
                                        onSubmitCompletion={() => setIsBillingAsideOpen(false)}
                                    />
                                )}
                            </PaymentOption>
                            <PaymentOption
                                $clickable={!isIBANActive}
                                data-test="payment-methods-iban-button"
                                onClick={() => setselectedPaymentType('IBAN')}
                            >
                                <SelectLine>
                                    <NameAndIcon>
                                        <IconBuildingBank size={ICON_SIZE} />
                                        IBAN
                                    </NameAndIcon>
                                    {selectedPaymentType !== 'IBAN' && <IconCirclePlus size={ICON_SIZE} />}
                                </SelectLine>
                                {selectedPaymentType === 'IBAN' && (
                                    <IBANForm
                                        organization={organization}
                                        currentUser={user}
                                        onSubmitCompletion={() => setIsBillingAsideOpen(false)}
                                    />
                                )}
                            </PaymentOption>
                            {(window.__TOTENV__.E2E || window.__TOTENV__.ENV === ('development' || 'cypress')) && (
                                <PaymentOption
                                    $clickable={!isBankTransferActive}
                                    data-test="payment-methods-bank-transfer-button"
                                    onClick={() => setselectedPaymentType('bankTransfer')}
                                >
                                    <SelectLine>
                                        <NameAndIcon>
                                            <IconBuildingBank size={ICON_SIZE} />
                                            Virement
                                        </NameAndIcon>
                                        {selectedPaymentType !== 'bankTransfer' && <IconCirclePlus size={ICON_SIZE} />}
                                    </SelectLine>
                                    {selectedPaymentType === 'bankTransfer' && (
                                        <BankTransferForm
                                            organization={organization}
                                            setIsBillingAsideOpen={setIsBillingAsideOpen}
                                        />
                                    )}
                                </PaymentOption>
                            )}
                        </PaymentTypeToggle>
                    </Elements>
                )}
            </AsideBody>
        </>
    );
};

const BillingInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 2em;
`;

const PaymentTypeToggle = styled.div`
    padding: 0 2em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const SelectLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NameAndIcon = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    svg {
        margin-right: 5px;
    }
`;

const PaymentOption = styled.div`
    background-color: white;
    border-radius: ${({ theme }) => theme.variables.borderRadius};
    width: 100%;
    padding: 1em;
    cursor: ${({ $clickable }) => $clickable && 'pointer'};
    margin-bottom: 1em;
`;
