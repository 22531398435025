import React, { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { IconSettings } from '@tabler/icons-react';
import { AuthContext } from '@totem/auth';
import { User } from '@totem/roles';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled, { keyframes } from 'styled-components';

import { SPYEE_TOKEN } from 'constants';
import { TOTEM_ORGANIZATION_ID } from 'constants/organization';

import { Tooltip } from 'styles';
import { DropdownContainer } from 'styles/wrappers/navbar.styles';
import { IconContainer } from './NavBar';
import { FadeIn } from 'components/FadeIn';

import { analyticsEvents, track } from 'tracking/segment';

export const AccountMenu = ({ open, setOpen, setOverlayOpen }) => {
    const client = useApolloClient();
    const { authActions } = useContext(AuthContext);
    const { logout } = authActions;

    // eslint-disable-next-line
    const { show } = window.__TOTENV__.E2E ? { show : () => {} } : useIntercom();

    const handleMouseDown = () => {
        track(analyticsEvents.OPEN_NAVBAR_OVERLAY);
        setOpen(true);
        setOverlayOpen(true);
    };

    const handleSelect = () => {
        setOpen(false);
        setOverlayOpen(false);
    };

    const handleLogout = async () => {
        track(analyticsEvents.LOG_OUT);
        logout();
        client.resetStore();
    };

    const handleSpy = async () => {
        if (window.TOTEM.storage.get(SPYEE_TOKEN)) {
            await window.TOTEM.storage.remove(SPYEE_TOKEN);
            window.location.replace('/spying');
        } else {
            window.location.replace('/spying');
        }
    };

    return (
        <>
            <IconContainer onMouseDown={handleMouseDown} data-test="icon-settings">
                <IconItem data-for="accountTooltip" data-tip="Mon compte">
                    <IconSettings />
                </IconItem>
                <Tooltip id="accountTooltip" type="dark" effect="solid" place="right" />
            </IconContainer>
            {open && (
                <FadeIn duration={500}>
                    <DropdownContainer
                        $fontSize="0.95rem"
                        $lowercase
                        $paddingMultiplier={[1.5, 1.5, 1.5, 1.5]}
                        onClick={handleSelect}
                    >
                        <ul>
                            {(User.isInRole('Toteam', 'global') ||
                                User.isInRole('Totech', 'global') ||
                                User.get().profile.organizationId === TOTEM_ORGANIZATION_ID) && (
                                <SuperSpyFeature onClick={handleSpy}>Espionner un client</SuperSpyFeature>
                            )}
                            {window.TOTEM.storage.get(SPYEE_TOKEN) && (
                                <SuperSpyFeature onClick={handleSpy}>Arrêter d'espionner</SuperSpyFeature>
                            )}
                            <Link to="/settings/account" data-test="link-to-parameters-page">
                                <li>Paramètres</li>
                            </Link>
                            <li>
                                <StyleLessButton onClick={show}>Demander de l'aide à un conseiller</StyleLessButton>
                            </li>
                            <li onClick={handleLogout}>Se déconnecter</li>
                        </ul>
                    </DropdownContainer>
                </FadeIn>
            )}
        </>
    );
};

const StyleLessButton = styled.button`
    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;
`;

const colorRotate = keyframes`
    from {
            color: #6666ff;
        }
        10% {
            color: #0099ff;
        }
        50% {
            color: #00ff00;
        }
        75% {
            color: #ff3399;
        }
        100% {
            color: #6666ff;
        }
`;

const SuperSpyFeature = styled.li`
    font-weight: 800;
    animation: ${colorRotate} 3s linear 0s infinite;
`;

const IconItem = styled.div`
    background-color: ${({ theme }) => theme.colors.green};
    &:hover {
        background-color: ${({ theme }) => theme.colors.greenDarker};
    }
    color: ${({ theme }) => theme.colors.black};
    border-radius: ${({ theme }) => theme.variables.borderRadius};
    border: 2px solid ${({ theme }) => theme.colors.greenDarker};
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
