export const startDeliveryRange = ['07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00'];

export const stopDeliveryRange = [
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
];

export const SLOT_INTERVAL_DURATION = 0.5; // In hour, the duration of one pickable slot
export const MIN_SLOTS_IN_DELIVERY_WINDOW = 2; // We want the customer to pick a delivery window of at least 1 hour (2 * 30 min slot)
export const MIN_SLOT_INTERVAL_DURATION = (MIN_SLOTS_IN_DELIVERY_WINDOW - 1) * SLOT_INTERVAL_DURATION; // The minimum nb of hours betwen the start and the end of a delivery slot

export const MIN_FREE_INTERVAL_DURATION = 3;
