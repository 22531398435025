import dayjs from 'dayjs';

import { FORMAT } from 'constants/date-constants';

import { capitalize } from 'utils';

export const FormattedDeliveryDate = ({ deliveryDate, shouldCapitalize = true }) => {
    if (!deliveryDate) {
        return 'Pas de date sélectionnée';
    }
    const now = new Date();
    const dayJsDeliveryDate = dayjs(deliveryDate, FORMAT);
    const translatedWeekday = dayJsDeliveryDate.format('dddd');
    const dateDisplayFormat = dayJsDeliveryDate.$y === now.getFullYear() ? 'D MMMM' : 'D MMMM YYYY';
    const formattedDate = `${
        shouldCapitalize ? capitalize(translatedWeekday) : translatedWeekday
    } ${dayJsDeliveryDate.format(dateDisplayFormat)}`;
    return formattedDate;
};
