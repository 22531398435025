import { colors } from 'styles/theme';

export const smallSelectStyle = {
    option: (provided, state) => ({
        ...provided,
        color: (state.isSelected || state.isFocused) && colors.black,
        backgroundColor: state.isFocused && colors.culturedGrey,
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? colors.blueOutline : colors.borderGrey,
        boxShadow: state.isFocused && `0 0 0 1px ${colors.blueOutline}`,
        ':hover': {
            borderColor: colors.blueOutline,
        },
    }),
};
