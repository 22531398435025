import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '@totem/auth';

import { AUTHENTICATE_USER } from 'data/mutations';

import { SetPasswordInfo } from 'pages/SetPassword/SetPasswordInfo';
import { SetPasswordInner } from 'pages/SetPassword/SetPasswordInner';
import { Loading } from 'components/Loading';
import { Logo } from 'components/Logo';

import { LoginBottomHalfStyle, LoginStyle, LoginTopHalfStyle, LoginWrapperStyle } from 'styles/pages/login.styles';
import { TotemLoadingStyle } from 'styles/pages/totem.styles';
import { TOKEN } from 'constants';

export const SetPassword = (props) => {
    const { setSkip } = props;
    const navigate = useNavigate();
    const { stepId } = useParams();

    const {
        authActions: { setPassword },
    } = useContext(AuthContext);
    const [verifyUser, setVerifyUser] = useState(null);
    const [authenticateUser] = useMutation(AUTHENTICATE_USER);
    const token = stepId;

    useEffect(() => {
        const verifyToken = async () => {
            const result = await authenticateUser({ variables: { token } }).catch((e) => {
                if (e.message === 'GraphQL error: invalid token') {
                    setVerifyUser({ success: false, error: 'Veuillez vérifier votre lien' });
                } else if (e.message === 'GraphQL error: maxAge exceeded') {
                    setVerifyUser({
                        success: false,
                        error: 'Ce lien a expiré. Veuillez contacter votre office manager',
                    });
                } else {
                    setVerifyUser({
                        success: false,
                        error: 'Il y a eu un problème avec la création de votre compte.',
                    });
                }
            });
            if (result && !verifyUser) {
                const {
                    data: { authenticateUser },
                } = result;
                setVerifyUser(authenticateUser);
            }
        };
        if (stepId !== '2' && window.TOTEM.storage.get(TOKEN)) {
            navigate('/', { relative: 'path' });
        }
        if (stepId !== '2' && !verifyUser) {
            verifyToken();
        }
    }, [verifyUser, token, stepId, authenticateUser, navigate]);

    if (stepId === '2') {
        return <SetPasswordInfo {...props} />;
    }

    if (!verifyUser) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <LoginBottomHalfStyle>
                <LoginWrapperStyle>
                    <SetPasswordInner
                        verifyUser={verifyUser}
                        setSkip={setSkip}
                        setPassword={setPassword}
                        token={token}
                    />
                </LoginWrapperStyle>
            </LoginBottomHalfStyle>
        </LoginStyle>
    );
};
