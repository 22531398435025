import React from 'react';
import styled from 'styled-components';

import { capitalize } from 'utils';

export const InlineError = ({ message = 'Ce champ est requis', fontSize, display, inline, top, right }) => {
    const ErrorComponent = inline ? InlineErrorInlineStyle : InlineErrorStyle;
    return display ? (
        <ErrorComponent fontSize={fontSize} top={top} right={right}>
            {capitalize(display?.message?.toLowerCase()) || capitalize(message?.toLowerCase())}
        </ErrorComponent>
    ) : null;
};

const InlineErrorStyle = styled.span`
    position: absolute;
    top: ${(props) => (props.top ? props.top : '20px')};
    right: ${(props) => (props.right ? props.right : '4px')};
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => props.fontSize || '0.9em'};
    text-transform: none;
    z-index: 2;
`;

const InlineErrorInlineStyle = styled.span`
    color: ${(props) => props.theme.colors.red};
    font-size: ${(props) => props.fontSize || '0.9em'};
    text-transform: none;
`;
