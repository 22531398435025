import React, { useState } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { Spy } from '@styled-icons/remix-fill';
import { User } from '@totem/roles';
import dayjs from 'dayjs';
import styled, { keyframes } from 'styled-components';

import { GET_ADMIN_USERS } from 'data/queries';
import { FETCH_USER_TOKEN } from 'data/mutations/user.mutations';

import { Loading } from 'components/Loading';

import { throwMessage } from 'utils/LoggingService';

import { TotemLoadingStyle } from 'styles/pages/totem.styles';
import {
    SettingsTitleContainerWrapper,
    SettingsTitleContainer,
    SettingsTitle,
    SettingsPageStyle,
    SettingsCard,
} from 'styles/pages/settings.styles';
import { SPYEE_TOKEN } from 'constants';
import { TotemInput } from 'styles';

export const Spying = () => {
    const client = useApolloClient();
    const [inputValue, setInputValue] = useState('');
    const { loading, error, data } = useQuery(GET_ADMIN_USERS, {
        fetchPolicy: 'no-cache',
    });
    const [fetchUserToken] = useMutation(FETCH_USER_TOKEN);
    let users = [];

    if (loading) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    if (error) {
        throwMessage('warn', error.message);
    }

    if (data && data.adminUsers.length) {
        users = data.adminUsers.sort((a, b) => (a.lastSeenAt > b.lastSeenAt ? -1 : 1));
    }

    const handleUserSelection = async (userId) => {
        const userToken = await fetchUserToken({ variables: { spyeeId: userId } });

        if (userToken?.data?.fetchSpyeeToken) {
            window.TOTEM.storage.set(SPYEE_TOKEN, userToken.data.fetchSpyeeToken);
            client.onResetStore(() => {
                if (window.TOTEM.storage.get(SPYEE_TOKEN)) {
                    window.location.replace('/');
                }
            });
            client.resetStore();
        }
    };

    const filteredUsers = users.filter(
        (user) =>
            user._id !== User.get()._id &&
            (!inputValue ||
                user.organization?.name
                    .replace(/ /g, '')
                    .toLowerCase()
                    .includes(inputValue.replace(/ /g, '').toLowerCase())),
    );

    return (
        <SettingsPageStyle>
            <SettingsCard>
                <SettingsTitleContainerWrapper>
                    <SettingsTitleContainer>
                        <Spy />
                        <SettingsSpecialTitle>Espionnage de clients</SettingsSpecialTitle>
                    </SettingsTitleContainer>
                </SettingsTitleContainerWrapper>
                <TotemInput
                    autoFocus
                    placeholder="Cherchez une organisation à espionner"
                    onChange={({ target: { value } }) => setInputValue(value)}
                />
                {filteredUsers.length ? (
                    <ResultsContainer>
                        {filteredUsers.map(({ _id, username, organization: { name }, lastSeenAt }) => {
                            return (
                                <UserOption key={_id} onClick={() => handleUserSelection(_id)}>
                                    <UserCompanyStyle>
                                        {name}
                                        <UserEmailStyle>{username}</UserEmailStyle>
                                    </UserCompanyStyle>
                                    <div>{`Actif ${lastSeenAt ? dayjs(lastSeenAt).fromNow() : 'Never'}`}</div>
                                </UserOption>
                            );
                        })}
                    </ResultsContainer>
                ) : (
                    <div>Aucun résultat... Vous êtes sûr·e que ça s'écrit comme ça ?</div>
                )}
            </SettingsCard>
        </SettingsPageStyle>
    );
};

const colorRotate = keyframes`
    to {
    background-position: 200% center;
  }
`;

const SettingsSpecialTitle = styled(SettingsTitle)`
    background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #ffffff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    animation: ${colorRotate} 3s linear 0s infinite;
`;

const ResultsContainer = styled.div`
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    border-radius: ${(props) => props.theme.variables.borderRadius};
    border: 1px solid ${(props) => props.theme.colors.borderGrey};
`;

const UserOption = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${(props) => props.theme.variables.margin}px;
    &:hover {
        background: ${(props) => props.theme.colors.whiteGrey};
        cursor: pointer;
        color: ${(props) => props.theme.colors.black};
    }
    border-bottom: 1px solid ${(props) => props.theme.colors.borderGrey};
    background-color: #ffffff;
    border-radius: ${(props) => props.theme.variables.borderRadius};
`;

const UserCompanyStyle = styled.div`
    color: ${(props) => props.theme.variables.secondary};
    font-size: 20px;
    font-weight: 500;
`;

const UserEmailStyle = styled.div`
    font-size: 12px;
    color: ${(props) => props.theme.colors.pantinGrey};
`;
