import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { FileInvoiceDollar } from '@styled-icons/fa-solid';
import styled from 'styled-components';

import { StripeInvoicesTable } from 'pages/Settings/SettingsPage/SettingsInvoices/StripeInvoicesTable';
import { ZuoraInvoicesTable } from 'pages/Settings/SettingsPage/SettingsInvoices/ZuoraInvoicesTable';

import { Loading } from 'components/Loading';

import { UserContext } from 'constants/user-context';
import { GET_ZUORA_INVOICES, GET_STRIPE_INVOICES } from 'data/queries/organization.queries';

import {
    SettingsTitleContainerWrapper,
    SettingsTitleContainer,
    SettingsTitle,
    SettingsCardSubtitle,
    SettingsPageStyle,
    SettingsCard,
} from 'styles/pages/settings.styles';
import { TotemButton } from 'ui/Button';

export const SettingsPageInvoices = () => {
    const {
        organization: { _id: organizationId, octobatId },
    } = useContext(UserContext);
    const {
        loading: stripeInvoicesLoading,
        error: stripeInvoicesError,
        data: stripeInvoicesData,
        fetchMore: fetchMoreInvoices,
    } = useQuery(GET_STRIPE_INVOICES, {
        variables: { organizationId },
        notifyOnNetworkStatusChange: true, // This line is necessary to have loading: true on fetchMore
    });
    const {
        loading: zuoraInvoicesLoading,
        error: zuoraInvoicesError,
        data: zuoraInvoicesData,
    } = useQuery(GET_ZUORA_INVOICES, {
        variables: { organizationId },
    });

    if (stripeInvoicesError || zuoraInvoicesError) {
        throw Error(stripeInvoicesError || zuoraInvoicesError);
    }

    return (
        <SettingsPageStyle>
            <SettingsCard>
                <SettingsTitleContainerWrapper>
                    <SettingsTitleContainer>
                        <FileInvoiceDollar />
                        <SettingsTitle>Mes factures</SettingsTitle>
                    </SettingsTitleContainer>
                </SettingsTitleContainerWrapper>
                <SettingsCardSubtitle>Commandes e-store</SettingsCardSubtitle>
                {stripeInvoicesLoading && !stripeInvoicesData ? (
                    <Loading relative />
                ) : stripeInvoicesData?.getStripeInvoices?.invoices ? (
                    <StripeInvoicesTable
                        invoices={stripeInvoicesData?.getStripeInvoices.invoices}
                        organizationId={organizationId}
                        hasMore={stripeInvoicesData?.getStripeInvoices.hasMore}
                        isLoading={stripeInvoicesLoading}
                        fetchMore={fetchMoreInvoices}
                    />
                ) : (
                    <ErrorMessage>
                        {stripeInvoicesData.getOctobatInvoices.error ||
                            'Il y a eu un souci lors de la récupération de vos factures.'}
                    </ErrorMessage>
                )}
                {octobatId && !stripeInvoicesData?.getStripeInvoices.hasMore && (
                    <TotemButton
                        as="a"
                        $margins={[1, 0]}
                        $type="secondary"
                        $alignSelf="center"
                        $fontSize="0.75em"
                        href={`https://repo.octobat.com/customers/${octobatId}`}
                        target="_blank"
                    >
                        Voir les factures antérieures à Avril 2023
                    </TotemButton>
                )}
                <SettingsCardSubtitle>Abonnements</SettingsCardSubtitle>
                {zuoraInvoicesLoading ? (
                    <Loading relative />
                ) : zuoraInvoicesData?.getZuoraInvoices?.success ? (
                    <ZuoraInvoicesTable invoices={zuoraInvoicesData?.getZuoraInvoices.invoices} />
                ) : (
                    <ErrorMessage>
                        {zuoraInvoicesData.getZuoraInvoices.error ||
                            'Il y a eu un souci lors de la récupération de vos factures.'}
                    </ErrorMessage>
                )}
            </SettingsCard>
        </SettingsPageStyle>
    );
};

const ErrorMessage = styled.p`
    color: ${({ theme }) => theme.colors.red};
`;
