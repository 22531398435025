import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Plus } from '@styled-icons/boxicons-regular';
import Select from 'react-select';
import { User } from '@totem/roles';
import styled from 'styled-components';

import { UserContext } from 'constants/user-context';
import { TOTEM_ICONS } from 'constants/totemIcons';

import { UPDATE_TEAM_ROLE } from 'data/mutations/user.mutations';

import { TeamPopupWindow } from 'pages/Settings/SettingsPage/SettingsTeam/TeamPopupWindow';

import { OptimizedImage } from 'components/OptimizedImage';

import { buildImgUrl } from 'utils/image/buildImgUrl';

import { SettingsCardSubtitle } from 'styles/pages/settings.styles';

export const UsersTable = ({ users, totems }) => {
    const {
        organization: { maxUsers },
    } = useContext(UserContext);
    const [teamPopupOpen, setTeamPopupOpen] = useState(false);
    const [roleBeingChanged, setRoleBeingChanged] = useState({ userId: '', totemId: '' });
    const [updateTeamRole, { loading }] = useMutation(UPDATE_TEAM_ROLE);

    const handleChangeUserRole = async (role, userId, totemId) => {
        setRoleBeingChanged({ userId, totemId });
        await updateTeamRole({ variables: { role, userId, totemId }, refetchQueries: ['GET_USERS_OF_ORGANIZATION'] });
    };

    const roles = {
        Admin: 'Admin',
        Editor: 'Éditeur',
        Analyst: 'Analyste',
    };
    const roleKeys = Object.keys(roles);
    const teamMembers = new Array(maxUsers).fill(1);

    return (
        <>
            <SettingsCardSubtitle $noMargin>
                Mon équipe ({users.length}/{maxUsers})
            </SettingsCardSubtitle>
            <UsersTableStyle>
                <thead>
                    <tr>
                        <th />
                        {teamMembers.map((member, index) => {
                            return <th key={index}>Utilisateur #{index + 1}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th />
                        {teamMembers.map((_, i) => {
                            return (
                                <th key={i}>
                                    <Username>
                                        {users[i]?.username ? (
                                            <>
                                                <div>{users[i].username}</div>
                                            </>
                                        ) : (
                                            <PlusButton
                                                height="23px"
                                                width="23px"
                                                onClick={() => setTeamPopupOpen(true)}
                                            >
                                                <Plus size="17" />
                                            </PlusButton>
                                        )}
                                    </Username>
                                </th>
                            );
                        })}
                    </tr>
                    {totems.map((totem) => {
                        return (
                            <tr key={totem._id}>
                                <th>
                                    <OptimizedImage
                                        src={buildImgUrl(
                                            `img/illustrations/Totem_illustration-${
                                                TOTEM_ICONS[(totem.number - 1) % 6 ?? 6].id
                                            }.png`,
                                        )}
                                        width={18}
                                    />{' '}
                                    TOTEM #{totem.number}
                                </th>
                                {teamMembers.map((_, i) => {
                                    const selectEnabled = i && User.isInRole('Admin', totem._id);
                                    let currentUserRole;
                                    const isLoading =
                                        loading &&
                                        roleBeingChanged.totemId === totem._id &&
                                        roleBeingChanged.userId === users[i]?._id;
                                    if (users[i]) {
                                        currentUserRole = User.roleInGroup(totem._id, users[i].roles)[0];
                                    }
                                    return (
                                        <td key={i}>
                                            {!!users[i] && (
                                                <Select
                                                    placeholder="Ajuster les droits"
                                                    value={{
                                                        label: isLoading ? 'chargement...' : roles[currentUserRole],
                                                        value: currentUserRole,
                                                    }}
                                                    isDisabled={isLoading || !selectEnabled}
                                                    options={roleKeys.map((role) => ({
                                                        value: role,
                                                        label: roles[role],
                                                    }))}
                                                    onChange={({ value }) =>
                                                        handleChangeUserRole(value, users[i]._id, totem._id)
                                                    }
                                                />
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </UsersTableStyle>
            {teamPopupOpen && <TeamPopupWindow setPopupOpen={setTeamPopupOpen} isEmployee={false} />}
        </>
    );
};

const Username = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const UsersTableStyle = styled.table`
    position: relative;
    text-align: center;
    overflow-x: auto;
    width: 100%;
    min-width: 600px;
    thead {
        th {
            text-align: center;
            font-size: 1.1rem;
        }
    }
    th {
        text-align: left;
        font-size: 1.1rem;
    }
`;

const PlusButton = styled.button`
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.yellow};
    border: none;
    cursor: pointer;
    height: 23px;
    width: 23px;
    display: inline-grid;
    align-items: center;
    justify-content: center;
`;
