import React, { useRef, useEffect, useState } from 'react';

export const OutsideClickTracker = ({ render, setOverlayOpen }) => {
    const wrapperRef = useRef(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    });

    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpen(false);
            setOverlayOpen && setOverlayOpen(false);
        }
    }

    return <div ref={wrapperRef}>{render(open, setOpen, setOverlayOpen)}</div>;
};
