import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UserCircle } from '@styled-icons/boxicons-regular';
import { Flex } from 'deprecated-enkel';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { UPDATE_USER } from 'data/mutations/user.mutations';
import { USERS_IMAGE_BASE_URL } from 'constants/url-constants';

import { ImageDragAndDrop } from 'components/ImageDragAndDrop';

import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { handleTextInput, objectIsInObject, validatePhone } from 'utils';

import { TotemLabel, TotemInput } from 'styles';
import {
    SettingsTitleContainerWrapper,
    SettingsTitleContainer,
    SettingsTitle,
    SettingsForm,
    SettingsCard,
    SettingsLeftHalf,
    SettingsRightHalf,
    OptionText,
} from 'styles/pages/settings.styles';

import { analyticsEvents, track } from 'tracking/segment';

export const AccountInformation = ({ user }) => {
    const [updateUser, { loading }] = useMutation(UPDATE_USER);
    const {
        profile: {
            firstname,
            lastname,
            phone,
            jobTitle,
            imageUrl,
            hasOptedOutOfEmail,
            hasOptedOutOfReminderEmail,
            hasOptedOutOfSoldOutProductsEmail,
        },
    } = user;

    const [profileValues, setProfileValues] = useState({
        hasOptedOutOfEmail,
        hasOptedOutOfReminderEmail,
        hasOptedOutOfSoldOutProductsEmail,
    });
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({
        reValidateMode: 'onSubmit',
        defaultValues: {
            firstname,
            lastname,
            phone,
            jobTitle,
        },
    });

    const handleAccountChange = async (data) => {
        try {
            const { phone: validatedPhoneNumber } = data;
            const parsedPhoneNumber = parsePhoneNumberFromString(validatedPhoneNumber, 'FR').number;
            setProfileValues({ ...profileValues, phone: parsedPhoneNumber });

            await updateUser({
                variables: {
                    data: {
                        profile: {
                            ...user.profile,
                            ...profileValues,
                            phone: parsedPhoneNumber,
                        },
                    },
                    _id: user._id,
                },
                refetchQueries: ['GET_USER'],
            });
            track(analyticsEvents.UPDATE_USER);
        } catch (error) {
            window.Log.error(error);
            throw error;
        }
    };

    const hasAccountChanged = Boolean(
        Object.keys(profileValues).length > 0 &&
            !objectIsInObject(user.profile, profileValues) &&
            user.profile.imageUrl !== USERS_IMAGE_BASE_URL + profileValues?.imageUrl?.name,
    );

    const controller = (e) => {
        handleTextInput(e, setProfileValues, profileValues);
    };

    const updateNewsletterOptOut = (e, value) => {
        e.preventDefault();
        setProfileValues({ ...profileValues, hasOptedOutOfEmail: value });
    };

    const updateReminderOptOut = (e, value) => {
        e.preventDefault();
        setProfileValues({ ...profileValues, hasOptedOutOfReminderEmail: value });
    };

    const updateSoldOutProductsOptOut = (e, value) => {
        e.preventDefault();
        setProfileValues({ ...profileValues, hasOptedOutOfSoldOutProductsEmail: value });
    };

    return (
        <SettingsCard>
            <SettingsForm onSubmit={handleSubmit(handleAccountChange)}>
                <SettingsTitleContainerWrapper>
                    <SettingsTitleContainer>
                        <UserCircle />
                        <SettingsTitle>Mon compte</SettingsTitle>
                    </SettingsTitleContainer>
                    <TotemButton
                        $fontSize="1em"
                        $alignSelf="flex-end"
                        type="submit"
                        disabled={!hasAccountChanged}
                        $isLoading={loading}
                    >
                        {loading ? <ClipLoader color="white" size="16px" /> : 'Mettre à jour'}
                    </TotemButton>
                </SettingsTitleContainerWrapper>
                <Flex $flow="row" $wrap="wrap" $alignItems="flex-start">
                    <SettingsLeftHalf $width="232px" $flexShrink={0}>
                        <ImageDragAndDrop
                            imageUrl={imageUrl}
                            user={user}
                            label="Photo"
                            values={profileValues}
                            setValues={setProfileValues}
                        />
                    </SettingsLeftHalf>
                    <SettingsRightHalf $width="100%">
                        <TotemLabel>
                            Prénom <InlineError display={errors.firstname} />
                            <TotemInput
                                placeholder="Entrez votre prénom"
                                {...register('firstname', { required: true })}
                                onChange={controller}
                            />
                        </TotemLabel>
                        <TotemLabel>
                            Nom <InlineError display={errors.lastname} />
                            <TotemInput
                                placeholder="Entrez votre nom"
                                {...register('lastname', { required: true })}
                                onChange={controller}
                            />
                        </TotemLabel>
                        <TotemLabel>
                            Poste <InlineError display={errors.jobTitle} />
                            <TotemInput
                                placeholder="Entrez votre poste"
                                {...register('jobTitle', { required: true })}
                                onChange={controller}
                            />
                        </TotemLabel>
                        <TotemLabel>
                            Numéro de téléphone <InlineError display={errors.phone} />
                            <TotemInput
                                placeholder="Entrez votre numéro de téléphone"
                                type="phone"
                                {...register('phone', {
                                    required: true,
                                    validate: validatePhone("Votre numéro de téléphone n'est pas valide."),
                                })}
                                onChange={controller}
                            />
                        </TotemLabel>
                        <EmailOptingContainer>
                            <Option>
                                <OptionText>Je souhaite recevoir la newsletter</OptionText>
                                <Flex>
                                    <OptionButton
                                        $fontSize="0.75em"
                                        onClick={(e) => updateNewsletterOptOut(e, false)}
                                        $margins={[0, 0.5, 0, 0]}
                                        $selected={!profileValues.hasOptedOutOfEmail}
                                    >
                                        Oui
                                    </OptionButton>
                                    <OptionButton
                                        $type="secondary"
                                        $fontSize="0.75em"
                                        onClick={(e) => updateNewsletterOptOut(e, true)}
                                        $selected={profileValues.hasOptedOutOfEmail}
                                    >
                                        Non
                                    </OptionButton>
                                </Flex>
                            </Option>
                            <Option>
                                <OptionText>
                                    Je souhaite recevoir des rappels concernant mes commandes non validées
                                </OptionText>
                                <Flex>
                                    <OptionButton
                                        $fontSize="0.75em"
                                        onClick={(e) => updateReminderOptOut(e, false)}
                                        $margins={[0, 0.5, 0, 0]}
                                        $selected={!profileValues.hasOptedOutOfReminderEmail}
                                    >
                                        Oui
                                    </OptionButton>
                                    <OptionButton
                                        $type="secondary"
                                        $fontSize="0.75em"
                                        onClick={(e) => updateReminderOptOut(e, true)}
                                        $selected={profileValues.hasOptedOutOfReminderEmail}
                                    >
                                        Non
                                    </OptionButton>
                                </Flex>
                            </Option>
                            <Option>
                                <OptionText>
                                    Je souhaite recevoir un email si des produits que j'ai commandés sont en rupture
                                </OptionText>
                                <Flex>
                                    <OptionButton
                                        $fontSize="0.75em"
                                        onClick={(e) => updateSoldOutProductsOptOut(e, false)}
                                        $margins={[0, 0.5, 0, 0]}
                                        $selected={!profileValues.hasOptedOutOfSoldOutProductsEmail}
                                    >
                                        Oui
                                    </OptionButton>
                                    <OptionButton
                                        $type="secondary"
                                        $fontSize="0.75em"
                                        onClick={(e) => updateSoldOutProductsOptOut(e, true)}
                                        $selected={profileValues.hasOptedOutOfSoldOutProductsEmail}
                                    >
                                        Non
                                    </OptionButton>
                                </Flex>
                            </Option>
                        </EmailOptingContainer>
                    </SettingsRightHalf>
                </Flex>
            </SettingsForm>
        </SettingsCard>
    );
};

const EmailOptingContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1300px) {
        flex-direction: column;
    }
`;

const Option = styled.div`
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1300px) {
        margin: 0 0 ${({ theme }) => theme.variables.margin}px 0;
    }
`;

const OptionButton = styled(TotemButton)`
    ${({ $selected, theme: { colors } }) =>
        !$selected &&
        css`
            border: 1px solid ${colors.borderGrey};
            color: ${colors.pantinGrey};
            background: ${colors.white};

            &:hover {
                border: 1px solid ${colors.borderGrey};
                color: ${colors.pantinGrey};
                background: ${colors.white};
            }
        `}
`;
