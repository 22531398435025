import dayjs from 'dayjs';

export const FORMAT = 'DD/MM/YYYY';
export const NEW_FORMAT = 'YYYY-MM-DD';

export function getNextWeekMonday() {
    return dayjs().startOf('week').add(7, 'days');
}

export const WEEKDAY_INFO = [
    {
        value: 'monday',
        name: 'lundi',
    },
    {
        value: 'tuesday',
        name: 'mardi',
    },
    {
        value: 'wednesday',
        name: 'mercredi',
    },
    {
        value: 'thursday',
        name: 'jeudi',
    },
    {
        value: 'friday',
        name: 'vendredi',
    },
];

export const DAYS_OF_THE_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export function getDisplayDeliveryDate(deliveryDate) {
    return dayjs(deliveryDate, FORMAT).format('D MMMM');
}
