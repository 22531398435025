import gql from 'graphql-tag';

export const GET_LATEST_TERMS_RECORD = gql`
    query GET_LATEST_TERMS_RECORD {
        latestTermsRecord {
            _id
            createdAt
            termsOfUseUrl
        }
    }
`;
