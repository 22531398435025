import gql from 'graphql-tag';

export const PRODUCT_FRAGMENT = gql`
    fragment ProductFragment on Product {
        _id
        access
        preferences {
            _id
            name
            imageUrl
        }
        diets {
            _id
            name
            imageUrl
        }
        brand
        categoryId
        conditionningTotem
        crossedPrice
        description
        displayKg
        flow
        imageUrl
        isBeingTested
        isFreefood
        isPaused
        isToBeArchived
        isWithoutStock
        name
        newUntil
        portion
        price
        state
        tags {
            _id
            name
        }
        weightWithoutPackaging
        isPunctual
        allowNegativeStock
        indexDisplay
    }
`;
