import React from 'react';

import styled from 'styled-components';
import { Flex } from 'deprecated-enkel';

import { colors } from 'styles/theme';

import { OptimizedImage } from 'components/OptimizedImage';
import { AspectRatio } from 'components/AspectRatio';
import { CardPackagingDisplay, CardSubtitle, CardTitle } from 'styles/components/card.styles';
import { IconButton, TotemButton } from 'ui/Button';

import { getGrams, getKgs, pluralize } from 'utils';
import { X } from '@styled-icons/boxicons-regular';

function getQuantityDisplay({ displayKg, weightWithoutPackaging, quantity }) {
    const quantityToUse = parseFloat(quantity.toFixed(3));

    if (quantityToUse === 0) {
        return 'supprimé';
    }

    if (!displayKg) {
        return `${quantityToUse} ${pluralize('pièce', quantityToUse)}`;
    }
    const weightDisplay = getKgs(displayKg, weightWithoutPackaging, quantityToUse);
    const grams = getGrams(displayKg, weightWithoutPackaging, quantityToUse);
    const gramsDisplay = grams ? `${grams}g` : '';

    return `${weightDisplay}kg ${gramsDisplay}`;
}

export const StockWarningPopup = ({ productsWithInsufficientQuantity, handleClose, confirmStock }) => {
    return (
        <Container>
            <CloseIcon $rotateOnHover $large $white onClick={handleClose}>
                <X size="25px" />
            </CloseIcon>
            <Title>Certains produits ne sont pas disponibles dans les quantités demandées</Title>
            <MissingQuantityContainer>
                {productsWithInsufficientQuantity.map((product) => {
                    const {
                        productId,
                        name,
                        brand,
                        volume,
                        imageUrl,
                        availableStock,
                        requestedQuantity,
                        displayKg,
                        weightWithoutPackaging,
                    } = product;
                    return (
                        <ProductRow key={productId}>
                            <Flex>
                                <AspectRatio size="80px" ratio="3:2">
                                    <OptimizedImage src={imageUrl} width={80} />
                                </AspectRatio>
                                <InfoContainer>
                                    <CardTitle fontWeight={600} fontSize="15px">
                                        {name}
                                    </CardTitle>
                                    <Flex>
                                        <CardSubtitle>{brand}</CardSubtitle>
                                        <CardPackagingDisplay leftMargin="5px">{volume}</CardPackagingDisplay>
                                    </Flex>
                                </InfoContainer>
                            </Flex>
                            <QuantitiesInfo>
                                <QuantityText fontSize="12px" marginBottom="5px">
                                    Quantité demandée :
                                    <span>
                                        {getQuantityDisplay({
                                            displayKg,
                                            quantity: requestedQuantity,
                                            weightWithoutPackaging,
                                        })}
                                    </span>
                                </QuantityText>
                                <QuantityText>
                                    Nouvelle quantité :
                                    <span>
                                        {getQuantityDisplay({
                                            displayKg,
                                            quantity: Math.max(availableStock, 0),
                                            weightWithoutPackaging,
                                        })}
                                    </span>
                                </QuantityText>
                            </QuantitiesInfo>
                        </ProductRow>
                    );
                })}
            </MissingQuantityContainer>
            <TotemButton $fontSize="1em" onClick={confirmStock}>
                Confirmer les nouvelles quantités
            </TotemButton>
        </Container>
    );
};

const Title = styled.h1`
    text-align: center;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    background: ${({ theme }) => theme.colors.culturedGrey};
    padding: ${({ theme }) => theme.variables.padding * 2}px;
    border-radius: ${(props) => props.theme.variables.borderRadius};
    max-height: 700px;
    overflow: auto;
`;

const CloseIcon = styled(IconButton)`
    position: absolute;
    right: ${({ theme }) => theme.variables.padding * 2}px;
`;

const MissingQuantityContainer = styled.div`
    display: flex;
    margin-top: 15px;
    margin-bottom: 20px;
    flex-direction: column;
    border-radius: 3px;
    padding-top: 5px;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
`;

const ProductRow = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${colors.borderGrey};
    padding: 15px;
    margin-bottom: 10px;
    background: white;
    align-items: center;
    justify-content: space-between;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 15px;
`;

const QuantitiesInfo = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 190px;
    margin-left: 10px;
`;

const QuantityText = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    white-space: nowrap;
    font-size: ${({ fontSize }) => fontSize || '15px'};
    margin-bottom: ${({ marginBottom }) => marginBottom || '0px'};

    > span {
        margin-left: 4px;
    }
`;
