import React from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorName } from '@totem/errors';

import { SvenStyle, SvenImg } from 'styles/pages/sven.styles';
import { SignupTitleStyle } from 'styles/pages/signup.styles';

export const Sven = () => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const reason = searchParams.get('reason');
    const banned = reason === ErrorName.AHEBANNED;

    return (
        <SvenStyle style={{ color: 'white' }}>
            <SignupTitleStyle>
                {banned ? "You're not welcome here ..." : 'We remember what you did last time'}
            </SignupTitleStyle>
            <SvenImg src="https://s3.eu-central-1.amazonaws.com/totems3/img/sven.jpg" />
        </SvenStyle>
    );
};
