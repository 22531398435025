import React, { useCallback, useState } from 'react';
import { Flex, AspectRatio } from 'deprecated-enkel';
import { useDropzone } from 'react-dropzone';

import styled from 'styled-components';
import { margin, darken } from 'polished';
import { ImageAdd } from 'styled-icons/boxicons-solid';

import { TotemLabelStyleTitle, SettingsCardSubtitle, EditIcon, TrashIcon } from 'styles/pages/settings.styles';

export const ImageDragAndDrop = ({ imageUrl, values, setValues, label }) => {
    const [error, setError] = useState('');
    const [preview, setPreview] = useState('');

    const checkError = (acceptedFiles, rejectedFiles) => {
        const fileNumber = acceptedFiles.length + rejectedFiles.length;
        if (fileNumber > 1) {
            return 'Veuillez ne sélectionner qu’une seule image';
        } else if (rejectedFiles[0] && !/^image\//.test(rejectedFiles[0].type)) {
            return 'Veuillez sélectionner une image';
        } else if (rejectedFiles[0]?.size > 2000000) {
            return 'Veuillez choisir une image inférieure à 2 Mo';
        }
    };
    const onDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            const errorMessage = checkError(acceptedFiles, rejectedFiles);

            if (errorMessage) {
                setError(errorMessage);
                setTimeout(() => setError(''), 4000);
                return;
            }

            const imageFile = acceptedFiles[0];
            const fileReader = new FileReader();

            fileReader.onload = () => {
                setValues({
                    ...values,
                    image: {
                        blob: fileReader.result,
                        name: imageFile.name,
                        type: imageFile.type,
                    },
                });
            };

            if (imageFile.type === 'image/svg+xml') {
                fileReader.readAsText(imageFile, 'utf-8');
            } else {
                fileReader.readAsDataURL(imageFile);
            }

            const fileReaderPreview = new FileReader();

            fileReaderPreview.onload = () => {
                setPreview(fileReaderPreview.result);
            };
            fileReaderPreview.readAsDataURL(imageFile);
        },
        [values, setValues],
    );

    const handleRemoveImage = () => {
        setPreview('removed');
        setValues({ ...values, imageUrl: '' });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxSize: 2000000,
        multiple: false,
        accept: 'image/*',
    });
    const showPicture = (imageUrl || preview) && preview !== 'removed';
    return (
        <>
            <TitleContainer>
                <TotemLabelStyleTitle>{label}</TotemLabelStyleTitle>
                {showPicture && (
                    <Flex>
                        <EditIcon size="20px" {...getRootProps()} />
                        <TrashIcon size="20px" onClick={handleRemoveImage} />
                    </Flex>
                )}
            </TitleContainer>
            <DragAndDropContainer {...getRootProps()} $imageUrl={showPicture}>
                <input {...getInputProps()} />
                {showPicture ? (
                    <AspectRatio
                        size="100%"
                        ratio="1:1"
                        innerStyle={{
                            background: `url("${preview ? preview : imageUrl}") no-repeat center / cover white`,
                        }}
                    />
                ) : (
                    <>
                        <AspectRatio
                            size="100%"
                            ratio="1:1"
                            innerStyle={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            Drag & drop ou cliquez
                            <ImageIcon size="20%" />
                        </AspectRatio>
                    </>
                )}
            </DragAndDropContainer>
            {!!error && <ErrorMessage>{error}</ErrorMessage>}
        </>
    );
};

const TitleContainer = styled(Flex.Style)`
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
`;

const ImageIcon = styled(ImageAdd)`
    color: ${(props) => props.theme.colors.pantinGrey};
    transition: all 0.25s ease;
`;

const DragAndDropContainer = styled(Flex.Style)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.borderGrey};
    border-radius: ${(props) => props.theme.variables.borderRadius};
    flex-shrink: 0;
    ${(props) =>
        margin(
            props.theme.variables.margin * 0.25,
            props.theme.variables.margin,
            props.theme.variables.margin * 0.25,
            0,
        )};
    color: ${(props) => props.theme.colors.pantinGrey};
    transition: all 0.25s ease;
    z-index: 2;
    cursor: pointer;
    font-size: 1em;
    overflow: hidden;

    &:hover {
        ${(props) => !props.$imageUrl && `border: 1px solid ${props.theme.colors.black}`};
    }

    &:focus {
        outline: none;
    }
`;

const ErrorMessage = styled(SettingsCardSubtitle)`
    width: 100%;
    font-size: 1em;
    font-weight: 500;
    color: ${(props) => darken(0.05, props.theme.colors.red)};
`;
