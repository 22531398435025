import React from 'react';
import styled from 'styled-components';

export const ProgressBar = ({ max, value, $isErrorStyle }) => {
    const percentage = (value / max) * 100;
    return (
        <ProgressBarContainer $isErrorStyle={$isErrorStyle}>
            <Filler $isErrorStyle={$isErrorStyle} width={`${percentage > 100 ? 100 : percentage}%`} />
        </ProgressBarContainer>
    );
};

const ProgressBarContainer = styled.div`
    height: 10px;
    width: 100%;
    background-color: ${({ $isErrorStyle, theme }) =>
        $isErrorStyle ? theme.colors.lightRed + '1A' : theme.colors.culturedGrey}; // 1A for opacity
    border-radius: 50px;
`;

const Filler = styled.div`
    height: 100%;
    width: ${({ width }) => width};
    transition: width 1s ease-in-out;
    background-color: ${({ $isErrorStyle, theme }) => ($isErrorStyle ? theme.colors.lightRed : theme.colors.green)};
    border-radius: inherit;
`;
