import { roundPrice } from 'utils/orders';

export function calculateOrderPrices({ products, priceDelivery }) {
    const allOrderedProductsPrice = products.reduce((acc, product) => {
        acc += roundPrice(product.unitPrice * product.quantity);
        return acc;
    }, 0);
    const allProductsPrice = products.reduce((acc, product) => {
        acc += roundPrice(product.unitPrice * (product.quantityPicked ?? product.quantity));
        return acc;
    }, 0);
    const allProductPriceWithDelivery = allProductsPrice + priceDelivery;
    return { allOrderedProductsPrice, allProductsPrice, allProductPriceWithDelivery };
}
