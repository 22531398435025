import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconArrowLeft } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

import { AsideBody, AsideDescription, AsideForm, AsideHeader, AsideSubTitle } from 'pages/Totem/Aside/AsideComponents';

import { IconButton, TotemButton } from 'ui/Button';
import { TotemInput } from 'styles';
import { EmojiLabel } from 'components/EmojiLabel';

import { UPDATE_TOTEM_DELIVERY_INFO } from 'data/mutations';

import { MapContainer } from 'pages/Totem/TotemDashboard/MapContainer';
import { DeliveryInfoForm } from 'pages/Totem/TotemDashboard/DeliveryInfoForm';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const DeliveryAccessInstructionsContainer = ({ totem, setIsDeliveryAccessInstructionsAsideOpen }) => {
    const [updateTotemDeliveryInfo, { loading }] = useMutation(UPDATE_TOTEM_DELIVERY_INFO);
    const [tempDeliveryInfo, setTempDeliveryInfo] = useState(totem);
    const {
        handleSubmit,
        register,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            accessInstructions: totem?.address_details,
        },
    });
    async function validateDeliveryAddress(newDeliveryInfo) {
        const { address, city, country, latitude, longitude, zipcode } = newDeliveryInfo;
        await updateTotemDeliveryInfo({
            variables: {
                totemId: totem._id,
                data: {
                    address,
                    city,
                    country,
                    latitude,
                    longitude,
                    zipcode,
                },
            },
        });

        totem.address_details && setIsDeliveryAccessInstructionsAsideOpen(false);
    }
    async function handleAccessInstructionsValidation({ accessInstructions }) {
        await updateTotemDeliveryInfo({
            variables: {
                totemId: totem._id,
                data: {
                    address_details: accessInstructions,
                },
            },
        });
        track(analyticsEvents.UPDATE_DELIVERY_INSTRUCTION, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
        });

        totem.address && setIsDeliveryAccessInstructionsAsideOpen(false);
    }
    return (
        <>
            <AsideHeader>
                <IconButton
                    data-test="aside-return-button"
                    $white
                    $large
                    onClick={() => setIsDeliveryAccessInstructionsAsideOpen(false)}
                >
                    <IconArrowLeft size={25} />
                </IconButton>
            </AsideHeader>
            <AsideBody>
                {totem.type !== 'secondary' && (
                    <>
                        <AsideSubTitle>Adresse de livraison</AsideSubTitle>
                        <MapWrapper>
                            <MapContainer
                                position={{
                                    lat: totem.latitude || '48.8534',
                                    lng: totem.longitude || '2.3488',
                                }}
                                zoom={10}
                                disableDefaultUI={true}
                            />
                        </MapWrapper>
                        <Margins>
                            <DeliveryInfoForm
                                buttonFontSize="1rem"
                                currentTotem={totem}
                                deliveryInfo={tempDeliveryInfo}
                                hasDeliveryInstructions={false}
                                hasLocationRestriction={true}
                                isAddressNonEditable={false}
                                loading={loading}
                                requestDirty
                                setDeliveryInfo={setTempDeliveryInfo}
                                sendFormContent={validateDeliveryAddress}
                                submitLabel="Modifier"
                                source={analyticsSourceConstants.CHECKOUT_BASKET}
                            />
                        </Margins>
                    </>
                )}
                <AsideForm onSubmit={handleSubmit(handleAccessInstructionsValidation)}>
                    <AsideSubTitle>Étage, code, bâtiment et autres instructions d'accès à votre TOTEM</AsideSubTitle>
                    <AsideDescription>
                        Indiquez nous les informations nécessaires pour permettre à nos livreurs de vous remettre les
                        meilleurs produits, dans les meilleurs temps <EmojiLabel src="😋" />
                    </AsideDescription>
                    <TotemInput
                        {...register('accessInstructions', { required: 'ce champ est requis' })}
                        as={TextareaAutosize}
                        async // necessary because we use styled-component
                        rows={4}
                        $margins={[1, 2, 0, 2]}
                        placeholder="Entrez les instructions pour permettre au livreur d'accéder à vos locaux"
                        data-test="deliveries-access-instructions"
                    />
                    <TotemButton
                        $margins={[2, 2]}
                        $fontSize="1rem"
                        disabled={!isValid || !isDirty || loading}
                        type="submit"
                        data-test="basket-delivery-instructions-validation"
                    >
                        Modifier
                    </TotemButton>
                </AsideForm>
            </AsideBody>
        </>
    );
};

const Margins = styled.div`
    margin: 0 2em;
`;

const MapWrapper = styled.div`
    position: relative;
    height: 200px;
    border-radius: ${(props) => props.theme.variables.borderRadius};
    overflow: hidden;
    pointer-events: none;
    margin: 0 2em 1em 2em;
`;
