import gql from 'graphql-tag';
import { ORDER_FRAGMENT } from 'data/fragments/order.fragments';
import { ORGANIZATION_FRAGMENT } from 'data/fragments/organization.fragments';
import { TOTEM_FRAGMENT } from 'data/fragments/totem.fragments';

export const SIGNUP = gql`
    mutation SIGNUP($data: Signup1Input!) {
        signup(data: $data) {
            token
            user {
                _id
                createdAt
                username
                emails {
                    address
                    verified
                }
                profile {
                    organizationId
                    phone
                    jobTitle
                }
                organization {
                    _id
                    mandateSEPA
                    paymentTimeDays
                    paymentTimeType
                    paymentType
                    salesforceId
                    totalCoworkers
                }
            }
            message
        }
    }
`;

export const SIGNUP2 = gql`
    mutation SIGNUP2($data: Signup2Input!) {
        signup2(data: $data) {
            ...OrganizationFragment
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const SIGNUP3 = gql`
    mutation SIGNUP3($data: Signup3Input!) {
        signup3(data: $data) {
            order {
                ...OrderFragment
            }
            totem {
                ...TotemFragment
            }
            user {
                _id
                username
                emails {
                    address
                    verified
                }
                profile {
                    firstname
                    lastname
                    fullname
                    organizationId
                    phone
                    jobTitle
                    jobType
                }
                organization {
                    name
                }
            }
        }
    }
    ${ORDER_FRAGMENT}
    ${TOTEM_FRAGMENT}
`;
