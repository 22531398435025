import React from 'react';
import { useMutation } from '@apollo/client';
import { IconCornerDownRight, IconShieldLock } from '@tabler/icons-react';
import { formatPrice } from '@totem/constants';
import { Calendar, Fridge, Time } from '@styled-icons/boxicons-regular';
import styled from 'styled-components';

import { BasketButtonLineItem } from './BasketButtonLineItem';
import { BasketToggleLineItem } from './BasketToggleLineItem';
import { FadeIn } from 'components/FadeIn';

import { Tooltip } from 'styles';

import { UPDATE_HAS_ONSITE_SETUP } from 'data/mutations';

import { FormattedDeliveryDate } from 'pages/Totem/TotemDashboard/FormattedDeliveryDate';

import { MIN_FREE_INTERVAL_DURATION } from 'constants/deliveryTime';
import { MODIFIABLE_ORDER_STATES } from 'constants/states-app';
import { ONSITE_SETUP_PRICE, SHORT_TIME_WINDOW_PRICE } from 'constants/order';

import { getDeliveryHourRangeString } from 'utils/time';
import { truncateString } from 'utils/text';
import { getTimeDifference } from 'utils';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const BasketDeliveryInfos = ({
    hasSubscription,
    onSiteSetupInstructions,
    order,
    ordersOfTotem,
    setIsDeliveryDateAsideOpen,
    setIsDeliveryHoursAsideOpen,
    setIsDeliveryAccessInstructionsAsideOpen,
    setIsOnsiteSetupInstructionsOpen,
    totem,
}) => {
    const { hasConfirmedTotemOrders } = ordersOfTotem;
    const ICON_SIZE = '18px';
    const [updateHasOnsiteSetup, { loading }] = useMutation(UPDATE_HAS_ONSITE_SETUP);
    async function handleChangeHasOnSiteSetup(hasOnsiteSetup) {
        await updateHasOnsiteSetup({
            variables: {
                orderId: order._id,
                hasOnsiteSetup,
            },
        });
        track(analyticsEvents.SET_HAS_ONSITE_SETUP, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
        });
    }

    const shouldButtonsBeDisabled = !MODIFIABLE_ORDER_STATES.includes(order.state);
    const isTimeWindowFreeOfCharge =
        getTimeDifference(order.deliveryTimeWindows?.[0].start, order.deliveryTimeWindows?.[0].stop) >=
        MIN_FREE_INTERVAL_DURATION;

    return (
        <DeliveryInfoContainer>
            <BasketButtonLineItem
                actionLabel="Modifier"
                dataTest="basket-delivery-date"
                icon={<Calendar size={ICON_SIZE} />}
                label={<FormattedDeliveryDate deliveryDate={order.date_delivery} />}
                onClick={() => setIsDeliveryDateAsideOpen(true)}
                disabled={shouldButtonsBeDisabled}
            />
            <BasketButtonLineItem
                actionLabel="Modifier"
                dataTest="basket-delivery-hours"
                icon={<Time size={ICON_SIZE} />}
                label={`Livraison ${getDeliveryHourRangeString(
                    order.deliveryTimeWindows?.[0].start,
                    order.deliveryTimeWindows?.[0].stop,
                )} ${hasSubscription || isTimeWindowFreeOfCharge ? '' : `(+${formatPrice(SHORT_TIME_WINDOW_PRICE)})`}`}
                onClick={() => setIsDeliveryHoursAsideOpen(true)}
                disabled={shouldButtonsBeDisabled}
            />
            {!hasConfirmedTotemOrders && (
                <BasketButtonLineItem
                    actionLabel={totem?.address_details ? 'Modifier' : 'Ajouter'}
                    dataTest="basket-delivery-instructions"
                    icon={<IconShieldLock size={ICON_SIZE} />}
                    label="Adresse et instructions de livraison"
                    onClick={() => setIsDeliveryAccessInstructionsAsideOpen(true)}
                    disabled={shouldButtonsBeDisabled}
                />
            )}
            <BasketToggleLineItem
                borderBottom={!order.hasOnsiteSetup}
                checked={order.hasOnsiteSetup}
                dataTest="basket-onsite-setup-toggle"
                disabled={loading || shouldButtonsBeDisabled}
                icon={<Fridge size={ICON_SIZE} />}
                label={
                    <>
                        <span
                            data-tip="Nos livreurs installent, dans  <br /> le respect des  mesures <br /> sanitaires, les produits commandés. <br /> Indiquez nous où vous souhaitez que  <br /> le café soit posé, comment les  <br /> boissons doivent être rangées, etc.."
                            data-for="onSiteSetupTooltip"
                        >
                            <u>Mise en place</u> {!hasSubscription && `(+${formatPrice(ONSITE_SETUP_PRICE)})`}
                        </span>
                        <Tooltip id="onSiteSetupTooltip" type="dark" effect="solid" place="bottom" multiline={true} />
                    </>
                }
                onChange={handleChangeHasOnSiteSetup}
            />
            {order.hasOnsiteSetup && (
                <FadeIn duration={500}>
                    <BasketButtonLineItem
                        borderBottom
                        dataTest="basket-onsite-setup-instructions"
                        icon={<IconCornerDownRight size={ICON_SIZE} />}
                        label={
                            onSiteSetupInstructions
                                ? truncateString(onSiteSetupInstructions, 40)
                                : 'Consignes de mise en place'
                        }
                        actionLabel={onSiteSetupInstructions ? 'Modifier' : 'Ajouter'}
                        onClick={() => setIsOnsiteSetupInstructionsOpen(true)}
                        disabled={shouldButtonsBeDisabled}
                    />
                </FadeIn>
            )}
        </DeliveryInfoContainer>
    );
};

const DeliveryInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    border-width: 0 0 0.1rem;
`;
