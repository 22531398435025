import React, { useCallback, useState } from 'react';

import { DashboardDeliveryDateSelector } from 'pages/Totem/TotemDashboard/DashboardDeliveryDateSelector';
import { OrderTypeSelection } from 'pages/Totem/TotemDashboard/OrderSelection/OrderTypeSelection';
import { AddressSelection } from 'pages/Totem/TotemDashboard/OrderSelection/AddressSelection';
import { UpdateExistingOrderConfirmation } from 'pages/Totem/TotemDashboard/OrderSelection/UpdateExistingOrderConfirmation';

import { Popup } from 'components/Popup';

import { RemoveIcon } from 'styles/components/card.styles';
import { PopupContent, CloseContainer } from 'styles/pages/settings.styles';
import { ArrowCircleIcon, AbsoluteContainer } from 'styles/pages/totem.styles';

export const OrderSelectionPopup = ({
    orderPopupOpen,
    ordersOfTotem,
    currentTotem,
    defaultCategoryId,
    closeOrderPopup,
}) => {
    const { hasConfirmedTotemOrders } = ordersOfTotem;
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTimeWindow, setSelectedTimeWindow] = useState(null);
    const [orderSelectionStep, setOrderSelectionStep] = useState(!currentTotem.latitude ? 0 : 1);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const setSelectedOrderCallback = useCallback(
        (order) => {
            setSelectedOrder(order);
            setOrderSelectionStep(orderSelectionStep + 1);
        },
        [orderSelectionStep],
    );

    return (
        <Popup isOpen={orderPopupOpen} color="white" handleClose={closeOrderPopup} width="470px" overflow="visible">
            {orderSelectionStep === 0 ? (
                <PopupContent $paddings={[2, 2]} $spaceBetween $width="455px">
                    <AddressSelection
                        currentTotem={currentTotem}
                        handleClose={closeOrderPopup}
                        setOrderSelectionStep={setOrderSelectionStep}
                    />
                </PopupContent>
            ) : orderSelectionStep === 1 ? (
                <DashboardDeliveryDateSelector
                    shouldHideBackButton={hasConfirmedTotemOrders}
                    focused={orderPopupOpen}
                    onSubmit={() => setOrderSelectionStep(2)}
                    onClickBackButton={() => setOrderSelectionStep(0)}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTimeWindow={selectedTimeWindow}
                    setSelectedTimeWindow={setSelectedTimeWindow}
                    handleClose={closeOrderPopup}
                    currentTotem={currentTotem}
                />
            ) : orderSelectionStep === 2 ? (
                <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween $width="455px">
                    <OrderTypeSelection
                        ordersOfTotem={ordersOfTotem}
                        currentTotem={currentTotem}
                        defaultCategoryId={defaultCategoryId}
                        selectedDate={selectedDate}
                        selectedTimeWindow={selectedTimeWindow}
                        setSelectedOrder={setSelectedOrderCallback}
                    />
                    <AbsoluteContainer $top="9px" $left="9px">
                        <ArrowCircleIcon onClick={() => setOrderSelectionStep(orderSelectionStep - 1)} size="32px" />
                    </AbsoluteContainer>
                    <CloseContainer>
                        <RemoveIcon size="25px" onClick={closeOrderPopup} />
                    </CloseContainer>
                </PopupContent>
            ) : (
                <PopupContent $alignItems="center" $paddings={[4, 1, 2]} $width="550px" $height="320px">
                    <UpdateExistingOrderConfirmation
                        order={selectedOrder}
                        ordersOfTotem={ordersOfTotem}
                        currentTotem={currentTotem}
                        defaultCategoryId={defaultCategoryId}
                        selectedDate={selectedDate}
                    />
                    <AbsoluteContainer $top="9px" $left="9px">
                        <ArrowCircleIcon onClick={() => setOrderSelectionStep(orderSelectionStep - 1)} size="32px" />
                    </AbsoluteContainer>
                    <CloseContainer>
                        <RemoveIcon size="25px" onClick={closeOrderPopup} />
                    </CloseContainer>
                </PopupContent>
            )}
        </Popup>
    );
};
