import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { POST_TO_SLACK } from 'data/mutations';
import { UserContext } from 'constants/user-context';

import { TotemButton } from 'ui/Button';
import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

import { TotemInput, TotemLabel } from 'styles';
import { Popup, PopupContent, CloseContainer, PopupTitle } from 'styles/pages/settings.styles';
import { RemoveIcon } from 'styles/components/card.styles';

export const SuggestionPopup = ({ currentCategory, setIsSuggestionPopupOpen }) => {
    const [postMessageToSlack] = useMutation(POST_TO_SLACK);
    const {
        user: {
            profile: { firstname, lastname },
        },
    } = useContext(UserContext);

    function postSuggestionToSlack(e) {
        e.preventDefault();

        const { productName, productBrand } = [...new FormData(e.target).entries()].reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: value,
            }),
            {},
        );

        if (productName || productBrand) {
            postMessageToSlack({
                variables: {
                    message: `• ${firstname} ${lastname} aimerait beaucoup qu'on ajoute ${productName}${
                        productBrand ? ` de la marque ${productBrand}` : ''
                    } à la catégorie ${currentCategory.title}`,
                },
            });
        }
        track(analyticsEvents.CREATE_PRODUCT_SUGGESTION, {
            [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: currentCategory.title,
            [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: currentCategory._id,
            [analyticsPropertiesConstants.PRODUCTS.NAME]: productName,
            [analyticsPropertiesConstants.PRODUCTS.BRAND]: productBrand,
        });
        setIsSuggestionPopupOpen(false);
    }

    return (
        <Popup $alignItems="center" $justifyContent="center">
            <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween $width="455px" onClick={(e) => e.stopPropagation()}>
                <PopupTitle>Suggestion de produit</PopupTitle>
                <ProductSuggestionForm onSubmit={postSuggestionToSlack}>
                    <TotemLabel>
                        Nom du produit
                        <TotemInput placeholder="Entrez le nom du produit désiré" name="productName" autoFocus />
                    </TotemLabel>
                    <TotemLabel>
                        Marque du produit
                        <TotemInput placeholder="Entrez la marque du produit désiré" name="productBrand" />
                    </TotemLabel>
                    <TotemButton type="submit" style={{ minWidth: 100 }}>
                        Suggérer
                    </TotemButton>
                </ProductSuggestionForm>
                <CloseContainer>
                    <RemoveIcon size="25px" onClick={() => setIsSuggestionPopupOpen(false)} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};

const ProductSuggestionForm = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    width: 100%;
`;
