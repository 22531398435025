import React, { useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { SECONDARY } from 'constants/types';

import { UPDATE_TOTEM_ADDRESS } from 'data/mutations';

import { extractKeysFromObject } from 'utils';
import { addressFields } from 'utils/googleMapsAddress';
import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';
import { DeliveryInfoForm } from 'pages/Totem/TotemDashboard/DeliveryInfoForm';
import { totemUpdatedCacheHandler } from 'utils/cache/totemUpdatedCacheHandler';
import { CloseContainer, PopupTitle } from 'styles/pages/settings.styles';
import { RemoveIcon } from 'styles/components/card.styles';

export const AddressSelection = ({ currentTotem, handleClose, setOrderSelectionStep }) => {
    const [addressValues, setAddressValues] = useState(currentTotem);
    const apolloClient = useApolloClient();
    const [updateTotemAddress, { loading }] = useMutation(UPDATE_TOTEM_ADDRESS);

    const sendFormContent = async (addressValues) => {
        const newAddressValues = extractKeysFromObject(addressValues, addressFields);
        await updateTotemAddress({
            variables: {
                totemId: currentTotem._id,
                data: {
                    ...newAddressValues,
                },
            },
        });
        totemUpdatedCacheHandler({
            apolloClient,
            currentTotem,
            totemUpdatedValues: newAddressValues,
        });
        track(analyticsEvents.ADD_TOTEM_DELIVERY_ADDRESS, {
            [analyticsPropertiesConstants.ONBOARDING.DELIVERY_CITY]: newAddressValues.city,
        });
    };

    return (
        <>
            <PopupTitle>Renseignez votre adresse de livraison</PopupTitle>
            <DeliveryInfoForm
                currentTotem={currentTotem}
                deliveryInfo={addressValues}
                hasDeliveryInstructions={false}
                hasLocationRestriction={true}
                isAddressNonEditable={currentTotem.type === SECONDARY}
                loading={loading}
                onFormSubmitted={() => setOrderSelectionStep(1)}
                sendFormContent={sendFormContent}
                setDeliveryInfo={setAddressValues}
                submitLabel="Continuer"
                isCreatingAddress
            />
            <CloseContainer>
                <RemoveIcon size="25px" onClick={handleClose} />
            </CloseContainer>
        </>
    );
};
