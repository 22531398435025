import styled from 'styled-components';

export const LineItemContainer = styled.button`
    background-color: white;
    border-top: ${(props) => (props.$borderTop ? `1px solid ${props.theme.colors.borderGrey}` : 'none')};
    border-bottom: ${(props) => (props.$borderBottom ? `1px solid ${props.theme.colors.borderGrey}` : 'none')};
    border-left: none;
    border-right: none;
    padding: 25px 25px 25px ${({ $paddingLeft }) => ($paddingLeft !== undefined ? $paddingLeft : '25px')};
    width: 100%;
    cursor: ${({ $clickable }) => $clickable && 'pointer'};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.1em;
    &:hover {
        background-color: ${({ theme, $clickable }) => $clickable && theme.colors.whiteGrey};
    }
`;
