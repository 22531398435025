import React, { memo } from 'react';
import { Map, Marker } from 'google-maps-react';

import { mapStyle } from 'utils/maps/mapStyle';

const mapStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',
};

export const MapContainer = memo(function MapContainer({ position: { lat, lng }, disableDefaultUI, zoom }) {
    const onMapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle,
        });
    };
    if (!google) {
        return;
    }
    return (
        <Map
            google={google}
            zoom={zoom}
            style={mapStyles}
            initialCenter={{ lat, lng }}
            center={{ lat, lng }}
            disableDefaultUI={disableDefaultUI}
            onReady={(mapProps, map) => onMapLoaded(mapProps, map)}
        >
            <Marker name="Position du TOTEM" position={{ lat, lng }} />
        </Map>
    );
});
