// Before adding new zipcodes:
// - check with ops that they are OK with the address, and under which conditions
// - bump the package versions in constants/package.json
// - bump the dependency versions in both api and app
// - commit some code changes in both api and app so the docker images get built (not only in package.json)

export const PARIS_AREA_ZIPCODES = new Set([
    '60330', // EGB for Ewy
    '77185',
    '77432',
    '78200',
    '78410',
    '91940',
    '92000',
    '92100',
    '92110',
    '92120',
    '92130',
    '92140',
    '92150',
    '92170',
    '92190',
    '92200',
    '92210',
    '92220',
    '92230',
    '92240',
    '92250',
    '92260',
    '92270',
    '92290',
    '92300',
    '92310',
    '92320',
    '92330',
    '92340',
    '92350',
    '92360',
    '92370',
    '92380',
    '92390',
    '92400',
    '92410',
    '92420',
    '92430',
    '92500',
    '92600',
    '92700',
    '92800',
    '93000',
    '93100',
    '93110',
    '93120',
    '93130',
    '93140',
    '93170',
    '93200',
    '93210',
    '93230',
    '93260',
    '93300',
    '93340',
    '93400',
    '93500',
    '93600',
    '93700',
    '94000',
    '94110',
    '94120',
    '94130',
    '94140',
    '94160',
    '94170',
    '94200',
    '94220',
    '94230',
    '94240',
    '94250',
    '94270',
    '94300',
    '94400',
    '94500',
    '94100',
    '94700',
    '94800',
    '93160',
    '93330',
    '93360',
    '93340',
    '94340',
    '94350',
    '94360',
    '93220',
    '93250',
]);
