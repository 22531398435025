import styled, { css } from 'styled-components';
import { SignupTitleStyle } from 'styles/pages/signup.styles';
import { margin, padding } from 'polished';
import { Flex } from 'deprecated-enkel';

export const SetPasswordTitle = styled(SignupTitleStyle)`
    letter-spacing: 0.3px;
    ${(props) => margin(props.theme.variables.margin, 0, props.theme.variables.margin * 0.5)};

    @media not screen, (min-width: 600px) {
        font-size: 3em;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2em;
    }

    ${(props) =>
        props.error &&
        css`
            font-size: 2.5em;
            width: max-content;
            font-weight: 500;
            border-radius: ${(props) => props.theme.variables.borderRadius};
            ${(props) => padding(props.theme.variables.padding * 0.5, props.theme.variables.padding)};
            color: ${(props) => props.theme.colors.white};
            text-transform: none;
        `}

    > span {
        ${(props) => margin(0, props.theme.variables.margin * 0.2)};
        color: ${(props) => props.theme.colors.black};
    }
`;

export const PasswordStrengthContainer = styled(Flex)`
    width: 100%;
    position: absolute;
    border-radius: 0 0 3px 3px;
    overflow: hidden;
    top: 65px;
`;

export const PasswordStrengthElement = styled.div`
    content: '';
    height: 3px;
    background: ${(props) => props.theme.colors[props.$background]};
    width: 100%;
`;
