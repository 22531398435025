export class Storage {
    static STORAGE_KEY = '_totem-storage';
    static keys = {};

    constructor() {
        this.__createStorageConditionally();
        this.__populateKeys();
    }

    __createStorageConditionally() {
        if (!this.getAll()) {
            this.unset();
        }
    }

    __populateKeys() {
        this.keys = Object.keys(this.getAll());
    }

    get(key) {
        return this.getAll()[key];
    }

    getAll() {
        const storageItem = window.localStorage.getItem(Storage.STORAGE_KEY);
        if (storageItem && storageItem !== '[object Object]') {
            return JSON.parse(storageItem);
        }
        return {};
    }

    set(key, value) {
        const newItems = {
            ...this.getAll(),
            [key]: value,
        };
        this.setAll(newItems);
        return newItems;
    }

    setAll(meta) {
        const newMeta = JSON.stringify(meta);
        window.localStorage.setItem(Storage.STORAGE_KEY, newMeta);
        this.__populateKeys();
        return meta;
    }

    remove(key) {
        const { [key]: discardedItem, ...rest } = this.getAll();
        this.setAll(rest);
        this.__populateKeys();
        return discardedItem;
    }

    unset() {
        window.localStorage.setItem(Storage.STORAGE_KEY, '{}');
        return {};
    }

    discard() {
        return window.localStorage.removeItem(Storage.STORAGE_KEY);
    }
}
