import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Popup = ({ width, isOpen, handleClose, color, children, shouldClose = true, overflow }) => {
    const props = { $shouldDisplay: isOpen };
    if (shouldClose) {
        props.onClick = () => handleClose();
    }

    return (
        <PopupWrapper {...props}>
            <PopupContainer onClick={(e) => e.stopPropagation()} width={width} color={color} overflow={overflow}>
                {children}
            </PopupContainer>
        </PopupWrapper>
    );
};

const PopupWrapper = styled.div`
    position: fixed;
    left: ${(props) => props.theme.variables.globalNavbarWidth}px;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 6; /* NavBar has z-index of 4 */
    display: ${(props) => (props.$shouldDisplay ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    background-color: ${(props) => transparentize(0.35, props.theme.colors.black)};
    transition: all 0.25s ease;
`;

const PopupContainer = styled.div`
    position: relative;
    width: ${(props) => props.width || '550px'};
    overflow: ${(props) => props.overflow || 'hidden'};
    border-radius: 3px;
    background-color: ${(props) => props.color || props.theme.colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
`;
