import React from 'react';
import styled from 'styled-components';

import { LineItemContainer } from 'pages/Totem/Aside/Basket/LineItemContainer';

export const BasketButtonLineItem = ({
    actionLabel,
    borderBottom,
    bold = true,
    dataTest,
    disabled = false,
    icon,
    label,
    onClick,
}) => {
    return (
        <LineItemContainer
            as="button"
            onClick={onClick}
            $borderBottom={borderBottom}
            disabled={disabled}
            data-test={dataTest}
        >
            <LabelAndIcon>
                {icon}
                {label}
            </LabelAndIcon>
            {!disabled && <ActionLabel $bold={bold}>{actionLabel}</ActionLabel>}
        </LineItemContainer>
    );
};

const LabelAndIcon = styled.div`
    svg {
        margin-right: 1.6rem;
    }
    display: flex;
    align-items: center;
`;

const ActionLabel = styled.div`
    font-weight: ${({ $bold }) => ($bold ? 800 : 500)};
    cursor: pointer;
`;
