import gql from 'graphql-tag';

import { CARD_FRAGMENT } from 'data/fragments/stripe.fragments';

export const ORGANIZATION_FRAGMENT = gql`
    fragment OrganizationFragment on Organization {
        _id
        accountManagerId
        attachUsersManually
        cards {
            ...CardFragment
        }
        domains
        acceptedTerms {
            areAccepted
            termsRecordId
            acceptanceDate
        }
        billingInfo {
            defaultPaymentMethod
            invoiceAddress
            invoiceCity
            invoiceCountry
            invoiceEmail
            invoiceName
            invoicePO
            invoiceVAT
            invoiceZipcode
        }
        isTest
        maxUsers
        name
        octobatId
        paymentType
        imageUrl
        state
        stripeId
        hasSubscription
    }
    ${CARD_FRAGMENT}
`;
