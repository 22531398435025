import React, { useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Flex } from 'deprecated-enkel';
import { User } from '@totem/roles';
import { Plus, Minus } from '@styled-icons/boxicons-regular';
import { ClipLoader } from 'react-spinners';

import { CardActions } from 'data/functions/card-actions';
import { ADD_PRODUCT, REMOVE_PRODUCT, MORE_PRODUCT, LESS_PRODUCT, SET_QUANTITY } from 'data/mutations';
import { ARCHIVED } from 'constants/states-app';
import { colors } from 'styles/theme';

import { Overlay } from 'ui/Overlay';

import { CardProductImage } from './CardProduct/CardProductImage';
import { ProductTags } from './CardProduct/ProductTags';
import { ProductBadges } from 'pages/Totem/TotemProducts/CardProduct/ProductBadges';

import { debounce, decimals, getEuros, getCents, limitToNcharacters } from 'utils';

import {
    Card,
    CardBottom,
    CardTitle,
    CardSubtitle,
    CardPackagingDisplay,
    CardDescription,
    CardContent,
    CardPriceGroup,
    CardPriceEuro,
    CardPriceCents,
    CardActionButton,
    CardButtonGroup,
    CardBottomContent,
    QuantityInputStyle,
} from 'styles/components/card.styles';
import { formatPrice } from '@totem/constants';

export const CardProduct = ({ product, order, categoryId, currentCategory, totemId, organization }) => {
    const [addProduct, addProductData] = useMutation(ADD_PRODUCT);
    const [removeProduct, removeProductData] = useMutation(REMOVE_PRODUCT);
    const [lessProduct, lessProductData] = useMutation(LESS_PRODUCT);
    const [moreProduct, moreProductData] = useMutation(MORE_PRODUCT);
    const [setQuantity] = useMutation(SET_QUANTITY);

    const isAnalyst = User.isAnalyst(totemId);
    const handlers = {
        addProduct: {
            handler: addProduct,
            data: addProductData,
        },
        removeProduct: {
            handler: removeProduct,
            data: removeProductData,
        },
        moreProduct: {
            handler: moreProduct,
            data: moreProductData,
        },
        lessProduct: {
            handler: lessProduct,
            data: lessProductData,
        },
    };

    const delayedSetQuantity = useCallback(
        debounce((mutationOptions) => setQuantity(mutationOptions), 500),
        [],
    );

    const { handleAddProduct, handleRemoveProduct, handleMoreProduct, handleLessProduct } = CardActions(
        {
            product,
            order,
            currentCategory,
            debouncedFunction: delayedSetQuantity,
        },
        handlers,
        [],
    );

    const {
        _id: productId,
        crossedPrice,
        name,
        preferences,
        diets,
        newUntil,
        brand,
        volume,
        description,
        isWithoutStock,
        price,
        portion,
        conditionningTotem,
        weightWithoutPackaging,
        displayKg,
        isPaused,
        allowNegativeStock,
        canBeSuppliedBeforeOrderDate,
        state,
        availableStock,
    } = product;

    const productInOrder = order.products.find(({ _id }) => _id === productId);
    const isProductInOrder = !!productInOrder?.portion;

    const [quantityInput, setQuantityInput] = useState(productInOrder?.quantity);

    const synchronizeQuantity = (quantity) => () => {
        setQuantityInput(quantity);
    };

    const handleSetQuantity = ({ target: { value = 0 } }) => {
        setQuantityInput(value);

        if (!value) {
            delayedSetQuantity.cancel();
            return;
        }

        delayedSetQuantity({
            variables: {
                orderId: order._id,
                productId,
                categoryId,
                quantity: Math.round(value),
            },
            refetchQueries: [],
        });
    };

    let unitPrice = decimals(Math.round(price * 100) / 100, 2);

    if (displayKg) {
        unitPrice = Number(unitPrice / weightWithoutPackaging).toFixed(2);
    }

    useEffect(() => {
        setQuantityInput(productInOrder?.quantity);
    }, [productInOrder?.quantity]);

    function isLoading(keys) {
        return keys.some((key) => handlers[`${key}Product`] && handlers[`${key}Product`].data.loading);
    }

    const quantityForecast = parseFloat((conditionningTotem / portion).toFixed(3));
    const isQtyMoreThanAvailableStock = (productInOrder?.quantity || 0) + quantityForecast > availableStock;
    const isNotEnoughStock =
        !isWithoutStock && !allowNegativeStock && !canBeSuppliedBeforeOrderDate && isQtyMoreThanAvailableStock;

    const badges = [...preferences, ...diets];
    if (newUntil) {
        badges.push({
            name: 'Nouveau',
            imageUrl: 'img/new.svg',
        });
    }

    return (
        <Card $noPadding data-test="product-card">
            <CardProductImage
                order={order}
                currentCategory={currentCategory}
                productInOrder={productInOrder}
                product={product}
                totemId={totemId}
                organization={organization}
                isAnalyst={isAnalyst}
                handleRemoveProduct={handleRemoveProduct}
            />
            <CardBottom>
                <ProductBadges badges={badges} />
                <CardContent height="max-content" $paddingMultiplier={[0.5, 0.5, 0, 0.5]}>
                    <Flex $direction="row" $justifyContent="space-between">
                        <CardTitle $vAlign="flex-start">{limitToNcharacters(name, 36)}</CardTitle>
                        <CardPackagingDisplay>{volume}</CardPackagingDisplay>
                    </Flex>

                    <Flex $direction="row">
                        <CardSubtitle>{brand}</CardSubtitle>
                    </Flex>
                </CardContent>
                <ProductTags product={product} />
                {description && <CardDescription>{limitToNcharacters(description)}</CardDescription>}
                <CardBottomContent style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    <CardPriceGroup $flow="row">
                        {crossedPrice ? (
                            <CardPriceEuro $isCrossed>
                                {formatPrice(crossedPrice, { withoutSpace: true })}
                            </CardPriceEuro>
                        ) : null}
                        <CardPriceEuro>{getEuros(unitPrice)}</CardPriceEuro>
                        <CardPriceCents>
                            ,{getCents(unitPrice)}€<br />
                            {displayKg ? 'le kg' : 'la pièce'}
                        </CardPriceCents>
                    </CardPriceGroup>
                </CardBottomContent>
                <Overlay name={`card-${productId}-overlay`} inverse />
            </CardBottom>
            {!isAnalyst && state !== ARCHIVED && !isPaused && (
                <>
                    {isProductInOrder && (
                        <CardButtonGroup $borderRadius={[false, false, true, true]} $noBorder>
                            <CardActionButton
                                $marginMultiplier={[0, 0.15, 0, 0]}
                                disabled={isLoading(['more', 'less', 'add'])}
                                $isLoading={isLoading(['more', 'less', 'add'])}
                                onClick={handleLessProduct}
                                $borderRadius={[3, 3, 3, 3]}
                                data-test="product-less-button"
                            >
                                {isLoading(['less']) ? (
                                    <ClipLoader color={colors.black} size="20px" />
                                ) : (
                                    <Minus size="20px" />
                                )}
                            </CardActionButton>
                            <QuantityInputStyle
                                type="number"
                                value={quantityInput || ''}
                                onChange={handleSetQuantity}
                                onBlur={synchronizeQuantity(productInOrder.quantity)}
                                data-test="quantity-input"
                            />
                            <CardActionButton
                                $marginMultiplier={[0, 0, 0, 0.15]}
                                disabled={isLoading(['more', 'less', 'add']) || isNotEnoughStock}
                                onClick={handleMoreProduct}
                                $isLoading={isLoading(['more', 'less', 'add'])}
                                $borderRadius={[3, 3, 3, 3]}
                                data-test="product-more-button"
                            >
                                {isLoading(['more']) ? (
                                    <ClipLoader color={colors.black} size="20px" />
                                ) : (
                                    <Plus size="20px" />
                                )}
                            </CardActionButton>
                        </CardButtonGroup>
                    )}
                    {!isProductInOrder && (
                        <CardButtonGroup $borderRadius={[false, false, true, true]} $noBorder>
                            <CardActionButton
                                disabled={isLoading(['more', 'less', 'add']) || isNotEnoughStock}
                                $isLoading={isLoading(['more', 'less', 'add'])}
                                onClick={handleAddProduct}
                                data-test="product-add-button"
                            >
                                {isLoading(['add']) ? <ClipLoader color={colors.black} size="20px" /> : 'Ajouter'}
                            </CardActionButton>
                        </CardButtonGroup>
                    )}
                </>
            )}
        </Card>
    );
};
