// Paygreen payment types
const CB = 'CB';
const AMEX = 'AMEX';
const ECV = 'ECV';
const TRD = 'TRD';
const RESTOFLASH = 'RESTOFLASH';
const LUNCHR = 'LUNCHR'; // paygreen 3d party swile integration
const SEPA = 'SEPA';
const PASSRESTO = 'PASSRESTO';

const EDENRED = 'EDENRED';
const SWILE = 'SWILE'; // swile first party integration

export const PAYMENT_TYPE = {
    AMEX,
    CB,
    ECV,
    EDENRED,
    LUNCHR,
    PASSRESTO,
    RESTOFLASH,
    SEPA,
    SWILE,
    TRD,
};

const EUR = 'EUR';

export const CURRENCY = { EUR };

const CASH = 'CASH';
const RECURRING = 'RECURRING';
const XTIME = 'XTIME';
const TOKENIZE = 'TOKENIZE';

export const AVAILABLE_PAYMENT_MODE = { CASH, RECURRING, XTIME, TOKENIZE };

export const PAYGREEN_SUCCESS = 'SUCCESSED';
export const PAYGREEN_REFUSED = 'REFUSED';
export const PAYGREEN_PENDING_EXEC = 'PENDING_EXEC';
