import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Flex } from 'deprecated-enkel';

import { SEND_RESET_PASSWORD } from 'data/mutations';

import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';
import { Logo } from 'components/Logo';

import { TotemLabel, TotemInput } from 'styles';
import {
    LoginBottomHalfStyle,
    LoginFormStyle,
    LoginStyle,
    LoginTopHalfStyle,
    LoginWrapperStyle,
    PasswordReminderText,
    BorderBottomLink,
    BorderBottomText,
} from 'styles/pages/login.styles';
import { SignupTitleStyle } from 'styles/pages/signup.styles';

export const RequestResetPassword = () => {
    const [sendResetPassword] = useMutation(SEND_RESET_PASSWORD);
    const [resetResult, setResetResult] = useState({});
    const handleResetPassword = async (e) => {
        e.preventDefault();
        const emailAddress = e.target.email.value;
        if (emailAddress) {
            const {
                data: { sendResetPassword: result },
            } = await sendResetPassword({ variables: { emailAddress } });

            setResetResult(result);
        }
    };

    const { success, error } = resetResult;

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <LoginBottomHalfStyle>
                <LoginWrapperStyle>
                    {!success && (
                        <>
                            <SignupTitleStyle>Un mot de passe de perdu, dix de retrouvés</SignupTitleStyle>
                            <LoginFormStyle onSubmit={handleResetPassword}>
                                <TotemLabel color="white">
                                    Email Professionnel
                                    <TotemInput
                                        placeholder="Entrez l’email de votre compte"
                                        name="email"
                                        autoFocus
                                        data-test="login-username"
                                    />
                                    <InlineError display={error} message={error} />
                                </TotemLabel>
                                <Flex $alignItems="center" $direction="column">
                                    <TotemButton $margins={[1, 0, 1, 0]} type="submit" data-test="login-submit">
                                        M'envoyer un email
                                    </TotemButton>
                                    <BorderBottomText>
                                        Un retour de mémoire ?{' '}
                                        <BorderBottomLink to="/login">Connectez-vous</BorderBottomLink>
                                    </BorderBottomText>
                                </Flex>
                            </LoginFormStyle>
                        </>
                    )}
                    {success && (
                        <>
                            <PasswordReminderText yellow fontWeight="600">
                                Email trouvé !
                            </PasswordReminderText>
                            <PasswordReminderText> Vous devriez avoir reçu un lien par email.</PasswordReminderText>
                            <PasswordReminderText>
                                Cliquez dessus pour réinitialiser votre mot de passe !
                            </PasswordReminderText>
                        </>
                    )}
                </LoginWrapperStyle>
            </LoginBottomHalfStyle>
        </LoginStyle>
    );
};
