import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { Flex } from 'deprecated-enkel';
import { User } from '@totem/roles';

import { GET_EQUIPMENTS } from 'data/queries';
import {
    COFFEE_CATEGORY_ID,
    TOTEM_INSTALLATION_CATEGORY_ID,
    BEER_CATEGORY_ID,
    BEER_EQUIPMENT_CATEGORY_ID,
} from 'constants/categories-constants';

import { EquipmentCard } from 'pages/Totem/TotemDashboard/EquipmentCard';
import {
    SettingsTitleContainerWrapper,
    SettingsTitleContainer,
    SettingsTitle,
    SettingsPageStyle,
    SettingsCard,
    SettingsCredentialsSubtitle,
} from 'styles/pages/settings.styles';

import { CoffeeMaker } from '@styled-icons/material-rounded';

import { Loading } from 'components/Loading';

import { TotemLoadingStyle } from 'styles/pages/totem.styles';

export const Equipments = ({ currentTotem }) => {
    const { loading: loadingEquipments, error: errorEquipments, data: dataEquipments } = useQuery(GET_EQUIPMENTS);
    const [equipmentsRequested, setEquipmentsRequested] = useState([]);

    const { equipments = [] } = dataEquipments || {};

    const categoriesToDisplay = [
        { _id: TOTEM_INSTALLATION_CATEGORY_ID, title: 'Nos TOTEMs' },
        { _id: COFFEE_CATEGORY_ID, title: 'Nos machines à café' },
        { _id: BEER_CATEGORY_ID, title: 'Nos frigos' },
        { _id: BEER_EQUIPMENT_CATEGORY_ID, title: 'Nos tireuses à bière' },
    ];

    const isAnalyst = User.isAnalyst(currentTotem._id);

    if (loadingEquipments) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    if (errorEquipments) {
        throw Error(errorEquipments);
    }

    const hardEquipmentsWithDescription = equipments.filter(({ description }) => description);
    const hasHardEquipments = !!hardEquipmentsWithDescription.length;

    return (
        <SettingsPageStyle>
            <SettingsCard>
                <SettingsTitleContainerWrapper>
                    <SettingsTitleContainer>
                        <CoffeeMaker />
                        <SettingsTitle>Equipements</SettingsTitle>
                    </SettingsTitleContainer>
                </SettingsTitleContainerWrapper>
                {hasHardEquipments &&
                    categoriesToDisplay.map(({ _id: categoryId, title: categoryTitle }) => {
                        const hardEquipmentsForCategory = hardEquipmentsWithDescription.filter(
                            (hardEquipment) => hardEquipment.categories[0]._id === categoryId,
                        );

                        return hardEquipmentsForCategory.length ? (
                            <Flex $flow="column" key={categoryId}>
                                <SettingsCredentialsSubtitle>{categoryTitle}</SettingsCredentialsSubtitle>
                                <TotemEquipmentsStyle>
                                    {hardEquipmentsForCategory.map((hardEquipment) => {
                                        return (
                                            <EquipmentCard
                                                key={hardEquipment._id}
                                                equipment={hardEquipment}
                                                equipmentPicture={encodeURI(hardEquipment.imageUrl)}
                                                displayButton={!isAnalyst}
                                                totemId={currentTotem._id}
                                                totemNumber={currentTotem.number}
                                                equipmentsRequested={equipmentsRequested}
                                                setEquipmentsRequested={setEquipmentsRequested}
                                            />
                                        );
                                    })}
                                </TotemEquipmentsStyle>
                            </Flex>
                        ) : null;
                    })}
            </SettingsCard>
        </SettingsPageStyle>
    );
};

const TotemEquipmentsStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;
