import React from 'react';
import styled from 'styled-components';

export const SimpleImage = ({ width, height = 'auto', src, alt }) => {
    return <ImgStyle src={src} width={width} height={height} alt={alt} />;
};

export const ImgStyle = styled.img`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;
