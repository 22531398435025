import moment from 'moment';
import dayjs from 'dayjs';

import { NEW_FORMAT } from 'constants/date-constants';

export function isBankHoliday({ totemClosedDays, date }) {
    return totemClosedDays.includes(moment(date).format(NEW_FORMAT));
}

export function isDateBeforeNext(date, nextDate) {
    return date < nextDate;
}

export function isWeekEnd(date) {
    return ['6', '0'].includes(dayjs(date).format('d'));
}

export function getInitialVisibleMonth({ selectedDate, nextDeliveryDate }) {
    return selectedDate || moment(dayjs(nextDeliveryDate).format());
}
