import React, { createContext, useState, useEffect, useMemo, useRef, useContext } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import { VALIDATE_RATING } from 'data/mutations';
import { FORMAT } from 'constants/date-constants';
import { TotemClosedDaysContext } from 'contexts/TotemClosedDaysContext';

import { ErrorHandler } from 'utils/error-handler';

import { AsideWithOverlayContainer } from 'pages/Totem/Aside/AsideWithOverlayContainer';
import { BasketContent } from 'pages/Totem/Aside/Basket/BasketContent';
import { BillingContainer } from 'pages/Totem/Aside/Billing/BillingContainer';
import { DeliveryHoursContainer } from 'pages/Totem/Aside/DeliveryHours/DeliveryHoursContainer';
import { DeliveryDateContainer } from 'pages/Totem/Aside/DeliveryDate/DeliveryDateContainer';
import { OnsiteSetupContainer } from 'pages/Totem/Aside/OnsiteSetup/OnsiteSetupContainer';
import { SubscriptionContainer } from 'pages/Totem/Aside/Subscription/SubscriptionContainer';
import { DeliveryAccessInstructionsContainer } from 'pages/Totem/Aside/DeliveryInstructions/DeliveryAccessInstructionsContainer';
import { RatingPopupContent } from 'pages/Totem/TotemProducts/RatingPopupContent';
import { TotemHeader } from 'pages/Totem/TotemCategory/TotemHeader';
import { TotemProducts } from 'pages/Totem/TotemProducts';

import { Popup } from 'components/Popup';

import { TotemCategoryInner, TotemCategoryStyle } from 'styles/pages/totem.styles';

import { getNextAvailableDeliveryDate } from 'utils/time';

const TotemCategoryContext = createContext({
    filter: {},
    filterActions: {},
});

export const TotemCategory = ({
    totemId,
    navItem,
    categoryFlowSelected,
    currentCategory,
    order,
    ordersOfTotem,
    lastOrder,
    totems,
}) => {
    const totemClosedDays = useContext(TotemClosedDaysContext);
    const currentTotem = totems.find((totem) => totem._id === totemId);
    const nextAvailableDeliveryDate = dayjs(getNextAvailableDeliveryDate(totemClosedDays)).startOf('day');
    const currentDeliveryDate = dayjs(order.date_delivery, FORMAT);
    const hasDeliveryDatePassed = currentDeliveryDate < nextAvailableDeliveryDate;

    const previousCurrentCategory = useRef(null);
    const [showFilters, toggleFilters] = useState(false);
    const [allFilterItems, setAllFilterItems] = useState({});
    const [filterSearch, setFilterSearch] = useState('');
    const [filterPrice, setFilterPrice] = useState([]);
    const [filterBadge, setFilterBadge] = useState([]);
    const [filterBrand, setFilterBrand] = useState([]);
    const [filterTag, setFilterTag] = useState([]);
    const isOrderWithoutDateOrPassedDate = !order.date_delivery || hasDeliveryDatePassed;
    const [isBasketOpen, setIsBasketOpen] = useState(false);
    const [isBillingAsideOpen, setIsBillingAsideOpen] = useState(false);
    const [isDeliveryDateAsideOpen, setIsDeliveryDateAsideOpen] = useState(isOrderWithoutDateOrPassedDate);
    const [isDeliveryHoursAsideOpen, setIsDeliveryHoursAsideOpen] = useState(false);
    const [isOnsiteSetupInstructionsOpen, setIsOnsiteSetupInstructionsOpen] = useState(false);
    const [isDeliveryAccessInstructionsAsideOpen, setIsDeliveryAccessInstructionsAsideOpen] = useState(false);
    const [isSubscriptionAsideOpen, setIsSubscriptionAsideOpen] = useState(false);

    const nbOfOpenedAsideContainers = [
        isBasketOpen,
        isBillingAsideOpen,
        isDeliveryDateAsideOpen,
        isDeliveryHoursAsideOpen,
        isOnsiteSetupInstructionsOpen,
        isDeliveryAccessInstructionsAsideOpen,
        isSubscriptionAsideOpen,
    ].filter(Boolean).length;

    const [isRatingPopupOpen, setIsRatingPopupOpen] = useState(
        lastOrder && !lastOrder.appreciation && lastOrder.appreciation !== '',
    );
    const [validateRating] = useMutation(VALIDATE_RATING);

    const { recentOrders } = ordersOfTotem;
    const recentOrdersToCheck = recentOrders.filter(({ _id: recentOrderId }) => recentOrderId !== order._id);

    useEffect(() => {
        setIsDeliveryDateAsideOpen(isOrderWithoutDateOrPassedDate);
    }, [order, hasDeliveryDatePassed, isOrderWithoutDateOrPassedDate]);

    // As we do not really re-render this component, React tries to update the existing TotemCategory when switching from a category to another
    // Thus, the setState functions are not re-initialized! And that is why we need the useEffect
    // https://stackoverflow.com/questions/55254269/react-hooks-state-in-usestate-is-not-reset-when-route-is-changed/55255119
    useEffect(() => {
        handleToggleFilters(false);
        setFilterSearch('');
        // eslint-disable-next-line
    }, [currentCategory]);

    const categoryHasChanged = useMemo(() => {
        const hasChanged = currentCategory._id !== previousCurrentCategory.current;
        previousCurrentCategory.current = currentCategory._id;
        return hasChanged;
        // eslint-disable-next-line
    }, [currentCategory, order, previousCurrentCategory]);

    const handleToggleFilters = (show) => {
        toggleFilters(show);
        document.querySelectorAll('[data-overlay-for="popup-for-products"]').forEach((overlay) => {
            overlay.setAttribute('data-visible', show);
            overlay.onclick = show
                ? function () {
                      handleToggleFilters(false);
                  }
                : null;
        });
    };

    const bulkSetSelectedFilters = ({ filterTag, filterBadge, filterBrand, filterPrice }) => {
        setFilterTag(filterTag);
        setFilterBadge(filterBadge);
        setFilterBrand(filterBrand);
        setFilterPrice(filterPrice);
    };

    async function ratePreviousOrder({ appreciation = 0, feedback = '', badServicesObject = {} }) {
        setIsRatingPopupOpen(false);
        const badServices = Object.keys(badServicesObject).reduce((acc, badService) => {
            if (badServicesObject[badService]) {
                acc.push(badService);
            }
            return acc;
        }, []);
        validateRating({
            variables: {
                orderId: lastOrder._id,
                deliveryDate: lastOrder.date_delivery,
                totemId: lastOrder.totemId,
                appreciation,
                feedback,
                badServices,
            },
        });
    }

    async function skipPreviousOrderRating() {
        setIsRatingPopupOpen(false);
        validateRating({
            variables: {
                orderId: lastOrder._id,
                deliveryDate: lastOrder.date_delivery,
                totemId: lastOrder.totemId,
                appreciation: 0,
                feedback: '',
                badServices: [],
            },
        });
    }

    return (
        <>
            <Popup isOpen={isRatingPopupOpen} handleClose={skipPreviousOrderRating}>
                <RatingPopupContent
                    ratePreviousOrder={ratePreviousOrder}
                    handleClose={skipPreviousOrderRating}
                    weekday={order.weekday}
                />
            </Popup>
            <TotemCategoryStyle>
                <TotemCategoryInner>
                    <TotemCategoryContext.Provider
                        value={{
                            filter: {
                                allFilterItems: { ...allFilterItems },
                                filterSearch,
                                filterPrice,
                                filterBadge,
                                filterBrand,
                                filterTag,
                                showFilters,
                            },
                            filterActions: {
                                setAllFilterItems,
                                setFilterSearch,
                                setFilterPrice,
                                setFilterBadge,
                                setFilterBrand,
                                setFilterTag,
                                bulkSetSelectedFilters,
                                toggleFilters: handleToggleFilters,
                            },
                        }}
                    >
                        <ErrorHandler type="totem">
                            <TotemHeader
                                context={TotemCategoryContext}
                                currentCategory={currentCategory}
                                isBasketOpen={isBasketOpen}
                                order={order}
                                setIsBasketOpen={setIsBasketOpen}
                            />
                            <TotemProducts
                                totem={currentTotem}
                                categoryId={navItem}
                                categoryFlowSelected={categoryFlowSelected}
                                currentCategory={currentCategory}
                                context={TotemCategoryContext}
                                order={order}
                                lastOrder={!order.isPunctual ? lastOrder : null}
                                categoryHasChanged={categoryHasChanged}
                            />
                            <AsideWithOverlayContainer isOpen={isBasketOpen} setIsOpen={setIsBasketOpen} zIndex={2}>
                                <BasketContent
                                    currentTotem={currentTotem}
                                    onSiteSetupInstructions={
                                        order?.onsiteSetupInstructions || currentTotem?.consigneDelivery
                                    }
                                    order={order}
                                    ordersOfTotem={ordersOfTotem}
                                    setIsBillingAsideOpen={setIsBillingAsideOpen}
                                    setIsDeliveryDateAsideOpen={setIsDeliveryDateAsideOpen}
                                    setIsDeliveryAccessInstructionsAsideOpen={setIsDeliveryAccessInstructionsAsideOpen}
                                    setIsDeliveryHoursAsideOpen={setIsDeliveryHoursAsideOpen}
                                    setIsOnsiteSetupInstructionsOpen={setIsOnsiteSetupInstructionsOpen}
                                    setIsSubscriptionAsideOpen={setIsSubscriptionAsideOpen}
                                    setIsOpen={setIsBasketOpen}
                                />
                            </AsideWithOverlayContainer>
                            <AsideWithOverlayContainer
                                hasOverlay={nbOfOpenedAsideContainers < 2}
                                isOpen={isDeliveryHoursAsideOpen}
                                setIsOpen={setIsDeliveryHoursAsideOpen}
                                zIndex={3}
                            >
                                <DeliveryHoursContainer
                                    orderId={order._id}
                                    deliveryTimeWindow={order.deliveryTimeWindows?.[0]}
                                    setIsDeliveryHoursAsideOpen={setIsDeliveryHoursAsideOpen}
                                />
                            </AsideWithOverlayContainer>
                            <AsideWithOverlayContainer
                                hasOverlay={nbOfOpenedAsideContainers < 2}
                                forceOpen={isOrderWithoutDateOrPassedDate}
                                isOpen={isDeliveryDateAsideOpen}
                                setIsOpen={setIsDeliveryDateAsideOpen}
                                zIndex={3}
                            >
                                <DeliveryDateContainer
                                    order={order}
                                    nextAvailableDeliveryDate={nextAvailableDeliveryDate}
                                    recentOrdersToCheck={recentOrdersToCheck}
                                    setIsDeliveryDateAsideOpen={
                                        isOrderWithoutDateOrPassedDate ? () => undefined : setIsDeliveryDateAsideOpen
                                    }
                                    totemId={currentTotem._id}
                                />
                            </AsideWithOverlayContainer>
                            <AsideWithOverlayContainer
                                hasOverlay={nbOfOpenedAsideContainers < 2}
                                isOpen={isOnsiteSetupInstructionsOpen}
                                setIsOpen={setIsOnsiteSetupInstructionsOpen}
                                zIndex={3}
                            >
                                <OnsiteSetupContainer
                                    order={order}
                                    setIsOnsiteSetupInstructionsOpen={setIsOnsiteSetupInstructionsOpen}
                                    totem={currentTotem}
                                />
                            </AsideWithOverlayContainer>
                            <AsideWithOverlayContainer
                                hasOverlay={nbOfOpenedAsideContainers < 2}
                                isOpen={isDeliveryAccessInstructionsAsideOpen}
                                setIsOpen={setIsDeliveryAccessInstructionsAsideOpen}
                                zIndex={3}
                            >
                                <DeliveryAccessInstructionsContainer
                                    setIsDeliveryAccessInstructionsAsideOpen={setIsDeliveryAccessInstructionsAsideOpen}
                                    totem={currentTotem}
                                />
                            </AsideWithOverlayContainer>
                            <AsideWithOverlayContainer
                                hasOverlay={nbOfOpenedAsideContainers < 2}
                                isOpen={isSubscriptionAsideOpen}
                                setIsOpen={setIsSubscriptionAsideOpen}
                                zIndex={3}
                            >
                                <SubscriptionContainer setIsSubscriptionAsideOpen={setIsSubscriptionAsideOpen} />
                            </AsideWithOverlayContainer>
                            <AsideWithOverlayContainer
                                hasOverlay={nbOfOpenedAsideContainers < 2}
                                isOpen={isBillingAsideOpen}
                                setIsOpen={setIsBillingAsideOpen}
                                zIndex={3}
                            >
                                <BillingContainer
                                    currentTotem={currentTotem}
                                    setIsBillingAsideOpen={setIsBillingAsideOpen}
                                />
                            </AsideWithOverlayContainer>
                        </ErrorHandler>
                    </TotemCategoryContext.Provider>
                </TotemCategoryInner>
            </TotemCategoryStyle>
        </>
    );
};
