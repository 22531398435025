export const colors = {
    black: '#111111',
    hoveredBlack: '#333333',
    yellow: '#ffeb00',
    green: '#00ED76',
    greenDarker: '#29DD82',
    orange: '#ff6205',
    pink: '#ff00A8',
    blue: '#093AFB',
    blueOutline: '#2684FF', // not in the color palette,
    violet: '#8600FC',
    whiteGrey: '#F9F9F9', // white element is hovered
    culturedGrey: '#f2f0f1', // backgroud
    lightGrey: '#E0E0E0', // shadows OR [disabled] background + border
    borderGrey: '#c6c6c6', // border
    pantinGrey: '#939393', // secondary text, informative, placeholder
    darkGrey: '#404040', // dark element is hovered
    red: '#FF0000', // // not in the color palette, for errors
    lightRed: '#F46A6A', // // not in the color palette, for errors
};

export const variables = {
    asideWidth: '34em',
    asideWidthWithMargin: '35em',
    margin: 10,
    padding: 10,
    minWidth: 768,
    minHeight: 600,
    minWidthAdmin: 1024,
    borderRadius: '4px',
    maxWidth: '1200px',
    globalNavbarWidth: 75,
    specificNavbarWidth: 225,
};
