import React from 'react';
import styled, { css } from 'styled-components';
import { margin } from 'polished';

export const EmojiLabel = ({ children = '', position, src, size, spacing = 0 }) => {
    return (
        <EmojiLabelStyle size={size} position={position} spacing={spacing}>
            <span role="img" aria-label="Emoji">
                {src}
            </span>
            <span>{children}</span>
        </EmojiLabelStyle>
    );
};

const EmojiLabelStyle = styled.span`
    display: inline-flex;
    align-items: flex-end;

    > span:first-child {
        font-size: ${({ size = 'inherit' }) => size};
        ${({ spacing }) => margin(0, spacing, 0, 0)};

        ${({ position }) => {
            if (position) {
                const { top, right, bottom, left } = position;

                return css`
                    position: absolute;
                    top: ${top};
                    right: ${right};
                    bottom: ${bottom};
                    left: ${left};
                `;
            }
        }}
    }
`;
