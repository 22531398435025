import React from 'react';
import styled, { css } from 'styled-components';
import { OptimizedImage } from 'components/OptimizedImage';

import { buildImgUrl } from 'utils/image/buildImgUrl';

export const Loading = ({ relative, containerHeight = '100%', imageSize = 125 }) => {
    return (
        <LoadingStyle $relative={relative} $containerHeight={containerHeight}>
            <OptimizedImage
                blurOnLoad={false}
                src={buildImgUrl('img/illustrations/Totem_illustration-02.png')}
                width={imageSize}
            />
        </LoadingStyle>
    );
};

const LoadingStyle = styled.div`
    width: 100%;
    height: ${({ $containerHeight }) => $containerHeight};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    ${(props) =>
        !props.$relative &&
        css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        `};

    > img {
        animation: spin 2s linear infinite;

        @keyframes spin {
            100% {
                transform: rotate(360deg);
            }
        }
    }
`;
