import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Overlay = ({ children, name, inverse }) => {
    return (
        <OverlayStyle data-overlay-for={name} $inverse={inverse} data-visible="false">
            {children}
        </OverlayStyle>
    );
};

const OverlayStyle = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    background: ${(props) =>
        props.$inverse ? transparentize(0.35, 'white') : transparentize(0.35, props.theme.colors.black)};

    &[data-visible='false'] {
        display: none;
    }
`;
