import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PopupTitle } from 'styles/pages/settings.styles';
import { TotemButton } from 'ui/Button';

export const ValidateReplacingOrder = ({
    defaultCategoryId,
    destinationTotemId,
    handleCancel,
    handleDuplicateOrder,
    sameDayModifiableRecurringOrder,
    translatedWeekday,
}) => {
    const navigate = useNavigate();
    return (
        <>
            <PopupTitle>Une commande récurrente existe déjà pour cette date et à cette adresse.</PopupTitle>
            <DisclaimerText>
                Vous ne pouvez avoir qu'une seule commande récurrente par jour, souhaitez vous remplacer la commande
                récurrente du {translatedWeekday}? (cela prendra effet dès le{' '}
                {sameDayModifiableRecurringOrder.date_delivery})
            </DisclaimerText>
            <TotemButton
                $margins={[1, 0]}
                data-test="yes-replace"
                onClick={() => {
                    handleDuplicateOrder(false, sameDayModifiableRecurringOrder);
                }}
            >
                Oui, remplacer
            </TotemButton>
            <TotemButton
                $type="secondary"
                $margins={[1, 0]}
                data-test="no-modify"
                onClick={() =>
                    navigate(
                        `/totem/${destinationTotemId}/${defaultCategoryId}/${sameDayModifiableRecurringOrder?._id}`,
                        { relative: 'path' },
                    )
                }
            >
                Non, modifier la commande existante
            </TotemButton>
            <TotemButton $type="secondary" $margins={[1, 0]} onClick={() => handleCancel()} data-test="no-cancel">
                Non, annuler la duplication de commande
            </TotemButton>
        </>
    );
};

const DisclaimerText = styled.div`
    font-size: 1.2em;
    max-width: 450px;
    align-self: center;
    text-align: center;
`;
