import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Users } from 'styled-icons/fa-solid';
import { User } from '@totem/roles';

import { UserContext } from 'constants/user-context';
import { GET_USERS_OF_ORGANIZATION, GET_USER_GROUPS } from 'data/queries';

import { PrivilegesTable } from 'pages/Settings/SettingsPage/SettingsTeam/SettingsTeamPrivileges';
import { UsersTable } from 'pages/Settings/SettingsPage/SettingsTeam/SettingsTeamUsers';
import { EmployeesTable } from 'pages/Settings/SettingsPage/SettingsTeam/SettingsTeamEmployees';

import { Loading } from 'components/Loading';

import {
    SettingsTitleContainerWrapper,
    SettingsTitleContainer,
    SettingsTitle,
    SettingsSubtitle,
    SettingsPageStyle,
    SettingsCard,
} from 'styles/pages/settings.styles';

export const SettingsPageTeam = ({ totems }) => {
    const {
        user: currentUser,
        organization: { _id: organizationId, maxUsers },
    } = useContext(UserContext);
    const { error, loading, data, refetch: refetchUsers } = useQuery(GET_USERS_OF_ORGANIZATION);
    const {
        error: userGroupError,
        loading: userGroupLoading,
        data: userGroupData,
    } = useQuery(GET_USER_GROUPS, { variables: { organizationId } });

    if (error || userGroupError) {
        throw new Error();
    }
    if (loading || userGroupLoading) {
        return <Loading relative />;
    }

    const { usersOfOrganization: users } = data;
    const { userGroups } = userGroupData;

    const { teamUsers, employeeUsers } = users
        .filter((user) => !!user.profile?.organizationId)
        .reduce(
            (userTypes, user) => {
                if (currentUser._id === user._id) {
                    userTypes.teamUsers.unshift(user);
                } else if (User.isGlobalTeamMember(user.roles)) {
                    userTypes.teamUsers.push(user);
                } else {
                    userTypes.employeeUsers.push(user);
                }

                return userTypes;
            },
            {
                teamUsers: [],
                employeeUsers: [],
            },
        );

    return (
        <SettingsPageStyle>
            <SettingsCard>
                <SettingsTitleContainerWrapper>
                    <SettingsTitleContainer>
                        <Users />
                        <SettingsTitle>Utilisateurs</SettingsTitle>
                    </SettingsTitleContainer>
                </SettingsTitleContainerWrapper>
                <SettingsSubtitle>Gérez ici votre équipe : ajoutez jusqu'à {maxUsers} utilisateurs.</SettingsSubtitle>
                <SettingsSubtitle>Gérez aussi les droits d'accès : Admin, Editeur ou Analyste.</SettingsSubtitle>
                <PrivilegesTable />
            </SettingsCard>
            <SettingsCard>
                <UsersTable users={teamUsers} totems={totems} />
            </SettingsCard>
            <SettingsCard>
                <EmployeesTable
                    users={[...teamUsers, ...employeeUsers]}
                    userGroups={userGroups}
                    refetchUsers={refetchUsers}
                />
            </SettingsCard>
        </SettingsPageStyle>
    );
};
