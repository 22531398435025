import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Logo } from 'components/Logo';
import { TotemButton } from 'ui/Button';

import { OptimizedImage } from 'components/OptimizedImage';

import { LoginStyle, LoginTopHalfStyle } from 'styles/pages/login.styles';

export const SplitScreen = () => {
    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <SplitScreenWrapper>
                <SplitPart color="yellow" hasRightPadding>
                    <SectionHeader>
                        <SectionTitle>Magasin et App</SectionTitle>
                        <SectionSubTitleText>
                            Vous souhaitez découvrir l'offre magasin TOTEM ou simplement tester le service ?
                        </SectionSubTitleText>
                    </SectionHeader>
                    <SplitRow>
                        <SectionContent>
                            <LinksToStores>
                                <DownloadLink
                                    href="https://play.google.com/store/apps/details?id=thetotem.co&hl=fr&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DownloadLinkImg
                                        alt="Disponible sur Google Play"
                                        src="https://upload.wikimedia.org/wikipedia/commons/8/8e/Google_Play_Store_badge_FR.svg"
                                    />
                                </DownloadLink>
                                <DownloadLink
                                    href="https://apps.apple.com/fr/app/totem-votre-microstore/id1517575797?mt=8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DownloadLinkImg
                                        alt="Disponible sur l'App Store"
                                        src="https://www.mobizel.com/wp-content/uploads/2016/05/appstore1.png"
                                    />
                                </DownloadLink>
                            </LinksToStores>
                            <SplitPageButton
                                as="a"
                                $color="yellow"
                                $margins={[1, 0]}
                                href="https://totem.co/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Découvrez l'offre magasin
                            </SplitPageButton>
                        </SectionContent>
                        <MobileImageContainer>
                            <OptimizedImage from="cloudinary" src="webapp/mocks/appmobile-mockup_yggif2.png" />
                        </MobileImageContainer>
                    </SplitRow>
                </SplitPart>
                <SplitPart color="green">
                    <SectionHeader>
                        <SectionTitle>Livraisons</SectionTitle>
                        <SectionSubTitleText hasRightPadding>
                            Vous êtes gestionnaire d'entreprise ou office manager ? Faites livrer dans vos bureaux des
                            produits pour vos collaborateurs
                        </SectionSubTitleText>
                    </SectionHeader>
                    <SplitRow>
                        <SectionContent>
                            <SplitPageButton as={Link} $color="green" $margins={[0, 0, 1, 0]} to="/signup">
                                Créer mon compte
                            </SplitPageButton>
                            <Link to="/login">J'ai déjà un compte de gestionnaire</Link>
                        </SectionContent>
                        <WebImageContainer>
                            <OptimizedImage from="cloudinary" src="webapp/mocks/webapp-mockup_ksiv4d.png" />
                        </WebImageContainer>
                    </SplitRow>
                </SplitPart>
            </SplitScreenWrapper>
        </LoginStyle>
    );
};

const MobileImageContainer = styled.div`
    width: 48%;
    @media screen and (max-width: 1300px) {
        width: 40%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        height: inherit;
    }
`;

const WebImageContainer = styled.div`
    width: 60%;
    @media screen and (max-width: 1300px) {
        width: 50%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        height: inherit;
    }
`;

const SplitRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 60px;
`;

const SplitPageButton = styled(TotemButton)`
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme, $color }) => theme.colors[$color]};
    border: none;
    text-align: center;
    &:hover {
        background-color: ${({ theme }) => theme.colors.hoveredBlack};
    }
    @media screen and (max-width: 800px) {
        font-size: 1rem;
    }
    @media screen and (max-width: 450px) {
        font-size: 0.75rem;
    }
`;

const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

const SectionTitle = styled.h1`
    margin-top: 150px;
    margin-bottom: 30px;
    font-size: 100px;
    @media screen and (max-width: 1850px) {
        margin-top: 120px;
        font-size: 80px;
    }
    @media screen and (max-width: 1540px) {
        margin-top: 100px;
        font-size: 60px;
    }
    @media screen and (max-width: 1300px) {
        margin-top: 75px;
        font-size: 80px;
    }
    @media screen and (max-width: 1000px) {
        margin-top: 60px;
        font-size: 50px;
    }
    @media screen and (max-width: 450px) {
        font-size: 40px;
    }
`;

const SectionSubTitleText = styled.h1`
    font-size: 25px;
    padding-right: ${({ hasRightPadding }) => hasRightPadding && '80px'};
    @media screen and (max-width: 800px) {
        padding-right: ${({ hasRightPadding }) => hasRightPadding && '40px'};
    }
    @media screen and (max-width: 450px) {
        font-size: 20px;
    }
`;

const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 800px) {
        width: 45%;
    }
    width: 35%;
`;

const SplitPart = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${({ theme, color }) => theme.colors[color]};
    padding-left: 80px;
    padding-right: ${({ hasRightPadding }) => hasRightPadding && '80px'};
    height: 100%;
    width: 100%;
    @media screen and (max-width: 800px) {
        padding-left: 40px;
        padding-right: ${({ hasRightPadding }) => hasRightPadding && '40px'};
    }
`;

const SplitScreenWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1300px) {
        flex-direction: column;
        overflow: scroll;
        background: ${({ theme }) => theme.colors.green};
    }
`;

const LinksToStores = styled.div`
    display: flex;
    gap: 10px;
    max-width: 300px;
    margin: 10px 0;
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const DownloadLink = styled.a`
    width: 100%;
`;

const DownloadLinkImg = styled(OptimizedImage)`
    width: 100%;
`;
