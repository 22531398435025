import React from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import Select from 'react-select';

import { GET_TOTEMS } from 'data/queries';

import { TotemButton } from 'ui/Button';

import { PopupTitle, PopupSubtitle } from 'styles/pages/settings.styles';
import { TotemLabel, TotemSelect, TotemSelectInput } from 'styles';

import { createSelectOptionsWithValue } from 'utils';

export const ExistingTotemSelection = ({
    title,
    description,
    defaultTotemId,
    validateNewAddress,
    validateExistingTotem,
    isDuplicatingOrder = false,
}) => {
    const apolloClient = useApolloClient();
    const { totemsOfUser } = apolloClient.readQuery({
        query: GET_TOTEMS,
    });
    const totemAddressesToDuplicate = totemsOfUser.map((totem) => {
        const label = `${totem.address || 'Sans adresse'} (${totem.name || 'TOTEM #' + totem.number})`;
        return {
            value: totem._id,
            label,
        };
    });
    const totemAddressesToCreateNewFloor = totemsOfUser
        .filter((totem) => totem.address)
        .map((totem) => {
            const label = `Nouvel étage au ${totem.address} (${totem.name || 'TOTEM #' + totem.number})`;
            return {
                value: totem._id,
                label,
            };
        });
    const totemAddresses = isDuplicatingOrder ? totemAddressesToDuplicate : totemAddressesToCreateNewFloor;
    const newAddressOption = { value: 'newAddress', label: 'Nouvelle adresse' };
    const defaultAddress = totemAddresses.find((address) => address.value === defaultTotemId) || newAddressOption;
    const totemAddressOptions = createSelectOptionsWithValue([newAddressOption, ...totemAddresses], 'totemAddress');
    const handleConfirmAddress = async (e) => {
        e.preventDefault();
        if (e.target.address.value === 'newAddress') {
            validateNewAddress();
        } else {
            validateExistingTotem(totemsOfUser.find((totem) => totem._id === e.target.address.value));
        }
    };

    return (
        <Container>
            <PopupTitle>{title}</PopupTitle>
            {description && <PopupSubtitle>{description}</PopupSubtitle>}
            <TotemSelectionForm onSubmit={handleConfirmAddress}>
                <TotemLabel>
                    Adresse
                    <Select
                        name="address"
                        placeholder="Sélectionnez une adresse"
                        defaultValue={defaultAddress}
                        component={TotemSelect}
                        inputComponent={TotemSelectInput}
                        options={totemAddressOptions}
                        classNamePrefix="select-totem"
                    />
                </TotemLabel>
                <TotemButton type="submit" data-test="confirm-address">
                    Suivant
                </TotemButton>
            </TotemSelectionForm>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
`;

const TotemSelectionForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    gap: 15px;
`;
