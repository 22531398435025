import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { AtCircle } from '@styled-icons/ionicons-solid';
import { AuthContext } from '@totem/auth';
import { useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';

import { CHECK_PASSWORD, UPDATE_EMAIL } from 'data/mutations';

import { ChangePassword } from 'pages/Settings/SettingsPage/SettingsAccount/ChangePassword';

import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { handleTextInput, validateEmail } from 'utils';

import { TotemLabel, TotemInput } from 'styles';
import {
    SettingsTitleContainerWrapper,
    SettingsTitleContainer,
    SettingsTitle,
    SettingsCard,
    SettingsCredentialsSubtitle,
    CredentialsSubtitleContainer,
    Subtext,
} from 'styles/pages/settings.styles';

import { analyticsEvents, track } from 'tracking/segment';

export const LoginCredentials = ({ username }) => {
    const [emailValues, setEmailValues] = useState({ emailAddress: username, password: '' });
    const {
        authActions: { logout },
    } = useContext(AuthContext);
    const [updateEmail, { loading: emailLoading }] = useMutation(UPDATE_EMAIL);
    const [checkPassword, { loading: checkLoading }] = useMutation(CHECK_PASSWORD);
    const {
        register,
        clearErrors,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
    } = useForm({ reValidateMode: 'onSubmit', defaultValues: { emailAddress: username } });

    const emailFieldsFilled = emailValues.password && emailValues.emailAddress && emailValues.emailAddress !== username;

    const isLoading = emailLoading || checkLoading;

    const handleUpdateEmail = async () => {
        const { password, emailAddress } = emailValues;
        const trimmedEmailAddress = emailAddress.trim();

        const {
            data: { checkPassword: passwordIsCorrect },
        } = await checkPassword({ variables: { password } });

        if (!passwordIsCorrect) {
            const message = 'Mot de passe incorrect';
            setError('password', { type: 'invalid', message });
            return;
        }

        try {
            const {
                data: { updateEmail: response },
            } = await updateEmail({
                variables: { emailAddress: trimmedEmailAddress },
                refetchQueries: ['GET_USER', 'GET_ORGANIZATION'],
            });

            if (response.success) {
                track(analyticsEvents.UPDATE_EMAIL);
                setValue('password', '');
                setEmailValues({ password: '' });
            }
        } catch (error) {
            setError('emailAddress', { type: 'invalid', message: error.message.split(': ').pop() });
        }
    };

    const handleEmailInputs = (e) => {
        clearErrors(e.target.name);
        handleTextInput(e, setEmailValues, emailValues);
    };

    const handleForgottenPassword = async () => {
        logout();
        window.location.replace('/recover-password');
    };

    return (
        <SettingsCard>
            <SettingsTitleContainerWrapper>
                <SettingsTitleContainer>
                    <AtCircle />
                    <SettingsTitle>Mes identifiants</SettingsTitle>
                </SettingsTitleContainer>
            </SettingsTitleContainerWrapper>
            <form onSubmit={handleSubmit(handleUpdateEmail)}>
                <CredentialsSubtitleContainer>
                    <SettingsCredentialsSubtitle $noMargin>Changer l'adresse email</SettingsCredentialsSubtitle>
                    <TotemButton $fontSize="1em" disabled={!emailFieldsFilled} type="submit" $isLoading={isLoading}>
                        {isLoading ? <ClipLoader color="white" size="16px" /> : 'Mettre à jour'}
                    </TotemButton>
                </CredentialsSubtitleContainer>
                <TotemLabel>
                    Email de connexion <InlineError display={errors.emailAddress} />
                    <TotemInput
                        placeholder="Entrez votre email de connexion"
                        type="email"
                        {...register('emailAddress', {
                            required: true,
                            validate: validateEmail('Votre mail n’est pas valide'),
                        })}
                        onChange={handleEmailInputs}
                    />
                </TotemLabel>
                <TotemLabel>
                    Mot de passe <InlineError display={errors.password} />
                    <TotemInput
                        placeholder="Entrez votre mot de passe"
                        type="password"
                        {...register('password', { required: true })}
                        onChange={handleEmailInputs}
                    />
                </TotemLabel>
                <Subtext onClick={handleForgottenPassword}>Mot de passe oublié ?</Subtext>
            </form>
            <ChangePassword />
        </SettingsCard>
    );
};
