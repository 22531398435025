import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { IconFileDownload } from '@tabler/icons-react';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';

import { DOWNLOAD_ZUORA_INVOICE } from 'data/queries';

import { IconButton } from 'ui/Button';

import { InvoicesTableStyle, SettingsSubtitle } from 'styles/pages/settings.styles';
import { FORMAT } from 'constants/date-constants';

/**
 * Creates an anchor element `<a></a>` with
 * the base64 pdf source and a filename with the
 * HTML5 `download` attribute then clicks on it.
 * @param  {string} base64pdf
 * @return {void}
 */
function downloadPDF({ base64pdf, fileName }) {
    const linkSource = `data:application/pdf;charset=utf-8;base64,${encodeURI(base64pdf)}`;
    const downloadLink = document.createElement('a');
    const downloadFileName = `${fileName}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = downloadFileName;
    downloadLink.click();
}

export const ZuoraInvoicesTable = ({ invoices }) => {
    const [invoiceBeingDownloaded, setInvoiceBeingDownloaded] = useState(undefined);
    const sortedInvoices = [...invoices].sort((a, b) => (dayjs(a.invoiceDate).isBefore(b.invoiceDate) ? 1 : -1));
    const [downloadZuoraInvoice, { loading: isDownloadingInvoice }] = useLazyQuery(DOWNLOAD_ZUORA_INVOICE, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data?.downloadZuoraInvoice?.success) {
                downloadPDF({
                    base64pdf: data?.downloadZuoraInvoice?.file,
                    fileName: `TOTEM_${invoiceBeingDownloaded?.invoiceDate}_${invoiceBeingDownloaded?.invoiceNumber}`,
                });
            } else {
                toast.error(data?.downloadZuoraInvoice?.error);
            }
        },
    });

    return (
        <>
            {sortedInvoices?.length === 0 ? (
                <SettingsSubtitle $topMargin="15px">Pas de facture pour le moment</SettingsSubtitle>
            ) : (
                <>
                    <InvoicesTableStyle>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Numéro</th>
                                <th>Montant</th>
                                <th>Facture</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedInvoices.map((invoice) => {
                                const invoiceDate = dayjs(invoice.invoiceDate).format(FORMAT);
                                return (
                                    <tr key={invoice.id}>
                                        <td>{invoiceDate}</td>
                                        <td>{invoice.invoiceNumber}</td>
                                        <td>{`${invoice.amount}€`}</td>
                                        <td width="5%">
                                            {invoice.invoiceFiles && (
                                                <IconButton
                                                    $isLoading={isDownloadingInvoice}
                                                    disabled={isDownloadingInvoice}
                                                    onClick={() => {
                                                        setInvoiceBeingDownloaded(invoice);
                                                        downloadZuoraInvoice({
                                                            variables: {
                                                                zuoraInvoiceFilesUrl: invoice.invoiceFiles,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <IconFileDownload />
                                                </IconButton>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </InvoicesTableStyle>
                </>
            )}
        </>
    );
};
