import { customErrorFactory } from 'ts-custom-error';

import { PartialUserInterface, ErrorNameType, ErrorGeneratorInterface, ErrorMetaObject } from './interfaces';

import { ConfigureScope } from './configurations';
import ErrorMeta, { ErrorName } from './errors';

export { ErrorMeta, ErrorName };

/**
 * Generates an internal error instance which can be used to create a custom error class.
 * ```js
 * import Sentry from '@sentry/node';
 * const ErrorInstance = GeneratedErrorInstance(Sentry, false)
 * ```
 * @param {Sentry} sentry The imported Sentry object.
 * @param {bool} [isDevelopment] Whether the environment is development or not. Determines if logs will be sent to Sentry.
 */
export function GenerateErrorInstance(sentry: any): ErrorGeneratorInterface {
    /**
     * Used to create a custom class instance which provides information throughout the entire application.
     * Should ideally be called in the `context` to pass contextual information.
     *
     * @param {User} user The user object present in the context.
     */
    return function InternalErrorInstanceGenerator(user?: PartialUserInterface): ErrorConstructor {
        /**
         * The primary custom error class, which is to be used everywhere to send relevant error data to the frontend.
         */
        return customErrorFactory(function TotemError<Error>(errorCode: ErrorNameType, error?: Error | string) {
            console.log('');
            const errorMeta = ErrorMeta[errorCode] || ErrorMeta[ErrorName.INTERNAL];
            const { status, message: errorMessage, global, level } = errorMeta;
            const message = error instanceof Error ? error.message : errorMessage;
            // Higher-level setup

            // Local setup
            this.stack = error instanceof Error ? error.stack : this.stack;
            this.status = status;
            this.message = message;
            this.global = global;
            this.level = level;

            let sentryFunction: any = sentry.captureException;

            if (typeof error === 'string' && level !== 'debug') {
                sentryFunction = sentry.captureEvent;
            } else if (typeof error === 'string' && level === 'debug') {
                sentryFunction = sentry.captureMessage;
            }

            sentry.withScope(
                ConfigureScope(
                    sentry.severity,
                    {
                        user,
                        errorContext: '@totem/errors',
                        level,
                        errorInfo: {
                            message: errorMessage,
                            errorCode,
                            status,
                            global,
                        },
                    },
                    sentryFunction,
                    this
                )
            );

            /**
             * Resetting message so that errorFormatter can receive the code instead of the message.
             */
            this.message = errorCode;
            throw this;
        });
    };
}

export function errorFormatter(log = false) {
    return function ({ message: code, ...error }: { message: ErrorNameType }): ErrorMetaObject {
        if (log) {
            console.log(code, error);
        }
        const { level, status, message, global } = ErrorMeta[code] || ErrorMeta[ErrorName.INTERNAL];

        return {
            ...error,
            code,
            level,
            status,
            message,
            global,
        };
    };
}
