import React from 'react';

import { Checkbox } from 'ui/Checkbox';
import { CardInfo } from './CardInfo';
import { IbanInfo } from './IbanInfo';

import { InvoicesTableStyle } from 'styles/pages/settings.styles';
import { AddPaymentPopup } from 'pages/Settings/SettingsPage/SettingsPayment/PopupWindow';
import { BANK_TRANSFER, STRIPE_CARD } from 'constants/organization';

export const PaymentCards = ({
    organization,
    user,
    hasCards,
    selectedCardIndex,
    originalCardIndex,
    setSelectedCardIndex,
    setStripeDefaultPaymentMethod,
    setSelectedCardModified,
    popupWindowOpen,
    setPopupWindowOpen,
    invoiceValues,
}) => {
    const hasBankTransfer = organization.paymentType === BANK_TRANSFER;

    function handleSelectCard(index) {
        setSelectedCardModified(index !== originalCardIndex);
        setSelectedCardIndex(index);
    }

    return (
        <>
            <InvoicesTableStyle>
                <thead>
                    {hasCards && (
                        <tr>
                            <th />
                            <th>Type de paiement</th>
                            <th>Mode de paiement</th>
                            <th>Nom du titulaire</th>
                            <th>Expiration</th>
                            <th>Supprimer</th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {hasBankTransfer ? (
                        <tr>
                            <td>
                                <Checkbox readOnly checked />
                            </td>
                            <td />
                            <td>Virement bancaire</td>
                            <td>{organization.billingInfo?.invoiceName || organization.name}</td>
                            <td />
                            <td />
                        </tr>
                    ) : (
                        hasCards &&
                        organization.cards.map((paymentMethod, i) => (
                            <tr key={paymentMethod.id}>
                                <td>
                                    <Checkbox checked={selectedCardIndex === i} onChange={() => handleSelectCard(i)} />
                                </td>
                                {paymentMethod.card || paymentMethod.object === STRIPE_CARD ? (
                                    <CardInfo
                                        paymentMethod={paymentMethod}
                                        numberOfCards={organization.cards.length}
                                        organizationId={organization._id}
                                    />
                                ) : (
                                    <IbanInfo
                                        paymentMethod={paymentMethod}
                                        numberOfCards={organization.cards.length}
                                        organizationId={organization._id}
                                    />
                                )}
                            </tr>
                        ))
                    )}
                </tbody>
            </InvoicesTableStyle>
            {popupWindowOpen && (
                <AddPaymentPopup
                    organization={organization}
                    user={user}
                    setStripeDefaultPaymentMethod={setStripeDefaultPaymentMethod}
                    setPopupWindowOpen={setPopupWindowOpen}
                    invoiceValues={invoiceValues}
                />
            )}
        </>
    );
};
