import React from 'react';
import styled from 'styled-components';
import { Image } from 'cloudinary-react';

import { buildImgUrl } from 'utils/image/buildImgUrl';

export const ProductBadges = ({ badges }) => {
    return (
        <BadgesContainer>
            {badges && badges.length > 0
                ? badges.map((badge) => {
                      return (
                          <BadgeContainer key={badge.name}>
                              <Image src={buildImgUrl(badge.imageUrl)} height={36} loading="lazy" />
                          </BadgeContainer>
                      );
                  })
                : null}
        </BadgesContainer>
    );
};

const BadgesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 5px;
`;

const BadgeContainer = styled.div`
    margin-left: 5px;
`;
