import React, { useContext } from 'react';
import { X } from '@styled-icons/boxicons-regular';

import { BasketProducts } from 'pages/Totem/Aside/Basket/BasketProducts';

import { BasketDeliveryInfos } from 'pages/Totem/Aside/Basket/BasketDeliveryInfos';
import { BasketPriceDetail } from 'pages/Totem/Aside/Basket/BasketPriceDetail';
import { AsideBody, AsideHeader, AsideTitle, AsideSubTitle } from 'pages/Totem/Aside/AsideComponents';

import { IconButton } from 'ui/Button';
import { BasketFooter } from 'pages/Totem/Aside/Basket/BasketFooter';
import { BasketPaymentInfos } from 'pages/Totem/Aside/Basket/BasketPaymentInfos';

import { UserContext } from 'constants/user-context';

export const BasketContent = ({
    currentTotem,
    onSiteSetupInstructions,
    order,
    ordersOfTotem,
    setIsBillingAsideOpen,
    setIsDeliveryDateAsideOpen,
    setIsDeliveryAccessInstructionsAsideOpen,
    setIsDeliveryHoursAsideOpen,
    setIsOnsiteSetupInstructionsOpen,
    setIsSubscriptionAsideOpen,
    setIsOpen,
}) => {
    const { organization } = useContext(UserContext);
    const { products: productsFromOrder } = order;
    return (
        <>
            <AsideHeader>
                <AsideTitle>Votre commande</AsideTitle>
                <IconButton data-test="close-basket" $rotateOnHover $large $white onClick={() => setIsOpen(false)}>
                    <X size="25px" />
                </IconButton>
            </AsideHeader>
            <AsideBody>
                <AsideSubTitle>Infos de livraison</AsideSubTitle>
                <BasketDeliveryInfos
                    hasSubscription={organization.hasSubscription}
                    onSiteSetupInstructions={onSiteSetupInstructions}
                    order={order}
                    ordersOfTotem={ordersOfTotem}
                    setIsDeliveryDateAsideOpen={setIsDeliveryDateAsideOpen}
                    setIsDeliveryAccessInstructionsAsideOpen={setIsDeliveryAccessInstructionsAsideOpen}
                    setIsDeliveryHoursAsideOpen={setIsDeliveryHoursAsideOpen}
                    setIsOnsiteSetupInstructionsOpen={setIsOnsiteSetupInstructionsOpen}
                    totem={currentTotem}
                />
                <AsideSubTitle>Produits</AsideSubTitle>
                <BasketProducts products={productsFromOrder} />
                <AsideSubTitle>Paiement</AsideSubTitle>
                <BasketPaymentInfos organization={organization} setIsBillingAsideOpen={setIsBillingAsideOpen} />
                <AsideSubTitle>Total</AsideSubTitle>
                <BasketPriceDetail
                    order={order}
                    hasSubscription={organization.hasSubscription}
                    setIsSubscriptionAsideOpen={setIsSubscriptionAsideOpen}
                />
            </AsideBody>
            <BasketFooter
                currentTotem={currentTotem}
                order={order}
                ordersOfTotem={ordersOfTotem}
                setIsBillingAsideOpen={setIsBillingAsideOpen}
                setIsDeliveryAccessInstructionsAsideOpen={setIsDeliveryAccessInstructionsAsideOpen}
            />
        </>
    );
};
