import React, { memo } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { SETTINGS_PAGES } from 'constants/settings-pages';

import { ErrorHandler } from 'utils/error-handler';

import { SettingsNav } from 'pages/Settings/SettingsNav';
import { SettingsPage } from 'pages/Settings/SettingsPage';

import { isClient } from 'utils';

import { SettingsWrapper, SettingsInner, SettingsScrollable } from 'styles/pages/settings.styles';

export const Settings = memo(function SettingsComponent({ user, totems }) {
    const isToteam = !isClient();

    return (
        <SettingsWrapper>
            <SettingsNav />
            <ErrorHandler type="settings">
                <SettingsInner>
                    <SettingsScrollable>
                        <Routes>
                            {SETTINGS_PAGES.map(({ key, roles }) => {
                                const hasAccess = isToteam || user.roles.global.some((role) => roles.includes(role));
                                return hasAccess ? (
                                    <Route
                                        key={key}
                                        path={`/${key}`}
                                        element={<SettingsPage pageKey={key} user={user} totems={totems} />}
                                    />
                                ) : (
                                    <Route key={key} path={`/${key}`} render={() => <Navigate to="/account" />} />
                                );
                            })}
                            <Route render={() => <Navigate to="/account" />} />
                        </Routes>
                    </SettingsScrollable>
                </SettingsInner>
            </ErrorHandler>
        </SettingsWrapper>
    );
});
