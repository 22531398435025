import { gql } from '@apollo/client';

export function organizationCardUpdatedCacheHandler({ apolloClient, organizationCard }) {
    const data = apolloClient.readFragment({
        id: `User:${organizationCard.userId}`,
        fragment: gql`
            fragment UserOrganizationCard on User {
                wallet {
                    organizationCard
                }
            }
        `,
    });

    apolloClient.writeFragment({
        id: `User:${organizationCard.userId}`,
        fragment: gql`
            fragment UserOrganizationCard on User {
                wallet {
                    organizationCard
                }
            }
        `,
        data: {
            wallet: { ...data.wallet, organizationCard },
        },
    });
}
