import * as Sentry from '@sentry/react';
import { ErrorName } from '@totem/errors';
import { TOKEN } from 'constants';

export const createErrorHandler =
    (history) =>
    ({ graphQLErrors }) => {
        if (graphQLErrors) {
            const [error] = graphQLErrors;
            const { code, message, locations, path } = error;

            const formattedMessage = [
                '[GraphQLError]',
                `Message: ${message}`,
                `Location: ${JSON.stringify(locations)}`,
                `Path: ${path}`,
            ].join('\n');

            // TODO: Update with the new logger when available
            Sentry.captureEvent({
                level: 'error',
                message: formattedMessage,
                contexts: { error },
            });

            console.error(formattedMessage);

            if (code === ErrorName.AHEBANNED || code === ErrorName.AHEARCHIVED) {
                history.replace(`/sven?reason=${code}`);
                // TODO should not be required, but app does not rerender on location change
                history.go();
            }
            if (code === ErrorName.AHERA) {
                window.TOTEM.storage.remove(TOKEN);
                history.replace('/login');
                history.go();
            }
        }
    };
