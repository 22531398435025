import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ChevronLeft } from '@styled-icons/boxicons-regular';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

import { GET_ARCHIVED_ORDERS } from 'data/queries';

import { FORMAT } from 'constants/date-constants';

import { OrderHistoryTable } from './OrderHistoryTable';
import { Loading } from 'components/Loading';

import { TotemButton } from 'ui/Button';

import { TotemCard } from 'styles/pages/totem.styles';
import { colors } from 'styles/theme';

export const OrderHistory = ({ baseUrl, currentTotem, defaultCategoryId, ordersOfTotem }) => {
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const {
        loading: archivedOrdersLoading,
        error: archivedOrdersError,
        data: archivedOrdersData,
        fetchMore: fetchMoreArchivedOrders,
    } = useQuery(GET_ARCHIVED_ORDERS, {
        variables: { offset: 0, totemId: currentTotem._id },
        skip: !currentTotem?._id,
    });
    if (archivedOrdersError) {
        throw Error(archivedOrdersError);
    }
    if (archivedOrdersLoading) {
        return <Loading />;
    }
    const {
        archivedOrders: { hasMore: hasMoreArchivedOrders, archivedOrders },
    } = archivedOrdersData;

    const loadMoreArchivedOrders = async () => {
        await fetchMoreArchivedOrders({
            variables: {
                offset: archivedOrders.length,
            },
        });
    };

    const ordersToShow = archivedOrders
        .filter((order) => order)
        .sort((a, b) => (dayjs(a.date_delivery, FORMAT).isBefore(dayjs(b.date_delivery, FORMAT)) ? -1 : 1));

    return (
        <TotemOrderHistoryWrapper>
            <BackButton to={`/totem/${currentTotem._id}`}>
                <ChevronLeft size={18} />
                Retour
            </BackButton>
            <TotemOrderHistoryTitle>Mon historique de commande</TotemOrderHistoryTitle>
            <TotemBigCard>
                <>
                    {ordersToShow.length ? (
                        <>
                            <OrderHistoryTable
                                baseUrl={baseUrl}
                                defaultCategoryId={defaultCategoryId}
                                nbOrderToShow={ordersToShow.length}
                                ordersOfTotem={ordersOfTotem}
                                ordersToShow={ordersToShow}
                                isOnboarding={false}
                            />
                            {hasMoreArchivedOrders && (
                                <TotemButton
                                    $type="secondary"
                                    $alignSelf="center"
                                    $fontSize="1em"
                                    onClick={async () => {
                                        setIsFetchingMore(true);
                                        await loadMoreArchivedOrders();
                                        setIsFetchingMore(false);
                                    }}
                                >
                                    {isFetchingMore ? <ClipLoader color={colors.black} size="16px" /> : 'Voir plus'}
                                </TotemButton>
                            )}
                        </>
                    ) : (
                        <ScheduleText>Une fois terminées, vos commandes apparaîtront ici !</ScheduleText>
                    )}
                </>
            </TotemBigCard>
        </TotemOrderHistoryWrapper>
    );
};

const TotemOrderHistoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 40px;
    flex-shrink: 1;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
`;

const TotemOrderHistoryTitle = styled.div`
    font-size: 36px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: ${(props) => props.theme.colors.black};
    margin: 10px 0;
    width: max-content;
    text-transform: uppercase;
`;

const BackButton = styled(Link)`
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
`;

const ScheduleText = styled.div`
    font-size: 18px;
`;

const TotemBigCard = styled(TotemCard)`
    padding-bottom: 10px;
    margin-bottom: 30px;
`;
