import gql from 'graphql-tag';

export const GET_BADGES = gql`
    query GET_BADGES {
        badges {
            _id
            name
            type
        }
    }
`;
