export const isEveryRequiredFieldFilled = (valueArray, ...requiredFields) => {
    let fieldsFilled = true;

    requiredFields.forEach((field) => {
        if (!valueArray[field]) {
            fieldsFilled = false;
        }
    });
    return fieldsFilled;
};
