import { ENV_PREPROD, ENV_PROD } from 'constants/environment-constants';

import { isClient } from 'utils';

/**
 * NAMING CONVENTIONS FOR ANALYTICS
 * - events : all lowercase, action->object (action is an infinitive verb)
 * - properties : camelCase
 * - sources : camelCase
 */

/**
 * NAMING CONVENTIONS FOR ANALYTICS
 * - events : all lowercase, action->object (action is an infinitive verb)
 */
export const analyticsEvents = {
    ADD_CARD: 'add card',
    ADD_IBAN: 'add IBAN',
    ADD_COMPANY_INFO: 'add company info',
    ADD_PERSONAL_INFO: 'add personal info',
    ADD_TOTEM_DELIVERY_ADDRESS: 'add totem delivery address',
    ADD_PRODUCT: 'add product',
    ADD_USER_TO_ORG: 'add user to organization',
    ADD_USERS_TO_ORG: 'add users to organization',
    ASK_INFO_EQUIPMENT: 'ask info equipment',
    CLICK_TOTEM_ICON: 'click on totem icon',
    CLOSE_ORDER_DETAILS: 'close order details',
    CLOSE_PRODUCTS_CART: 'close products cart',
    CONFIRM_ORDER: 'confirm order', // first order of the totem
    COPY_REFERRAL_CODE: 'copy referral code',
    CREATE_ADDITIONAL_TOTEM: 'create additional totem',
    CREATE_ORDER: 'create order',
    CREATE_PRODUCT_SUGGESTION: 'create product suggestion',
    DELETE_ORDER: 'delete order',
    DELETE_PAYMENT_METHOD: 'delete payment method',
    DISCARD_TOTEM: 'discard totem',
    DUPLICATE_ORDER: 'duplicate order',
    LOG_IN: 'log in',
    LOG_OUT: 'log out',
    MODIFY_DELIVERY_INFO: 'modify delivery info',
    MODIFY_ORDER: 'modify order',
    MODIFY_PRODUCT_QUANTITY: 'modify product quantity',
    OPEN_ORDER_DETAILS: 'open order details',
    OPEN_PRODUCTS_CART: 'open products cart',
    OPEN_PRODUCTS_FILTER: 'open products filter',
    OPEN_TOTEM_BUDGET_MODAL: 'open totem budget modal',
    OPEN_DELIVERY_SUBSCRIPTION_SECTION: 'open delivery subscription section',
    OPEN_NAVBAR_OVERLAY: 'open navbar overlay',
    OPEN_TOTEM_DELIVERY_INFO: 'open totem delivery info',
    PAUSE_ORDER: 'pause order',
    REACTIVATE_ORDER: 'reactivate order',
    REMOVE_PRODUCT: 'remove product',
    SET_HAS_ONSITE_SETUP: 'set has onsite setup',
    SET_ONSITE_SETUP_INSTRUCTIONS: 'set onsite setup instructions',
    SEE_ORDER_PHOTOS: 'see order photos',
    SET_ORDER_DELIVERY_DATETIME: 'set order delivery datetime',
    SET_PRODUCTS_FILTER: 'use products filter',
    SET_TOTEM_BUDGET: 'set totem budget',
    SEND_REFERRAL_EMAIL: 'send referral email',
    SIGNUP: 'signup',
    START_ADDING_PAYMENT_METHOD: 'start adding payment method',
    START_ORDER_CREATION: 'start order creation',
    START_ORDER_DUPLICATION: 'start order duplication',
    START_ORDER_PAUSING: 'start order pausing',
    START_PRODUCT_SUGGESTION: 'start product suggestion',
    START_TOTEM_CREATION: 'start totem creation',
    SUBSCRIBE_TO_UNLIMITED_DELIVERY: 'subscribe to unlimited delivery',
    UPDATE_DELIVERY_DATE: 'update delivery date',
    UPDATE_DELIVERY_HOURS: 'update delivery hours',
    UPDATE_DELIVERY_INSTRUCTION: 'update delivery instructions',
    UPDATE_EMAIL: 'update email',
    UPDATE_TOTEM_NAME: 'update totem name',
    UPDATE_USER: 'update user',
    VALIDATE_BILLING_INFO: 'validate billing info',
    VALIDATE_ORDER: 'validate order',
};

/**
 * Event properties
 *
 * Properties are attributes that deliver additional context around the events triggered.
 *
 * NAMING CONVENTIONS FOR ANALYTICS
 * - properties : camelCase
 */
export const analyticsPropertiesConstants = {
    COMMON: {
        IS_ONBOARDING: 'isOnboarding',
        SOURCE: 'source', // defines the context from which the event was triggered (see analyticsSourceConstants)
        IS_EMPLOYEE: 'isEmployee',
    },
    EQUIPMENTS: {
        EQUIPMENT_NAME: 'equipmentName',
        EQUIPMENT_ID: 'equipmentId',
    },
    ONBOARDING: {
        DELIVERY_CITY: 'deliveryCity',
        COMPANY_TYPE: 'companyType',
        JOB_TITLE: 'jobTitle',
    },
    ORDER: {
        BILLING: {
            HAS_PO_NUMBER: 'hasPONumber',
            HAS_TVA_NUMBER: 'hasTVANumber',
            PAYMENT_METHOD: 'paymentMethod', //
        },
        DELIVERY: {
            CITY: 'city',
            IS_OUTSIDE_DELIVERY_ZONE: 'isOutsideDeliveryZone',
            POSTAL_CODE: 'postalCode',
            PRICE: 'deliveryPrice',
            HAS_ON_SITE_SETUP: 'hasOnsiteSetup',
        },
        DUPLICATION: {
            IS_FOR_SAME_TOTEM: 'isForSameTotem',
            IS_CREATING_NEW_TOTEM: 'isCreatingNewTotem',
            IS_REPLACING: 'isReplacing',
        },
        IS_PUNCTUAL: 'isPunctual',
        TOTAL: 'total', // has to be a number, in euros (23,60€ should be 23.6)
    },
    PRODUCTS: {
        BRAND: 'brand',
        CATEGORY: 'category',
        CATEGORY_ID: 'categoryId',
        FILTERS: {
            HAS_BADGE_FILTER: 'hasBadgeFilter',
            HAS_BRAND_FILTER: 'hasBrandFilter',
            HAS_PRICE_FILTER: 'hasPriceFilter',
            HAS_TAG_FILTER: 'hasTagFilter',
        },
        ID: 'id',
        NAME: 'name',
    },
    SIGNUP: {
        UTM_CAMPAIGN: 'utmCampaign',
        UTM_CONTENT: 'utmContent',
        UTM_MEDIUM: 'utmMedium',
        UTM_SOURCE: 'utmSource',
        UTM_TERM: 'utmTerm',
    },
    TOTEM: {
        NUMBER: 'number',
        IS_SECONDARY_TOTEM: 'isSecondaryTotem',
    },
};

/**
 * Event source constants
 *
 * NAMING CONVENTIONS
 * - sources : camelCase
 */
export const analyticsSourceConstants = {
    CHECKOUT_BASKET: 'checkout basket',
    DASHBOARD: 'dashboard',
    ORDER_CREATION: 'orderCreation',
    SETTINGS: 'settings',
};

export const load = () => {
    [ENV_PROD, ENV_PREPROD].includes(window.__TOTENV__.ENV) &&
        isClient() &&
        window.analytics.load(window.__TOTENV__.SEGMENT_WRITE_KEY);
};

export const page = () => {
    [ENV_PROD, ENV_PREPROD].includes(window.__TOTENV__.ENV) && isClient() && window.analytics.page();
};

export const track = (name, properties) => {
    [ENV_PROD, ENV_PREPROD].includes(window.__TOTENV__.ENV) && isClient() && window.analytics.track(name, properties);
};

export const identify = ({ id, name, email, createdAt }) => {
    [ENV_PROD, ENV_PREPROD].includes(window.__TOTENV__.ENV) &&
        isClient() &&
        window.analytics.identify(id, {
            createdAt,
            email,
            name,
        });
};
