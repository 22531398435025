import React from 'react';
import { OptimizedImage } from 'components/OptimizedImage';
import styled from 'styled-components';
import { padding } from 'polished';

import { CREATED } from 'constants/states-app';
import { PRODUCT_FLOW, PRODUCT_FLOW_LABEL } from 'constants/product-constants';

import {
    TotemNavCategories,
    TotemNavCategoriesScrollable,
    TotemNavInner,
    TotemNavLink,
    TotemNavStyle,
} from 'styles/pages/totem.styles';

import { Tag } from 'ui/Tag';
import { Logo } from 'components/Logo';

import { getCategoriesInOrderInfo } from 'utils/orders';
import { formUrl, isCategoryAvailable } from 'utils';

export const TotemNav = ({ categoryFlowSelected, totemId, navItem, categories, order }) => {
    const toUrl = formUrl('totem', totemId, true);
    const isOrderPunctual = order.isPunctual;

    const createTestingTags = (state) => {
        return state === CREATED ? <Tag invertColor>Testing</Tag> : null;
    };

    if (!order || !order._id) {
        return (
            <>
                <TotemNavStyle>
                    <Logo dark isLink={false} width={100} />
                    <TotemNavInner />
                </TotemNavStyle>
            </>
        );
    }

    const filteredCategories = categories.filter((category) => isCategoryAvailable(category));
    const categoriesInOrderInfo = getCategoriesInOrderInfo(order);
    const categoriesInOrderIds = categoriesInOrderInfo.map((category) => category._id);
    const categoriesToDisplay = isOrderPunctual
        ? filteredCategories.filter((category) => category.isPunctual || categoriesInOrderIds.includes(category._id))
        : filteredCategories.filter((category) => category.isFreefood || categoriesInOrderIds.includes(category._id));

    const categoriesByFlow = {
        [PRODUCT_FLOW.DRY]: categoriesToDisplay.filter(({ associatedFreefoodProductFlows }) =>
            associatedFreefoodProductFlows.includes(PRODUCT_FLOW.DRY),
        ),
        [PRODUCT_FLOW.BAKERY]: categoriesToDisplay.filter(({ associatedFreefoodProductFlows }) =>
            associatedFreefoodProductFlows.includes(PRODUCT_FLOW.BAKERY),
        ),
        [PRODUCT_FLOW.FRESH]: categoriesToDisplay.filter(({ associatedFreefoodProductFlows }) =>
            associatedFreefoodProductFlows.includes(PRODUCT_FLOW.FRESH),
        ),
        [PRODUCT_FLOW.FRUIT]: categoriesToDisplay.filter(({ associatedFreefoodProductFlows }) =>
            associatedFreefoodProductFlows.includes(PRODUCT_FLOW.FRUIT),
        ),
    };

    function getCategoryNavLinkElement(category, flow) {
        const { _id, imageUrl, title, state } = category;
        const isCategoryInOrder = categoriesInOrderInfo.some((c) => c._id === _id);

        return (
            <TotemNavLink
                key={_id}
                to={`${toUrl(_id)}/${order._id}?flow=${flow}`}
                $spaceBetween
                $isSelected={navItem === _id && categoryFlowSelected === flow}
                $isNotInOrder={!isCategoryInOrder}
                data-test={`category-${title.replace(' ', '-')}`}
            >
                <IconAndTitleContainer>
                    <OptimizedImage
                        src={imageUrl}
                        width={20}
                        // alt={`icone de la catégorie - ${title}`} />
                    />
                    {createTestingTags(state)} {title}
                </IconAndTitleContainer>
            </TotemNavLink>
        );
    }

    return (
        <>
            <TotemNavStyle>
                <Logo dark isLink={false} width={100} />
                <TotemNavInner>
                    <TotemNavCategoriesScrollable>
                        <TotemNavCategories>
                            <CategoryFlow>{PRODUCT_FLOW_LABEL[PRODUCT_FLOW.FRUIT]}</CategoryFlow>
                            {categoriesByFlow[PRODUCT_FLOW.FRUIT].map((category) => {
                                return getCategoryNavLinkElement(category, PRODUCT_FLOW.FRUIT);
                            })}
                            <CategoryFlow>{PRODUCT_FLOW_LABEL[PRODUCT_FLOW.DRY]}</CategoryFlow>
                            {categoriesByFlow[PRODUCT_FLOW.DRY].map((category) => {
                                return getCategoryNavLinkElement(category, PRODUCT_FLOW.DRY);
                            })}
                            <CategoryFlow>{PRODUCT_FLOW_LABEL[PRODUCT_FLOW.FRESH]}</CategoryFlow>
                            {categoriesByFlow[PRODUCT_FLOW.FRESH].map((category) => {
                                return getCategoryNavLinkElement(category, PRODUCT_FLOW.FRESH);
                            })}
                            <CategoryFlow>{PRODUCT_FLOW_LABEL[PRODUCT_FLOW.BAKERY]}</CategoryFlow>
                            {categoriesByFlow[PRODUCT_FLOW.BAKERY].map((category) => {
                                return getCategoryNavLinkElement(category, PRODUCT_FLOW.BAKERY);
                            })}
                        </TotemNavCategories>
                    </TotemNavCategoriesScrollable>
                </TotemNavInner>
            </TotemNavStyle>
        </>
    );
};

const IconAndTitleContainer = styled.div`
    display: flex;
    img {
        margin-right: ${({ theme }) => theme.variables.margin * 0.5}px;
    }
`;

// padding like in theme.js for the TotemNavLink definition
const CategoryFlow = styled.div`
    color: ${(props) => props.theme.colors.black};
    background: ${(props) => props.theme.colors.yellow};
    font-weight: 800;
    ${(props) =>
        padding(
            props.theme.variables.padding * 1.3,
            props.theme.variables.padding,
            props.theme.variables.padding * 1.3,
            props.theme.variables.padding * 1.8,
        )};
`;
