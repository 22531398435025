import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { variables } from 'styles/theme';

export const AsideWithOverlayContainer = ({
    children,
    hasOverlay = true,
    forceOpen = false,
    isOpen,
    setIsOpen,
    zIndex,
}) => {
    const INTERCOM_ABS_DISTANCE_TO_RIGHT = '20px';
    useEffect(() => {
        const intercomChatWindow = document
            ?.getElementById('intercom-container')
            ?.getElementsByClassName('intercom-messenger-frame')?.[0];
        const intercomWidget =
            document?.getElementsByName('intercom-launcher-frame')?.[0] ||
            document?.getElementsByClassName('intercom-launcher')[0];
        // intercom widget isn't always the same html element
        if (isOpen) {
            if (intercomChatWindow && intercomChatWindow.style) {
                intercomChatWindow.style.right = variables.asideWidthWithMargin;
            }
            if (intercomWidget && intercomWidget.style) {
                intercomWidget.style.right = variables.asideWidthWithMargin;
            }
        }
        if (!isOpen) {
            if (intercomChatWindow && intercomChatWindow.style) {
                intercomChatWindow.style.right = INTERCOM_ABS_DISTANCE_TO_RIGHT;
            }
            if (intercomWidget && intercomWidget.style) {
                intercomWidget.style.right = INTERCOM_ABS_DISTANCE_TO_RIGHT;
            }
        }
        return () => {
            if (intercomChatWindow && intercomChatWindow.style) {
                intercomChatWindow.style.right = INTERCOM_ABS_DISTANCE_TO_RIGHT;
            }
            if (intercomWidget && intercomWidget.style) {
                intercomWidget.style.right = INTERCOM_ABS_DISTANCE_TO_RIGHT;
            }
        };
    }, [isOpen]);
    return (
        <Container $isOpen={isOpen} $zIndex={zIndex}>
            <Overlay $isOpen={isOpen} $hasOverlay={hasOverlay} onClick={() => !forceOpen && setIsOpen(false)} />
            {isOpen && <AsideContainer $maxWidth={variables.asideWidth}>{children}</AsideContainer>}
        </Container>
    );
};

const fadeIn = keyframes`
  from {
    background: transparent;
  }
  to {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

// Intercom z-index: 2147483001 -_-"
const AsideContainer = styled.aside`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;
    overflow: auto;
    background: ${({ theme }) => theme.colors.culturedGrey};
    z-index: 2147483002;
    animation: ${slideIn} 0.2s linear;
    width: 100%;
    will-change: transform;
    @media (min-width: 640px) {
        max-width: ${({ $maxWidth }) => $maxWidth};
    }
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    pointer-events: ${(props) => (props.$isOpen ? 'all' : 'none')};
    z-index: ${({ $zIndex }) => $zIndex};
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    animation: ${fadeIn} 0.2s linear;
    background: ${({ $isOpen, $hasOverlay }) => ($isOpen && $hasOverlay ? 'rgba(0, 0, 0, 0.7)' : 'transparent')};
`;
