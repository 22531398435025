import React from 'react';
import { useMutation } from '@apollo/client';
import { Flex } from 'deprecated-enkel';
import styled from 'styled-components';
import { margin } from 'polished';

import { ADD_PRODUCT, REMOVE_PRODUCT, MORE_PRODUCT, LESS_PRODUCT } from 'data/mutations';
import { MEAL_CATEGORIES } from 'constants/categories-constants';
import { CardActions } from 'data/functions/card-actions';

import { checkIfProductIsAvailable, getGrams, getKgs } from 'utils';

import { TotemButton } from 'ui/Button';

export const ExtendedProductInfoButtons = ({ product, order, currentCategory }) => {
    const [addProduct, addProductData] = useMutation(ADD_PRODUCT);
    const [removeProduct, removeProductData] = useMutation(REMOVE_PRODUCT);
    const [lessProduct, lessProductData] = useMutation(LESS_PRODUCT);
    const [moreProduct, moreProductData] = useMutation(MORE_PRODUCT);

    const handlers = {
        addProduct: {
            handler: addProduct,
            data: addProductData,
        },
        removeProduct: {
            handler: removeProduct,
            data: removeProductData,
        },
        moreProduct: {
            handler: moreProduct,
            data: moreProductData,
        },
        lessProduct: {
            handler: lessProduct,
            data: lessProductData,
        },
    };

    const { handleAddProduct, handleRemoveProduct, handleMoreProduct, handleLessProduct } = CardActions(
        { product, order, currentCategory },
        handlers,
    );

    const productInOrder = order.products.find(({ _id }) => _id === product._id);
    let quantityToDisplay = null;

    if (productInOrder) {
        quantityToDisplay = parseFloat(productInOrder.quantity.toFixed(3));
    }

    const { conditionningTotem, displayKg, portion } = product;

    const quantityForecast = parseFloat((conditionningTotem / portion).toFixed(3));
    const isQtyMoreThanAvailableStock = (productInOrder?.quantity || 0) + quantityForecast > product.stockOnline;
    const isNotEnoughStock =
        !product.isWithoutStock &&
        !product.allowNegativeStock &&
        !product.canBeSuppliedBeforeOrderDate &&
        isQtyMoreThanAvailableStock;

    const orderProductIds = order.products.map(({ _id }) => _id);
    const isAlreadyInOrder = orderProductIds.includes(product._id);
    const isProductAvailable = checkIfProductIsAvailable(product);

    return (
        <ProductAdjustQuantity>
            {productInOrder && (
                <Flex $direction="column" $justifyContent="flex-start" $alignItems="center">
                    <ProductPortions>
                        {displayKg
                            ? getKgs(product.displayKg, product.weightWithoutPackaging, quantityToDisplay)
                            : quantityToDisplay}{' '}
                        {displayKg ? 'kg ' : ''}
                        {displayKg
                            ? getGrams(product.displayKg, product.weightWithoutPackaging, quantityToDisplay)
                            : ''}
                        {displayKg
                            ? getGrams(product.displayKg, product.weightWithoutPackaging, quantityToDisplay)
                                ? 'g'
                                : ''
                            : 'pièce'}
                        {quantityToDisplay > 1 && !displayKg ? 's' : ''}
                    </ProductPortions>
                    {!MEAL_CATEGORIES.includes(product.categoryId) && product.volume !== 'Pièce' && (
                        <ProductPortions subtext>
                            Soit {productInOrder.portion} portion{productInOrder.portion > 1 ? 's' : ''}
                        </ProductPortions>
                    )}
                </Flex>
            )}
            {isProductAvailable && (
                <>
                    {productInOrder?.quantity ? (
                        <Flex $direction="column">
                            <Flex>
                                <TotemButton
                                    $type="secondary"
                                    data-test="extended-product-less-button"
                                    $fontSize="0.8em"
                                    onClick={handleLessProduct}
                                    $margins={[0, 0.15, 0.3, 0]}
                                >
                                    -
                                </TotemButton>
                                <TotemButton
                                    data-test="extended-product-more-button"
                                    disabled={isNotEnoughStock}
                                    $fontSize="0.8em"
                                    onClick={handleMoreProduct}
                                    $margins={[0, 0, 0.3, 0.15]}
                                >
                                    +
                                </TotemButton>
                            </Flex>

                            <TotemButton $fontSize="0.7em" onClick={handleRemoveProduct}>
                                Supprimer
                            </TotemButton>
                        </Flex>
                    ) : (
                        <Flex>
                            <TotemButton disabled={isNotEnoughStock} $fontSize="1em" onClick={handleAddProduct}>
                                Ajouter
                            </TotemButton>
                        </Flex>
                    )}
                </>
            )}
            {!isProductAvailable && isAlreadyInOrder && (
                <TotemButton $fontSize="0.7em" onClick={handleRemoveProduct}>
                    Supprimer
                </TotemButton>
            )}
        </ProductAdjustQuantity>
    );
};

const ProductAdjustQuantity = styled(Flex.Style)`
    justify-content: flex-end;
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-start')};
    flex-shrink: 0;
    color: ${(props) => props.theme.colors.black};
    border-radius: ${(props) => props.theme.variables.borderRadius};
    ${(props) => !props.$noMargin && margin(props.theme.variables.margin * 2, props.theme.variables.margin * 2, 0, 0)};
`;

const ProductPortions = styled.div`
    align-self: flex-end;
    ${(props) => margin(0, props.theme.variables.margin, 0, 0)};
    font-size: ${(props) => (props.subtext ? '1em' : '1.5em')};
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-weight: ${(props) => (props.subtext ? 600 : 800)};
    color: ${(props) => (props.subtext ? props.theme.colors.black : props.theme.colors.black)};
`;
