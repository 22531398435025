import styled, { css } from 'styled-components';
import { margin, padding, transparentize, darken } from 'polished';
import { Flex, Select } from 'deprecated-enkel';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import 'assets/font/fonts.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const globalStyles = css`
    * {
        -webkit-font-smoothing: antialiased;
    }

    html {
        overflow: hidden;
    }

    body {
        overflow: auto;
    }

    #root {
        overflow: hidden;
        color: ${(props) => props.theme.colors.black};
        font-weight: 500;
        font-family: 'Aeonik', sans-serif;
        font-style: normal;
        background: ${(props) => props.theme.colors.culturedGrey};
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Sharp Grotesk', sans-serif;
        }
        @media not screen, (min-width: 600px) {
            min-width: ${(props) => props.theme.variables.minWidth}px;
            min-height: ${(props) => props.theme.variables.minHeight}px;
        }
    }

    #HW_frame_cont {
        position: fixed !important;
        top: unset !important;
        bottom: 0px !important;
        left: 75px !important;
    }

    input {
        font-family: inherit;
    }

    a {
        color: ${(props) => props.theme.colors.black};
    }

    /* Hide input file which is ugly af */
    [type='file'] {
        height: 0;
        overflow: hidden;
        width: 0;
    }

    #Error_Notification_Renderer {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        z-index: 9999;
    }
`;

export const Text = styled.span`
    color: ${({ $color, theme: { colors } }) => colors[$color || 'secondary']};
    font-size: ${({ $size }) => $size || '1em'};
    font-weight: ${({ $weight }) => $weight || 'normal'};
    font-style: ${({ $style }) => $style || 'normal'};
    text-transform: ${({ $transform }) => $transform || 'none'};
    width: ${({ width }) => width || 'unset'};
    min-width: ${({ minWidth }) => minWidth || 'unset'};
    max-width: ${({ maxWidth }) => maxWidth || 'unset'};

    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};
`;

export const Bold = styled.b`
    color: ${({ color, theme: { colors } }) => colors[color || 'secondary']};
    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};
`;

export const TotemContainer = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
`;

export const Wrapper = styled.div`
    flex: 1;
    height: 100%;
    overflow: hidden;
`;

export const RootWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

export const TotemLabel = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors[props.color || 'grey']};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '0.9rem')};
    text-transform: uppercase;
    letter-spacing: 0.25px;
    ${(props) => props.$halfWidth && 'width: 50%'};

    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};
`;

export const TotemInput = styled.input`
    position: relative;
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => (props.readOnly ? props.theme.colors.culturedGrey : 'white')};
    font-size: 1.2em;
    font-family: inherit;
    border-radius: ${({ theme }) => theme.variables.borderRadius};
    border: 1px solid ${(props) => props.theme.colors.borderGrey};
    ${(props) =>
        margin(
            props.marginLeft ? 0 : props.theme.variables.margin * 0.25,
            props.marginLeft ? props.theme.variables.margin : 0,
            props.marginLeft ? 0 : props.theme.variables.margin * 1.5,
        )};
    ${(props) =>
        padding(props.sidePadding ? 0 : props.theme.variables.padding * 1.5, props.theme.variables.padding * 1.5)};
    ${(props) => props.readOnly && 'cursor: default'};
    ${(props) => props.width && `width: ${props.width}`};
    ${(props) => props.minHeight && `min-height: ${props.minHeight}`};
    ${(props) => props.textAlign && `text-align: ${props.textAlign}`};

    ${({ theme: { variables }, $margins }) =>
        $margins &&
        margin(
            variables.margin * $margins[0],
            variables.margin * $margins[1],
            variables.margin * $margins[2],
            variables.margin * $margins[3],
        )}

    ::placeholder {
        color: ${(props) => props.theme.colors.pantinGrey};
    }
    &:hover {
        border: 1px solid ${(props) => (props.readOnly ? props.theme.colors.lightGrey : props.theme.colors.borderGrey)};
    }
    &:active,
    &:focus {
        border: 1px solid ${(props) => (props.readOnly ? props.theme.colors.lightGrey : props.theme.colors.blueOutline)};
        box-shadow: 0 0 0 1px
            ${(props) => (props.readOnly ? props.theme.colors.lightGrey : props.theme.colors.blueOutline)};
    }
    // for textarea
    resize: none;
`;

// TODO: check
// before: export const TotemSelectInput = styled(Select.Style.Input)`

export const TotemSelectInput = styled(Select.Style.Input)`
    color: ${(props) => props.theme.colors.black};
    background-color: white;
    font-size: 1.2em;
    border: 1px solid ${(props) => props.theme.colors.borderGrey};
    ${(props) => margin(props.theme.variables.margin * 0.25, 0, 0)};
    ${(props) => padding(props.theme.variables.padding * 1.5)};

    ::placeholder {
        color: ${(props) => props.theme.colors.pantinGrey};
    }

    &:hover,
    &:active,
    &:focus {
        border: 1px solid ${(props) => props.theme.colors.black};
    }
`;

export const TotemSelect = styled(Select.Style.Main)`
    ${(props) => margin(0, 0, props.theme.variables.margin * 1.5, 0)};

    ${TotemSelectInput} + span {
        color: ${(props) => (props.children[2] ? props.theme.colors.black : darken(0.5, props.theme.colors.lightGrey))};
    }
`;

export const Tooltip = styled(ReactTooltip)`
    display: flex;
    ${({ direction }) => direction && `flex-direction: ${direction}`};
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    background-color: ${(props) => (props.type === 'dark' ? props.theme.colors.black : 'white')} !important;
    z-index: 6; /*higher by 1 than TotemNav */
    pointer-events: none;
    text-transform: none;
`;

export const MultilineTooltip = styled(ReactTooltip)`
    background-color: ${(props) => (props.type === 'dark' ? props.theme.colors.black : 'white')} !important;
    z-index: 5;
    pointer-events: none;
`;

export const ErrorHandlerStyle = styled(Flex)`
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.colors.white};
`;

export const ErrorHandlerTitle = styled.p`
    max-width: 500px;
    ${(props) => margin(0, 0, props.theme.variables.margin)};
    ${(props) => padding(0, props.theme.variables.padding)};
    font-size: 1.25rem;
    font-weight: 800;
    text-align: center;
`;

export const ErrorHandlerMessage = styled.p`
    max-width: 500px;
    ${(props) => margin(0, 0, props.theme.variables.margin)};
    ${(props) => padding(0, props.theme.variables.padding)};
    font-size: 1.1rem;
    text-align: center;
`;

export const ErrorHandlerCode = styled.pre`
    ${(props) => margin(0, 0, props.theme.variables.margin)};
    ${(props) => padding(props.theme.variables.padding)};
    color: ${(props) => transparentize(0.35, props.theme.colors.red)};
    background: ${(props) => transparentize(0.95, props.theme.colors.red)};
    border: 1px solid ${(props) => transparentize(0.5, props.theme.colors.red)};
    border-radius: ${(props) => props.theme.variables.borderRadius};
`;
