import { Cloudinary } from 'cloudinary-core';

export function createOptimizedImageUri({ src, height, width, quality = 60 }) {
    const cld = new Cloudinary({
        cloud_name: 'totem-co',
        secure: 'true',
    });
    return cld.url(src, {
        height: height || 'auto',
        quality,
        type: 'fetch',
        width: width || 'auto',
    });
}
