import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';

import dayjs from 'dayjs';
import moment from 'moment';
import { ANCHOR_LEFT, OPEN_DOWN } from 'react-dates/constants';
import { Controller, useForm } from 'react-hook-form';
import { SingleDatePicker } from 'react-dates';
import styled from 'styled-components';

import { FORMAT } from 'constants/date-constants';
import { TotemClosedDaysContext } from 'contexts/TotemClosedDaysContext';

import { PAUSE_ORDER } from 'data/mutations';

import { TotemInput, TotemLabel } from 'styles';
import { CloseContainer, Popup, PopupContent, PopupTitle } from 'styles/pages/settings.styles';
import { RemoveIcon } from 'styles/components/card.styles';
import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { analyticsEvents, track } from 'tracking/segment';
import { isBankHoliday, isDateBeforeNext } from 'utils/datePicker';
import { getNextAvailableDeliveryDate } from 'utils/time';

export const PauseOrderPopUp = ({ order, closePopup }) => {
    const totemClosedDays = useContext(TotemClosedDaysContext);
    const nextDeliveryDate = dayjs(getNextAvailableDeliveryDate(totemClosedDays));
    const initialReturnDate = order.date_delivery
        ? moment(order.date_delivery, FORMAT).add(7, 'day').isAfter(nextDeliveryDate)
            ? moment(order.date_delivery, FORMAT).add(7, 'day')
            : moment(nextDeliveryDate).add(7, 'day')
        : null;
    const [pauseOrder] = useMutation(PAUSE_ORDER);
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            returnDate: initialReturnDate,
        },
    });
    const [isDatePickerFocused, setIsDatePickerFocused] = useState(false);

    const isDateBeforeOrEqualToInitialDeliveryDate = ({ _d: date }) => {
        return date < dayjs(order.date_delivery, FORMAT).add(1, 'day').$d;
    };

    const isNotSameWeekday = ({ _d: date }) => {
        return dayjs(date).format('d') !== dayjs(order.date_delivery, FORMAT).format('d');
    };

    const handleOrderPausing = async ({ reason, returnDate }) => {
        const {
            data: {
                pauseOrder: { success },
            },
        } = await pauseOrder({
            variables: { orderId: order._id, reason, returnDate: returnDate.toDate() },
        });
        if (success) {
            track(analyticsEvents.PAUSE_ORDER);
            closePopup();
        }
    };
    return (
        <Popup $alignItems="center" $justifyContent="center">
            <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="520px" onClick={(e) => e.stopPropagation()}>
                <PopupTitle>Suspension de commande</PopupTitle>
                <OrderPausingForm onSubmit={handleSubmit(handleOrderPausing)}>
                    <TotemLabel>
                        <span>Raison de la suspension</span>
                        <TotemInput
                            placeholder="Pourquoi suspendez-vous cette commande?"
                            type="text"
                            autoFocus
                            data-test="pause-order-reason"
                            {...register('reason', { required: 'ce champ est requis' })}
                        />
                        <InlineError display={errors.reason} />
                    </TotemLabel>
                    <TotemLabel>
                        <span>Date de retour</span>
                        <Controller
                            control={control}
                            rules={{
                                required: 'ce champ est requis',
                                validate: (momentDate) =>
                                    momentDate.isAfter(moment(order.date_delivery, FORMAT)) ||
                                    'veuillez sélectionner une date ultérieure à la prochaine date de livraison',
                            }}
                            name="returnDate"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <SingleDatePicker
                                        id="return-date-picker"
                                        placeholder="Sélectionnez la date de retour"
                                        date={value}
                                        onDateChange={(date) => onChange(date)}
                                        isOutsideRange={(date) =>
                                            isDateBeforeNext(date._d, nextDeliveryDate) ||
                                            isNotSameWeekday(date) ||
                                            isDateBeforeOrEqualToInitialDeliveryDate(date) ||
                                            isBankHoliday({ totemClosedDays, date })
                                        }
                                        calendarInfoPosition="after"
                                        openDirection={OPEN_DOWN}
                                        anchorDirection={ANCHOR_LEFT}
                                        numberOfMonths={1}
                                        firstDayOfWeek={1}
                                        hideKeyboardShortcutsPanel
                                        showDefaultInputIcon={true}
                                        focused={isDatePickerFocused}
                                        onFocusChange={() => setIsDatePickerFocused(!isDatePickerFocused)}
                                        displayFormat={() => `dddd  ${FORMAT}`}
                                    />
                                );
                            }}
                        />
                        <InlineError display={errors.returnDate} />
                    </TotemLabel>
                    <AdditionalInfo>
                        Nous vous recontacterons afin de réactiver votre commande en temps voulu.
                        <br />
                        Si vous souhaitez stopper définitement cette commande,{' '}
                        <a href="mailto:help@totem.co">contactez nous !</a>
                    </AdditionalInfo>
                    <TotemButton type="submit" data-test="confirm-pausing-order">
                        Suspendre
                    </TotemButton>
                </OrderPausingForm>
                <CloseContainer>
                    <RemoveIcon size="25px" onClick={closePopup} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};

const OrderPausingForm = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    .DateInput {
        width: 389px;
    }
`;

const AdditionalInfo = styled.div`
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.pantinGrey};
    margin: 10px 0;
`;
