import { GET_ORDERS_OF_TOTEM } from 'data/queries';

import { ORGANIZATION_FRAGMENT } from 'data/fragments/organization.fragments';

import { cache } from 'data/connection/client.connection';

export function orderValidatedCacheHandler({ apolloClient, orderId, totemId, updatedOrder, updatedOrganization }) {
    /* Update organization */
    apolloClient.writeFragment({
        _id: cache.identify(updatedOrganization),
        fragment: ORGANIZATION_FRAGMENT,
        fragmentName: 'OrganizationFragment',
        data: updatedOrganization,
    });

    /* Update order */
    const { ordersOfTotem } = apolloClient.readQuery({
        query: GET_ORDERS_OF_TOTEM,
        variables: { totemId },
    });

    const { recentOrders } = ordersOfTotem;
    const updatedOrders = [...recentOrders];
    const orderIds = updatedOrders.map((localOrder) => localOrder._id);
    const orderIndex = orderIds.indexOf(orderId);

    updatedOrders[orderIndex] = updatedOrder;

    apolloClient.writeQuery({
        query: GET_ORDERS_OF_TOTEM,
        variables: { totemId },
        data: { ordersOfTotem: { ...ordersOfTotem, recentOrders: ordersOfTotem } },
    });
}
