import React, { useContext } from 'react';
import { AuthContext } from '@totem/auth';
import styled from 'styled-components';

import { OptimizedImage } from 'components/OptimizedImage';

import { TotemButton } from 'ui/Button';

import { buildImgUrl } from 'utils/image/buildImgUrl';

export const DownloadMobileApp = () => {
    const {
        authActions: { logout },
    } = useContext(AuthContext);

    const handleLogout = async () => {
        logout();
        window.location.replace('/');
    };
    return (
        <DownloadPageContainer alignItems="center" justifyContent="space-around">
            <DownloadPageContent>
                <OptimizedImage width={150} src={buildImgUrl('img/illustrations/Totem_illustration-022.png')} />
                <DesktopDownloadTitle>
                    Ce site n'est pas accessible aux employés,
                    <br />
                    téléchargez l'application mobile qui vous est réservée !
                </DesktopDownloadTitle>
                <MobileDownloadTitle>Téléchargez l'application mobile réservée aux employés&nbsp;!</MobileDownloadTitle>
                <DownloadPageWrapperStyle>
                    <DownloadLink
                        href="https://play.google.com/store/apps/details?id=thetotem.co&hl=fr&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <DownloadLinkImg
                            alt="Disponible sur Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                        />
                    </DownloadLink>
                    <DownloadLink
                        href="https://apps.apple.com/fr/app/totem-votre-microstore/id1517575797?mt=8"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <DownloadLinkImg
                            alt="Disponible sur l'App Store"
                            src="https://shortcuts-france.fr/wp-content/uploads/2017/06/app-store-badge.png"
                        />
                    </DownloadLink>
                </DownloadPageWrapperStyle>
            </DownloadPageContent>
            <TotemButton $margins={[1, 0]} onClick={() => handleLogout()} data-test="logout">
                Se déconnecter
            </TotemButton>
        </DownloadPageContainer>
    );
};

const DownloadPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: inherit;
`;

const DownloadPageContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px;
`;

const DownloadTitle = styled.h2`
    width: 100%;
    text-transform: uppercase;
    text-align: center;
`;

const DesktopDownloadTitle = styled(DownloadTitle)`
    @media only screen and (max-width: 600px) {
        display: none;
    }

    @media not screen, (min-width: 600px) {
        font-size: 2.6em;
    }
`;

const MobileDownloadTitle = styled(DownloadTitle)`
    @media not screen, (min-width: 600px) {
        display: none;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2em;
    }
`;

const DownloadPageWrapperStyle = styled.div`
    display: flex;
    flex-direction: column;
`;

const DownloadLink = styled.a`
    @media only screen and (min-width: 600px) {
        width: 30%;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const DownloadLinkImg = styled(OptimizedImage)`
    width: 100%;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;
