import styled from 'styled-components';

export const FlexStyle = styled.div`
    display: ${({ $display }) => $display || 'flex'};
    ${({ $order }) => $order && `order: ${$order}`};
    ${({ $shrink }) => $shrink && `flex-shrink: ${$shrink}`};
    ${({ $wrap }) => $wrap && `flex-wrap: ${$wrap}`};
    ${({ $basis }) => $basis && `flex-basis: ${$basis}`};
    ${({ $flow }) => $flow && `flex-flow: ${$flow}`};
    ${({ $flex }) => $flex && `flex: ${$flex}`};
    ${({ $direction }) => $direction && `flex-direction: ${$direction}`};
    ${({ $alignItems }) => $alignItems && `align-items: ${$alignItems}`};
    ${({ $alignContent }) => $alignContent && `align-content: ${$alignContent}`};
    ${({ $justifyContent }) => $justifyContent && `justify-content: ${$justifyContent}`};
    ${({ $position }) => $position && `position: ${$position}`};
    ${({ $width }) => $width && `width: ${$width}`};
`;
