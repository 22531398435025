import React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { theme } from '../defaults/theme';

import { GlobalStyles } from '../lib/GlobalStyles';

export const CustomThemeProvider = ({ colors, variables, globalStyles, includeFont, children }) => {
    return (
        <StyledComponentsThemeProvider
            theme={{
                colors: {
                    ...theme.colors,
                    ...colors,
                },
                variables: {
                    ...theme.variables,
                    ...variables,
                },
            }}
        >
            <>
                <GlobalStyles custom={globalStyles} includeFont={includeFont} />
                {children}
            </>
        </StyledComponentsThemeProvider>
    );
};
