import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

import { CREATE_PUNCTUAL_ORDER, CREATE_NEW_RECURRING_ORDER } from 'data/mutations';
import { GET_ORDERS_OF_TOTEM, GET_ORDERS_OF_TOTEM_WITHIN_DATE_RANGE } from 'data/queries';
import { DAYS_OF_THE_WEEK, FORMAT, WEEKDAY_INFO } from 'constants/date-constants';

import { TotemButton } from 'ui/Button';

import { HandleNotification } from 'utils';
import { getRecurringOrdersForDay } from 'utils/orders';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';
import { PopupTitle } from 'styles/pages/settings.styles';
import { colors } from 'styles/theme';

export const OrderTypeSelection = ({
    ordersOfTotem,
    currentTotem,
    defaultCategoryId,
    selectedDate,
    selectedTimeWindow,
    setSelectedOrder,
}) => {
    const { _id: totemId } = currentTotem;
    const apolloClient = useApolloClient();
    const navigate = useNavigate();
    const startDate = selectedDate.startOf('day').toDate();
    const endDate = selectedDate.endOf('day').toDate();
    const {
        loading: ordersLoading,
        error: ordersError,
        data: ordersData,
    } = useQuery(GET_ORDERS_OF_TOTEM_WITHIN_DATE_RANGE, {
        variables: { totemId, startDate, endDate, isPunctual: true },
        skip: !totemId,
        fetchPolicy: 'no-cache', // prevents from creating 2 consecutive orders for the same date
    });
    if (ordersError) {
        throw Error(ordersError);
    }
    const [createNewRecurringOrder, { loading: isNewRecurringCreationLoading }] =
        useMutation(CREATE_NEW_RECURRING_ORDER);
    const [createPunctualOrder, { loading: isPunctualCreationLoading }] = useMutation(CREATE_PUNCTUAL_ORDER);

    const [loading, setLoading] = useState(false);
    const weekday = selectedDate && DAYS_OF_THE_WEEK[dayjs(selectedDate._d).format('d').toLowerCase()];
    const translatedWeekday = WEEKDAY_INFO.find((day) => day.value === weekday).name;

    if (isNewRecurringCreationLoading || isPunctualCreationLoading || ordersLoading || loading) {
        return (
            <>
                <PopupTitle>Voulez-vous vous faire livrer tous les {translatedWeekday}s ?</PopupTitle>
                <LoaderContainer>
                    <ClipLoader color={colors.black} size="30px" />
                </LoaderContainer>
            </>
        );
    }
    const { ordersOfTotemWithinDateRange } = ordersData;
    const hasSameDayPunctualOrder = ordersOfTotemWithinDateRange.length;

    const { recentOrders } = ordersOfTotem;

    const { modifiableOrder: sameDayModifiableRecurringOrder } = getRecurringOrdersForDay(recentOrders, weekday);

    const createUrl = (orderId) => `/totem/${totemId}/${defaultCategoryId}/${orderId}`;

    const handleRecurringOrder = async () => {
        try {
            setLoading(true);
            const {
                data: { createNewRecurringOrder: newRecurringOrder },
            } = await createNewRecurringOrder({
                variables: {
                    totemId: currentTotem._id,
                    deliveryDate: dayjs(selectedDate._d).format(FORMAT),
                    deliveryTimeWindow: selectedTimeWindow,
                },
            });
            apolloClient.writeQuery({
                query: GET_ORDERS_OF_TOTEM,
                variables: { totemId },
                data: {
                    ordersOfTotem: {
                        ...ordersOfTotem,
                        recentOrders: [
                            ...recentOrders,
                            {
                                ...newRecurringOrder,
                                productsWithQuantityPicked: [],
                                productsRequestedButNotFullyDelivered: [],
                            },
                        ],
                    },
                },
            });
            navigate(createUrl(newRecurringOrder?._id));
        } catch (error) {
            HandleNotification(error);
            setLoading(false);
        }
    };

    const handlePunctualOrder = async () => {
        try {
            setLoading(true);
            const {
                data: { createPunctualOrder: newPunctualOrder },
            } = await createPunctualOrder({
                variables: {
                    totemId,
                    deliveryDate: dayjs(selectedDate._d).format(FORMAT),
                    deliveryTimeWindow: selectedTimeWindow,
                },
            });
            apolloClient.writeQuery({
                query: GET_ORDERS_OF_TOTEM,
                variables: { totemId },
                data: {
                    ordersOfTotem: {
                        ...ordersOfTotem,
                        recentOrders: [...recentOrders, newPunctualOrder],
                    },
                },
            });
            navigate(createUrl(newPunctualOrder?._id));
        } catch (error) {
            HandleNotification(error);
            setLoading(false);
        }
    };

    return (
        <>
            <PopupTitle>Voulez-vous vous faire livrer tous les {translatedWeekday}s ?</PopupTitle>
            <OrderTypeContainer>
                <TotemButton
                    $margins={[1, 1, 0.5, 1]}
                    data-test="create-recurring-order-button"
                    onClick={async () => {
                        if (sameDayModifiableRecurringOrder) {
                            setSelectedOrder(sameDayModifiableRecurringOrder);
                        } else {
                            handleRecurringOrder();
                            track(analyticsEvents.CREATE_ORDER, {
                                [analyticsPropertiesConstants.ORDER.IS_PUNCTUAL]: false,
                            });
                        }
                    }}
                >
                    Oui
                </TotemButton>
                <Tip>
                    Votre commande sera automatiquement renouvelée et livrée toutes les semaines (elle reste modifiable
                    chaque semaine)
                </Tip>
                <TotemButton
                    $type="secondary"
                    $margins={[1, 1, 0.5, 1]}
                    onClick={async () => {
                        handlePunctualOrder();
                        track(analyticsEvents.CREATE_ORDER, {
                            [analyticsPropertiesConstants.ORDER.IS_PUNCTUAL]: true,
                        });
                    }}
                    data-test="create-punctual-order-button"
                >
                    Non
                </TotemButton>
                {!!hasSameDayPunctualOrder && (
                    <Tip color="red">Attention : vous avez déjà une commande ponctuelle pour ce jour.</Tip>
                )}
            </OrderTypeContainer>
        </>
    );
};

const OrderTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const LoaderContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const Tip = styled.div`
    color: ${(props) => (props.color === 'red' ? props.theme.colors.red : props.theme.colors.pantinGrey)};
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    max-width: 470px;
`;
