import React from 'react';
import { useMutation } from '@apollo/client';
import { IconArrowLeft } from '@tabler/icons-react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

import { UPDATE_ORDER_DELIVERY_DATE_TIME } from 'data/mutations';

import { AsideBody, AsideForm, AsideHeader, AsideSubTitle } from 'pages/Totem/Aside/AsideComponents';

import { smallSelectStyle } from 'styles/components/reactSelect.styles';

import {
    MIN_FREE_INTERVAL_DURATION,
    MIN_SLOTS_IN_DELIVERY_WINDOW,
    MIN_SLOT_INTERVAL_DURATION,
    startDeliveryRange,
    stopDeliveryRange,
} from 'constants/deliveryTime';

import { IconButton, TotemButton } from 'ui/Button';
import { InlineError } from 'ui/InlineError';
import { TotemLabel } from 'styles';

import { getTimeDifference } from 'utils';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';
import { SHORT_TIME_WINDOW_PRICE } from 'constants/order';

export const DeliveryHoursContainer = ({ orderId, deliveryTimeWindow, setIsDeliveryHoursAsideOpen }) => {
    const [updateOrderDeliveryDateTime, { loading }] = useMutation(UPDATE_ORDER_DELIVERY_DATE_TIME);
    const startDeliveryOptions = startDeliveryRange.map((value) => ({ label: value, value }));
    const stopDeliveryOptions = stopDeliveryRange.map((value) => ({ label: value, value }));
    const defaultdeliveryTimeStart = startDeliveryOptions[2];
    const defaultdeliveryTimeStop = stopDeliveryOptions[6];

    const {
        handleSubmit,
        control,
        formState: { errors, isValid, isDirty },
        setValue,
        getValues,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            deliveryTimeStart: deliveryTimeWindow
                ? { label: deliveryTimeWindow?.start, value: deliveryTimeWindow?.start }
                : defaultdeliveryTimeStart,
            deliveryTimeStop: deliveryTimeWindow
                ? { label: deliveryTimeWindow?.stop, value: deliveryTimeWindow?.stop }
                : defaultdeliveryTimeStop,
        },
    });

    const handleDeliveryHoursValidation = async ({
        deliveryTimeStart: { value: deliveryTimeStartValue },
        deliveryTimeStop: { value: deliveryTimeStopValue },
    }) => {
        await updateOrderDeliveryDateTime({
            variables: {
                orderId,
                deliveryTimeWindow: { start: deliveryTimeStartValue, stop: deliveryTimeStopValue },
            },
        });
        track(analyticsEvents.UPDATE_DELIVERY_HOURS, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
        });
        setIsDeliveryHoursAsideOpen(false);
    };

    const deliveryTimeStartValue = watch('deliveryTimeStart').value;
    const deliveryTimeStopValue = watch('deliveryTimeStop').value;
    const isTimeWindowFreeOfCharge =
        getTimeDifference(deliveryTimeStartValue, deliveryTimeStopValue) >= MIN_FREE_INTERVAL_DURATION;

    return (
        <>
            <AsideHeader>
                <IconButton
                    data-test="aside-return-button"
                    $white
                    $large
                    onClick={() => setIsDeliveryHoursAsideOpen(false)}
                >
                    <IconArrowLeft size={25} />
                </IconButton>
            </AsideHeader>
            <AsideBody>
                <AsideForm onSubmit={handleSubmit(handleDeliveryHoursValidation)}>
                    <AsideSubTitle>Plage horaire de livraison</AsideSubTitle>
                    <Disclaimer>
                        En choisissant une plage horaire large ({MIN_FREE_INTERVAL_DURATION}h ou plus), vous nous aidez
                        à réduire les émissions liées à la livraison, grâce à des optimisations de transport (moins de
                        camionnettes sont nécessaires, avec des trajets plus courts).
                        <br />
                        <br /> Lorsque cela est nécessaire, vous avez la possibilité de définir un créneau de livraison
                        réduit (fenêtre de 1h minimum, avec un surcoût de {SHORT_TIME_WINDOW_PRICE}€).
                    </Disclaimer>
                    <Controller
                        name="deliveryTimeStart"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <TotemLabel>
                                <SelectContainer>
                                    <SelectLabel>A partir de</SelectLabel>
                                    <Select
                                        {...field}
                                        styles={smallSelectStyle}
                                        multi={false}
                                        onChange={(selectedOption) => {
                                            const { value: deliveryTimeStartValue } = selectedOption;
                                            const {
                                                deliveryTimeStop: { value: deliveryTimeStopValue },
                                            } = getValues();
                                            if (
                                                getTimeDifference(deliveryTimeStartValue, deliveryTimeStopValue) <=
                                                MIN_SLOT_INTERVAL_DURATION
                                            ) {
                                                setValue(
                                                    'deliveryTimeStop',
                                                    stopDeliveryOptions[
                                                        stopDeliveryOptions.findIndex(
                                                            (option) => option.value === selectedOption.value,
                                                        ) + MIN_SLOTS_IN_DELIVERY_WINDOW
                                                    ],
                                                );
                                            }
                                            field.onChange(selectedOption);
                                        }}
                                        classNamePrefix="delivery-time-start"
                                        placeholder="Livraison à partir de"
                                        options={startDeliveryOptions}
                                        noOptionsMessage={() => "Pas d'horaire trouvé"}
                                    />
                                    <InlineError display={errors.deliveryTimeStart} />
                                </SelectContainer>
                            </TotemLabel>
                        )}
                    />
                    <Controller
                        name="deliveryTimeStop"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <TotemLabel>
                                <SelectContainer>
                                    <SelectLabel>Jusqu'à</SelectLabel>
                                    <Select
                                        {...field}
                                        styles={smallSelectStyle}
                                        multi={false}
                                        onChange={(selectedOption) => {
                                            const { value: deliveryTimeStopValue } = selectedOption;
                                            const {
                                                deliveryTimeStart: { value: deliveryTimeStartValue },
                                            } = getValues();
                                            if (
                                                getTimeDifference(deliveryTimeStartValue, deliveryTimeStopValue) <=
                                                MIN_SLOT_INTERVAL_DURATION
                                            ) {
                                                setValue(
                                                    'deliveryTimeStart',
                                                    startDeliveryOptions[
                                                        startDeliveryOptions.findIndex(
                                                            (option) => option.value === selectedOption.value,
                                                        ) - MIN_SLOTS_IN_DELIVERY_WINDOW
                                                    ],
                                                );
                                            }
                                            field.onChange(selectedOption);
                                        }}
                                        placeholder="Plutôt avant"
                                        options={stopDeliveryOptions}
                                        noOptionsMessage={() => "Pas d'horaire trouvé"}
                                    />
                                    <InlineError display={errors.deliveryTimeStop} />
                                </SelectContainer>
                            </TotemLabel>
                        )}
                    />
                    {!isTimeWindowFreeOfCharge ? (
                        <Warning>
                            <span role="img" aria-label="warning">
                                ⚠️
                            </span>{' '}
                            Un surcoût de {SHORT_TIME_WINDOW_PRICE}€ s’applique car la plage horaire est inférieure à{' '}
                            {MIN_FREE_INTERVAL_DURATION}h.
                        </Warning>
                    ) : null}
                    <TotemButton
                        $margins={[2, 2]}
                        $fontSize="1rem"
                        disabled={!isValid || !isDirty}
                        type="submit"
                        data-test="basket-delivery-hours-validation"
                    >
                        {loading ? <ClipLoader color="white" size="20px" /> : 'Modifier'}
                    </TotemButton>
                </AsideForm>
            </AsideBody>
        </>
    );
};

const Disclaimer = styled.div`
    margin: 0 2em;
    color: ${(props) => props.theme.colors.pantinGrey};
`;

const Warning = styled.div`
    margin: 15px 2em 0;
    color: ${(props) => props.theme.colors.pantinGrey};
`;

const SelectContainer = styled.div`
    margin: 0 2em;
`;

const SelectLabel = styled.div`
    margin-top: 15px;
    color: ${(props) => props.theme.colors.pantinGrey};
`;
