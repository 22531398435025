import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useWindowSize } from 'usehooks-ts';

import { BasketProductItem } from 'pages/Totem/Aside/Basket/BasketProductItem';
import { FadeIn } from 'components/FadeIn';

export const OrderDetailsProducts = ({ products }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfColumns, setNumberOfColumns] = useState(3);
    const [productsPerColumn, setProductsPerColumn] = useState(5);

    const { width, height } = useWindowSize();

    const productsPerPage = productsPerColumn * numberOfColumns;
    const numberOfPages = Math.ceil(products.length / productsPerPage);

    useEffect(() => {
        if (currentPage > numberOfPages) {
            setCurrentPage(0);
        }
        if (height > 1300) {
            setProductsPerColumn(8);
        } else if (height > 1150) {
            setProductsPerColumn(7);
        } else if (height > 1050) {
            setProductsPerColumn(6);
        } else if (height > 950) {
            setProductsPerColumn(5);
        } else if (height > 850) {
            setProductsPerColumn(4);
        } else {
            setProductsPerColumn(3);
        }
    }, [height, currentPage, numberOfPages]);

    useEffect(() => {
        if (currentPage > numberOfPages) {
            setCurrentPage(0);
        }
        if (width > 1400) {
            setNumberOfColumns(3);
        } else if (width > 1000) {
            setNumberOfColumns(2);
        } else {
            setNumberOfColumns(1);
        }
    }, [width, currentPage, numberOfPages]);
    return (
        <>
            <OrderDetailsProductsContainer>
                {new Array(numberOfColumns).fill(0).map((_, index) => {
                    if (
                        products.slice(
                            currentPage * productsPerColumn * numberOfColumns + productsPerColumn * index,
                            currentPage * productsPerColumn * numberOfColumns + productsPerColumn * (index + 1),
                        ).length > 0
                    ) {
                        return (
                            <OrderDetailsProductsColumn key={index} hasRightBorder={index !== numberOfColumns - 1}>
                                {products
                                    .slice(
                                        currentPage * productsPerColumn * numberOfColumns + productsPerColumn * index,
                                        currentPage * productsPerColumn * numberOfColumns +
                                            productsPerColumn * (index + 1),
                                    )
                                    .map((product, index) => {
                                        return (
                                            <FadeIn key={product._id} duration={500} delay={index * 10}>
                                                <BasketProductItem product={product} />
                                            </FadeIn>
                                        );
                                    })}
                            </OrderDetailsProductsColumn>
                        );
                    } else {
                        return null;
                    }
                })}
            </OrderDetailsProductsContainer>
            {products.length > productsPerColumn * numberOfColumns && (
                <PageCounter>
                    <strong>Page</strong>
                    {new Array(numberOfPages).fill(0).map((_, index) => {
                        return (
                            <React.Fragment key={index}>
                                <span>{index !== 0 && '/'}</span>
                                <PageNumber
                                    onClick={() => setCurrentPage(index)}
                                    key={index}
                                    isActive={index === currentPage}
                                >
                                    {index + 1}
                                </PageNumber>
                            </React.Fragment>
                        );
                    })}
                </PageCounter>
            )}
        </>
    );
};

const PageNumber = styled.span`
    font-weight: ${({ isActive }) => isActive && 'bold'};
    padding: 0 5px;
    cursor: pointer;
`;

const OrderDetailsProductsColumn = styled.div`
    display: flex;
    flex-direction: column;
    border-right: ${({ theme, hasRightBorder }) => hasRightBorder && `1px solid ${theme.colors.borderGrey}`};
    margin: 30px 0;
`;

const OrderDetailsProductsContainer = styled.div`
    padding: 0 35px 0 20px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    @media only screen and (max-width: 1400px) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
    @media only screen and (max-width: 1000px) {
        grid-template-columns: minmax(0, 1fr);
    }
    ${OrderDetailsProductsColumn}:not(:first-child) {
        padding-left: 30px;
    }
    ${OrderDetailsProductsColumn}:not(:last-child) {
        padding-right: 30px;
    }
`;

const PageCounter = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 20px 20px 20px;
`;
