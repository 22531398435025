import React from 'react';
import styled from 'styled-components';
import { IconBuildingBank, IconCreditCard } from '@tabler/icons-react';

import { BasketButtonLineItem } from './BasketButtonLineItem';
import { capitalize } from 'utils';
import { BANK_TRANSFER, STRIPE_SEPA_DEBIT, STRIPE_CARD } from 'constants/organization';

export const BasketPaymentInfos = ({ organization, setIsBillingAsideOpen }) => {
    const ICON_SIZE = '18px';
    const { cards, billingInfo, paymentType } = organization;
    const defaultSourceObject = cards.find((card) => card.id === billingInfo?.defaultPaymentMethod);
    return (
        <PaymentInfosContainer>
            <BasketButtonLineItem
                icon={
                    defaultSourceObject?.type === STRIPE_SEPA_DEBIT || paymentType === BANK_TRANSFER ? (
                        <IconBuildingBank size={ICON_SIZE} />
                    ) : (
                        <IconCreditCard size={ICON_SIZE} />
                    )
                }
                label={
                    paymentType === BANK_TRANSFER
                        ? 'Virement bancaire'
                        : billingInfo?.defaultPaymentMethod
                          ? defaultSourceObject?.type === STRIPE_SEPA_DEBIT
                              ? 'SEPA (Virement bancaire)'
                              : defaultSourceObject?.type === STRIPE_CARD &&
                                `${capitalize(
                                    defaultSourceObject.card?.brand || defaultSourceObject.brand || 'carte bancaire',
                                )}`
                          : 'Informations de facturation'
                }
                actionLabel={
                    !billingInfo?.defaultPaymentMethod && paymentType !== BANK_TRANSFER
                        ? 'Ajouter'
                        : defaultSourceObject?.type === STRIPE_CARD &&
                          `• • • • • • • • • • • • ${defaultSourceObject.card?.last4 || defaultSourceObject.last4}`
                }
                bold={!billingInfo?.defaultPaymentMethod && paymentType !== BANK_TRANSFER}
                onClick={() => setIsBillingAsideOpen(true)}
                borderBottom
            />
        </PaymentInfosContainer>
    );
};

const PaymentInfosContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    border-width: 0 0 0.1rem;
`;
