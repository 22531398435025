import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { IconInfoCircle } from '@tabler/icons-react';
import styled from 'styled-components';
import { margin } from 'polished';
import { ClipLoader } from 'react-spinners';

import { SET_STRIPE_DEFAULT_PAYMENT_METHOD, CREATE_SETUP_INTENT } from 'data/mutations/stripe.mutations';

import { getBillingDetails } from 'pages/Settings/SettingsPage/SettingsPayment/utils';

import { TotemButton } from 'ui/Button';

import { STRIPE_SEPA_DEBIT } from 'constants/organization';

import { colors } from 'styles/theme';
import { TotemInput } from 'styles';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const IBANForm = ({ organization, currentUser, onSubmitCompletion }) => {
    // inspired by https://github.com/stripe/react-stripe-js/blob/9fe1a5473cd1125fcda4e01adb6d6242a9bae731/examples/hooks/4-IBAN.js
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [setStripeDefaultPaymentMethod] = useMutation(SET_STRIPE_DEFAULT_PAYMENT_METHOD);
    const [createSetupIntent] = useMutation(CREATE_SETUP_INTENT);
    const [cardName, setCardName] = useState(organization.billingInfo?.invoiceName);
    const [errorMessage, setErrorMessage] = useState(null);
    const ICON_SIZE = 20;
    const ELEMENT_OPTIONS = {
        supportedCountries: ['SEPA'],
        hideIcon: true,
        style: {
            base: {
                fontSize: '18px',
                color: colors.black,
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: colors.pantinGrey,
                },
            },
            invalid: {
                color: colors.red,
            },
        },
    };
    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        if (!stripe || !elements) {
            setIsLoading(false);
            return;
        }

        const ibanElement = elements.getElement(IbanElement);
        track(analyticsEvents.ADD_IBAN, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
        });
        const {
            data: {
                createSetupIntent: { clientSecret },
            },
            // creates stripe customer if non-existent
        } = await createSetupIntent({
            variables: {
                organizationId: organization._id,
            },
        });
        const { setupIntent, error } = await stripe.confirmSepaDebitSetup(clientSecret, {
            payment_method: {
                type: STRIPE_SEPA_DEBIT,
                sepa_debit: ibanElement,
                billing_details: getBillingDetails({ cardName, organization, user: currentUser }),
            },
        });
        if (error) {
            setErrorMessage(error.message);
            return;
        } else if (setupIntent.status === 'succeeded') {
            setStripeDefaultPaymentMethod({
                variables: {
                    userId: currentUser._id,
                    organizationId: organization._id,
                    paymentMethodId: setupIntent.payment_method,
                },
            });
            onSubmitCompletion();
        }
        setIsLoading(false);
    };
    return (
        <IBANFormContainer onSubmit={handleSubmit}>
            <CardNameInput
                placeholder="Titulaire du compte"
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
            />
            <IBANContainer $margins={[1, 0, 0, 1]}>
                <IbanElement options={ELEMENT_OPTIONS} />
            </IBANContainer>
            <IBANDescription>
                <IconInfoCircle size={ICON_SIZE} />
                <div>
                    En enregistrant cet IBAN, vous acceptez les conditions d’utilisation du prélèvement{' '}
                    <a
                        href="https://s3.eu-central-1.amazonaws.com/totems3/assets/mandate_sepa.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        SEPA Direct Debit Stripe
                    </a>
                </div>
            </IBANDescription>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <TotemButton disabled={isLoading} $margins={[1, 0, 0, 0]} $fontSize="1em" type="submit">
                {isLoading ? <ClipLoader color="white" size="16px" /> : "Enregistrer l'IBAN"}
            </TotemButton>
        </IBANFormContainer>
    );
};

const IBANFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1em 0 0 0;
`;

const IBANContainer = styled.div`
    ${({ $margins }) =>
        $margins &&
        margin(
            $margins[0] + 'rem',
            ($margins[1] ?? $margins[0]) + 'rem',
            ($margins[2] ?? $margins[0]) + 'rem',
            ($margins[3] ?? $margins[1] ?? $margins[0]) + 'rem',
        )}
    width: 100%;
`;

const IBANDescription = styled.div`
    margin-top: 2em;
    display: flex;
    width: 100%;
    svg {
        flex: 1 0 auto;
        margin-right: 0.5em;
    }
    a {
        text-decoration: underline;
    }
`;

const ErrorMessage = styled.div`
    margin: 1em 1em 0 1em;
    color: ${({ theme: { colors } }) => colors.red};
`;

const CardNameInput = styled(TotemInput)`
    border: none;
    margin: 0;
    &:hover {
        border: none;
    }
    &:active,
    &:focus {
        border: none;
        box-shadow: none;
    }
    color: #111111;
    font-family: Aeonik;
    font-size: 16px;
    outline: none;
`;
