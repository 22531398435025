import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import differenceBy from 'lodash/differenceBy';
import styled from 'styled-components';

import { UserContext } from 'constants/user-context';

import { GET_PRODUCTS, GET_BADGES } from 'data/queries';
import { ARCHIVED } from 'constants/states-app';

import { CardProduct } from 'pages/Totem/TotemProducts/CardProduct';
import { CardSuggestion } from 'pages/Totem/TotemProducts/CardSuggestion';
import { TotemFiltersDropdown } from 'pages/Totem/TotemCategory/TotemFiltersDropdown';
import { SuggestionPopup } from 'pages/Totem/TotemProducts/SuggestionPopup';

import { Loading } from 'components/Loading';
import { Overlay } from 'ui/Overlay';

import { getProductFilters, getFilteredProducts, checkIfProductIsAvailable, hasAccessToPrivateProduct } from 'utils';

import { TotemProductsInnerStyle, TotemProductsInnerTitleStyle, TotemLoadingStyle } from 'styles/pages/totem.styles';

function filterProductForFlow(products, flow) {
    return products.filter((product) => flow === product.flow);
}

function isProductOutOfStock(product) {
    const { allowNegativeStock, availableStock, canBeSuppliedBeforeOrderDate, isPaused, isWithoutStock, state } =
        product;
    return (
        state !== ARCHIVED &&
        !isPaused &&
        !isWithoutStock &&
        !allowNegativeStock &&
        !canBeSuppliedBeforeOrderDate &&
        !availableStock > 0
    );
}

function isProductInOrder({ product, order }) {
    return order.products.some((orderProduct) => orderProduct._id === product._id);
}

// We want:
// - products in order first
// - products not in order second, with:
//     - products to be archived first
//     - then products in stock, in which products with discount are displayed first
//     - products without stock second, in which products with discount are displayed first
function sortProducts({ products, order }) {
    return products
        .sort((productA, productB) => {
            const isProductAInOrder = isProductInOrder({ product: productA, order });
            const isProductBInOrder = isProductInOrder({ product: productB, order });

            if (isProductAInOrder) {
                return -1;
            }
            if (isProductBInOrder) {
                return 1;
            }
            if (productA.crossedPrice) {
                return -1;
            }
            if (productB.crossedPrice) {
                return 1;
            }
            if (productA.isToBeArchived) {
                return -1;
            }
            if (productB.isToBeArchived) {
                return 1;
            }
            return 0;
        })
        .sort((productA, productB) => {
            const isProductAOutOfStock = isProductOutOfStock(productA);
            const isProductBOutOfStock = isProductOutOfStock(productB);

            return isProductAOutOfStock - isProductBOutOfStock;
        });
}

export const TotemProducts = ({
    context,
    categoryFlowSelected,
    currentCategory,
    categoryId,
    order,
    lastOrder,
    totem,
    categoryHasChanged,
}) => {
    const totemProductsRef = useRef(null);
    useEffect(() => {
        if (totemProductsRef.current) {
            totemProductsRef.current.scrollTop = 0;
        }
    }, [categoryId]);

    const productsAlreadySelected = useRef({ order: [], lastOrder: [] });
    const previousOrderDate = useRef(order.date_delivery);
    const {
        filter: { filterSearch, filterTag, filterBadge, filterBrand, filterPrice, showFilters },
        filterActions: { setAllFilterItems, setFilterTag, setFilterBadge, setFilterBrand, setFilterPrice },
    } = useContext(context);
    const { organization } = useContext(UserContext);
    const [isSuggestionPopupOpen, setIsSuggestionPopupOpen] = useState(false);
    const lastOrderProducts = lastOrder?.products || [];
    const productsIdsInLastOrder = lastOrderProducts
        .filter((product) => product.categoryId === currentCategory._id)
        .map((product) => product._id);

    const { loading, error, data } = useQuery(GET_PRODUCTS, {
        variables: {
            categoryId,
            productsIdsInLastOrder,
            orderId: order._id,
        },
    });

    const { loading: badgesLoading, error: badgesError, data: badgesData } = useQuery(GET_BADGES);
    const deliveryDateHasChanged = useMemo(() => {
        const hasChanged = order.date_delivery !== previousOrderDate.current;
        previousOrderDate.current = order.date_delivery;
        return hasChanged;
    }, [order]);

    const products = filterProductForFlow(data?.freefoodProducts ?? [], categoryFlowSelected);

    useEffect(() => {
        if (data?.freefoodProducts && badgesData?.badges) {
            setAllFilterItems(
                getProductFilters(
                    filterProductForFlow(data?.freefoodProducts ?? [], categoryFlowSelected),
                    organization,
                    badgesData.badges,
                ),
            );
        } else {
            setAllFilterItems({});
        }
    }, [data, totem, organization, setAllFilterItems, badgesData, categoryFlowSelected]);

    useEffect(() => {
        setFilterTag([]);
        setFilterBadge([]);
        setFilterPrice([]);
        setFilterBrand([]);
    }, [loading, data, setFilterTag, setFilterBadge, setFilterBrand, setFilterPrice]);

    if (loading || badgesLoading) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    if (error || badgesError) {
        throw Error(error || badgesError);
    }

    let filteredProducts = getFilteredProducts(products, organization, {
        search: filterSearch,
        tags: filterTag,
        badges: filterBadge,
        brands: filterBrand,
        prices: filterPrice,
    });

    const productsOutOfStock = filteredProducts.filter(
        (product) =>
            !product.isWithoutStock &&
            !product.allowNegativeStock &&
            checkIfProductIsAvailable(product) &&
            !product.canBeSuppliedBeforeOrderDate &&
            product.availableStock <= 0,
    );

    const productsOutOfStockIds = productsOutOfStock.map((product) => product._id);
    filteredProducts = filteredProducts.filter((product) => !productsOutOfStockIds.includes(product._id));
    filteredProducts = filteredProducts.concat(productsOutOfStock);

    const productsInLastOrder = lastOrder?.products
        ? filteredProducts.filter((product) =>
              lastOrder.products.find((productInLastOrder) => productInLastOrder._id === product._id),
          )
        : [];

    const productsNotInLastOrder = lastOrder
        ? differenceBy(filteredProducts, productsInLastOrder, '_id')
        : filteredProducts;

    if (categoryHasChanged || deliveryDateHasChanged) {
        const selectedProductsFromLastOrder = productsInLastOrder.filter((product) => {
            return order.products.find(({ _id }) => _id === product._id);
        });

        const selectedProductsFromOrder = productsNotInLastOrder.filter((product) => {
            return order.products.find(({ _id }) => _id === product._id);
        });
        productsAlreadySelected.current = {
            order: selectedProductsFromOrder,
            lastOrder: selectedProductsFromLastOrder,
        };
    }

    const productsNotSelectedLastOrder = differenceBy(
        productsInLastOrder,
        productsAlreadySelected.current.lastOrder,
        '_id',
    );

    const productsNotSelectedOrder = differenceBy(productsNotInLastOrder, productsAlreadySelected.current.order, '_id');

    const sortedProductsNotInLastOrder = sortProducts({
        products: productsAlreadySelected.current.order.concat(productsNotSelectedOrder),
        order,
    });
    const sortedProductsInLastOrder = sortProducts({
        products: productsAlreadySelected.current.lastOrder.concat(productsNotSelectedLastOrder),
        order,
    });

    const orderProductIds = order.products.map(({ _id }) => _id);

    const displayCard = (product) => {
        const isAlreadyInOrder = orderProductIds.includes(product._id);
        const isProductAvailable = checkIfProductIsAvailable(product);
        const isProductPrivate = !!product?.access?.length;
        const isProductAllowed = (!order.isPunctual && product.isFreefood) || (order.isPunctual && product.isPunctual);

        return (
            isAlreadyInOrder ||
            ((isProductAvailable || product.state === ARCHIVED || product.isPaused) &&
                isProductAllowed &&
                (!isProductPrivate || (isProductPrivate && hasAccessToPrivateProduct(product, organization))))
        );
    };

    return (
        <>
            <TotemProductsWrapperStyle>
                <TotemProductsStyle ref={totemProductsRef}>
                    {productsInLastOrder.length > 0 && (
                        <>
                            <TotemProductsInnerTitleStyle $marginMultiplier={[2.5, 1.5, 1, 1.5]}>
                                Dans mon TOTEM cette semaine
                            </TotemProductsInnerTitleStyle>
                            <TotemProductsInnerStyle $wrap="wrap">
                                {sortedProductsInLastOrder.map((product) => {
                                    const isPaused = product.isPaused;

                                    return (
                                        displayCard(product, isPaused) && (
                                            <CardProduct
                                                key={product._id}
                                                product={product}
                                                totemId={totem._id}
                                                order={order}
                                                categoryId={categoryId}
                                                currentCategory={currentCategory}
                                                organization={organization}
                                            />
                                        )
                                    );
                                })}
                            </TotemProductsInnerStyle>
                            <TotemProductsInnerTitleStyle $marginMultiplier={[1.5, 1.5, 0.5, 1.5]}>
                                Suggestions pour la semaine prochaine
                            </TotemProductsInnerTitleStyle>
                        </>
                    )}
                    <TotemProductsInnerStyle $wrap="wrap">
                        {productsNotInLastOrder.length > 0 &&
                            sortedProductsNotInLastOrder.map((product) => {
                                return (
                                    displayCard(product) && (
                                        <CardProduct
                                            key={product._id}
                                            product={product}
                                            totemId={totem._id}
                                            order={order}
                                            categoryId={categoryId}
                                            currentCategory={currentCategory}
                                            organization={organization}
                                        />
                                    )
                                );
                            })}
                        <CardSuggestion
                            setIsSuggestionPopupOpen={setIsSuggestionPopupOpen}
                            currentCategory={currentCategory}
                        />
                    </TotemProductsInnerStyle>
                </TotemProductsStyle>
                <Overlay name="popup-for-products" />
                {showFilters && <TotemFiltersDropdown context={context} />}
                {isSuggestionPopupOpen && (
                    <SuggestionPopup
                        currentCategory={currentCategory}
                        setIsSuggestionPopupOpen={setIsSuggestionPopupOpen}
                    />
                )}
            </TotemProductsWrapperStyle>
        </>
    );
};

const TotemProductsWrapperStyle = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const TotemProductsStyle = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: scroll;
    background: ${(props) => props.theme.colors.culturedGrey};
`;
