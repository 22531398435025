import gql from 'graphql-tag';

export const CARD_FRAGMENT = gql`
    fragment CardFragment on OrganizationPaymentCard {
        id
        billing_details {
            name
        }
        brand
        card {
            brand
            exp_month
            exp_year
            last4
        }
        exp_month
        exp_year
        last4
        name
        object
        owner {
            name
        }
        sepa_debit {
            bank_code
            country
            last4
        }
        type
    }
`;
