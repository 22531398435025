import React from 'react';
import styled, { keyframes } from 'styled-components';

/**
 * Special thanks to Josh W Comeau for the snippet
 * https://www.joshwcomeau.com/snippets/react-components/fade-in/
 */
export const FadeIn = ({ duration = 300, delay = 0, children, ...delegated }) => {
    return (
        <Wrapper
            {...delegated}
            style={{
                ...(delegated.style || {}),
                animationDuration: duration + 'ms',
                animationDelay: delay + 'ms',
            }}
        >
            {children}
        </Wrapper>
    );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
    @media (prefers-reduced-motion: no-preference) {
        animation-name: ${fadeIn};
        animation-fill-mode: backwards;
    }
`;
