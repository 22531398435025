import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';

import { SIGNUP3 } from 'data/mutations';

import { TotemButton } from 'ui/Button';
import { InlineError } from 'ui/InlineError';

import { validatePhone } from 'utils';

import { Logo } from 'components/Logo';

import { LoginStyle, LoginBottomHalfStyle, LoginTopHalfStyle, LoginWrapperStyle } from 'styles/pages/login.styles';
import { SignupForm, SignupTitleStyle, SignupOneRow } from 'styles/pages/signup.styles';
import { TotemLabel, TotemInput } from 'styles';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

export const GetOfficeManagerInfo = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const [signup3] = useMutation(SIGNUP3);

    const handleSignup3 = async (data) => {
        try {
            const { phone: validatedPhoneNumber } = data;
            const parsedPhoneNumber = parsePhoneNumberFromString(validatedPhoneNumber, 'FR').number;

            const {
                data: {
                    signup3: { totem },
                },
            } = await signup3({
                variables: {
                    data: {
                        ...data,
                        phone: parsedPhoneNumber,
                    },
                },
                // we should write to cache
                refetchQueries: ['GET_ORGANIZATION'],
            });
            track(analyticsEvents.ADD_PERSONAL_INFO, {
                [analyticsPropertiesConstants.ONBOARDING.JOB_TITLE]: data.jobTitle,
            });
            navigate(`/totem/${totem._id}`, { relative: 'path' });
        } catch (error) {
            window.Log.error(error);
        }
    };

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <LoginBottomHalfStyle>
                <LoginWrapperStyle>
                    <SignupTitleStyle>Faisons connaissance</SignupTitleStyle>

                    <SignupForm onSubmit={handleSubmit(handleSignup3)}>
                        <SignupOneRow>
                            <TotemLabel color="white" $marginMultiplier={[1, 0, 0, 0]}>
                                Prénom <InlineError display={errors.firstName} />
                                <TotemInput
                                    placeholder="Entrez votre prénom"
                                    type="text"
                                    autoFocus
                                    data-test="signup-firstname"
                                    {...register('firstName', { required: true })}
                                />
                            </TotemLabel>
                            <TotemLabel color="white" $marginMultiplier={[1, 0, 0, 0]}>
                                Nom <InlineError display={errors.lastName} />
                                <TotemInput
                                    placeholder="Entrez votre nom"
                                    type="text"
                                    data-test="signup-lastname"
                                    {...register('lastName', { required: true })}
                                />
                            </TotemLabel>
                        </SignupOneRow>
                        <SignupOneRow>
                            <TotemLabel color="white" $marginMultiplier={[0, 0.5, 1, 0]}>
                                Rôle <InlineError display={errors.jobTitle} />
                                <TotemInput
                                    placeholder="Entrez votre rôle dans l'entreprise"
                                    type="text"
                                    data-test="signup-job-title"
                                    {...register('jobTitle', { required: true })}
                                />
                            </TotemLabel>
                            <TotemLabel color="white" $marginMultiplier={[0, 0, 1, 0.5]}>
                                Numéro de téléphone <InlineError display={errors.phone} />
                                <TotemInput
                                    placeholder="Entrez votre numéro de téléphone"
                                    type="text"
                                    data-test="signup-phone"
                                    {...register('phone', {
                                        required: true,
                                        validate: validatePhone("Votre numéro de téléphone n'est pas valide"),
                                    })}
                                />
                            </TotemLabel>
                        </SignupOneRow>
                        <TotemButton $margins={[0, 0.5]} type="submit" data-test="signup-submit-step-3">
                            Suivant
                        </TotemButton>
                    </SignupForm>
                </LoginWrapperStyle>
            </LoginBottomHalfStyle>
        </LoginStyle>
    );
};
