import { GET_TOTEMS } from 'data/queries';

export function totemDeletedCacheHandler({ apolloClient, deletedTotemId }) {
    const { totemsOfUser } = apolloClient.readQuery({
        query: GET_TOTEMS,
    });
    apolloClient.writeQuery({
        query: GET_TOTEMS,
        data: {
            totemsOfUser: totemsOfUser.filter((totem) => {
                return totem._id !== deletedTotemId;
            }),
        },
    });
}
