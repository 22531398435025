import styled from 'styled-components';

export const AsideBody = styled.section`
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    overflow-y: auto;
    height: 100%;
`;

export const AsideDescription = styled.div`
    padding: 0 2em 1em 2em;
`;

export const AsideForm = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const AsideHeader = styled.header`
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1em 1.5em;
    z-index: 10;
    background: ${({ theme }) => theme.colors.green};
    h1 {
        font-size: 2em;
    }
`;

export const AsideSubTitle = styled.h2`
    padding: 1rem 2rem 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const AsideTitle = styled.h1`
    margin: 0.25em 0 0 0;
`;
