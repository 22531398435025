import gql from 'graphql-tag';
import { ORGANIZATION_FRAGMENT } from 'data/fragments/organization.fragments';

export const GET_ORGANIZATION = gql`
    query GET_ORGANIZATION {
        organizationOfUser {
            ...OrganizationFragment
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const GET_STRIPE_INVOICES = gql`
    query GET_ZUORA_INVOICES($organizationId: ID!, $startingAfterInvoice: String) {
        getStripeInvoices(organizationId: $organizationId, startingAfterInvoice: $startingAfterInvoice) {
            invoices {
                id
                created
                description
                due_date
                hosted_invoice_url
                invoice_pdf
                number
                receipt_number
                paid
                status
                subtotal
                tax
                total
            }
            hasMore
        }
    }
`;

export const GET_ZUORA_INVOICES = gql`
    query GET_ZUORA_INVOICES($organizationId: ID!) {
        getZuoraInvoices(organizationId: $organizationId) {
            success
            invoices
            error
        }
    }
`;

export const DOWNLOAD_ZUORA_INVOICE = gql`
    query DOWNLOAD_ZUORA_INVOICE($zuoraInvoiceFilesUrl: String!) {
        downloadZuoraInvoice(zuoraInvoiceFilesUrl: $zuoraInvoiceFilesUrl) {
            success
            file
            error
        }
    }
`;

export const GET_USER_GROUPS = gql`
    query GET_USER_GROUPS($organizationId: ID!) {
        userGroups(organizationId: $organizationId) {
            _id
            label
        }
    }
`;
