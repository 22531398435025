import { ApolloClient, InMemoryCache, HttpLink, split, from } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import gql from 'graphql-tag';

import { typePolicies, LinkConfig, ClientConfig, WSConfig } from './config.connections';

import { createErrorHandler } from './connection.handlers';
import { client } from 'index';
import { SPYEE_TOKEN, TOKEN } from 'constants';

const httpLink = new HttpLink(LinkConfig);

export const cache = new InMemoryCache({ typePolicies });

export const readCacheFragment = ({ typeName, id, fragment: fragmentArg }) => {
    return client.readFragment({
        id: `${typeName}:${id}`,
        fragment: gql`
            ${fragmentArg}
        `,
    });
};

export const writeCacheFragment = ({ typeName, id, fragment: fragmentArg, data: dataArg }) => {
    return client.writeFragment({
        id: `${typeName}:${id}`,
        fragment: gql`
            ${fragmentArg}
        `,
        data: {
            __typename: typeName,
            ...dataArg,
        },
    });
};

// We currently have no subscription on this project
const wsLink = new GraphQLWsLink(createClient(WSConfig));

const authenticationLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const authorization = (window.TOTEM.auth.authenticated && window.TOTEM.storage.get(TOKEN)) || '';
    const spiedusertoken = (window.TOTEM.auth.authenticated && window.TOTEM.storage.get(SPYEE_TOKEN)) || '';

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            spiedusertoken,
            authorization,
            webapporigin: true,
        },
    };
});

export function createApolloClient(history) {
    const errorLink = onError(createErrorHandler(history));
    return new ApolloClient({
        link: from([
            errorLink,
            authenticationLink,
            split(
                ({ query }) => {
                    const definition = getMainDefinition(query);
                    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
                },
                wsLink,
                httpLink,
            ),
        ]),
        cache,
        ...ClientConfig,
    });
}
