import React, { useState } from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { CloseContainer, Popup, PopupContent } from 'styles/pages/settings.styles';
import { AbsoluteContainer, ArrowCircleIcon } from 'styles/pages/totem.styles';

import { RemoveIcon } from 'styles/components/card.styles';
import { CreateTotemDeliveryInfo } from 'pages/Order/OrderDuplicate//CreateTotemDeliveryInfo';
import { ExistingTotemSelection } from 'pages/Order/OrderDuplicate/ExistingTotemSelection';

import { CREATE_NEW_TOTEM } from 'data/mutations';

import { totemCreatedCacheHandler } from 'utils/cache/totemCreatedCacheHandler';

import { analyticsEvents, track, analyticsPropertiesConstants } from 'tracking/segment';

/**
 * step: 0 -> Choose TOTEM address or newAddress
 * step: 1 -> Define delivery info
 */
export const CreateTotemPopUp = ({ setIsModalOpen }) => {
    const [hasChosenAddress, setHasChosenAddress] = useState(false);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [existingTotemId, setExistingTotemId] = useState(null);
    const defaultDeliveryInfo = {
        address: '',
        zipcode: '',
        city: '',
        address_details: '',
        consigneDelivery: '',
    };
    const apolloClient = useApolloClient();
    const [createNewTotem] = useMutation(CREATE_NEW_TOTEM);
    const navigate = useNavigate();
    const handleCreateTotem = async (newDeliveryInfo) => {
        const {
            data: { createNewTotem: totemAndUser },
        } = await createNewTotem({
            variables: { primaryTotemId: existingTotemId, deliveryInfo: newDeliveryInfo },
        });
        toast.success('Nouvelle adresse créée avec succès');
        track(analyticsEvents.CREATE_ADDITIONAL_TOTEM, {
            [analyticsPropertiesConstants.TOTEM.IS_SECONDARY_TOTEM]: !!existingTotemId,
        });
        const { totem: newTotem, user: updatedUser } = totemAndUser;
        totemCreatedCacheHandler({ apolloClient, newTotem, updatedUser });
        const { _id: newTotemId } = newTotem;
        setIsModalOpen(false);
        navigate(`/totem/${newTotemId}`, { relative: 'path' });
    };

    return (
        <Popup $alignItems="center" justifyContent="center">
            {!hasChosenAddress ? (
                <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="520px" onClick={(e) => e.stopPropagation()}>
                    <ExistingTotemSelection
                        title="Créer un nouveau point de livraison"
                        description="Livraison à un nouvel étage ou à une nouvelle adresse ? Vous êtes au bon endroit !"
                        validateNewAddress={() => {
                            setHasChosenAddress(true);
                            setDeliveryInfo(defaultDeliveryInfo);
                            setExistingTotemId(null);
                        }}
                        validateExistingTotem={(totem) => {
                            setHasChosenAddress(true);
                            setDeliveryInfo({
                                address: totem.address,
                                zipcode: totem.zipcode,
                                city: totem.city,
                                country: totem.country,
                                latitude: totem.latitude,
                                longitude: totem.longitude,
                                address_details: '',
                                consigneDelivery: '',
                            });
                            setExistingTotemId(totem._id);
                        }}
                        setDeliveryInfo={setDeliveryInfo}
                    />
                    <CloseContainer>
                        <RemoveIcon size="25px" onClick={() => setIsModalOpen(false)} />
                    </CloseContainer>
                </PopupContent>
            ) : (
                <PopupContent $paddings={[3, 2, 2, 2]} $spaceBetween width="520px" onClick={(e) => e.stopPropagation()}>
                    <CreateTotemDeliveryInfo
                        deliveryInfo={deliveryInfo}
                        onSubmit={(newDeliveryInfo) => {
                            handleCreateTotem(newDeliveryInfo);
                        }}
                        isAddressNonEditable={!!existingTotemId}
                    />
                    <AbsoluteContainer $top="9px" $left="9px">
                        <ArrowCircleIcon onClick={() => setHasChosenAddress(false)} size="32px" />
                    </AbsoluteContainer>
                    <CloseContainer>
                        <RemoveIcon size="25px" onClick={() => setIsModalOpen(false)} />
                    </CloseContainer>
                </PopupContent>
            )}
        </Popup>
    );
};
