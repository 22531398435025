import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { SimpleImage } from 'components/SimpleImage';

import { Popup, CloseContainer } from 'styles/pages/settings.styles';
import { RemoveIcon } from 'styles/components/card.styles';

export const TotemDeliveryPicturesPopUp = ({ photos, setShowPictures }) => {
    const photosUrl = [];
    if (!photos) {
        return 'Il y a eu un problème avec ce post.';
    }
    photos.forEach((photoArray) => {
        photosUrl.push(...photoArray.urls);
    });
    const renderImages = () =>
        photosUrl.map((url) => (
            <SliderImageWrapper key={url} tabIndex="-1">
                <SliderImageContent photo={url} />
            </SliderImageWrapper>
        ));

    const sliderSettings = {
        dots: true,
        arrows: true,
        dotsClass: 'slider-dots',
        focusOnSelect: true,
        customPaging: (i) => (
            <a>
                <SimpleImage src={photosUrl[i]} alt="delivery-picture" width={100} />
            </a>
        ),
    };

    const stopPropagation = (e) => {
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
    };

    return (
        <Popup $alignItems="center" $justifyContent="center" onClick={() => setShowPictures(false)}>
            <SliderContainer onClick={stopPropagation}>
                <Slider {...sliderSettings}>{renderImages()}</Slider>
                <CloseContainer top="-20px" right="-20px">
                    <RemoveIcon size="30px" onClick={() => setShowPictures(false)} />
                </CloseContainer>
            </SliderContainer>
        </Popup>
    );
};

export const SliderImageWrapper = styled.div`
    position: relative;
    outline: 0;
    background-color: black;
    border-radius: ${(props) => props.theme.variables.borderRadius};
`;

export const SliderImageContent = styled.div`
    width: 75vw;
    height: 70vh;
    background: url(${({ photo }) => photo}) no-repeat center center / contain;
`;
export const SliderContainer = styled.div`
    position: relative;
    width: 75vw;
    height: 70vh;

    .slick-slide {
        opacity: 0;
        display: inline-block;

        &.slick-active.slick-current {
            opacity: 1;
        }
    }

    .slick-arrow {
        &:before {
            font-family: inherit;
            color: white;
            font-size: 35px;
            font-weight: 500;
            opacity: 1;
        }

        &:hover {
            &:before {
                color: ${(props) => props.theme.colors.yellow};
            }
        }
    }

    .slick-prev {
        margin-left: -30px;
    }

    .slick-next {
        margin-right: -20px;
    }

    .slider-dots {
        padding: 0;
        text-align: center;
        list-style-type: none;

        > li {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            margin: 0 5px;
            overflow: hidden;
            border-radius: ${(props) => props.theme.variables.borderRadius};
            cursor: pointer;
            opacity: 0.75;
            transition: all 0.5s ease;

            &:hover,
            &.slick-active {
                opacity: 1;
            }

            &.slick-active {
                border: 2px solid ${(props) => props.theme.colors.yellow};
            }

            > img {
                position: absolute;
                top: 50%;
                left: 50%;
                background: transparent no-repeat center / cover;
                transform: translate(-50%, -50%);
            }
        }
    }
`;
