import { Logo } from 'components/Logo';
import React from 'react';

import {
    TotemNavStyle,
    TotemNavInner,
    TotemNavCategories,
    TotemNavFooter,
    TotemNavCategoriesScrollable,
} from 'styles/pages/totem.styles';

export const TotemNavPlaceholder = () => {
    return (
        <TotemNavStyle>
            <Logo isLink={false} width={100} />
            <TotemNavInner>
                <TotemNavCategoriesScrollable>
                    <TotemNavCategories />
                </TotemNavCategoriesScrollable>
                <TotemNavFooter />
            </TotemNavInner>
        </TotemNavStyle>
    );
};
