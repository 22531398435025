import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { padding } from 'polished';
import { ClipLoader } from 'react-spinners';
import { formatPrice } from '@totem/constants';
import dayjs from 'dayjs';

import { colors } from 'styles/theme';

import { ProgressBar } from 'components/ProgressBar';

import { GET_MONTHLY_USED_BUDGET_FROM_DATE } from 'data/queries';

export const BudgetDetailInfo = ({ date, totemId, totemMonthlyBudget }) => {
    const currentMonth = dayjs(date).locale('fr').format('MMMM');

    const { loading, data } = useQuery(GET_MONTHLY_USED_BUDGET_FROM_DATE, {
        variables: { totemId, date },
    });

    return (
        <Container>
            <BudgetInfoText>
                Total dépensé sur le mois de {currentMonth} :{' '}
                {loading ? (
                    <ClipLoader size="12px" color={colors.pantinGrey} />
                ) : (
                    formatPrice(data?.getMonthlyUsedBudgetFromDateQuery)
                )}
            </BudgetInfoText>
            <BudgetChart>
                <BudgetData>
                    <BudgetSpent>
                        {loading ? <ClipLoader size="18px" /> : formatPrice(data?.getMonthlyUsedBudgetFromDateQuery)}/
                    </BudgetSpent>
                    <BudgetAvailable>{formatPrice(totemMonthlyBudget)}</BudgetAvailable>
                </BudgetData>
                <ProgressBar max={totemMonthlyBudget} value={loading ? 0 : data?.getMonthlyUsedBudgetFromDateQuery} />
            </BudgetChart>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    width: 100%;
    ${(props) => padding(props.theme.variables.padding, 0)};
    justify-content: flex-end;
`;

const BudgetSpent = styled.div`
    font-size: 20px;
`;

const BudgetAvailable = styled.div`
    font-size: 14px;
`;

const BudgetData = styled.div`
    display: flex;
    font-weight: 800;
    align-items: baseline;
    font-family: Sharp Grotesk;
`;

const BudgetChart = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-end;
`;

const BudgetInfoText = styled.div`
    color: ${(props) => props.theme.colors.pantinGrey};
    font-weight: 500;
    font-size: 16px;
    align-self: flex-end;
    margin-right: 20px;
`;
