import { gql } from '@apollo/client';

import { GET_TOTEMS } from 'data/queries';
import { cache } from 'data/connection/client.connection';

export function totemCreatedCacheHandler({ apolloClient, newTotem, updatedUser }) {
    const { totemsOfUser: totems } = apolloClient.readQuery({
        query: GET_TOTEMS,
    });
    apolloClient.writeQuery({
        query: GET_TOTEMS,
        data: { totemsOfUser: [...totems, newTotem] },
    });
    apolloClient.writeFragment({
        id: cache.identify(updatedUser),
        fragment: gql`
            fragment UserRoles on User {
                roles
            }
        `,
        data: {
            roles: updatedUser.roles,
        },
    });
}
