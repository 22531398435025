import uniq from 'lodash/uniq';

import { ARCHIVED, MODIFIABLE_ORDER_STATES, READY } from 'constants/states-app';

function round(number, numberOfDecimals = 0) {
    const multiplier = Math.pow(10, numberOfDecimals);
    return Math.round(number * multiplier) / multiplier;
}

export function roundPrice(number) {
    return round(number, 2);
}

function getCategoriesInOrderIds(order) {
    if (!order) {
        return [];
    }
    return uniq(order.products.map(({ categoryId }) => categoryId));
}

function getCategoryInOrderInfo(order, categoryId) {
    const productsFromCategory = order.products.filter((product) => product.categoryId === categoryId);
    if (!productsFromCategory.length) {
        return;
    }
    let quantity = 0;
    let portion = 0;
    let price = 0;

    productsFromCategory.forEach((product) => {
        quantity += product.quantity;
        portion += product.portion;
        price += product.unitPrice * product.quantity;
    });

    return {
        _id: categoryId,
        quantity,
        portion,
        price,
    };
}

export function getCategoriesInOrderInfo(order) {
    const categoryIds = getCategoriesInOrderIds(order);
    return categoryIds.reduce((acc, categoryId) => {
        const categoryObject = getCategoryInOrderInfo(order, categoryId);
        return [...acc, categoryObject];
    }, []);
}

export function getRecurringOrdersForDay(recentOrders, day) {
    return recentOrders.reduce((acc, order) => {
        const { state, isPunctual, weekday } = order;
        if (!isPunctual && weekday === day) {
            if (state === ARCHIVED) {
                acc.orderPrevious = order;
            } else if (state === READY) {
                acc.orderReady = order;
            } else if (MODIFIABLE_ORDER_STATES.includes(state)) {
                acc.modifiableOrder = order;
            }
        }
        return acc;
    }, {});
}
