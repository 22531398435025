import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { margin } from 'polished';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

import { SET_STRIPE_DEFAULT_PAYMENT_METHOD, CREATE_SETUP_INTENT } from 'data/mutations/stripe.mutations';

import { getBillingDetails } from 'pages/Settings/SettingsPage/SettingsPayment/utils';

import { TotemButton } from 'ui/Button';
import { TotemInput } from 'styles';
import { colors } from 'styles/theme';

import { STRIPE_CARD } from 'constants/organization';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const CreditCardForm = ({ organization, currentUser, onSubmitCompletion }) => {
    // inspired by https://github.com/stripe/react-stripe-js/blob/9fe1a5473cd1125fcda4e01adb6d6242a9bae731/examples/hooks/2-Split-Card.js
    const elements = useElements();
    const stripe = useStripe();
    const [isLoading, setIsLoading] = useState(false);
    const [createSetupIntent] = useMutation(CREATE_SETUP_INTENT);
    const [setStripeDefaultPaymentMethod] = useMutation(SET_STRIPE_DEFAULT_PAYMENT_METHOD);
    const [cardName, setCardName] = useState(
        currentUser?.profile?.firstname &&
            currentUser?.profile?.lastname &&
            `${currentUser?.profile?.firstname} ${currentUser?.profile?.lastname}`,
    );
    const [errorMessage, setErrorMessage] = useState(null);
    const ELEMENT_STYLES = {
        base: {
            color: colors.black,
            fontFamily: 'Aeonik',
            fontSize: '16px',
            margin: '14px',
            ':focus': {
                color: colors.black,
            },

            '::placeholder': {
                color: colors.pantinGrey,
            },
        },
        invalid: {
            color: '#fff',
            ':focus': {
                color: colors.red,
            },
            '::placeholder': {
                color: '#FFCCA5',
            },
        },
    };
    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        if (!stripe || !elements) {
            setIsLoading(false);
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);
        track(analyticsEvents.ADD_CARD, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
        });

        const {
            data: {
                createSetupIntent: { clientSecret },
            },
            // creates stripe customer if non-existent
        } = await createSetupIntent({
            variables: {
                organizationId: organization._id,
            },
        });

        const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                type: STRIPE_CARD,
                card: cardElement,
                billing_details: getBillingDetails({ cardName, organization, user: currentUser }),
            },
        });
        if (error) {
            setErrorMessage(error.message);
            return;
        } else if (setupIntent.status === 'succeeded') {
            setStripeDefaultPaymentMethod({
                variables: {
                    userId: currentUser._id,
                    organizationId: organization._id,
                    paymentMethodId: setupIntent.payment_method,
                },
            });
            onSubmitCompletion();
        }
        setIsLoading(false);
    };

    return (
        <CreditCardFormContainer onSubmit={handleSubmit}>
            <CardNameInput
                placeholder="Titulaire de la carte"
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
            />
            <ElementWithTitleContainer $margins={[1, 0, 2, 1]}>
                <CardNumberElement options={{ style: ELEMENT_STYLES }} />
            </ElementWithTitleContainer>
            <Line>
                <ElementWithTitleContainer $margins={[0, 0, 0, 1]}>
                    <CardExpiryElement options={{ style: ELEMENT_STYLES }} />
                </ElementWithTitleContainer>
                <ElementWithTitleContainer $margins={[0, 0, 0, 1]}>
                    <CardCvcElement options={{ style: ELEMENT_STYLES }} />
                </ElementWithTitleContainer>
            </Line>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <TotemButton disabled={isLoading} $margins={[2, 0, 0, 0]} $fontSize="1em" type="submit">
                {isLoading ? <ClipLoader color="white" size="16px" /> : 'Enregistrer la carte'}
            </TotemButton>
        </CreditCardFormContainer>
    );
};

const CreditCardFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1em 0 0 0;
`;

const Line = styled.div`
    display: flex;
    flex-direction: row;
`;

const ErrorMessage = styled.div`
    margin: 1em 1em 0 1em;
    color: ${({ theme: { colors } }) => colors.red};
`;

const ElementWithTitleContainer = styled.div`
    ${({ $margins }) =>
        $margins &&
        margin(
            $margins[0] + 'rem',
            ($margins[1] ?? $margins[0]) + 'rem',
            ($margins[2] ?? $margins[0]) + 'rem',
            ($margins[3] ?? $margins[1] ?? $margins[0]) + 'rem',
        )}
    width: 100%;
`;

const CardNameInput = styled(TotemInput)`
    border: none;
    margin: 0;
    &:hover {
        border: none;
    }
    &:active,
    &:focus {
        border: none;
        box-shadow: none;
    }
    color: #111111;
    font-family: Aeonik;
    font-size: 16px;
    outline: none;
`;
