import React, { useContext } from 'react';
import { AspectRatio } from 'deprecated-enkel';

import { limitToNcharacters } from 'utils';
import {
    Card,
    CardTitle,
    CardSubtitle,
    CardPackagingDisplay,
    CardDescription,
    CardContent,
    CardButtonGroup,
    CardActionButton,
    CardOverlay,
    CardOverlayTitle,
} from 'styles/components/card.styles';
import { POST_TO_SLACK } from 'data/mutations';
import { useMutation } from '@apollo/client';
import { UserContext } from 'constants/user-context';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

export const EquipmentCard = ({
    equipment,
    equipmentPicture,
    displayButton,
    totemId,
    totemNumber,
    equipmentsRequested,
    setEquipmentsRequested,
}) => {
    const { organization, user } = useContext(UserContext);
    const {
        _id: userId,
        profile: { firstname, lastname },
    } = user;
    const { _id: equipmentId, name, brand, volume, description } = equipment;
    const hasBeenRequested = equipmentsRequested.includes(equipment._id);
    const [postMessageToSlack] = useMutation(POST_TO_SLACK);

    const handleEquipmentNotification = async () => {
        track(analyticsEvents.ASK_INFO_EQUIPMENT, {
            [analyticsPropertiesConstants.EQUIPMENTS.EQUIPMENT_NAME]: equipment.name,
            [analyticsPropertiesConstants.EQUIPMENTS.EQUIPMENT_ID]: equipment._id,
        });
        setEquipmentsRequested([...equipmentsRequested, equipmentId]);
        const channel = 'hunters';
        const message = `EQUIPMENT REQUEST‼️: ${firstname} ${lastname} (userId: ${userId}) de ${organization.name} (organizationId: ${organization._id}) aimerait ajouter ${equipment.name} (equipmentId: ${equipment._id}) pour son TOTEM#${totemNumber} (totemId: ${totemId})`;
        await postMessageToSlack({
            variables: {
                message,
                channel,
            },
        });
    };

    return (
        <Card>
            <AspectRatio
                size="100%"
                ratio="3:2"
                innerStyle={{
                    background: `url("${equipmentPicture}") no-repeat bottom center / contain white`,
                }}
            >
                {hasBeenRequested && (
                    <CardOverlay>
                        <CardOverlayTitle justifyContent="center" fontSize="1.9em">
                            Bien noté, nous vous contacterons rapidement !
                        </CardOverlayTitle>
                    </CardOverlay>
                )}
            </AspectRatio>
            <CardContent height="unset" $paddingMultiplier={[0.5, 0.5, 0, 0.5]}>
                <CardTitle $vAlign="flex-start">{name}</CardTitle>

                <CardSubtitle>{brand}</CardSubtitle>
                <CardPackagingDisplay>{volume}</CardPackagingDisplay>
            </CardContent>
            {description && <CardDescription>{limitToNcharacters(description, 300)}</CardDescription>}
            {displayButton && (
                <CardButtonGroup
                    $borderRadius={[false, true, true, false]}
                    $noBorder
                    $justifyContent="flex-end"
                    style={{ background: 'transparent' }}
                >
                    <CardActionButton disabled={hasBeenRequested} onClick={handleEquipmentNotification}>
                        Demander un devis
                    </CardActionButton>
                </CardButtonGroup>
            )}
        </Card>
    );
};
