import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { SIGNUP2 } from 'data/mutations';

import { TotemButton } from 'ui/Button';
import { InlineError } from 'ui/InlineError';

import { Logo } from 'components/Logo';

import { LoginStyle, LoginBottomHalfStyle, LoginTopHalfStyle, LoginWrapperStyle } from 'styles/pages/login.styles';
import { SignupForm, SignupOneRow, SignupTitleStyle } from 'styles/pages/signup.styles';
import { TotemLabel, TotemInput } from 'styles';

export const GetOrganizationInfo = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [signup2] = useMutation(SIGNUP2);

    const handleSignup2 = async ({ organizationName }) => {
        try {
            await signup2({
                variables: {
                    data: {
                        organizationName,
                    },
                },
            });
            navigate('/signup/3');
        } catch (error) {
            window.Log.error(error);
        }
    };

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <LoginBottomHalfStyle>
                <LoginWrapperStyle>
                    <SignupTitleStyle>Faisons connaissance</SignupTitleStyle>
                    <SignupForm onSubmit={handleSubmit(handleSignup2)}>
                        <SignupOneRow>
                            <TotemLabel color="white" $marginMultiplier={[1, 0, 0, 0]}>
                                Nom de l'entreprise <InlineError display={errors.organizationName} />
                                <TotemInput
                                    placeholder="Nom de l'entreprise"
                                    type="text"
                                    autoFocus
                                    data-test="signup-organization-name"
                                    {...register('organizationName', { required: true })}
                                />
                            </TotemLabel>
                        </SignupOneRow>
                        <TotemButton $margins={[0, 0.5]} type="submit" data-test="signup-submit-step-2">
                            Suivant
                        </TotemButton>
                    </SignupForm>
                </LoginWrapperStyle>
            </LoginBottomHalfStyle>
        </LoginStyle>
    );
};
