import gql from 'graphql-tag';
import { TOTEM_FRAGMENT } from 'data/fragments/totem.fragments';

export const REACTIVATE_TOTEM = gql`
    mutation REACTIVATE_TOTEM($totemId: ID!) {
        reactivateTotem(totemId: $totemId) {
            totem {
                _id
                number
                state
            }
        }
    }
`;

export const UPDATE_MONTHLY_BUDGET = gql`
    mutation UPDATE_MONTHLY_BUDGET($totemId: ID!, $monthlyBudget: Int!) {
        updateMonthlyBudgetMutation(totemId: $totemId, monthlyBudget: $monthlyBudget) {
            _id
            monthlyBudget
        }
    }
`;

export const CREATE_NEW_TOTEM = gql`
    mutation CREATE_NEW_TOTEM($primaryTotemId: ID, $deliveryInfo: DeliveryInfoInput!) {
        createNewTotem(primaryTotemId: $primaryTotemId, deliveryInfo: $deliveryInfo) {
            totem {
                ...TotemFragment
            }
            user {
                _id
                roles
            }
        }
    }
    ${TOTEM_FRAGMENT}
`;

export const DISCARD_NEW_TOTEM = gql`
    mutation DISCARD_NEW_TOTEM($totemId: String!) {
        discardNewTotem(totemId: $totemId)
    }
`;

export const UPDATE_TOTEM_NAME = gql`
    mutation UPDATE_TOTEM_NAME($totemId: String!, $name: String!) {
        updateTotemName(totemId: $totemId, name: $name) {
            _id
            name
        }
    }
`;

export const UPDATE_TOTEM_ADDRESS = gql`
    mutation UPDATE_TOTEM_ADDRESS($totemId: String!, $data: AddressInput!) {
        updateTotemAddress(totemId: $totemId, data: $data)
    }
`;

export const UPDATE_TOTEM_DELIVERY_INFO = gql`
    mutation UPDATE_TOTEM_DELIVERY_INFO($totemId: String!, $data: DeliveryInfoInput!) {
        updateTotemDeliveryInfo(totemId: $totemId, data: $data) {
            _id
            address
            address_details
            zipcode
            city
            consigneDelivery
            latitude
            longitude
        }
    }
`;
