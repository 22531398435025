import React, { useState } from 'react';
import styled from 'styled-components';

import { DeliveryInfoForm } from 'pages/Totem/TotemDashboard/DeliveryInfoForm';

import { PopupTitle } from 'styles/pages/settings.styles';

export const CreateTotemDeliveryInfo = ({ deliveryInfo, onSubmit, isAddressNonEditable }) => {
    const [tempDeliveryInfo, setTempDeliveryInfo] = useState(deliveryInfo);
    const sendFormContent = (newDeliveryInfo) => {
        onSubmit(newDeliveryInfo);
    };

    return (
        <Container>
            <PopupTitle>Informations du nouveau point de livraison</PopupTitle>
            <DeliveryInfoForm
                currentTotem={deliveryInfo}
                deliveryInfo={tempDeliveryInfo}
                setDeliveryInfo={setTempDeliveryInfo}
                sendFormContent={sendFormContent}
                isAddressNonEditable={isAddressNonEditable}
                loading={false}
                isCreatingAddress
            />
        </Container>
    );
};

const Container = styled.div`
    padding: 0 20px;
`;
