import { ErrorMetaInterface } from './interfaces';

import * as ErrorName from './error-names';

export { ErrorName };

export default {
    [ErrorName.INTERNAL]: {
        message: 'Erreur interne',
        status: 500,
        global: false,
        level: 'critical',
    },

    // AHE: for Api Handled Error; example: AHEAD is Api Handled Error Access Denied
    // AUE: for Api Unhandled Error; example
    [ErrorName.AHEAD]: {
        message: 'Accès refusé',
        status: 403,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHERA]: {
        message: 'Authentification requise',
        status: 401,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHEBI]: {
        message: 'Identifiants erronés',
        status: 404,
        global: false,
        level: 'error',
    },
    [ErrorName.AHENA]: {
        message: 'Action refusée',
        status: 401,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHEARCHIVED]: {
        message: 'Action refusée',
        status: 401,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHEBANNED]: {
        message: 'Action refusée',
        status: 401,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHEEA]: {
        message: 'Cet email existe déjà',
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEEV]: {
        message: "Votre email n'est pas valide",
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEEP]: {
        message: 'Email professionel requis',
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.AHELPL]: {
        message: 'La quantité de produit ne peut être inférieure à une unité',
        status: 404,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHEOA]: {
        message: 'Cette entreprise existe déjà',
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.AHESD]: {
        message: 'Désolé.',
        status: 403,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHEEC]: {
        message: "Erreur lors de l'envoi d'email",
        status: 500,
        global: false,
        level: 'error',
    },
    [ErrorName.AHESC]: {
        message: 'Carte bancaire refusée',
        status: 500,
        global: false,
        level: 'error',
    },
    [ErrorName.AHEVC]: {
        message: 'Vous avez déjà ajouté cette carte',
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.AHENC]: {
        message: "Nous n'acceptons pas d'emails de ce domaine.",
        status: 403,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHECA]: {
        message: 'Cette commande ne peut plus être annulée',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHECC]: {
        message: 'Cette commande est une première commande',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHECP]: {
        message: "Cette commande n'est pas une première commande",
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHECJ]: {
        message: 'Cette commande ne peut plus être ajustée',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHECE]: {
        message: 'Cette méthode ne peut pas modifier cette commande',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEVN]: {
        message: "Vous n'avez pas de moyen de paiement",
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHELM]: {
        message:
            'Le montant est inférieur au minimum de commande (Total: 60€, Fruits: 25€, Produits frais: 20€, Matin: 15€)',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEAP]: {
        message: 'Ajouter des produits dans toutes les catégories',
        status: 400,
        level: 'info',
    },
    [ErrorName.AHEIY]: {
        message: 'Il y a eu un problème sur le paiement',
        status: 500,
    },
    [ErrorName.AHELE]: {
        message: 'La catégorie est introuvable dans votre totem',
        status: 500,
        global: false,
        level: 'error',
    },
    [ErrorName.AHEPD]: {
        message: 'Nous ne retrouvons pas votre commande précédente',
        status: 500,
        level: 'error',
    },
    [ErrorName.AHEPAM]: {
        message: 'Pas de conseiller dédié ou de livraison active',
        status: 300,
    },
    [ErrorName.AHETP]: {
        message: 'Trop de produits sélectionnés',
        status: 400,
        level: 'info',
    },
    [ErrorName.AHEPP]: {
        message: 'Pas de produit à ajouter trouvé',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEPC]: {
        message: 'Pas de catégorie trouvée',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEPA]: {
        message: 'Pas de produit à ajouter trouvé dans la commande',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEPS]: {
        message: 'Nope',
        status: 500,
        global: false,
        level: 'critical',
    },
    [ErrorName.AHECT]: {
        message: "Cette commande n'a pas de totemId",
        status: 500,
        global: false,
        level: 'error',
    },
    [ErrorName.AHECN]: {
        message: "Erreur lors de la validation d'achat",
        status: 500,
        global: false,
        level: 'error',
    },
    [ErrorName.AHETP1]: {
        message: 'Vous avez trop de produits sélectionnés: veuillez augmenter les portions par personne !',
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.AHETP2]: {
        message: 'Vous avez trop de produits sélectionnés: veuillez augmenter les portions par personne !',
        status: 404,
        global: false,
        level: 'warning',
    },
    [ErrorName.AHETP3]: {
        message: 'Vous avez trop de produits sélectionnés: veuillez augmenter les portions par personne !',
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEDBNM]: {
        message: 'Veuillez sélectionner une date de livraison à partir de la semaine prochaine.',
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.AHEDB48]: {
        message: 'Veuillez sélectionner une date de livraison dans plus de 48h.',
        status: 404,
        global: false,
        level: 'info',
    },
    [ErrorName.TOTEM_DELETION]: {
        message: 'Vous ne pouvez pas supprimer un TOTEM avec des commandes validées ou lié à un store.',
        status: 400,
        global: false,
        level: 'info',
    },
    [ErrorName.DOMAIN_ALREADY_TAKEN]: {
        message: "Une entreprise avec ce domaine d'email existe déjà.",
        status: 400,
        global: false,
        level: 'info',
    },
} as ErrorMetaInterface;
