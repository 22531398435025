import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Flex } from 'deprecated-enkel';

import { REACTIVATE_ORDER } from 'data/mutations';
import { GET_ORDERS_OF_TOTEM } from 'data/queries';

import { DAYS_OF_THE_WEEK, WEEKDAY_INFO } from 'constants/date-constants';
import { PAUSED } from 'constants/states-app';

import { TotemButton } from 'ui/Button';

import { PopupTitle } from 'styles/pages/settings.styles';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const UpdateExistingOrderConfirmation = ({
    order,
    ordersOfTotem,
    currentTotem,
    defaultCategoryId,
    selectedDate,
}) => {
    const apolloClient = useApolloClient();
    const navigate = useNavigate();

    const [reactivateOrder] = useMutation(REACTIVATE_ORDER);

    const { recentOrders } = ordersOfTotem;

    const { _id: totemId } = currentTotem;

    const weekday = selectedDate && DAYS_OF_THE_WEEK[dayjs(selectedDate._d).format('d').toLowerCase()];
    const translatedWeekday = WEEKDAY_INFO.find((day) => day.value === weekday).name;

    const createUrl = (orderId) => `${totemId}/${defaultCategoryId}/${orderId}`;

    const handleReactivateOrder = async (orderId) => {
        const {
            data: { reactivateOrder: updatedOrder },
        } = await reactivateOrder({
            variables: { orderId },
        });

        const updatedOrders = recentOrders.map((orderFromCache) =>
            orderFromCache._id === orderId ? updatedOrder : orderFromCache,
        );

        apolloClient.writeQuery({
            query: GET_ORDERS_OF_TOTEM,
            variables: { totemId },
            data: {
                ordersOfTotem: { ...ordersOfTotem, recentOrders: updatedOrders },
            },
        });
        track(analyticsEvents.REACTIVATE_ORDER, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.ORDER_CREATION,
        });

        navigate(createUrl(orderId));
    };

    const handleUpdateOrder = async (orderId) => {
        track(analyticsEvents.MODIFY_ORDER, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.ORDER_CREATION,
            [analyticsPropertiesConstants.ORDER.IS_PUNCTUAL]: order.isPunctual,
        });
        navigate(createUrl(orderId));
    };

    return (
        <>
            <PopupTitle $margins={[1, 0, 1]}>Vous avez déjà une commande prévue {translatedWeekday}</PopupTitle>
            <Flex $flex="1" $justifyContent="center" $direction="column">
                {order.isPunctual ? (
                    <TotemButton
                        $margins={[1, 3]}
                        style={{ color: 'yellow' }}
                        onClick={() => {
                            handleUpdateOrder(order._id);
                        }}
                    >
                        Modifier ma commande
                    </TotemButton>
                ) : order.state === PAUSED ? (
                    <TotemButton
                        $margins={[1, 3]}
                        style={{ color: 'yellow' }}
                        onClick={() => handleReactivateOrder(order._id)}
                        data-test="reactivate-order-button"
                    >
                        Réactiver ma commande en pause
                    </TotemButton>
                ) : (
                    <>
                        <TotemButton
                            style={{ color: 'yellow' }}
                            $margins={[0, 3]}
                            data-test="adjust-recurrent-order-button"
                            onClick={() => handleUpdateOrder(order._id)}
                        >
                            Modifier ma commande récurrente
                        </TotemButton>
                    </>
                )}
            </Flex>
        </>
    );
};
