import React from 'react';

import {
    NutritionFactsStyle,
    NutritionFactsHeader,
    NutritionFactsTitle,
    NutritionFactsTable,
    NutritionFactsSmallTable,
    NutritionFactsTeneur,
    NutritionFactsSmallPar,
    NutritionFactsMediumBottomRow,
    NutritionFactsSmallTd,
    NutritionFactsBlankTd,
} from 'styles/components/nutrition-facts.styles';

const dailyAllowances = {
    caloriesKCal: 2000,
    caloriesKJ: 8400,
    totalFat: 65,
    sturatedFat: 20,
    carbohydrates: 260,
    sugars: 90,
    fibers: 25,
    protein: 50,
    sodium: 6,
};

const getDailyRatioForValue = (value, key) => Math.round((value / dailyAllowances[key]) * 100);

export const NutritionFacts = ({ nutrition }) => {
    const { caloriesKCal, totalFat, sturatedFat, sodium, carbohydrates, fibers, sugars, protein } = nutrition;
    const caloriesKJ = Math.round(caloriesKCal * 4.184);

    return (
        <NutritionFactsStyle>
            <NutritionFactsHeader>
                <NutritionFactsTitle>Valeurs nutritionnelles</NutritionFactsTitle>
                <p>par portion de 100g</p>
            </NutritionFactsHeader>
            <NutritionFactsTable>
                <thead>
                    <tr>
                        <NutritionFactsTeneur colSpan="3">
                            <b>Teneur</b>
                        </NutritionFactsTeneur>
                    </tr>
                </thead>
                <tbody>
                    <NutritionFactsMediumBottomRow>
                        <th colSpan="2">
                            <b>Calories</b> {caloriesKCal} kCal
                        </th>
                        <td>
                            <b>Calories</b> {caloriesKJ} kJ
                        </td>
                    </NutritionFactsMediumBottomRow>
                    <tr>
                        <NutritionFactsSmallTd colSpan="3">
                            <b>% valeur quotidienne*</b>
                        </NutritionFactsSmallTd>
                    </tr>
                    <tr>
                        <th colSpan="2">
                            <b>Lipides</b> {totalFat || 0}g
                        </th>
                        <td>
                            <b>{getDailyRatioForValue(totalFat, 'totalFat')}%</b>
                        </td>
                    </tr>
                    <tr>
                        <NutritionFactsBlankTd />
                        <th>saturés {sturatedFat || 0}g</th>
                        <td>
                            <b>{getDailyRatioForValue(sturatedFat, 'sturatedFat')}%</b>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2">
                            <b>Glucides</b> {carbohydrates || 0}g
                        </th>
                        <td>
                            <b>{getDailyRatioForValue(carbohydrates, 'carbohydrates')}%</b>
                        </td>
                    </tr>
                    {!!fibers && (
                        <tr>
                            <NutritionFactsBlankTd />
                            <th>Fibres {fibers || 0}g</th>
                            <td>
                                <b>{getDailyRatioForValue(fibers, 'fibers')}%</b>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <NutritionFactsBlankTd />
                        <th>Sucres {sugars || 0}g</th>
                        <td>
                            <b>{getDailyRatioForValue(sugars, 'sugars')}%</b>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2">
                            <b>Protéines</b> {protein || 0}g
                        </th>
                        <td>
                            <b>{getDailyRatioForValue(protein, 'protein') || 0}%</b>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2">
                            <b>Sodium</b> {sodium || 0}mg
                        </th>
                        <td>
                            <b>{getDailyRatioForValue(sodium, 'sodium')}%</b>
                        </td>
                    </tr>
                </tbody>
            </NutritionFactsTable>

            <NutritionFactsSmallPar>
                * Les valeurs quotidiennes en pourcentage sont basées sur un régime de 2000 calories. Vos valeurs
                quotidiennes peuvent être supérieures ou inférieures en fonction de vos besoins en calories :
            </NutritionFactsSmallPar>
            <NutritionFactsSmallTable>
                <thead>
                    <tr>
                        <td colSpan="2" />
                        <th>Calories :</th>
                        <th>2000</th>
                        <th>2500</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th colSpan="2">Lipides</th>
                        <td className="less-than">Moins de</td>
                        <td>65g</td>
                        <td>80g</td>
                    </tr>
                    <tr>
                        <NutritionFactsBlankTd />
                        <th>saturés</th>
                        <td className="less-than">Moins de</td>
                        <td>20g</td>
                        <td>25g</td>
                    </tr>
                    <tr>
                        <th colSpan="2">Sodium</th>
                        <td className="less-than">Moins de</td>
                        <td>2400mg</td>
                        <td>2400mg</td>
                    </tr>
                    <tr>
                        <th colSpan="3">Glucides</th>
                        <td>300g</td>
                        <td>375g</td>
                    </tr>
                    <tr>
                        <NutritionFactsBlankTd />
                        <th colSpan="2">Fibres</th>
                        <td>25g</td>
                        <td>30g</td>
                    </tr>
                </tbody>
            </NutritionFactsSmallTable>
        </NutritionFactsStyle>
    );
};
