import React from 'react';
import { OptimizedImage } from 'components/OptimizedImage';

import { getInitials } from 'utils';

import { Tooltip } from 'styles';
import styled from 'styled-components';

export const TotemUserPicture = ({ firstName, lastName, imageUrl }) => {
    return (
        <>
            {imageUrl ? (
                <OptimizedImage
                    width={40}
                    src={imageUrl}
                    hasBorderRadius
                    data-for="userPicture"
                    data-tip={`${firstName} ${lastName}`}
                />
            ) : (
                firstName &&
                lastName && (
                    <PicturePlaceholder data-for="userPicture" data-tip={`${firstName} ${lastName}`}>
                        {getInitials(firstName, lastName)}
                    </PicturePlaceholder>
                )
            )}
            {((firstName && lastName) || imageUrl) && (
                <Tooltip id="userPicture" type="dark" effect="solid" place="bottom" />
            )}
        </>
    );
};

const PicturePlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    background-color: ${({ theme: { colors } }) => colors.black};
    color: white;
    border-radius: 50%;
`;
