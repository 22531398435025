import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { SECONDARY } from 'constants/types';

import { RemoveIcon } from 'styles/components/card.styles';
import { PopupContent, CloseContainer, PopupTitle } from 'styles/pages/settings.styles';

import { UPDATE_TOTEM_DELIVERY_INFO } from 'data/mutations';

import { Popup } from 'components/Popup';
import { DeliveryInfoForm } from './DeliveryInfoForm';

export const DeliveryInfoPopup = ({ currentTotem, closeAddressPopup }) => {
    const [deliveryInfo, setDeliveryInfo] = useState(currentTotem);
    const [updateTotemDeliveryInfo, { loading }] = useMutation(UPDATE_TOTEM_DELIVERY_INFO);

    const isSecondary = currentTotem.type === SECONDARY;

    const sendFormContent = async (newDeliveryInfo) => {
        await updateTotemDeliveryInfo({
            variables: {
                totemId: currentTotem._id,
                data: {
                    ...newDeliveryInfo,
                },
            },
        });
    };
    return (
        <Popup isOpen={true} color="white" handleClose={closeAddressPopup} width="455px" overflow="visible">
            <PopupContent $paddings={[2, 2, 2, 2]} $spaceBetween $width="455px" onClick={(e) => e.stopPropagation()}>
                <PopupTitle>Informations de livraison</PopupTitle>
                <DeliveryInfoForm
                    currentTotem={currentTotem}
                    deliveryInfo={deliveryInfo}
                    hasLocationRestriction
                    setDeliveryInfo={setDeliveryInfo}
                    sendFormContent={sendFormContent}
                    isAddressNonEditable={isSecondary}
                    onFormSubmitted={closeAddressPopup}
                    loading={loading}
                />
                <CloseContainer>
                    <RemoveIcon size="25px" onClick={closeAddressPopup} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};
