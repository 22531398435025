import React from 'react';
import styled from 'styled-components';
import { IconTruckDelivery } from '@tabler/icons-react';

import { ABORTED, DELIVERED, INPROGRESS, NOT_DELIVERED } from 'constants/states-app';

import { colors } from 'styles/theme';

export const OrderTableState = ({ delivery, firstOrder, hasBeenValidated, isPunctual, state }) => {
    const isUnvalidatedFirstOrder = firstOrder && !hasBeenValidated;
    const hasBeenDelivered = delivery?.state === DELIVERED;
    const isBeingDelivered = delivery?.state === INPROGRESS;
    const hasBeenAborted =
        delivery?.stateHistory.some(({ state }) => state === ABORTED) || delivery?.state === NOT_DELIVERED;

    const statusInfo = {
        Created:
            isPunctual || isUnvalidatedFirstOrder
                ? {
                      status: 'En cours de création',
                      color: colors.orange,
                  }
                : { status: 'Automatiquement renouvelée', color: colors.green },
        InProgress: isPunctual
            ? {
                  status: 'En cours de création',
                  color: colors.orange,
              }
            : isUnvalidatedFirstOrder
              ? {
                    status: 'En cours de création',
                    color: colors.orange,
                }
              : {
                    status: 'En cours de modification',
                    color: colors.yellow,
                },
        Validated: {
            status: 'Validée',
            color: colors.green,
        },
        Ready: hasBeenDelivered
            ? { status: 'Livrée', color: colors.green }
            : isBeingDelivered
              ? { status: 'Livraison en cours', color: colors.blue }
              : !isPunctual && (!delivery || hasBeenAborted)
                ? // Ready recurring orders should always have an associated delivery (otherwise the order is cancelled)
                  {
                      status: 'Annulée',
                      color: colors.red,
                  }
                : { status: 'En préparation', color: colors.green },
        Archived:
            !delivery || hasBeenAborted
                ? {
                      status: 'Annulée',
                      color: colors.red,
                  }
                : { status: 'Livrée', color: colors.green },
        Paused: { status: 'Mise en pause', color: colors.pantinGrey },
    };
    const { status, color } = statusInfo[state];
    return (
        <StatusCell data-test="order-status">
            <TotemStatus color={color} />
            {delivery?.state === INPROGRESS && delivery?.trackingUrl ? (
                <LinkToDelivery
                    href={delivery?.trackingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Voir la livraison en cours"
                >
                    {status}
                    <IconTruckDeliveryAsLink size="18px" />
                </LinkToDelivery>
            ) : (
                status
            )}
        </StatusCell>
    );
};

const LinkToDelivery = styled.a`
    display: flex;
    align-items: center;
`;

const IconTruckDeliveryAsLink = styled(IconTruckDelivery)`
    margin-left: 5px;
`;

const StatusCell = styled.div`
    display: flex;
    align-items: center;
`;

const TotemStatus = styled.div`
    background-color: ${({ color }) => color};
    border-radius: 7px;
    height: 14px;
    width: 14px;
    margin-right: 7px;
`;
