import { Link } from 'react-router-dom';
import { transparentize, margin } from 'polished';
import styled from 'styled-components';

import { IMAGE_LOGIN_BACKGROUND } from 'data/images';

import { buildImgUrl } from 'utils/image/buildImgUrl';
import { createOptimizedImageUri } from 'utils/image/createOptimizedImageUri';

export const LoginStyle = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    background: inherit;
`;

export const LoginFormStyle = styled.form`
    width: 90%;
    max-width: 450px;
`;

const LoginHalfStyle = styled.div`
    @media only screen and (max-width: 600px) {
        height: 100%;
    }
`;

export const LoginTopHalfStyle = styled(LoginHalfStyle)`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 25px;
    background: ${(props) => props.theme.colors.culturedGrey};
    user-select: none;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

export const LoginBottomHalfStyle = styled(LoginHalfStyle)`
    flex: 1;
    background: url(${createOptimizedImageUri({ src: buildImgUrl(IMAGE_LOGIN_BACKGROUND) })}) no-repeat center / cover;
`;

export const LoginWrapperStyle = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: ${(props) => transparentize(0.45, props.theme.colors.black)};
`;

export const PasswordReminderText = styled.div`
    font-size: 2.1em;
    letter-spacing: 0.2px;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
    color: ${(props) => (props.yellow ? props.theme.colors.yellow : props.theme.colors.white)};
    ${(props) => margin(props.theme.variables.margin * 0.35)};
`;

export const LoginText = styled.div`
    font-size: 1em;
    letter-spacing: 0.2px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.white};
`;

export const RecoverPasswordLink = styled(Link)`
    font-weight: 500;
    color: ${(props) => props.theme.colors.white};
`;

export const BorderBottomLink = styled(Link)`
    color: ${(props) => props.theme.colors.white};
    text-decoration: underline;
`;

export const BorderBottomText = styled.div`
    color: ${(props) => props.theme.colors.white};
`;
