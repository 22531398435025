import React, { Component } from 'react';

import { UserContext } from 'constants/user-context';

import { OptimizedImage } from 'components/OptimizedImage';

import { HandleNotification } from 'utils';
import { buildImgUrl } from 'utils/image/buildImgUrl';

import { ErrorHandlerStyle, ErrorHandlerTitle, ErrorHandlerMessage, ErrorHandlerCode } from 'styles';

export class ErrorHandler extends Component {
    static getDerivedStateFromError(error) {
        return { error, errorId: null, location: window.location.href, fromGdse: true };
    }

    state = {
        error: null,
        errorId: null,
        location: null,
        fromGdse: false,
    };

    componentDidUpdate(_, prevState) {
        if (prevState.location !== window.location.href) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                error: null,
                location: window.location.href,
            });
        }
    }

    componentDidCatch(error, errorInfo) {
        let errorId;
        const type = error.type || 'fatal';
        window.Log[type](
            error,
            errorInfo,
            (generatedErrorId) => {
                errorId = generatedErrorId;
            },
            false,
        );

        this.setState({ errorId });

        if (!error.global) {
            HandleNotification(error, false);
        }
    }

    static contextType = UserContext;

    handleReresh(e) {
        e.preventDefault();
        e.stopPropagation();
        window.location.reload();
    }

    render() {
        const { children } = this.props;
        const { error, errorId } = this.state;

        if (error) {
            return (
                <ErrorHandlerStyle>
                    <OptimizedImage
                        src={buildImgUrl('img/illustrations/Totem_illustration-021.png')}
                        // alt={"Illustration d'un café renversé, illustrant un problème qui vient de survenir."}
                        width={350}
                        $margins={[0, 0, 1, 0]}
                    />
                    <ErrorHandlerTitle>Oups, il y a eu une petite erreur...</ErrorHandlerTitle>
                    <ErrorHandlerMessage>
                        Vous pouvez ouvrir le chat et indiquer votre
                        <br />
                        ticket d'erreur unique que voici :
                    </ErrorHandlerMessage>
                    <ErrorHandlerCode>
                        <code>ID: {errorId}</code>
                    </ErrorHandlerCode>
                    <ErrorHandlerMessage>
                        Pendant ce temps, essayez {/* eslint-disable-next-line */}
                        <a href="#" onClick={this.handleReresh}>
                            d'actualiser la page
                        </a>
                        {' !'}
                    </ErrorHandlerMessage>
                </ErrorHandlerStyle>
            );
        }

        return children;
    }
}
