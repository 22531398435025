import { gql } from '@apollo/client';

export const TOTEM_FRAGMENT = gql`
    fragment TotemFragment on Totem {
        _id
        address
        address_details
        city
        consigneDelivery
        country
        hasValidatedOrders
        latitude
        longitude
        microstore {
            _id
        }
        minimumOrderAmountInfo {
            minimumBakeryAmount
            minimumDryAmount
            minimumFreshAmount
            minimumFruitAmount
            minimumTotalAmount
        }
        monthlyBudget
        name
        number
        organizationId
        primaryTotemId
        state
        type
        zipcode
    }
`;
