import { css } from 'styled-components';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { User } from '@totem/roles';

import { Notification } from 'lib/Notification';
import { FRUIT_CATEGORY_ID } from 'constants/categories-constants';
import { UNCOUNTABLE_PRODUCT_IDS } from 'constants/product-constants';
import { ARCHIVED, CREATED, REGULAR } from 'constants/states-app';

export function resolvePath() {
    return [...arguments].join('/');
}

export const regexInternationalNumber = new RegExp(/^([+]|0{2})([17]|[2-689][0-9]{1,2})[0-9]{8,14}$/);

export function isClient() {
    return !User.isGlobalToteam() && !User.isGlobalTotest();
}

export function formUrl() {
    const argv = [...arguments];
    const isAbsolute = argv.pop();

    if (typeof isAbsolute !== 'boolean') {
        argv.push(isAbsolute);
    }

    const baseUrl = resolvePath.apply(null, argv);

    return function () {
        return (isAbsolute ? '/' : '') + resolvePath.apply(null, [baseUrl, ...arguments]);
    };
}

export function breakpointMixin(cardWidth, minWidth, maxWidth = 9999999999, marginValue = 5) {
    if (marginValue === 5) {
        cardWidth = `calc(${cardWidth} - ${marginValue * 2}px)`;
    }

    return css`
        @media screen and (min-width: ${minWidth}px) and (max-width: ${maxWidth}px) {
            width: ${cardWidth};
        }
    `;
}

export function capitalize(word) {
    return word && word.charAt(0).toUpperCase() + word.substr(1);
}

export function getInitials(firstName, lastName) {
    return firstName && lastName && `${firstName.slice(0, 1)} ${lastName.slice(0, 1)}`.toUpperCase();
}

export function getKeysWithTrueValues(object) {
    return Object.keys(object).filter((key) => object[key] === true);
}

function getPriceValue(price, pricesRange) {
    const priceMin = Math.min(...pricesRange);
    const priceMax = Math.max(...pricesRange);
    const priceAvg = (priceMin + priceMax) / 2;
    const avgMinHalf = (priceMin + priceAvg) / 2;
    const avgMaxHalf = (priceMax + priceAvg) / 2;
    let value, label;

    if (price < avgMinHalf) {
        value = 'low';
        label = '€';
    } else if (price >= avgMinHalf && price < avgMaxHalf) {
        value = 'average';
        label = '€€';
    } else if (price >= avgMaxHalf) {
        value = 'high';
        label = '€€€';
    }

    return {
        value,
        label,
    };
}

export function isCategoryAvailable(category) {
    const LIST_OF_CATEGORY_UNAVAILABLE_STATES = User.isGlobalToteam() ? [ARCHIVED] : [ARCHIVED, CREATED];

    return !LIST_OF_CATEGORY_UNAVAILABLE_STATES.includes(category.state);
}

export function getPortionName(product) {
    const getFruitName = () => {
        const words = product.name.split(' ');
        return words[0].toLowerCase();
    };

    return product.categoryId === FRUIT_CATEGORY_ID && !UNCOUNTABLE_PRODUCT_IDS.includes(product._id)
        ? getFruitName()
        : 'portion';
}

export function checkIfProductIsAvailable(product) {
    return product.isFreefood && (product.state === REGULAR || (User.isGlobalToteam() && product.isBeingTested));
}

export function hasAccessToPrivateProduct(product, organization) {
    return product.access.includes(organization._id);
}

export function getProductFilters(products, organization, badges) {
    const filteredProducts = products.filter((product) => {
        const isProductPrivate = !!product?.access?.length;
        return (
            checkIfProductIsAvailable(product) &&
            (!isProductPrivate || (isProductPrivate && hasAccessToPrivateProduct(product, organization)))
        );
    });

    const pricesRange = filteredProducts.map((p) => parseFloat((p.price / p.portion).toFixed(2)));
    return filteredProducts.reduce(
        (filters, { badges, tags, brand, price, portion }) => {
            badges &&
                badges.forEach((badge) => {
                    if (!filters.badges.find((badgeFilter) => badgeFilter.value === badge._id)) {
                        !badge.includes('typename') &&
                            filters.badges.push({
                                label: badge.name,
                                value: badge._id,
                            });
                    }
                });

            tags &&
                tags.forEach((tag) => {
                    if (!filters.tags.find((tagFilter) => tagFilter.value === tag._id)) {
                        filters.tags.push({
                            label: tag.name,
                            value: tag._id,
                        });
                    }
                });

            if (brand && !filters.brands.find((brandFilter) => brandFilter.value === brand)) {
                filters.brands.push({
                    label: brand,
                    value: brand,
                });
            }

            if (!isNaN(price)) {
                const pricePerPortion = parseFloat((price / portion).toFixed(2));
                const priceValue = getPriceValue(pricePerPortion, pricesRange).value;

                if (!filters.prices.find((priceFilter) => priceFilter.value === priceValue)) {
                    filters.prices.push(getPriceValue(pricePerPortion, pricesRange));
                    filters.prices = filters.prices.sort((a, b) => a.label.length - b.label.length);
                }
            }

            return filters;
        },
        {
            badges:
                badges?.map((badge) => {
                    return { label: badge.name, value: badge._id };
                }) || [],
            tags: [],
            prices: [],
            brands: [],
        },
    );
}

export function getFilteredProducts(products, organization, { tags, badges, brands, prices, search = '' }) {
    const filteredProducts = products.filter((product) => {
        const isProductPrivate = !!product?.access?.length;
        return (
            checkIfProductIsAvailable(product) &&
            (!isProductPrivate || (isProductPrivate && hasAccessToPrivateProduct(product, organization)))
        );
    });
    const pricesRange = filteredProducts.map((p) => parseFloat((p.price / p.portion).toFixed(2)));
    const tagsLength = tags.length;
    const badgesLength = badges.length;
    const pricesLength = prices.length;
    const brandsLength = brands.length;

    if (
        (tags && tagsLength) ||
        (badges && badgesLength) ||
        (brands && brandsLength) ||
        (prices && pricesLength) ||
        search
    ) {
        let isFilteredTags = true;
        let isFilteredBadges = true;
        let isFilteredBrands = true;
        let isFilteredPrices = true;
        let isFilteredSearch = true;

        return products.filter((product) => {
            if (tags && tagsLength) {
                isFilteredTags = tags.some((o) => product.tags && product.tags.find(({ _id }) => o === _id));
            }

            if (badges && badgesLength) {
                const productBadges = [...product.preferences, ...product.diets];
                isFilteredBadges = badges.some((o) => productBadges.some((productBadge) => productBadge._id === o));
            }

            if (prices && pricesLength) {
                const subscriberPrice =
                    Math.round(((product.unitPrice * product.quantity) / product.portion) * 100) / 100;
                isFilteredPrices = prices.some((o) => o === getPriceValue(subscriberPrice, pricesRange).value);
            }

            if (brands && brandsLength) {
                isFilteredBrands = brands.some((brand) => brand === product.brand);
            }

            if (search) {
                const regExp = new RegExp(search, 'i');
                isFilteredSearch =
                    regExp.test(product.name) || regExp.test(product.fullname) || regExp.test(product.description);
            }

            return isFilteredTags && isFilteredBadges && isFilteredBrands && isFilteredPrices && isFilteredSearch;
        });
    }

    return products;
}

export function debounce(func, wait) {
    let timeout;

    function debounceFunction() {
        const args = [...arguments];

        if (args[0].constructor.name === 'SyntheticEvent') {
            args[0].persist();
        }

        const later = function () {
            timeout = null;
            func.apply(func, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    }

    debounceFunction.cancel = function () {
        clearTimeout(timeout);
    };

    return debounceFunction;
}

export function parseQueryStrings(queryString) {
    if (!queryString) {
        return {};
    }

    const params = new URLSearchParams(queryString);
    const searchParamsObject = [...params.entries()].reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    return searchParamsObject;
}

export const handleTextInput = (e, setState, state) => {
    const {
        target: { value, name },
    } = e;
    setState({
        ...state,
        [name]: value,
    });
};

/**
 * Function that gets the difference in time between two hours (eg. "9:00", "10:30" -> 1,5)
 * @param {String} time1 String representing the time (eg. "9:00")
 * @param {String} time2 String representing the time (eg. "10:30")
 */
export function getTimeDifference(time1, time2) {
    const [hour1, minute1] = time1.split(':').map(Number);
    const [hour2, minute2] = time2.split(':').map(Number);
    return hour2 - hour1 + (minute2 - minute1) / 60;
}

/**
 * Function that returns the endTime = startTime + deliverySlot
 * @param {String} startTime A string that represents an hour (eg. "9:30")
 * @param {Number} deliverySlot A number that represents the time between two slots
 */
export function getEndTime(startTime, deliverySlot) {
    const [hour, minute] = startTime.split(':').map(Number);
    const startHourAsNumber = hour + minute / 60;
    const endTimeAsNumber = startHourAsNumber + deliverySlot;
    const endHour = Math.floor(endTimeAsNumber);
    const endMinute = endTimeAsNumber % 1 === 0 ? '00' : '30';
    return `${endHour < 10 ? `0${endHour}` : endHour}:${endMinute}`;
}

export function objectChangeCheck(originalObject, changedObject, fieldsToCheck = []) {
    if (fieldsToCheck.length) {
        for (let i = 0; i < fieldsToCheck.length; i++) {
            const key = fieldsToCheck[i];
            if (!originalObject[key] && !!changedObject[key]) {
                return true;
            }

            if (originalObject[key] !== changedObject[key]) {
                return true;
            }
        }
        return false;
    }
    const originalKeyValues = Object.keys(originalObject);
    const changedKeyValues = Object.keys(changedObject);

    if (originalKeyValues.length !== changedKeyValues.length) {
        return true;
    }

    for (let i = 0; i < originalKeyValues.length; i++) {
        const key = originalKeyValues[i];
        const isStringComparison = typeof originalObject[key] === 'string' && typeof changedObject[key] === 'string';

        if (isStringComparison && originalObject[key] !== changedObject[key]) {
            return true;
        }
    }
    return false;
}

export function objectIsInObject(originalObject, objectToCheck) {
    const keyValues = Object.keys(objectToCheck);

    for (let i = 0; i < keyValues.length; i++) {
        const key = keyValues[i];

        if (originalObject[key] !== objectToCheck[key]) {
            return false;
        }
    }
    return true;
}

export function extractKeysFromObject(object, keys) {
    return keys.reduce(
        (reducedObject, key) => ({
            ...reducedObject,
            [key]: object[key],
        }),
        {},
    );
}

export function createSelectOptionsWithValue(optionsArray, name) {
    return optionsArray.map(({ value, label }) => ({
        value: value,
        label: label,
        name,
    }));
}

export function pluralize(words, count) {
    const splitWords = words.split(' ');

    return count > 1 ? splitWords.map((word) => `${word}s`).join(' ') : words;
}

export function decimals(n, d) {
    if (typeof n !== 'number' || typeof d !== 'number') {
        return false;
    }
    if (n.toString().includes('.')) {
        n = parseFloat(n) || 0;
        return n.toFixed(d);
    }
    return n.toFixed(0);
}

export function validateEmail(errorMessage) {
    return function emailValidate(email) {
        const trimmedEmail = email.trim(); // This removes spaces before and after a string
        return (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                trimmedEmail,
            ) || errorMessage
        );
    };
}

export function validatePhone(errorMessage) {
    return function phoneValidate(phoneNumber) {
        const errorMessageToUse =
            phoneNumber[0] === '+' || (phoneNumber[0] === '0' && phoneNumber[1] === '0')
                ? errorMessage
                : "Veuillez rajouter l'indicatif téléphonique";

        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'FR');
        return (
            (parsedPhoneNumber?.isValid() && parsedPhoneNumber?.country === 'FR') ||
            regexInternationalNumber.test(phoneNumber) ||
            errorMessageToUse
        );
    };
}

export function HandleNotification(error, shouldLog = true) {
    const { graphQLErrors, networkError } = error;
    const actualErrorToBeSent = graphQLErrors?.[0] || null;

    if (networkError) {
        Notification(new Error('Oops, une erreur est survenue. Merci de réessayer.'));
        shouldLog && window.Log[error.type || 'fatal'](error, null, null, false);
        return;
    }

    if (actualErrorToBeSent) {
        Notification(actualErrorToBeSent);
        shouldLog && window.Log[error.type || 'fatal'](error, null, null, false);
    }
}

export function displayQuantityKg(displayKg, netWeight, quantity) {
    if (displayKg) {
        return Number(quantity * netWeight)
            .toFixed(3)
            .replace('.', ',')
            .replace(',000', '');
    }
    return Number(quantity).toFixed(3).replace('.', ',').replace(',000', '');
}

export function getKgs(displayKg, netWeight, quantity) {
    return displayQuantityKg(displayKg, netWeight, quantity).split(',')[0];
}

export function getGrams(displayKg, netWeight, quantity) {
    return displayQuantityKg(displayKg, netWeight, quantity).split(',')[1];
}

export function displayQuantityPrice(number) {
    return Number(number).toFixed(2).replace('.', ',');
}

export function getEuros(number) {
    return displayQuantityPrice(number).split(',')[0];
}

export function getCents(number) {
    return displayQuantityPrice(number).split(',')[1];
}

export function limitToNcharacters(string, n = 200) {
    return string.substring(0, n) + (string.length >= n ? '...' : '');
}

export function getUrlParameters(pathname) {
    const [totemId, navItem, orderId, productId] = pathname
        .split('/')
        .filter((urlParameter) => urlParameter && urlParameter !== 'totem');

    return {
        totemId,
        navItem,
        orderId,
        productId,
    };
}
