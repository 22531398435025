import React from 'react';
import Toggle from 'react-toggle';
import styled from 'styled-components';
import 'react-toggle/style.css'; // for ES6 modules

import { LineItemContainer } from 'pages/Totem/Aside/Basket/LineItemContainer';

export const BasketToggleLineItem = ({ borderBottom, checked, dataTest, disabled, icon, label, onChange }) => {
    return (
        <LineItemToggleContainer as="div" $borderBottom={borderBottom} data-test={dataTest}>
            <LabelAndIcon>
                {icon}
                {label}
            </LabelAndIcon>
            <Toggle checked={checked} disabled={disabled} onChange={(e) => onChange(e.target.checked)} />
        </LineItemToggleContainer>
    );
};

const LineItemToggleContainer = styled(LineItemContainer)`
    .react-toggle--checked .react-toggle-track {
        background-color: ${({ theme }) => theme.colors.green};
    }
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${({ theme }) => theme.colors.greenDarker};
    }
`;

const LabelAndIcon = styled.div`
    svg {
        margin-right: 1.6rem;
    }
    display: flex;
    align-items: center;
`;
