import React, { useState } from 'react';
import styled from 'styled-components';

import CSVReader from 'react-csv-reader';

import { TotemButton } from 'ui/Button';

export const CsvInputButton = ({ onCsvDataUpload, disabled, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleCsvUpload = (data) => {
        setIsLoading(true);
        if (data?.length) {
            onCsvDataUpload(data);
        }
        setIsLoading(false);
    };

    const label = 'Sélectionner un fichier CSV';

    // With the CsvReader, we cannot add the same file twice. Therefore we did a hack :
    // we use the isLoading props to unmount the CSVReader component so it's reinitialise
    return (
        <TotemButton $margins={[2, 0, 0, 0]} disabled={disabled} {...props}>
            {isLoading ? (
                <div>
                    <label>{label}</label>
                </div>
            ) : (
                <StyledCSVReader label={label} onFileLoaded={handleCsvUpload} disabled={isLoading} />
            )}
        </TotemButton>
    );
};

const StyledCSVReader = styled(CSVReader)`
    label {
        cursor: pointer;
    }
`;
