import { Storage } from 'lib/Storage';
import { Auth } from '@totem/auth';
import { CustomError } from 'lib/Error';

export function initGlobals() {
    window.TOTEM = {};
    window.TOTEM.storage = new Storage();
    window.TOTEM.auth = new Auth();
    window.Log = new CustomError();
}
