import React, { useState } from 'react';
import styled from 'styled-components';

import { BasketProductItem } from 'pages/Totem/Aside/Basket/BasketProductItem';

import { TotemButton } from 'ui/Button';

export const BasketProducts = ({ products }) => {
    const NUMBER_OF_PRODUCTS_TO_SHOW = 5;
    const [isShowingEverything, setIsShowingEverything] = useState(false);
    return (
        <ProductsContainer>
            {products
                .slice(0, isShowingEverything ? products.length : NUMBER_OF_PRODUCTS_TO_SHOW)
                .map((product, index) => {
                    const lastItem = index === products.length - 1;
                    return (
                        <BasketProductItem
                            key={product._id}
                            product={product}
                            borderBottom={lastItem}
                            borderTop={true}
                            paddingLeft="0"
                        />
                    );
                })}
            {products.length > NUMBER_OF_PRODUCTS_TO_SHOW && (
                <TotemButton
                    $type="secondary"
                    $margins={[1, 0]}
                    $fontSize="0.75rem"
                    onClick={() => setIsShowingEverything(!isShowingEverything)}
                    $alignSelf="center"
                >
                    {!isShowingEverything ? 'Voir tous les produits' : 'Voir moins de produits'}
                </TotemButton>
            )}
        </ProductsContainer>
    );
};

const ProductsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-width: 0 0 0.1rem;
`;
