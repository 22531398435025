import React, { useState } from 'react';

import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { DUPLICATE_ORDER, DUPLICATE_ORDER_AND_CREATE_TOTEM } from 'data/mutations';
import { GET_ORDERS_OF_TOTEM_WITHIN_DATE_RANGE, GET_ORDERS_OF_TOTEM } from 'data/queries';

import { DAYS_OF_THE_WEEK, WEEKDAY_INFO, FORMAT } from 'constants/date-constants';

import { ChoosePunctualOrRecurringOrder } from './ChoosePunctualOrRecurring';
import { Loading } from 'components/Loading';
import { PreventReplacingOrderByItself } from './PreventReplacingOrderByItself';
import { ValidateReplacingOrder } from './ValidateReplacingOrder';

import { getRecurringOrdersForDay } from 'utils/orders';
import { totemCreatedCacheHandler } from 'utils/cache/totemCreatedCacheHandler';
import { orderDuplicatedCacheHandler } from 'utils/cache/orderDuplicatedCacheHandler';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

export const DuplicationLastStep = ({
    orderToDuplicate,
    isCreatingNewTotem,
    destinationTotemId,
    currentTotemId,
    deliveryInfo,
    defaultCategoryId,
    selectedDate,
    selectedTimeWindow,
    handleCancel,
}) => {
    const apolloClient = useApolloClient();
    const navigate = useNavigate();
    const [isConfirmingReplace, setIsConfirmingReplace] = useState(false);
    const startDate = selectedDate.startOf('day').toDate();
    const endDate = selectedDate.endOf('day').toDate();
    const {
        loading: ordersOfTotemLoading,
        error: ordersOfTotemError,
        data: ordersOfTotemData,
    } = useQuery(GET_ORDERS_OF_TOTEM, {
        variables: { totemId: destinationTotemId },
        skip: !destinationTotemId,
    });
    const {
        loading: ordersOfTotemWithinDateRangeLoading,
        error: ordersOfTotemWithinDateRangeError,
        data: ordersOfTotemWithinDateRangeData,
    } = useQuery(GET_ORDERS_OF_TOTEM_WITHIN_DATE_RANGE, {
        variables: { totemId: destinationTotemId, startDate, endDate, isPunctual: true },
        skip: !destinationTotemId,
        fetchPolicy: 'no-cache', // prevents from creating 2 consecutive orders for the same date
    });
    const [duplicateOrder, { loading: duplicateOrderIsLoading, error: duplicateOrderError }] =
        useMutation(DUPLICATE_ORDER);
    const [
        duplicateOrderAndCreateTotem,
        { loading: duplicateOrderAndCreateTotemIsLoading, error: duplicateOrderAndCreateTotemOrderError },
    ] = useMutation(DUPLICATE_ORDER_AND_CREATE_TOTEM);

    if (
        ordersOfTotemLoading ||
        ordersOfTotemWithinDateRangeLoading ||
        duplicateOrderIsLoading ||
        duplicateOrderAndCreateTotemIsLoading
    ) {
        return <Loading />;
    }
    if (
        ordersOfTotemError ||
        ordersOfTotemWithinDateRangeError ||
        duplicateOrderError ||
        duplicateOrderAndCreateTotemOrderError
    ) {
        throw Error(
            ordersOfTotemError ||
                ordersOfTotemWithinDateRangeError ||
                duplicateOrderError ||
                duplicateOrderAndCreateTotemOrderError,
        );
    }

    const hasSameDayPunctualOrder = ordersOfTotemWithinDateRangeData?.ordersOfTotemWithinDateRange.length;
    const weekday = selectedDate && DAYS_OF_THE_WEEK[dayjs(selectedDate._d).format('d').toLowerCase()];
    const translatedWeekday = WEEKDAY_INFO.find((day) => day.value === weekday).name;
    const { modifiableOrder: sameDayModifiableRecurringOrder } = getRecurringOrdersForDay(
        ordersOfTotemData?.ordersOfTotem?.recentOrders ? ordersOfTotemData.ordersOfTotem.recentOrders : [],
        weekday,
    );

    const handleDuplicateOrder = async (isOrderPunctual, sameDayRecurringOrder) => {
        if (isCreatingNewTotem) {
            const {
                data: {
                    duplicateOrderAndCreateTotem: { order: newOrder, totem: newTotem, user: updatedUser },
                },
            } = await duplicateOrderAndCreateTotem({
                variables: {
                    orderToDuplicateId: orderToDuplicate._id,
                    deliveryInfo: deliveryInfo,
                    deliveryDate: dayjs(selectedDate._d).format(FORMAT),
                    deliveryTimeWindow: selectedTimeWindow,
                    isOrderPunctual,
                },
            });
            track(analyticsEvents.DUPLICATE_ORDER, {
                [analyticsPropertiesConstants.ORDER.DUPLICATION.IS_FOR_SAME_TOTEM]:
                    destinationTotemId === currentTotemId,
                [analyticsPropertiesConstants.ORDER.DUPLICATION.IS_CREATING_NEW_TOTEM]: true,
                [analyticsPropertiesConstants.ORDER.IS_PUNCTUAL]: isOrderPunctual,
                [analyticsPropertiesConstants.ORDER.DUPLICATION.IS_REPLACING]: false,
            });
            totemCreatedCacheHandler({ apolloClient, newTotem, updatedUser });
            navigate(`/totem/${newTotem._id}/${defaultCategoryId}/${newOrder._id}`, { relative: 'path' });
        } else {
            let deliveryDate = dayjs(selectedDate._d).format(FORMAT);
            if (weekday === sameDayRecurringOrder?.weekday) {
                // replace next weekend recurring order
                deliveryDate = sameDayRecurringOrder.date_delivery;
            }
            const {
                data: { duplicateOrder: newOrder },
            } = await duplicateOrder({
                variables: {
                    orderToDuplicateId: orderToDuplicate._id,
                    destinationTotemId,
                    deliveryDate,
                    deliveryTimeWindow: selectedTimeWindow,
                    isOrderPunctual,
                },
            });
            track(analyticsEvents.DUPLICATE_ORDER, {
                [analyticsPropertiesConstants.ORDER.DUPLICATION.IS_FOR_SAME_TOTEM]:
                    destinationTotemId === currentTotemId,
                [analyticsPropertiesConstants.ORDER.DUPLICATION.IS_CREATING_NEW_TOTEM]: false,
                [analyticsPropertiesConstants.ORDER.IS_PUNCTUAL]: isOrderPunctual,
                [analyticsPropertiesConstants.ORDER.DUPLICATION.IS_REPLACING]: isConfirmingReplace,
            });
            orderDuplicatedCacheHandler({
                apolloClient,
                destinationTotemId,
                orderBeingReplaced: sameDayRecurringOrder,
                ordersOfDestinationTotem: ordersOfTotemData?.ordersOfTotem,
                newOrder,
            });
            navigate(`/totem/${destinationTotemId}/${defaultCategoryId}/${newOrder._id}`, { relative: 'path' });
        }
    };

    return (
        <Container>
            {isConfirmingReplace ? (
                sameDayModifiableRecurringOrder?._id === orderToDuplicate._id ? (
                    // trying to replace an order by itself
                    <PreventReplacingOrderByItself
                        defaultCategoryId={defaultCategoryId}
                        destinationTotemId={destinationTotemId}
                        handleCancel={handleCancel}
                        sameDayModifiableRecurringOrder={sameDayModifiableRecurringOrder}
                    />
                ) : (
                    // trying to replace an already exising recurring order
                    <ValidateReplacingOrder
                        defaultCategoryId={defaultCategoryId}
                        destinationTotemId={destinationTotemId}
                        handleCancel={handleCancel}
                        handleDuplicateOrder={handleDuplicateOrder}
                        sameDayModifiableRecurringOrder={sameDayModifiableRecurringOrder}
                        translatedWeekday={translatedWeekday}
                    />
                )
            ) : (
                <ChoosePunctualOrRecurringOrder
                    handleDuplicateOrder={handleDuplicateOrder}
                    hasSameDayPunctualOrder={hasSameDayPunctualOrder}
                    sameDayModifiableRecurringOrder={sameDayModifiableRecurringOrder}
                    setIsConfirmingReplace={setIsConfirmingReplace}
                    translatedWeekday={translatedWeekday}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
`;
