import React, { useState } from 'react';
import styled from 'styled-components';

import { CloseContainer, Popup, PopupContent } from 'styles/pages/settings.styles';
import { AbsoluteContainer, ArrowCircleIcon } from 'styles/pages/totem.styles';

import { RemoveIcon } from 'styles/components/card.styles';
import { ExistingTotemSelection } from './ExistingTotemSelection';
import { CreateTotemDeliveryInfo } from './CreateTotemDeliveryInfo';
import { DashboardDeliveryDateSelector } from 'pages/Totem/TotemDashboard/DashboardDeliveryDateSelector';
import { DuplicationLastStep } from './DuplicationLastStep';

/**
 * step: 0 -> Choose TOTEM address or newAddress
 * step: 1 -> If new TOTEM, enter delivery info, else skip to step 2
 * step: 2 -> Choose order delivery date
 * step: 3 -> Choose punctual or recurring order (+ ask for confirmation if replacing)
 */
export const DuplicateOrderPopUp = ({ order, closeOrderPopup, defaultCategoryId }) => {
    const [step, setStep] = useState(0);
    const [isCreatingNewTotem, setIsCreatingNewTotem] = useState(false);
    const [destinationTotemId, setDestinationTotemId] = useState(null);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTimeWindow, setSelectedTimeWindow] = useState(order.deliveryTimeWindows?.[0]);
    const defaultDeliveryInfo = {
        address: '',
        zipcode: '',
        city: '',
        address_details: '',
        consigneDelivery: '',
    };
    return (
        <Popup $alignItems="center" $justifyContent="center">
            {step === 0 && (
                <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="520px" onClick={(e) => e.stopPropagation()}>
                    <ExistingTotemSelection
                        title="À quelle adresse souhaitez vous dupliquer cette commande ?"
                        defaultTotemId={order.totemId}
                        validateNewAddress={() => {
                            setStep(1);
                            setIsCreatingNewTotem(true);
                            setDeliveryInfo(defaultDeliveryInfo);
                        }}
                        validateExistingTotem={(totem) => {
                            setStep(2);
                            setIsCreatingNewTotem(false);
                            setDestinationTotemId(totem._id);
                            // setting the delivery info because we need the totem's zipcode for the delivery date selection
                            setDeliveryInfo(totem);
                        }}
                        setDestinationTotemId={setDestinationTotemId}
                        setIsCreatingNewTotem={setIsCreatingNewTotem}
                        setDeliveryInfo={setDeliveryInfo}
                        isDuplicatingOrder
                    />
                    <CloseContainer>
                        <RemoveIcon size="25px" onClick={closeOrderPopup} />
                    </CloseContainer>
                </PopupContent>
            )}
            {step === 1 && (
                <PopupContent $paddings={[3, 2, 2, 2]} $spaceBetween width="520px" onClick={(e) => e.stopPropagation()}>
                    <CreateTotemDeliveryInfo
                        deliveryInfo={deliveryInfo}
                        onSubmit={(newDeliveryInfo) => {
                            setDeliveryInfo(newDeliveryInfo);
                            setStep(2);
                        }}
                    />
                    <AbsoluteContainer $top="9px" $left="9px">
                        <ArrowCircleIcon onClick={() => setStep(0)} size="32px" />
                    </AbsoluteContainer>
                    <CloseContainer>
                        <RemoveIcon size="25px" onClick={closeOrderPopup} />
                    </CloseContainer>
                </PopupContent>
            )}
            {step === 2 && (
                <RelativeContainer>
                    <DashboardDeliveryDateSelector
                        shouldHideBackButton={false}
                        focused={true}
                        onSubmit={() => setStep(3)}
                        onClickBackButton={isCreatingNewTotem ? () => setStep(1) : () => setStep(0)}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedTimeWindow={selectedTimeWindow}
                        setSelectedTimeWindow={setSelectedTimeWindow}
                        handleClose={closeOrderPopup}
                        currentTotem={deliveryInfo}
                    />
                </RelativeContainer>
            )}
            {step === 3 && (
                <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="520px" onClick={(e) => e.stopPropagation()}>
                    <DuplicationLastStep
                        orderToDuplicate={order}
                        isCreatingNewTotem={isCreatingNewTotem}
                        destinationTotemId={destinationTotemId}
                        currentTotemId={order.totemId}
                        deliveryInfo={deliveryInfo}
                        defaultCategoryId={defaultCategoryId}
                        selectedDate={selectedDate}
                        selectedTimeWindow={selectedTimeWindow}
                        handleCancel={closeOrderPopup}
                    />
                    <AbsoluteContainer $top="9px" $left="9px">
                        <ArrowCircleIcon onClick={() => setStep(2)} size="32px" />
                    </AbsoluteContainer>
                    <CloseContainer>
                        <RemoveIcon size="25px" onClick={closeOrderPopup} />
                    </CloseContainer>
                </PopupContent>
            )}
        </Popup>
    );
};

const RelativeContainer = styled.div`
    position: relative;
`;
