import {
    TOTEM_WAREHOUSE_LATITUDE,
    TOTEM_WAREHOUSE_LONGITUDE,
    TOTEM_WAREHOUSE_RADIUS_BIAS,
} from 'constants/warehouse-constants';

export const addressFields = ['address', 'zipcode', 'city', 'country', 'latitude', 'longitude'];

export const getSelectionAddressDetails = (detail) => {
    return {
        address: detail.name,
        latitude: detail.geometry.location.lat(),
        longitude: detail.geometry.location.lng(),
        city: detail.address_components[2].long_name,
        zipcode: detail.address_components[6].long_name,
        country: detail.address_components[5].long_name,
    };
};

export function formatAddressToShortString(addressDetails) {
    return `${addressDetails.address}, ${addressDetails.city}, ${addressDetails.country}`;
}

export function getAutocompleteSuggestions(enteredValue, setAutocompleteSuggestions) {
    const autocomplete = new google.maps.places.AutocompleteService();
    const myLatLng = new google.maps.LatLng(TOTEM_WAREHOUSE_LATITUDE, TOTEM_WAREHOUSE_LONGITUDE);
    autocomplete.getPlacePredictions(
        { input: enteredValue, radius: TOTEM_WAREHOUSE_RADIUS_BIAS, location: myLatLng, types: ['address'] },
        (predictions) => {
            setAutocompleteSuggestions(
                (predictions || []).map(({ description, reference }) => ({
                    reference,
                    label: description,
                })),
            );
        },
    );
}
