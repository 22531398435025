import * as Sentry from '@sentry/react';
import { User } from '@totem/roles';

import { ENV_DEV } from 'constants/environment-constants';

export function CustomError() {
    Sentry.init({
        dsn: 'https://04a3abe889b94e67aca6431e462877c2@o79738.ingest.sentry.io/1859142',
        environment: window.__TOTENV__.ENV,
    });

    this.fatal = HandleError('fatal', true);
    this.error = HandleError('error', true);
    this.warn = HandleError('warning', false);
    this.info = HandleError('info', false);
    this.debug = HandleError('debug', false);
}

export function ConfigureScope(
    { user, errorInfo = {}, errorContext = 'global', level },
    sentryFunction,
    error,
    callback,
) {
    return function extraInfoSetter(scope) {
        scope.setUser(ConfigureUser(user));
        scope.setLevel(level ?? 'error');
        scope.setTag('username', user?.username);
        scope.setTag('error-context', errorContext);
        scope.setExtras({
            ...errorInfo,
        });

        let errorId;

        if (window.__TOTENV__.ENV && window.__TOTENV__.ENV !== ENV_DEV) {
            errorId = sentryFunction(error);
        } else {
            errorId = `test-${Math.random().toString(36).substr(2)}`;
        }
        callback && callback(errorId);
    };
}

function ConfigureUser(user) {
    let userScope = {};

    if (user) {
        const {
            _id: id,
            emails: [{ address: email }],
            profile: { firstname, lastname, organizationId },
            username,
        } = user;
        userScope = {
            ...userScope,
            id,
            organizationId,
            username,
            email,
            name: `${firstname} ${lastname}`,
        };
    }

    return userScope;
}

function HandleError(type, shouldThrow = false) {
    return function ErrorHandlerInstance(
        error,
        errorInfo = {},
        callback,
        shouldThrowForInstance = type === 'fatal' || type === 'error' || type === 'critical',
    ) {
        let errorTranspose = error,
            sentryFunction;

        if (typeof error === 'string' && type !== 'debug') {
            sentryFunction = Sentry.captureEvent;
            errorTranspose = {
                message: error,
            };
        } else if (typeof error === 'string' && type === 'debug') {
            sentryFunction = Sentry.captureMessage;
        } else {
            sentryFunction = Sentry.captureException;
        }

        let consoleType = type;

        if (type === 'critical' || type === 'fatal') {
            consoleType = 'error';
        } else if (type === 'warning') {
            consoleType = 'warn';
        }

        console[consoleType](error);

        Sentry.withScope(
            ConfigureScope(
                {
                    errorInfo,
                    user: User.get(),
                    errorContext: 'logger',
                    level: type,
                },
                sentryFunction,
                errorTranspose,
                callback,
            ),
        );

        if (shouldThrowForInstance || (shouldThrow && shouldThrowForInstance)) {
            throw error;
        }
    };
}
