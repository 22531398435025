import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'deprecated-enkel';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { SET_PASSWORD } from 'data/mutations';

import { PasswordStrength } from 'components/PasswordStrength';

import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { TotemLabel, TotemInput } from 'styles';
import { SetPasswordTitle } from 'styles/pages/setPassword.styles';
import { LoginFormStyle } from 'styles/pages/login.styles';
import { handleTextInput } from 'utils';

export const SetPasswordInner = ({ verifyUser, setSkip, setPassword, token, isResetPassword }) => {
    const navigate = useNavigate();
    const [passwordValues, setPasswordValues] = useState({ password: '', passwordConfirmation: '' });
    const [setUserPassword] = useMutation(SET_PASSWORD);
    const { success, organizationName, error } = verifyUser;
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({ reValidateMode: 'onSubmit' });

    const handlePasswordSubmit = async () => {
        const mediumRegex = new RegExp(
            '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
        );

        const { password, passwordConfirmation } = passwordValues;

        if (password !== passwordConfirmation) {
            const message = 'Les mots de passe sont différents';
            setError('passwordConfirmation', { type: 'notMatch', message });
            return;
        }
        if (!password.match(mediumRegex)) {
            const message = 'Essayez un mot de passe avec une majuscule et au moins 6 caractères';
            setError('password', { type: 'weakPassword', message });
            return;
        }
        const { data } = await setUserPassword({
            variables: {
                password,
                token,
            },
        });

        const { setUserPassword: response } = data;

        if (response?.success) {
            if (isResetPassword) {
                toast.success(
                    "Votre mot de passe a bien été réinitialisé. Connectez-vous sur l'application mobile TOTEM ou sur l'application web si vous êtes un gestionnaire d'espace TOTEM.",
                    {
                        duration: 10000,
                        position: 'bottom-center',
                    },
                );
                navigate('/', { relative: 'path' });
            } else {
                toast.success('Mot de passe défini avec succès');
                await setPassword(response, () => {
                    navigate('/set-password/2', { relative: 'path' });
                    setSkip(false);
                });
            }
        } else if (response?.error) {
            setError('password', { type: 'identical', message: response.error });
        }
    };

    const handlePasswordInputs = (e) => {
        clearErrors(e.target.name);
        handleTextInput(e, setPasswordValues, passwordValues);
    };

    return (
        <>
            {success && (
                <>
                    <SetPasswordTitle>
                        {isResetPassword ? (
                            'Entrez votre nouveau mot de passe'
                        ) : (
                            <>
                                Rejoignez l'espace TOTEM <br /> <u>{organizationName}</u>
                            </>
                        )}
                    </SetPasswordTitle>
                    <LoginFormStyle onSubmit={handleSubmit(handlePasswordSubmit)}>
                        <TotemLabel color="white">
                            Mot de passe <InlineError display={errors.password} />
                            <TotemInput
                                placeholder="Votre mot de passe"
                                type="password"
                                {...register('password', { required: true })}
                                onChange={handlePasswordInputs}
                                autoFocus
                                data-test="set-password"
                            />
                            <PasswordStrength input={passwordValues.password} />
                        </TotemLabel>
                        <TotemLabel color="white">
                            Confirmez votre mot de passe <InlineError display={errors.passwordConfirmation} />
                            <TotemInput
                                placeholder="••••••••"
                                type="password"
                                {...register('passwordConfirmation', { required: true })}
                                onChange={handlePasswordInputs}
                                data-test="set-password-confirm"
                            />
                        </TotemLabel>
                        <Flex $justifyContent="center">
                            <TotemButton $margins={[1, 0, 1, 0]} type="submit" data-test="login-submit">
                                {isResetPassword ? 'Réinitialiser' : 'Continuer'}
                            </TotemButton>
                        </Flex>
                    </LoginFormStyle>
                </>
            )}
            {error && <SetPasswordTitle error>{error}</SetPasswordTitle>}
        </>
    );
};
