export const getStripeApiKey = (organization) => {
    const isProd = window.__TOTENV__.ENV === 'prod';

    return !isProd || organization.isTest
        ? window.__TOTENV__.STRIPE_PUBLISH_API_TEST_KEY
        : window.__TOTENV__.STRIPE_PUBLISH_API_LIVE_KEY;
};

export const getBillingDetails = ({ cardName, organization, user }) => {
    return {
        name: cardName,
        address: {
            line1: organization.billingInfo?.invoiceAddress || 'address was not provided by user',
            city: organization.billingInfo?.invoiceCity || 'Paris',
            country: 'FR',
        },
        phone: user?.profile?.phone || '+33600000000',
        email: organization.billingInfo?.invoiceEmail || 'email@notprovided.com',
    };
};

export const setInvoiceInitialValues = (organization) => {
    return {
        invoiceEmail: organization?.billingInfo?.invoiceEmail || '',
        invoiceCity: organization?.billingInfo?.invoiceCity || '',
        invoiceAddress: organization?.billingInfo?.invoiceAddress || '',
        invoiceCountry: organization?.billingInfo?.invoiceCountry || '',
        invoiceZipcode: organization?.billingInfo?.invoiceZipcode || '',
        invoiceName: organization?.billingInfo?.invoiceName || '',
        invoiceVAT: organization?.billingInfo?.invoiceVAT || '',
        invoicePO: organization?.billingInfo?.invoicePO || '',
    };
};

export const sumObjectValues = (items, callback) => items.reduce((sum, item) => (sum += callback(item)), 0);
