import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex } from 'deprecated-enkel';
import { padding, lighten, darken, margin } from 'polished';

export const Spybox = ({ onClick, organizationName }) => {
    const [show, setShow] = useState(true);
    const handleOnClick = (e) => {
        e.stopPropagation();
        setShow(true);
    };

    const hideElement = (e) => {
        e.stopPropagation();
        setShow(false);
    };
    return (
        <SpyBox $show={show} onClick={handleOnClick}>
            Vous consultez le profil de
            <span>{organizationName}</span>
            <Flex>
                <SpyButton onClick={onClick}>Arrêter</SpyButton>
                <SpyButton $black onClick={hideElement}>
                    Cacher
                </SpyButton>
            </Flex>
        </SpyBox>
    );
};

const SpyBox = styled(Flex.Style)`
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 180px;
    opacity: 0.8;
    ${(props) => padding(props.theme.variables.padding * 0.5)};
    background: ${(props) => props.theme.colors.black};
    border: 1px solid ${(props) => darken(0.1, props.theme.colors.black)};
    border-radius: ${(props) => props.theme.variables.borderRadius};
    position: fixed;
    bottom: 10px;
    right: ${(props) => (props.$show ? '10px' : '-170px')};
    z-index: 1000001 !important;
    font-size: 12px;
    color: white;
    transition: all 0.25s ease;

    > span {
        letter-spacing: 0.4px;
        font-size: 12px;
        ${(props) => padding(props.theme.variables.padding * 0.3)};
        font-weight: 800;
    }

    &:hover {
        opacity: 1;
        ${(props) => !props.show && 'cursor: pointer'};
    }
`;

const SpyButton = styled.button`
    font-weight: 500;
    font-size: 10px;
    width: max-content;
    min-width: 54px;
    text-transform: uppercase;
    ${(props) => padding(props.theme.variables.padding * 0.3)};
    ${(props) => margin(props.theme.variables.margin * 0.3)};
    color: white;
    background: ${(props) =>
        props.$black ? lighten(0.05, props.theme.colors.black) : lighten(0.05, props.theme.colors.red)};
    border: 1px solid ${(props) => lighten(0.1, props.theme.colors.red)};
    border-radius: ${(props) => props.theme.variables.borderRadius};
    transition: all 0.25s ease;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        background: ${(props) => (props.$black ? props.theme.colors.black : props.theme.colors.red)};
        border: 1px solid ${(props) => props.theme.colors.red};
    }
`;
