import gql from 'graphql-tag';

export const GET_USER = gql`
    query GET_USER {
        user {
            _id
            createdAt
            username
            emails {
                address
                verified
            }
            roles
            profile {
                firstname
                lastname
                organizationId
                organizationName
                phone
                jobTitle
                imageUrl
                hasOptedOutOfEmail
                hasOptedOutOfReminderEmail
                hasOptedOutOfSoldOutProductsEmail
            }
            visitedSites {
                _id
            }
        }
    }
`;

export const GET_ADMIN_USERS = gql`
    query GET_ADMIN_USERS {
        adminUsers {
            _id
            username
            emails {
                address
                verified
            }
            lastSeenAt
            roles
            profile {
                organizationId
                state
            }
            organization {
                _id
                name
            }
        }
    }
`;

export const GET_USERS_OF_ORGANIZATION = gql`
    query GET_USERS_OF_ORGANIZATION {
        usersOfOrganization {
            _id
            username
            emails {
                address
                verified
            }
            createdAt
            updatedAt
            lastSeenAt
            profile {
                firstname
                lastname
                organizationId
                phone
                jobTitle
            }
            roles
            userGroupIds
            wallet {
                organizationCard {
                    _id
                    state
                }
            }
        }
    }
`;
