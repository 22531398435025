import React, { useState } from 'react';
import { Flex } from 'deprecated-enkel';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { CreditCardForm } from 'pages/Totem/Aside/Billing/CreditCardForm';
import { IBANForm } from 'pages/Totem/Aside/Billing/IBANForm';

import { RemoveIcon } from 'styles/components/card.styles';
import {
    Popup,
    PopupContent,
    PaymentTab,
    CreditCardIcon,
    BankIcon,
    CloseContainer,
} from 'styles/pages/settings.styles';
import { getStripeApiKey } from 'pages/Settings/SettingsPage/SettingsPayment/utils';

export const AddPaymentPopup = ({ organization, user, setPopupWindowOpen }) => {
    const [stripePromise] = useState(() => loadStripe(getStripeApiKey(organization)));
    const [selectedPaymentType, setSelectedPaymentType] = useState('card');

    return (
        <Popup $alignItems="center" $justifyContent="center" onClick={() => setPopupWindowOpen(false)}>
            <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="600px" onClick={(e) => e.stopPropagation()}>
                <Flex $justifyContent="center" $alignItems="center">
                    <PaymentTab
                        onClick={() => setSelectedPaymentType('card')}
                        left
                        active={selectedPaymentType === 'card'}
                    >
                        <CreditCardIcon size="25" /> CB
                    </PaymentTab>
                    <PaymentTab
                        onClick={() => setSelectedPaymentType('iban')}
                        right
                        active={selectedPaymentType === 'iban'}
                        data-test="payment-methods-iban-button"
                    >
                        IBAN
                        <BankIcon size="25" />
                    </PaymentTab>
                </Flex>
                <Elements stripe={stripePromise}>
                    {selectedPaymentType === 'card' ? (
                        <CreditCardForm
                            organization={organization}
                            currentUser={user}
                            onSubmitCompletion={() => setPopupWindowOpen(false)}
                        />
                    ) : (
                        <IBANForm
                            organization={organization}
                            currentUser={user}
                            onSubmitCompletion={() => setPopupWindowOpen(false)}
                        />
                    )}
                </Elements>
                <CloseContainer>
                    <RemoveIcon size="25" onClick={() => setPopupWindowOpen(false)} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};
