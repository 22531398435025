import gql from 'graphql-tag';
import { ORGANIZATION_FRAGMENT } from 'data/fragments/organization.fragments';

export const UPDATE_ORGANIZATION_FIELDS = gql`
    mutation UPDATE_ORGANIZATION_FIELDS($data: JSON!) {
        updateOrganizationFields(data: $data) {
            ...OrganizationFragment
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const UPDATE_ACCEPTED_TERMS = gql`
    mutation UPDATE_ACCEPTED_TERMS($hasAcceptedTerms: Boolean!) {
        updateAcceptedTerms(hasAcceptedTerms: $hasAcceptedTerms) {
            _id
            acceptedTerms {
                areAccepted
                termsRecordId
                acceptanceDate
            }
        }
    }
`;

export const SET_PAYMENT_TYPE_AS_BANK_TRANSFER = gql`
    mutation SET_PAYMENT_TYPE_AS_BANK_TRANSFER($organizationId: ID!) {
        setPaymentTypeAsBankTransfer(organizationId: $organizationId) {
            _id
            paymentType
        }
    }
`;

export const UPDATE_ORGANIZATION_BILLING_INFO = gql`
    mutation UPDATE_ORGANIZATION_BILLING_INFO(
        $organizationId: ID!
        $organizationBillingInfo: OrganizationBillingInfoInput!
    ) {
        updateOrganizationBillingInfo(
            organizationId: $organizationId
            organizationBillingInfo: $organizationBillingInfo
        ) {
            ...OrganizationFragment
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;
