import gql from 'graphql-tag';

export const UPDATE_ORGANIZATION_HAS_SUBSCRIPTION = gql`
    mutation UPDATE_ORGANIZATION_HAS_SUBSCRIPTION($organizationId: ID!, $isValidatingSubscription: Boolean!) {
        updateOrganizationHasSubscription(
            organizationId: $organizationId
            isValidatingSubscription: $isValidatingSubscription
        ) {
            organization {
                _id
                hasSubscription
            }
            orders {
                _id
                prices {
                    priceDelivery
                }
            }
        }
    }
`;
