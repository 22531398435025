import React, { useContext, useState, useEffect } from 'react';
import { ShoppingBasket } from '@styled-icons/material';
import { Search } from '@styled-icons/boxicons-regular';
import { IconFilter } from '@tabler/icons-react';
import { padding } from 'polished';
import styled from 'styled-components';
import { animated, config, useSpring } from 'react-spring';

import { INPROGRESS } from 'constants/states-app';

import { Tooltip } from 'styles';

import { TotemButton, IconButton } from 'ui/Button';

import { debounce } from 'utils';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

export const TotemHeader = ({
    context,
    currentCategory: { _id: categoryId, title: categoryName },
    isBasketOpen,
    order,
    setIsBasketOpen,
}) => {
    const { filter, filterActions } = useContext(context);
    const [searchInputValue, setSearchInputValue] = useState(filter.filterSearch);
    useEffect(() => {
        if (filter.filterSearch !== searchInputValue) {
            setSearchInputValue(filter.filterSearch);
        }
    }, [filter.filterSearch]);

    const { products: productsFromOrder, state } = order;

    const styles = useSpring({
        translateX: productsFromOrder.length ? '0px' : '300px',
        config: config.stiff,
    });

    const handleSearch = (e) => {
        e.persist();
        setSearchInputValue(e.target.value);
        debounce(() => {
            filterActions.setFilterSearch(e.target.value);
        }, 10)(e);
    };

    const handleFilterToggle = () => {
        filterActions.toggleFilters(!filter.showFilters);
        track(analyticsEvents.OPEN_PRODUCTS_FILTER, {
            [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: categoryName,
            [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: categoryId,
        });
    };

    return (
        <TotemHeaderStyle>
            <HeaderLeftPart>
                <TotemHeaderSearchWrapper>
                    <Search size="20px" />
                    <TotemHeaderSearch
                        placeholder={`Cherchez nos ${categoryName.toLowerCase()}`}
                        value={searchInputValue}
                        onChange={handleSearch}
                    />
                </TotemHeaderSearchWrapper>
                <IconFilterButton data-tip="Filtres" data-for="filtersToolTip" $large>
                    <IconFilter onClick={handleFilterToggle} />
                </IconFilterButton>
                <Tooltip id="filtersToolTip" type="dark" effect="solid" place="right" />
            </HeaderLeftPart>
            <SlideInContainer style={styles}>
                <BasketButton
                    $fontSize="1rem"
                    style={{ alignSelf: 'flex-start' }}
                    onClick={() => {
                        setIsBasketOpen(!isBasketOpen);
                        if (isBasketOpen) {
                            track(analyticsEvents.CLOSE_PRODUCTS_CART, {
                                [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: categoryName,
                                [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: categoryId,
                            });
                        } else {
                            track(analyticsEvents.OPEN_PRODUCTS_CART, {
                                [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: categoryName,
                                [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: categoryId,
                            });
                        }
                    }}
                    data-test="basket-icon"
                >
                    <ShoppingBasket size="25px" />
                    <BasketText>Panier{!!productsFromOrder.length && ` • ${productsFromOrder.length}`}</BasketText>
                    {!!productsFromOrder.length && state === INPROGRESS && <ValidationReminderDot />}
                </BasketButton>
            </SlideInContainer>
        </TotemHeaderStyle>
    );
};

const IconFilterButton = styled(IconButton)`
    margin-left: 10px;
`;

const TotemHeaderStyle = styled.header`
    background: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderGrey};
    padding: 0.8em 1.3em;
`;

const HeaderLeftPart = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
`;

const TotemHeaderSearchWrapper = styled.div`
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colors.culturedGrey};
    padding: 0 1em;
    border-radius: ${({ theme }) => theme.variables.borderRadius};
    max-width: 500px;
    min-width: 50%;
`;

const TotemHeaderSearch = styled.input`
    background: inherit;
    ${(props) =>
        padding(
            props.theme.variables.padding * 1.5,
            0,
            props.theme.variables.padding * 1.5,
            props.theme.variables.padding,
        )};
    font-size: 1.1em;
    border: 0;
    border-radius: 0;
    width: 100%;
    outline: none;
    &:hover,
    &:focus {
        border: 0;
    }

    ::placeholder {
        opacity: 0.9;
        transition: opacity 0.4s ease-in-out;
    }

    :focus::placeholder {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
    }
`;

const BasketButton = styled(TotemButton)`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 2rem;
    width: 200px;
`;

const BasketText = styled.div`
    margin-left: 8px;
    display: block;
`;

const SlideInContainer = styled(animated.div)``;

const DOT_SIZE = 20;
const ValidationReminderDot = styled.div`
    position: absolute;
    top: -${DOT_SIZE / 4}px;
    right: -${DOT_SIZE / 4}px;
    width: ${DOT_SIZE}px;
    height: ${DOT_SIZE}px;
    border-radius: ${DOT_SIZE}px;
    background-color: ${(props) => props.theme.colors.pink};
`;
