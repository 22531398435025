import React from 'react';
import styled from 'styled-components';
import { margin } from 'polished';
import { Image, Transformation, Placeholder } from 'cloudinary-react';

// adding alt to parameters breaks image during loading
// TODO: update cloudinary for this to work (keeping commented for accessibility reasons)
// should ONLY provide width OR height, because adding both creates border during lazy loading
export const OptimizedImage = ({
    blurOnLoad = true,
    width,
    height,
    hasBorderRadius = false,
    $margins,
    src,
    fetchFormat = 'auto',
    quality,
    from = 's3',
    isLazy = true,
    ...rest // used for data-tip and data-for
}) => {
    const lazyProp = isLazy ? { loading: 'lazy' } : {};

    return (
        <OptimizedImageStyle
            cloudName="totem-co"
            publicId={src}
            type={from === 's3' ? 'fetch' : 'upload'}
            dpr="auto"
            responsive={width || height ? false : true}
            crop="scale"
            width={width || 'auto'}
            height={height}
            $hasBorderRadius={hasBorderRadius}
            $margins={$margins}
            {...lazyProp}
            {...rest}
        >
            <Transformation quality={quality || 'auto'} fetchFormat={fetchFormat} />
            {blurOnLoad && <Placeholder type="blur" />}
        </OptimizedImageStyle>
    );
};

const OptimizedImageStyle = styled(Image)`
    border-radius: ${({ $hasBorderRadius, theme }) => $hasBorderRadius && theme.variables.borderRadius};
    ${({
        theme: {
            variables: { margin: themeMargin },
        },
        $margins,
    }) =>
        $margins &&
        margin(
            $margins[0] * themeMargin,
            ($margins[1] ?? $margins[0]) * themeMargin,
            ($margins[2] ?? $margins[0]) * themeMargin,
            ($margins[3] ?? $margins[1] ?? $margins[0]) * themeMargin,
        )}
`;
