import React, { useContext, useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { IconArrowLeft } from '@tabler/icons-react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/fr';
import { SingleDatePicker } from 'react-dates';
import { OPEN_DOWN } from 'react-dates/constants';
import 'react-dates/initialize';
import { ClipLoader } from 'react-spinners';

import { AsideBody, AsideForm, AsideHeader, AsideSubTitle } from 'pages/Totem/Aside/AsideComponents';

import { IconButton, TotemButton } from 'ui/Button';

import { UPDATE_ORDER_DELIVERY_DATE_TIME } from 'data/mutations';
import { TotemLabel } from 'styles';
import { InlineError } from 'ui/InlineError';

import { FORMAT } from 'constants/date-constants';
import { TotemClosedDaysContext } from 'contexts/TotemClosedDaysContext';

import 'react-dates/lib/css/_datepicker.css';
import 'styles/components/deliveryDateSelector.styles.css';

import { orderUpdatedCacheHandler } from 'utils/cache/orderUpdatedCacheHandler';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';
import { getInitialVisibleMonth, isBankHoliday, isDateBeforeNext, isWeekEnd } from 'utils/datePicker';

moment.locale('fr');

export const DeliveryDateContainer = ({
    order,
    nextAvailableDeliveryDate,
    recentOrdersToCheck,
    setIsDeliveryDateAsideOpen,
    totemId,
}) => {
    const totemClosedDays = useContext(TotemClosedDaysContext);
    const [isDatePickerFocused, setIsDatePickerFocused] = useState(false);
    const apolloClient = useApolloClient();
    const defaultSelectedDate = order.date_delivery
        ? moment(order.date_delivery, FORMAT).toDate() >= nextAvailableDeliveryDate.toDate()
            ? moment(order.date_delivery, FORMAT)
            : null
        : null;
    const {
        handleSubmit,
        control,
        formState: { errors, isValid, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            deliveryDate: defaultSelectedDate,
        },
    });
    const [updateOrderDeliveryDateTime, { loading }] = useMutation(UPDATE_ORDER_DELIVERY_DATE_TIME);

    /**
     * - For punctual orders : we can select any date which doesn't already have a punctual order
     * - For recurring order : we can select any date which doesn't already have a recurring order
     * @param {momentDate} momentDate
     * @returns boolean
     */
    const alreadyHasWeeklyOrder = ({ _d: date }) => {
        if (order.isPunctual) {
            // punctual orders
            return recentOrdersToCheck.find(
                (order) => order.isPunctual && order.date_delivery === dayjs(date).format(FORMAT),
            );
        } else {
            // recurring orders
            return recentOrdersToCheck.find(
                (order) => !order.isPunctual && order.date_delivery === dayjs(date).format(FORMAT),
            );
        }
    };

    const handleDeliveryDateTimeValidation = async ({ deliveryDate }) => {
        const updatedDate = dayjs(deliveryDate._d).format(FORMAT);
        const {
            data: { updateOrderDeliveryDateTime: updatedOrder },
        } = await updateOrderDeliveryDateTime({
            variables: {
                orderId: order._id,
                dateDelivery: updatedDate,
            },
            refetchQueries: ['GET_PRODUCTS'],
            // we have to refetch the products because the catalog is different based on the date
        });
        track(analyticsEvents.UPDATE_DELIVERY_DATE, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
        });
        orderUpdatedCacheHandler({ apolloClient, totemId, orderId: order._id, updatedOrder });
        setIsDeliveryDateAsideOpen(false);
    };

    return (
        <>
            <AsideHeader>
                <IconButton
                    data-test="aside-return-button"
                    $large
                    onClick={() => setIsDeliveryDateAsideOpen(false)}
                    $white
                >
                    <IconArrowLeft size={25} />
                </IconButton>
            </AsideHeader>
            <AsideBody>
                <AsideSubTitle>Date de livraison</AsideSubTitle>
                <DeliveryDateForm onSubmit={handleSubmit(handleDeliveryDateTimeValidation)}>
                    <DeliveryDateInput>
                        <Controller
                            control={control}
                            rules={{
                                required: 'ce champ est requis',
                            }}
                            name="deliveryDate"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <TotemLabel>
                                        <SingleDatePicker
                                            id="date-picker"
                                            placeholder="Sélectionnez la date de livraison"
                                            date={value}
                                            onDateChange={(date) => onChange(date)}
                                            calendarInfoPosition="bottom"
                                            isOutsideRange={(date) =>
                                                isDateBeforeNext(date._d, nextAvailableDeliveryDate) ||
                                                isWeekEnd(date._d) ||
                                                alreadyHasWeeklyOrder(date) ||
                                                isBankHoliday({ totemClosedDays, date })
                                            }
                                            openDirection={OPEN_DOWN}
                                            initialVisibleMonth={() =>
                                                getInitialVisibleMonth({
                                                    selectedDate: defaultSelectedDate,
                                                    nextDeliveryDate: nextAvailableDeliveryDate,
                                                })
                                            }
                                            numberOfMonths={1}
                                            firstDayOfWeek={1}
                                            focused={isDatePickerFocused}
                                            onFocusChange={() => setIsDatePickerFocused(!isDatePickerFocused)}
                                            hideKeyboardShortcutsPanel
                                            showDefaultInputIcon={true}
                                            displayFormat={() => FORMAT}
                                        />
                                        <InlineError display={errors.deliveryDate} />
                                    </TotemLabel>
                                );
                            }}
                        />
                    </DeliveryDateInput>
                    <TotemButton
                        $margins={[2]}
                        $fontSize="1rem"
                        disabled={!isValid || !isDirty}
                        type="submit"
                        data-test="basket-delivery-date-validation"
                    >
                        {loading ? <ClipLoader color="white" size="20px" /> : 'Modifier'}
                    </TotemButton>
                </DeliveryDateForm>
            </AsideBody>
        </>
    );
};

const DeliveryDateInput = styled.div`
    padding: 0 2em;
`;

const DeliveryDateForm = styled(AsideForm)`
    .DateInput {
        width: 369px;
        border-radius: ${({ theme }) => theme.variables.borderRadius};
    }
    .DateInput_input {
        border-radius: ${({ theme }) => theme.variables.borderRadius};
    }
    .SingleDatePickerInput__withBorder {
        border-radius: ${({ theme }) => theme.variables.borderRadius};
    }
`;
