import styled, { css } from 'styled-components';
import { lighten, darken, transparentize, margin, padding } from 'polished';
import { Flex, Card, Select } from 'deprecated-enkel';

import { Trash, CreditCard } from 'styled-icons/boxicons-regular';

import { Bank, EditAlt } from 'styled-icons/boxicons-solid';

import { Warning } from 'styled-icons/material';

import { TotemNavInner } from 'styles/pages/totem.styles';
import { TotemButton } from 'ui/Button';

export const SettingsNavInner = styled(TotemNavInner)`
    top: 110px;
    justify-content: space-between;
`;

export const SettingsNavHeading = styled.h2`
    color: ${({ theme }) => theme.colors.black};
    text-transform: uppercase;
    ${(props) => padding(0, props.theme.variables.padding * 2)}
`;

export const SettingsPageStyle = styled.div`
    ${(props) => padding(props.theme.variables.padding * 2)};
`;

export const SettingsTitleContainerWrapper = styled(Flex.Style)`
    justify-content: space-between;
    ${(props) =>
        props.sticky
            ? css`
                  position: sticky;
                  top: 0;
                  left: auto;
                  z-index: 2;
                  background: white;
                  ${(props) => padding(props.theme.variables.padding * 2, props.theme.variables.padding * 4)};
                  border-radius: 3px 3px 0 0;
                  border: 1px solid ${(props) => props.theme.colors.borderGrey};
              `
            : margin(0, 0, props.theme.variables.margin * 2)};
`;

export const SettingsTitleContainer = styled.div`
    display: flex;
    align-items: baseline;
    svg {
        height: 30px;
        width: 30px;
        top: 0.3em;
        position: relative;
        margin-right: 0.5em;
    }
`;

export const SettingsTitle = styled.h2`
    margin: 0;
    color: ${(props) => props.theme.colors.black};
    font-size: 2em;
    text-transform: uppercase;
`;

export const SettingsSubtitle = styled.p`
    margin: 0;
    color: ${(props) => props.theme.colors.black};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '1.2em')};
    margin-top: ${(props) => props.$topMargin && props.$topMargin};

    > a {
        color: ${(props) => props.theme.colors.black};
        font-weight: 800;
    }
`;

export const SettingsCardSubtitle = styled.h2`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.6rem;
    text-transform: uppercase;
    ${(props) =>
        margin(
            props.$noMargin || props.noTopMargin ? 0 : props.theme.variables.margin * 2.5,
            0,
            props.$noMargin ? props.theme.variables.margin : props.theme.variables.margin * 2,
        )};

    > span {
        ${(props) => margin(0, 0, 0, props.theme.variables.margin * 0.75)};
        ${(props) => padding(props.theme.variables.padding * 0.15, 0, 0, 0)};
    }
`;

export const SettingsWrapper = styled.div`
    display: flex;
    flex-flow: row;
    height: 100%;
    overflow: auto;
`;

export const SettingsInner = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    min-width: ${(props) =>
        props.theme.variables.minWidthAdmin -
        props.theme.variables.specificNavbarWidth -
        props.theme.variables.globalNavbarWidth}px;
    overflow-y: auto;
`;

export const SettingsScrollable = styled.div`
    flex: 1;
    overflow-y: auto;
`;

export const SettingsForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

export const SettingsLeftHalf = styled.div`
    flex-shrink: ${(props) => props.$flexShrink ?? 1};
    width: ${(props) => props.$width || '50%'};
    height: max-content;
    ${(props) => padding(0, props.theme.variables.padding, props.theme.variables.padding, 0)};
`;

export const SettingsRightHalf = styled.div`
    flex-shrink: ${(props) => props.$flexShrink ?? 1};
    width: ${(props) => props.$width || '50%'};
    height: max-content;
    ${(props) => padding(0, 0, props.theme.variables.padding, props.theme.variables.padding)};
`;

export const SettingsCard = styled(Card.Style)`
    width: 100%;
    background: ${(props) => (props.transparent ? 'transparent' : props.theme.colors.white)};
    ${(props) => margin(props.theme.variables.margin * (props.$noMargin ? 0 : 2), 0)};
    ${(props) => padding(props.theme.variables.padding * (props.$noPadding ? 0 : 4))};
    z-index: 0;
`;

export const SettingsFooter = styled.div`
    color: ${(props) => props.theme.colors.pantinGrey};
    font-size: 0.75em;
    ${(props) => padding(props.theme.variables.padding * 2)};
`;

export const PrivilegesTableStyle = styled.table`
    width: 100%;
    min-width: 600px;
    font-size: 1.2em;
    text-transform: uppercase;

    tr {
        height: 35px;
    }

    tbody {
        th {
            text-align: left;
        }

        td {
            text-align: center;
        }
    }

    img {
        width: 20px;
    }
`;

export const InvoicesTableStyle = styled.table`
    width: 100%;
    color: ${(props) => props.theme.colors.black};
    border-collapse: collapse;
    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};

    td,
    th {
        ${({ $lessPadding, theme: { variables } }) =>
            padding($lessPadding ? variables.padding : variables.padding * 2, variables.padding)};
        text-align: ${({ align }) => align || 'left'};
        vertical-align: middle;
        ${({ $spacing }) => $spacing && 'letter-spacing: 0.1px'};
    }

    tbody {
        tr {
            border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
        }

        tr:last-of-type {
            border-bottom: none;
        }
    }

    th {
        color: ${(props) => props.theme.colors.pantinGrey};
        font-weight: 500;
        font-size: 1.1em;
    }

    thead {
        tr:nth-of-type(1) {
            border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
        }
    }
`;

export const TermsAnchor = styled.a`
    margin: 0 2px;

    &:link,
    &:visited,
    &:active {
        color: inherit;
    }
`;

export const CheckboxLine = styled(Flex.Style)`
    color: ${(props) => props.theme.colors.black};
    ${(props) => padding(props.theme.variables.padding * 2)};
    border-top: 1px solid ${(props) => props.theme.colors.borderGrey};
    cursor: default;
`;

export const Popup = styled(Flex.Style)`
    position: fixed;
    z-index: 6;
    left: ${(props) => props.theme.variables.globalNavbarWidth}px;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => transparentize(0.35, props.theme.colors.black)};
    transition: all 0.25s ease;
`;

export const CorrectlySizedPopup = styled(Popup)`
    width: calc(100% - 75px);
`;

export const PopupContent = styled(Flex.Style)`
    flex-direction: column;
    position: relative;
    ${({ $alignItems }) => $alignItems && `align-items: ${$alignItems};`}
    ${(props) =>
        padding(
            props.theme.variables.padding,
            props.theme.variables.padding,
            props.extraPadding ? props.theme.variables.padding * 3 : props.theme.variables.padding,
        )};
    border-radius: ${(props) => props.theme.variables.borderRadius};
    ${(props) => props.width && `width: ${props.$width}`};
    ${(props) => props.height && `height: ${props.$height}`};
    background-color: ${({ theme: { colors }, $background }) => $background || colors.white};

    ${({ theme: { variables }, $paddings }) =>
        $paddings &&
        padding(
            variables.padding * $paddings[0],
            variables.padding * $paddings[1],
            variables.padding * ($paddings[2] || $paddings[2] === 0 ? $paddings[2] : $paddings[0]),
            variables.padding * ($paddings[3] || $paddings[3] === 0 ? $paddings[3] : $paddings[1]),
        )}

    ${(props) =>
        props.$spaceBetween &&
        css`
            justify-content: space-between;
            align-items: center;
        `}
    ${(props) =>
        props.$center &&
        css`
            justify-content: center;
            align-items: center;
        `}
`;

export const TrashIcon = styled(Trash)`
    cursor: pointer;
    ${(props) =>
        props.margin &&
        margin(
            props.theme.variables.margin * 0.5,
            props.theme.variables.margin * 0,
            props.theme.variables.margin * 0.7,
        )};

    color: ${(props) => props.theme.colors.black};
    transition: all 0.25s ease;

    &:hover {
        color: ${(props) => darken(0.15, props.theme.colors.black)};
    }
`;

export const PaymentTab = styled(Flex.Style)`
    justify-content: space-around;
    cursor: pointer;
    width: 120px;
    font-size: 1.5rem;
    ${(props) => margin(props.theme.variables.margin * 1.5, 0, props.theme.variables.margin * 2.5)};
    ${(props) => padding(props.theme.variables.padding * 1)};
    color: ${(props) => props.theme.colors.pantinGrey};
    border: 2px solid ${(props) => (props.active ? props.theme.colors.black : props.theme.colors.borderGrey)};
    background: ${(props) => (props.active ? props.theme.colors.white : props.theme.colors.culturedGrey)};
    transition: all 0.25s ease;
    ${(props) =>
        props.left &&
        css`
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        `};
    ${(props) =>
        props.right &&
        css`
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            margin-left: -2px;
        `};
    ${(props) =>
        props.active &&
        css`
            z-index: 1;
        `};
`;

export const CreditCardIcon = styled(CreditCard)`
    color: ${(props) => props.theme.colors.black};
`;

export const BankIcon = styled(Bank)`
    color: ${(props) => props.theme.colors.black};
`;

export const CloseContainer = styled.div`
    position: absolute;
    ${(props) => margin(props.theme.variables.margin * 0.5)};
    right: ${(props) => props.right || '8px'};
    top: ${(props) => props.top || '8px'};
`;

export const InvoiceStatus = styled.span`
    ${(props) => padding(props.theme.variables.padding * 0.5, props.theme.variables.padding * 2)};
    color: ${({ color, theme: { colors } }) => darken(0.15, colors[color]) || colors.pantinGrey};
    background-color: ${({ color, theme: { colors } }) => lighten(0.3, colors[color]) || colors.culturedGrey};
    border-radius: 15px;
`;

export const SettingsAutocompleteContainer = styled.div`
    position: relative;
    z-index: 1;
`;

export const SettingsAutocompleteItems = styled.div`
    position: absolute;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.borderGrey};
    padding: 5px 0;
    background-color: white;
`;

export const SettingsAutocompleteItem = styled.div`
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.theme.colors.whiteGrey};
    }
`;

export const PopupTitle = styled.h1`
    ${(props) =>
        margin(props.theme.variables.margin * 2.5, props.theme.variables.margin * 2, props.theme.variables.margin * 2)};
    color: ${(props) => props.theme.colors.black};
    font-size: 24px;
    font-weight: 800;
    align-items: flex-end;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    max-width: 450px;
    align-self: center;
`;

export const PopupSubtitle = styled.p`
    ${(props) => margin(props.theme.variables.margin * 1.5, props.theme.variables.margin * 2)};
    color: ${(props) => props.theme.colors.black};
    font-size: 14px;
    font-weight: 800;
    align-items: flex-end;
    text-align: center;
    justify-content: center;
    max-width: 450px;
    align-self: center;
`;

export const RoleSelect = styled(Select.Style.Main)`
    width: 60%;
    font-size: 0.9em;
    ${margin(0, 'auto')};
    ${(props) => props.disabled && 'pointer-events: none'}
`;

export const RoleSelectInput = styled(Select.Style.Input)`
    text-align: center;
`;

export const RoleSelectInputDisabled = styled(Select.Style.Input)`
    text-align: center;
    background: ${(props) => props.theme.colors.culturedGrey};
`;

export const EmployeesTitle = styled(Flex.Style)`
    align-items: baseline;
    justify-content: space-between;
    ${(props) => margin(0, 0, props.theme.variables.margin * 2, 0)};
`;

export const WarningIcon = styled(Warning)`
    color: ${(props) => props.theme.colors.black};
    ${(props) => margin(0, props.theme.variables.margin * 0.5, props.theme.variables.margin * 0.8, 0)}
`;

export const DropdownButton = styled(TotemButton)`
    position: relative;
    transition: all 0.4s ease;
`;

export const DropdownContainer = styled.div`
    position: absolute;
    border: 1px solid ${(props) => props.theme.colors.pantinGrey};
    border-top: none;
    background: white;
    top: 35px;
    left: 0px;
    z-index: 3;
    overflow: auto;
    color: ${({ color, theme: { colors } }) => (color ? colors[color] : 'inherit')};

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const DropdownOption = styled(Flex)`
    justify-content: space-between;
    height: auto;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
    transition: all 0.25s ease;
    text-transform: ${({ $lowercase }) => ($lowercase ? 'none' : 'uppercase')};
    ${({ theme: { variables } }) => padding(variables.padding * 1.25)};

    &:hover {
        color: ${({ theme: { colors } }) => colors.black};
        cursor: pointer;
    }

    &:last-of-type {
        border: none;
    }

    ${(props) =>
        props.disabled &&
        css`
            cursor: default;
            background: ${({ theme: { colors } }) => colors.lightGrey};
            border: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
            &:hover {
                color: ${(props) => props.theme.colors.black};
            }
        `}
`;

export const SettingsCredentialsSubtitle = styled.h2`
    font-weight: 500;
    font-size: 1.6rem;
    text-transform: uppercase;
`;

export const CredentialsSubtitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${(props) =>
        margin(
            props.$topMargin ? props.theme.variables.margin * 3 : props.theme.variables.margin,
            0,
            props.theme.variables.margin,
        )};
`;

export const Subtext = styled.div`
    font-size: 1em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const TotemLabelStyleTitle = styled.div`
    position: relative;
    color: ${(props) => props.theme.colors[props.color || 'grey']};
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.25px;
`;

export const EditIcon = styled(EditAlt)`
    cursor: pointer;
    ${(props) =>
        props.margin &&
        margin(props.theme.variables.margin * 0.5, props.theme.variables.margin, props.theme.variables.margin * 0.7)};

    color: ${(props) => props.theme.colors.black};
    transition: all 0.25s ease;

    &:hover {
        color: ${(props) => darken(0.15, props.theme.colors.black)};
    }
`;

export const OptionText = styled.div`
    display: block;
    color: ${({ theme: { colors } }) => colors.pantinGrey};
    display: block;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.25px;
`;
