import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

import { DETACH_USER_FROM_ORGANIZATION } from 'data/mutations/user.mutations';

import { Popup, PopupContent, CloseContainer, PopupTitle } from 'styles/pages/settings.styles';
import { RemoveIcon } from 'styles/components/card.styles';
import { ClipLoader } from 'react-spinners';
import { TotemButton } from 'ui/Button';

export const DetachEmployeeWarningPopup = ({
    setWarningPopupOpen,
    userToDetach: {
        _id: userId,
        profile: { firstname, lastname },
    },
    hasTeamRole = false,
}) => {
    const [detachUserFromOrganization, { loading: isLoading }] = useMutation(DETACH_USER_FROM_ORGANIZATION);

    const handleDetachUser = async () => {
        const {
            data: {
                detachUserFromOrganization: { errors, success },
            },
        } = await detachUserFromOrganization({
            variables: { userId },
        });
        if (success) {
            toast.success(`L'utilisateur·rice ${firstname} ${lastname} a bien été détaché·e de votre entreprise.`, {
                duration: 4000,
            });
        } else if (errors) {
            errors.forEach((error) => toast.error(error));
        }
        setWarningPopupOpen(false);
    };

    return (
        <Popup $alignItems="center" $justifyContent="center" onClick={() => setWarningPopupOpen(false)}>
            <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="455px" onClick={(e) => e.stopPropagation()}>
                <PopupTitle>Êtes-vous sûr·e ?</PopupTitle>
                <InfoText>
                    Vous êtes sur le point de détacher{' '}
                    <strong>
                        {firstname} {lastname}
                    </strong>{' '}
                    de votre entreprise.
                </InfoText>
                <InfoText>
                    {!hasTeamRole
                        ? 'Cet utilisateur·rice ne pourra plus bénéficier des éventuelles subventions mises en place.'
                        : 'Cet utilisateur·rice ne pourra plus accéder à cet espace de gestion.'}
                </InfoText>
                <TotemButton $margins={[1, 0, 0, 0]} onClick={handleDetachUser} $isLoading={isLoading}>
                    {isLoading ? <ClipLoader color="white" size="16px" /> : 'Confirmer'}
                </TotemButton>
                <CloseContainer>
                    <RemoveIcon size="25" onClick={() => setWarningPopupOpen(false)} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};

const InfoText = styled.p`
    text-align: center;
`;
