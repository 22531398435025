import React from 'react';
import { Flex } from 'deprecated-enkel';

import { DropdownButton, DropdownContainer, DropdownOption } from 'styles/pages/settings.styles';

export const EmployeesDropdownButton = ({ open, setOpen, setAddEmployeesPopupOpen, setEmployeePopupOpen }) => {
    return (
        <Flex $direction="column">
            <DropdownButton onClick={() => setOpen(!open)} $fontSize="1em">
                Ajouter des employés
                {open && (
                    <DropdownContainer width="200px" color="secondary">
                        <DropdownOption onClick={() => setEmployeePopupOpen(true)} $lowercase>
                            Manuellement
                        </DropdownOption>
                        <DropdownOption onClick={() => setAddEmployeesPopupOpen(true)} $lowercase>
                            À partir d'un fichier csv
                        </DropdownOption>
                    </DropdownContainer>
                )}
            </DropdownButton>
        </Flex>
    );
};
