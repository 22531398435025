import React, { useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Flex } from 'deprecated-enkel';
import styled from 'styled-components';

export const Notification = (error, timeout = 3000) => {
    const { message, level } = error;

    const errorRenderer = document.getElementById('Error_Notification_Renderer');
    const uniqueId = `error-${Math.random().toString(36).substr(2)}-${Notification.Items++}`;

    const element = document.createElement('div');
    element.setAttribute('id', uniqueId);
    errorRenderer.appendChild(element);

    const root = createRoot(element);

    const handlePrune = () => {
        const target = document.getElementById(uniqueId);
        if (target) {
            root.unmount();
            errorRenderer.removeChild(element);
        }
    };

    root.render(<NotificationComponent message={message} timeout={timeout} handlePrune={handlePrune} type={level} />);
};

Notification.Items = 0;

const NotificationComponent = ({ message, type, timeout, handlePrune }) => {
    const timeoutHandler = useRef(null);
    window.Log.warn(message);

    useEffect(() => {
        if (!timeoutHandler.current && timeout) {
            timeoutHandler.current = setTimeout(() => {
                handlePrune();
            }, timeout);
        }
    }, [handlePrune, timeout]);

    return (
        <NotificationStyle type={type}>
            <NotificationMessageStyle data-test="notification-message">{message}</NotificationMessageStyle>
            <NotificationRemoveStyle onClick={handlePrune}>X</NotificationRemoveStyle>
        </NotificationStyle>
    );
};

function getColorsByType(type) {
    switch (type) {
        case 'info': {
            return '#1d48ef';
        }

        case 'warning': {
            return '#ffa500';
        }

        case 'success': {
            return '#1BBE4F';
        }

        default: {
            return '#d91d29';
        }
    }
}

const NotificationStyle = styled(Flex)`
    margin: 0;
    color: #fff;
    font-size: 1.25rem;
    background: ${(props) => getColorsByType(props.type)};
    border-bottom: 1px solid rgba(1, 1, 1, 0.1);
    overflow: hidden;
`;

const NotificationMessageStyle = styled.div`
    width: 100%;
    padding: 10px;
    text-align: center;
`;

const NotificationRemoveStyle = styled(Flex)`
    align-items: center;
    align-self: stretch;
    flex-shrink: 0;
    padding: 10px;
    color: rgba(1, 1, 1, 0.25);
    border-right: 1px solid rgba(1, 1, 1, 0.25);
    opacity: 0.5;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
        background: rgba(1, 1, 1, 0.15);
        opacity: 1;
    }
`;
