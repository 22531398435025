import React from 'react';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';

import { UPDATE_MONTHLY_BUDGET } from 'data/mutations';

import { CloseContainer, Popup, PopupContent, PopupTitle } from 'styles/pages/settings.styles';

import { RemoveIcon } from 'styles/components/card.styles';
import { TotemInput, TotemLabel } from 'styles';
import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { analyticsEvents, track } from 'tracking/segment';

export const BudgetSettingsPopup = ({ totemId, totemMonthlyBudget, setIsModalOpen }) => {
    const [updateMonthlyBudgetMutation, { loading }] = useMutation(UPDATE_MONTHLY_BUDGET);
    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: { monthlyBudget: totemMonthlyBudget },
        mode: 'onChange',
    });

    async function handleBudgetUpdate({ monthlyBudget }) {
        track(analyticsEvents.SET_TOTEM_BUDGET);
        await updateMonthlyBudgetMutation({
            variables: {
                totemId,
                monthlyBudget: Number(monthlyBudget),
            },
        });
        setIsModalOpen(false);
        toast.success(`Votre budget de ${monthlyBudget}€/mois bien été ${totemMonthlyBudget ? 'ajusté' : 'défini'}`);
    }

    return (
        <Popup $alignItems="center" $justifyContent="center">
            <PopupContent $paddings={[3, 2, 2, 2]} $spaceBetween $width="520px" onClick={(e) => e.stopPropagation()}>
                <PopupTitle>Définir mon budget mensuel</PopupTitle>
                <DisclaimerText>
                    Indiquez-nous le budget dont vous disposez au mois et nous vous aiderons à mieux gérer vos nouvelles
                    commandes.
                </DisclaimerText>
                <BudgetForm onSubmit={handleSubmit(handleBudgetUpdate)}>
                    <TotemLabel>
                        <span>Budget par mois</span>
                        <CurrencyInput>
                            <TotemInput
                                width="100%"
                                placeholder="budget en euros par mois"
                                autoFocus
                                data-test="monthly-budget"
                                type="number"
                                min={1}
                                step={1}
                                {...register('monthlyBudget', { required: 'ce champ est requis' })}
                            />
                            <CurrencyLabel>€/mois</CurrencyLabel>
                        </CurrencyInput>
                        <InlineError display={errors.monthlyBudget} right="80px" />
                    </TotemLabel>
                    <TotemButton type="submit" data-test="modify-budget" disabled={!isValid}>
                        {loading ? <ClipLoader color="white" size="16px" /> : 'Valider'}
                    </TotemButton>
                </BudgetForm>
                <CloseContainer>
                    <RemoveIcon size="25px" onClick={() => setIsModalOpen(false)} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};

const CurrencyInput = styled.div`
    display: flex;
`;

const CurrencyLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: initial;
    height: 50px;
    padding: 0 10px;
    font-size: 16px;
`;

const BudgetForm = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    width: 100%;
`;

const DisclaimerText = styled.div`
    font-size: 1.2em;
    max-width: 450px;
    align-self: center;
    text-align: center;
`;
