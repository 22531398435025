import React from 'react';
import { useQuery } from '@apollo/client';
import { IconArrowLeft, IconListSearch } from '@tabler/icons-react';
import { formatPrice } from '@totem/constants';
import styled from 'styled-components';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { GET_ORDER } from 'data/queries';

import { IconButton } from 'ui/Button';
import { Tooltip } from 'styles';
import { Loading } from 'components/Loading';
import { OrderDetailsProducts } from './OrderDetailsProducts';

import { FormattedDeliveryDate } from 'pages/Totem/TotemDashboard/FormattedDeliveryDate';
import { getDiscountFromOrder } from 'pages/Order/utils/getDiscountFromOrder';
import { mergeProductsAndProductsRequested } from 'pages/Order/OrderDetails/utils/mergeProductsAndProductsRequested';

import { getDeliveryHourRangeString } from 'utils/time';
import { calculateOrderPrices } from 'pages/Order/OrderDetails/utils/calculateOrderPrices';

export const OrderDetails = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();

    const { loading, data: getOrderData } = useQuery(GET_ORDER, {
        variables: {
            orderId,
        },
    });

    if (loading) {
        return <Loading />;
    }
    const { getOrder: order } = getOrderData;

    const productsWithQuantityRequested = mergeProductsAndProductsRequested({
        products: order.productsWithQuantityPicked,
        productRequestedWithQuantities: order.productsRequestedButNotFullyDelivered,
        deliveryDate: order.date_delivery,
    });

    const productsToShow = productsWithQuantityRequested.sort((productA, productB) => {
        const quantityNotDeliveredA = productA.quantityPicked
            ? productA.quantity - productA.quantityPicked
            : productA.quantity;
        const quantityNotDeliveredB = productB.quantityPicked
            ? productB.quantity - productB.quantityPicked
            : productB.quantity;
        if (quantityNotDeliveredA > quantityNotDeliveredB) {
            return -1;
        }
        if (quantityNotDeliveredA < quantityNotDeliveredB) {
            return 1;
        }
        return 0;
    });

    const { allOrderedProductsPrice, allProductsPrice, allProductPriceWithDelivery } = calculateOrderPrices({
        products: productsToShow,
        priceDelivery: order.prices.priceDelivery,
    });

    const { discountPrice, formattedDiscountPrice, formattedDiscountEffect, discountDescription } =
        getDiscountFromOrder({
            order,
            totalHT: allProductPriceWithDelivery,
        });

    const totalPriceAfterDiscount = allProductPriceWithDelivery - discountPrice;

    return (
        <Container>
            <PageHeader>
                <IconButton
                    data-test="return-to-totem"
                    $rotateOnHover
                    $large
                    $white
                    onClick={() => navigate(`/totem/${order.totemId}`)}
                >
                    <IconArrowLeft size={25} />
                </IconButton>
            </PageHeader>
            <ScrollableSection>
                <PageTitleSection>
                    <IconListSearch size={21} />
                    <PageTitle>Détails de la commande</PageTitle>
                </PageTitleSection>
                <OrderDetailsContent>
                    <OrderDetailsHeader>
                        <OrderInfoSection>
                            <OrderTitle>
                                Commande du{' '}
                                <FormattedDeliveryDate deliveryDate={order.date_delivery} shouldCapitalize={false} />
                            </OrderTitle>
                            <OrderInfo>
                                <OrderInfoElement>
                                    {order.isPunctual ? (
                                        'Ponctuelle'
                                    ) : (
                                        <span>
                                            Récurrente{' '}
                                            {order.previous_orderId && order.previous_orderId !== 'firstOrder' && (
                                                <Link to={`/order/${order.previous_orderId}`}>
                                                    (voir la commande précédente)
                                                </Link>
                                            )}
                                        </span>
                                    )}
                                </OrderInfoElement>
                                <OrderInfoElement>
                                    Livraison{' '}
                                    {getDeliveryHourRangeString(
                                        order.deliveryTimeWindows?.[0].start,
                                        order.deliveryTimeWindows?.[0].stop,
                                    )}
                                </OrderInfoElement>
                                <OrderInfoElement>
                                    Mise en place des produits : {order.hasOnsiteSetup ? 'Oui ' : 'Non '}
                                    {order.hasOnsiteSetup && order.onsiteSetupInstructions && (
                                        <OrderOnsiteSetupInstructions
                                            data-for="onsiteSetupInstructionsTooltip"
                                            data-tip={order.onsiteSetupInstructions}
                                        >
                                            (voir vos instructions)
                                        </OrderOnsiteSetupInstructions>
                                    )}
                                    <FixedWidthTooltip
                                        id="onsiteSetupInstructionsTooltip"
                                        type="dark"
                                        effect="solid"
                                        place="bottom"
                                    />
                                </OrderInfoElement>
                            </OrderInfo>
                        </OrderInfoSection>
                        <PriceDetailSection>
                            {allProductsPrice !== allOrderedProductsPrice && (
                                <>
                                    <PriceLine>
                                        Total produits commandés: <Price>{formatPrice(allOrderedProductsPrice)}</Price>
                                    </PriceLine>
                                    <PriceLine isNegative>
                                        Total produits non livrés:{' '}
                                        {formatPrice(-(allOrderedProductsPrice - allProductsPrice))}
                                    </PriceLine>
                                </>
                            )}
                            <PriceLine>
                                Sous-total HT: <Price>{formatPrice(allProductsPrice)}</Price>
                            </PriceLine>
                            <PriceLine>{`Frais de livraison : ${formatPrice(order.prices.priceDelivery)}`}</PriceLine>
                            {discountPrice > 0 && (
                                <PriceLine>
                                    {'Promo "' +
                                        discountDescription +
                                        ' ' +
                                        formattedDiscountEffect +
                                        '" : ' +
                                        formattedDiscountPrice}
                                </PriceLine>
                            )}
                            <PriceTotal>
                                Total HT :{' '}
                                {discountPrice > 0 && (
                                    <InitialPrice>{formatPrice(allProductPriceWithDelivery)}</InitialPrice>
                                )}
                                <Price hasDiscount={discountPrice > 0}>{formatPrice(totalPriceAfterDiscount)}</Price>
                            </PriceTotal>
                        </PriceDetailSection>
                    </OrderDetailsHeader>
                    <OrderDetailsProducts products={productsToShow} />
                </OrderDetailsContent>
            </ScrollableSection>
        </Container>
    );
};

const FixedWidthTooltip = styled(Tooltip)`
    max-width: 300px;
`;

const OrderOnsiteSetupInstructions = styled.span`
    text-decoration: underline;
`;

const OrderInfo = styled.ul`
    padding-inline-start: 17px;
`;

const OrderInfoElement = styled.li`
    margin-top: 10px;
`;

const OrderInfoSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const Price = styled.div`
    color: ${({ theme, hasDiscount }) => hasDiscount && theme.colors.pink};
    margin-left: 5px;
`;

const InitialPrice = styled(Price)`
    color: ${({ theme }) => theme.colors.pantinGrey};
    text-decoration: line-through;
    text-decoration-color: ${({ theme }) => theme.colors.pantinGrey};
`;

const OrderTitle = styled.h3`
    margin: 15px 0 0 0;
    padding: 0;
`;

const PriceTotal = styled.h3`
    display: flex;
    margin: 15px 0 0 0;
    padding: 0;
`;

const PriceLine = styled.div`
    display: flex;
    margin: 5px 0 0 0;
    padding: 0;
    color: ${({ theme, isNegative }) => isNegative && theme.colors.red};
    &::before {
        content: '(';
    }
    &::after {
        content: ')';
    }
`;

const PriceDetailSection = styled.section`
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    position: relative;
    flex-shrink: 1;
    width: 100%;
    height: 100%;
`;

const ScrollableSection = styled.div`
    overflow-y: auto;
    display: flex;
    flex-flow: column;
`;

const PageHeader = styled.header`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1em 1.5em;
    background: ${({ theme }) => theme.colors.green};
`;

const PageTitleSection = styled.div`
    padding: 25px;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.culturedGrey};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderGrey};
`;

const PageTitle = styled.h2`
    margin: 0;
    padding: 0 0 0 10px;
`;

const OrderDetailsContent = styled.div``;

const OrderDetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.culturedGrey};
    padding: 20px 55px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderGrey};
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;
