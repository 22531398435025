import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import toast from 'react-hot-toast';

import { ADD_MANY_USERS_TO_ORGANIZATION } from 'data/mutations/user.mutations';

import { TotemButton } from 'ui/Button';
import { CsvInputButton } from 'components/CsvInputButton';

import { Popup, PopupContent, CloseContainer, PopupTitle } from 'styles/pages/settings.styles';
import { RemoveIcon } from 'styles/components/card.styles';

import { HandleNotification } from 'utils';

import { analyticsEvents, track } from 'tracking/segment';

export const AddEmployeesPopupWindow = ({ setPopupOpen, refetchUsers }) => {
    const [newUsers, setNewUsers] = useState([]);
    const [shouldSendEmailToEmployee, setShouldSentEmailToEmployee] = useState(true);
    const [csvHasError, setCsvHasError] = useState(false);
    const [addManyUsersToOrganization, { loading }] = useMutation(ADD_MANY_USERS_TO_ORGANIZATION);

    function handleUploadEmployeesFromCsv(data) {
        setCsvHasError(false);
        const usersFromCsv = [];
        let counter = 0;

        if (!data?.length) {
            toast.error('Il semblerait que votre csv soit vide. Veuillez recommencer.');
            setCsvHasError(true);
        }
        for (const user of data) {
            counter += 1;
            if (!user) {
                toast.error(`La ligne ${counter} de votre csv ne contient aucune donnée. Veuillez recommencer.`);
                setCsvHasError(true);
                break;
            }
            if (user.length !== 3 || !user[0] || !user[1] || !user[2]) {
                toast.error(`La ligne ${counter} de votre csv est incomplète. Veuillez recommencer.`);
                setCsvHasError(true);
                break;
            }
            const trimmedEmail = user[2].trim(); // This removes spaces before and after a string
            const isEmailValidated =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    trimmedEmail,
                );
            if (!isEmailValidated) {
                toast.error(
                    `L'adresse email ${user[2]} de votre csv (ligne ${counter}) n'est pas un email valide. Veuillez recommencer.`,
                );
                setCsvHasError(true);
                break;
            }
            if (!csvHasError)
                usersFromCsv.push({
                    firstname: user[0],
                    lastname: user[1],
                    emailAddress: trimmedEmail,
                    isEmployee: true,
                });
        }
        if (!csvHasError) {
            setNewUsers(usersFromCsv);
        } else {
            setNewUsers([]);
        }
    }

    async function handleAddManyUsers() {
        try {
            await addManyUsersToOrganization({
                variables: { newUsers, shouldSendEmailToEmployee },
            });
            track(analyticsEvents.ADD_USERS_TO_ORG);
            setPopupOpen(false);
            refetchUsers();
            toast.success(`${newUsers.length} emails ont bien été ajoutés.`);
        } catch (error) {
            HandleNotification(error);
            setPopupOpen(false);
            refetchUsers();
        }
    }

    return (
        <Popup $alignItems="center" $justifyContent="center" onClick={() => setPopupOpen(false)}>
            <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="520px" onClick={(e) => e.stopPropagation()}>
                <PopupTitle>Ajouter plusieurs employés</PopupTitle>
                <InfoText>
                    <p>
                        Vous pouvez attacher autant d'employés que vous voulez à votre organisation. Ces derniers
                        bénéficieront d'éventuelles subventions que vous mettriez en place.
                    </p>
                    <p>Transmettez-nous un fichier au format .csv</p>{' '}
                    <p>
                        <u>Un utilisateur par ligne sous la forme : Prénom, Nom, Email</u>
                    </p>
                    <p>Attention à ne pas laisse de lignes ou de colonnes vides, et de bien remplir toutes les cases</p>
                </InfoText>
                <ShouldSendEmailToggle>
                    <Toggle
                        checked={shouldSendEmailToEmployee}
                        onChange={(e) => setShouldSentEmailToEmployee(e.target.checked)}
                    />
                    <CheckboxLabel>Envoyer un email aux nouveaux employés</CheckboxLabel>
                </ShouldSendEmailToggle>
                <Buttons>
                    {!newUsers.length ? (
                        <CsvInputButton
                            onCsvDataUpload={handleUploadEmployeesFromCsv}
                            $fontSize="1rem"
                            $alignSelf="stretch"
                        />
                    ) : (
                        <>
                            <TotemButton
                                $margins={[2, 0, 0, 0]}
                                onClick={() => setNewUsers(false)}
                                $type="secondary"
                                $alignSelf="stretch"
                                $fontSize="1rem"
                            >
                                Recommencer
                            </TotemButton>
                            <InfoMessage>{newUsers.length} employé(s) prêt(s) à être ajouté(s)</InfoMessage>
                            <TotemButton
                                disabled={!newUsers.length && !csvHasError}
                                $margins={[0, 0, 0, 0]}
                                onClick={handleAddManyUsers}
                                $fontSize="1rem"
                                type="submit"
                                $isLoading={loading}
                                $alignSelf="stretch"
                            >
                                {loading ? <ClipLoader color="white" size="20px" /> : 'Valider'}
                            </TotemButton>
                        </>
                    )}
                </Buttons>
                <CloseContainer>
                    <RemoveIcon size="25" onClick={() => setPopupOpen(false)} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};

const InfoMessage = styled.p`
    color: ${({ theme }) => theme.colors.green};
`;

const Buttons = styled.div`
    padding: 0 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ShouldSendEmailToggle = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .react-toggle--checked .react-toggle-track {
        background-color: ${({ theme }) => theme.colors.green};
    }
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${({ theme }) => theme.colors.greenDarker};
    }
`;

const CheckboxLabel = styled.span`
    margin-left: 5px;
    font-weight: 500;
    font-size: 16px;
`;

const InfoText = styled.p`
    margin: 0 40px;
`;
