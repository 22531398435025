import React, { memo } from 'react';

import { SettingsPageAccount } from 'pages/Settings/SettingsPage/SettingsAccount';
import { SettingsPageInvoices } from 'pages/Settings/SettingsPage/SettingsInvoices';
import { SettingsPageOrganization } from 'pages/Settings/SettingsPage/SettingsOrganization';
import { SettingsPagePayment } from 'pages/Settings/SettingsPage/SettingsPayment';
import { SettingsPageTeam } from 'pages/Settings/SettingsPage/SettingsTeam';

import { capitalize } from 'utils';

const pages = {
    SettingsPageAccount,
    SettingsPageInvoices,
    SettingsPageOrganization,
    SettingsPagePayment,
    SettingsPageTeam,
};

export const SettingsPage = memo(function SettingsPage({ pageKey, orderId, route, user, totems }) {
    const Component = pages[`SettingsPage${capitalize(pageKey)}`];
    return <Component pageKey={pageKey} route={route} orderId={orderId} user={user} totems={totems} />;
});
