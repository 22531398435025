import { PARIS_AREA_ZIPCODES } from './parisAreaZipcodes';

export const NEXT_TUESDAY = 8;
export const NEXT_WEDNESDAY = 9;

export function isParisRegion(zipcode: string): boolean {
    const isParis = /^(75)/.test(zipcode);
    if (isParis) return true;

    return PARIS_AREA_ZIPCODES.has(zipcode);
}
