import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { Flex } from 'deprecated-enkel';
import { Eye } from 'styled-icons/fa-solid';

import { POST_TO_SLACK } from 'data/mutations';

import { FRUIT_CATEGORY_ID, MEAL_CATEGORIES } from 'constants/categories-constants';
import { ARCHIVED } from 'constants/states-app';

import { OptimizedImage } from 'components/OptimizedImage';
import { AspectRatio } from 'components/AspectRatio';

import { getKgs, getGrams, pluralize, getPortionName } from 'utils';

import {
    DisplayUnit,
    DisplaySubUnit,
    CardOverlay,
    CardOverlayContainer,
    CardOverlayTitle,
    CardOverlayDisplayUnit,
    CardOverlaySubtitle,
    RemoveIcon,
    CardInner,
    ComeBackButton,
} from 'styles/components/card.styles';

export const CardProductImage = ({
    order,
    productInOrder,
    product,
    totemId,
    organization,
    isAnalyst,
    handleRemoveProduct,
}) => {
    const [wasRequested, setWasRequested] = useState(false);
    const [postMessageToSlack] = useMutation(POST_TO_SLACK);

    const {
        _id,
        crossedPrice,
        imageUrl,
        isBeingTested,
        isToBeArchived,
        isPaused,
        price,
        state,
        canBeSuppliedBeforeOrderDate,
    } = product;

    const handleComeBack = () => {
        const message = `🍑🍑 ${organization.name} veut que le produit ${product.name} revienne vite, très vite !`;

        postMessageToSlack({ variables: { message, channel: 'ops' } });
        setWasRequested(true);
    };

    const isProductInOrder = !!productInOrder?.portion;
    const isProductOutOfStock =
        state !== ARCHIVED &&
        !isPaused &&
        !product.isWithoutStock &&
        !product.allowNegativeStock &&
        !canBeSuppliedBeforeOrderDate &&
        !product.availableStock > 0;

    const portionName = getPortionName(product);

    const quantityToDisplay = productInOrder && parseFloat(productInOrder.quantity.toFixed(3));

    const discountPercentage = crossedPrice ? Math.round(((crossedPrice - price) / crossedPrice) * 100) : null;

    return (
        <AspectRatio
            size="100%"
            ratio="3:2"
            innerStyle={{
                overflow: 'hidden',
            }}
        >
            <ImageContainer>
                <OptimizedImage
                    src={imageUrl}
                    // alt={name}
                />
            </ImageContainer>
            <CardOverlay
                $transparent={
                    (isBeingTested && !isProductInOrder) ||
                    (!isProductInOrder && state !== ARCHIVED && !isPaused && !isProductOutOfStock)
                }
                $shouldHideDetails={state === ARCHIVED || isProductOutOfStock}
            >
                <CardIconWrapper>
                    {isProductInOrder && !isAnalyst && (
                        <div onClick={handleRemoveProduct} data-test="product-remove-button">
                            <RemoveIcon size="25px" />
                        </div>
                    )}
                    {discountPercentage && <DiscountContainer>-{discountPercentage}%</DiscountContainer>}
                </CardIconWrapper>
                {isProductInOrder && (
                    <CardOverlayContainer>
                        <CardOverlayDisplayUnit
                            style={{ alignItems: 'center', justifyContent: 'center' }}
                            className="card-product__info"
                        >
                            <DisplayUnit>
                                {product.displayKg
                                    ? getKgs(product.displayKg, product.weightWithoutPackaging, quantityToDisplay)
                                    : quantityToDisplay + ' '}
                            </DisplayUnit>
                            <DisplaySubUnit>
                                {product.displayKg ? 'kg ' : pluralize('pièce', quantityToDisplay)}
                                {product.displayKg
                                    ? getGrams(product.displayKg, product.weightWithoutPackaging, quantityToDisplay)
                                    : ''}
                                {product.displayKg &&
                                    (getGrams(product.displayKg, product.weightWithoutPackaging, quantityToDisplay)
                                        ? 'g'
                                        : '')}
                            </DisplaySubUnit>
                        </CardOverlayDisplayUnit>
                        {!MEAL_CATEGORIES.includes(product.categoryId) && product.volume !== 'Pièce' && (
                            <CardOverlaySubtitle className="card-product__info">
                                {`Soit ${product.categoryId === FRUIT_CATEGORY_ID ? 'environ' : ''} ${
                                    productInOrder.portion
                                } ${pluralize(portionName, productInOrder.portion)} dans votre TOTEM`}
                            </CardOverlaySubtitle>
                        )}
                    </CardOverlayContainer>
                )}
                {isPaused && (
                    <CardOverlayContainer>
                        <CardOverlayTitle
                            style={{ justifyContent: 'center' }}
                            className="card-product__info"
                            fontSize="1.9em"
                        >
                            Fini, c’est hors saison
                        </CardOverlayTitle>
                    </CardOverlayContainer>
                )}
                {((state === ARCHIVED && !isPaused) || isProductOutOfStock) && !isBeingTested && (
                    <CardOverlayContainer>
                        {wasRequested ? (
                            <CardOverlayTitle
                                style={{ justifyContent: 'center' }}
                                className="card-product__info"
                                fontSize="1.9em"
                            >
                                On vous tient au jus !
                            </CardOverlayTitle>
                        ) : (
                            <>
                                <CardOverlayTitle
                                    style={{ justifyContent: 'center' }}
                                    className="card-product__info"
                                    fontSize="1.6em"
                                >
                                    Victime de son succès !
                                </CardOverlayTitle>
                                {!isToBeArchived && (
                                    <>
                                        <CardOverlayTitle
                                            style={{ justifyContent: 'center' }}
                                            className="card-product__info"
                                            fontSize="1.6em"
                                        >
                                            De retour bientôt...
                                        </CardOverlayTitle>
                                        <ComeBackButton onClick={handleComeBack}>Revient vite !</ComeBackButton>
                                    </>
                                )}
                            </>
                        )}
                    </CardOverlayContainer>
                )}
                <CardInner
                    to={`/totem/${totemId}/${product.categoryId}/${order._id}/${_id}`}
                    data-test="open-extended-view"
                    className="card-product__link"
                    $hasBackground={!isProductInOrder && state !== ARCHIVED && !isPaused}
                >
                    <Eye size="35px" /> Détails
                </CardInner>
            </CardOverlay>
        </AspectRatio>
    );
};

const ImageContainer = styled.div`
    height: 100%;
    img {
        width: 100%;
        object-fit: contain;
        height: inherit;
    }
`;

const CardIconWrapper = styled(Flex)`
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: ${(props) => props.theme.variables.padding}px;
`;

const DiscountContainer = styled.div`
    margin-left: auto;
    padding: 5px;
    background-color: ${({ theme }) => theme.colors.pink};
    border-radius: 5px;
    color: white;
    font-weight: 600;
    font-size: 16px;
`;
