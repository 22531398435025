import { formatPrice } from '@totem/constants';

import { roundPrice } from 'utils/orders';

export function getDiscountFromOrder({ order, totalHT }) {
    if (order?.discount) {
        if (order.discount.type === 'Percentage') {
            return {
                discountPrice: roundPrice(totalHT * order.discount.amount),
                formattedDiscountPrice: formatPrice(-totalHT * order.discount.amount),
                formattedDiscountEffect: '(-' + order.discount.amount * 100 + '%)',
                discountDescription: order.discount.description,
            };
        }
        if (order.discount.type === 'Fixed') {
            const discountTotalValue = order.discount?.amount > totalHT ? totalHT : order.discount?.amount;
            return {
                discountPrice: discountTotalValue,
                formattedDiscountPrice: formatPrice(-discountTotalValue),
                formattedDiscountEffect: '(-' + order.discount.amount + '€)',
                discountDescription: order.discount.description,
            };
        }
    }
    return { discountPrice: 0 };
}
