import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { IconArrowLeft } from '@tabler/icons-react';

import { AsideBody, AsideDescription, AsideHeader, AsideSubTitle } from 'pages/Totem/Aside/AsideComponents';

import { IconButton, TotemButton } from 'ui/Button';

import { UPDATE_ORGANIZATION_HAS_SUBSCRIPTION } from 'data/mutations';
import { UserContext } from 'constants/user-context';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const SubscriptionContainer = ({ setIsSubscriptionAsideOpen }) => {
    const {
        organization: { _id: organizationId, hasSubscription },
    } = useContext(UserContext);
    const [updateOrganizationHasSubscription, { loading }] = useMutation(UPDATE_ORGANIZATION_HAS_SUBSCRIPTION);
    async function handleSubscriptionValidation() {
        await updateOrganizationHasSubscription({
            variables: { organizationId, isValidatingSubscription: true },
        });
        track(analyticsEvents.SUBSCRIBE_TO_UNLIMITED_DELIVERY, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
        });
        setIsSubscriptionAsideOpen(false);
    }
    return (
        <>
            <AsideHeader>
                <IconButton
                    data-test="aside-return-button"
                    $white
                    $large
                    onClick={() => setIsSubscriptionAsideOpen(false)}
                >
                    <IconArrowLeft size={25} />
                </IconButton>
            </AsideHeader>
            <AsideBody>
                <AsideSubTitle>
                    Livraison illimitée <b>250 €/mois</b>
                </AsideSubTitle>
                <AsideDescription>
                    <ul>
                        <li>La livraison à 0€ sur toutes vos commandes</li>
                        <li>Frais de mise en place offerts pour toutes vos commandes</li>
                        <li>Profitez de 2 mois offerts en payant pour l'année à venir</li>
                    </ul>
                </AsideDescription>
                <AsideDescription>
                    <b>Contactez nous sur le chat pour en savoir plus !</b>
                </AsideDescription>
                <TotemButton
                    $margins={[0, 2]}
                    $fontSize="1rem"
                    disabled={hasSubscription || loading}
                    onClick={() => handleSubscriptionValidation()}
                    data-test="basket-subscription-validation"
                >
                    Souscrire à l'abonnement
                </TotemButton>
                {hasSubscription && (
                    <AsideDescription>
                        Vous avez déjà souscrit à cette offre. Si vous souhaitez résilier, contactez-nous par le chat !
                    </AsideDescription>
                )}
            </AsideBody>
        </>
    );
};
