import styled from 'styled-components';
import { margin, lighten } from 'polished';

export const TotemButton = styled.button`
    font: inherit;
    letter-spacing: 0.1em;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: ${(props) => props.theme.variables.borderRadius};
    padding: 1em 2em 1.1em 2em;
    color: ${(props) => (props.$type === 'secondary' ? 'white' : props.theme.colors.black)};
    border: 1px solid
        ${(props) => (props.$type === 'secondary' ? props.theme.colors.black : props.theme.colors.greenDarker)};
    background: ${(props) => (props.$type === 'secondary' ? props.theme.colors.black : props.theme.colors.green)};
    font-size: ${(props) => (props.$fontSize ? props.$fontSize : '1.25rem')};
    ${({ $margins }) =>
        $margins &&
        margin(
            $margins[0] + 'rem',
            ($margins[1] ?? $margins[0]) + 'rem',
            ($margins[2] ?? $margins[0]) + 'rem',
            ($margins[3] ?? $margins[1] ?? $margins[0]) + 'rem',
        )}
    &:link,
    &:visited,
    &:active {
        text-decoration: none;
    }
    &:hover {
        background: ${(props) =>
            props.$type === 'secondary' ? lighten(0.1, props.theme.colors.black) : props.theme.colors.greenDarker};
    }
    ${({ $isLoading }) => $isLoading && 'pointer-events: none'};

    &:disabled {
        cursor: not-allowed;
        background: ${({ theme: { colors } }) => colors.lightGrey};
        border: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
        &:hover {
            background: ${({ theme: { colors } }) => colors.lightGrey};
        }
    }
    //prevent expanding height in a flex component
    align-self: ${({ $alignSelf }) => $alignSelf && $alignSelf};
    flex: ${({ $flex }) => ($flex ? $flex : 'initial')};
`;

export const IconButton = styled.button`
    border: none;
    border-radius: 50%;
    font: inherit;
    cursor: ${({ $isLoading }) => ($isLoading ? 'wait' : 'pointer')};
    height: ${({ $large }) => ($large ? '2.5em' : '2em')};
    width: ${({ $large }) => ($large ? '2.5em' : '2em')};
    background-color: ${({ theme, $white }) => ($white ? 'white' : theme.colors.green)};
    box-shadow: rgb(0 0 0 / 10%) 0px 0.4rem 1.6rem 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 6px;
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &:hover {
        background-color: ${({ theme, $white }) => ($white ? 'white' : theme.colors.greenDarker)};
        transform: ${({ $rotateOnHover }) => $rotateOnHover && 'rotate(90deg)'};
    }
    &:disabled {
        cursor: ${({ $isLoading }) => ($isLoading ? 'wait' : 'not-allowed')};
        background: ${({ theme: { colors } }) => colors.lightGrey};
        border: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
        &:hover {
            background: ${({ theme: { colors } }) => colors.lightGrey};
        }
    }
`;
