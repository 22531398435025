import React from 'react';

import { LoginCredentials } from 'pages/Settings/SettingsPage/SettingsAccount/LoginCredentials';
import { AccountInformation } from 'pages/Settings/SettingsPage/SettingsAccount/AccountInformation';

import { SettingsPageStyle } from 'styles/pages/settings.styles';

export const SettingsPageAccount = ({ user }) => {
    return (
        <SettingsPageStyle>
            <AccountInformation user={user} />
            <LoginCredentials username={user?.username} />
        </SettingsPageStyle>
    );
};
