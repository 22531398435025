import React, { useState, useEffect } from 'react';

import { PasswordStrengthContainer, PasswordStrengthElement } from 'styles/pages/setPassword.styles';

export const PasswordStrength = ({ input }) => {
    const [passwordStrength, setPasswordStrength] = useState('');

    const handlePasswordStrength = (input) => {
        const mediumRegex = new RegExp(
            '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
        );
        const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
        if (input.match(strongRegex)) {
            setPasswordStrength('strong');
        } else if (input.match(mediumRegex)) {
            setPasswordStrength('medium');
        } else if (input) {
            setPasswordStrength('weak');
        } else {
            setPasswordStrength('');
        }
    };

    useEffect(() => {
        handlePasswordStrength(input);
    }, [input]);

    return (
        <PasswordStrengthContainer>
            <PasswordStrengthElement $background={passwordStrength !== '' ? 'primary' : 'transparent'} />
            <PasswordStrengthElement
                $background={['medium', 'strong'].includes(passwordStrength) ? 'yellow' : 'transparent'}
            />
            <PasswordStrengthElement $background={passwordStrength === 'strong' ? 'green' : 'transparent'} />
        </PasswordStrengthContainer>
    );
};
