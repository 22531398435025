import React from 'react';
import { formatPrice } from '@totem/constants';
import styled from 'styled-components';

import { LineItemContainer } from 'pages/Totem/Aside/Basket/LineItemContainer';

import { OptimizedImage } from 'components/OptimizedImage';

import { getKgs, pluralize, getGrams } from 'utils';
import { roundPrice } from 'utils/orders';

const getWeightDisplay = ({ quantity, displayAsKilograms, weight }) => {
    if (!displayAsKilograms) {
        return `${quantity} ${pluralize('pièce', quantity)}`;
    }
    const kilograms = getKgs(displayAsKilograms, weight, quantity);
    const grams = getGrams(displayAsKilograms, weight, quantity);
    return `${kilograms}kg${grams ? ` ${grams}g` : ''}`;
};

export const BasketProductItem = ({ borderBottom, borderTop, paddingLeft, product }) => {
    // shouldProductHaveBeenDelivered tells us whether or not a product has been delivered or was supposed to be delivered
    // (quantityPicked is null before the delivery)
    const shouldProductHaveBeenDelivered = !!product.quantityPicked || product.quantityPicked === 0;
    // numberOfProductsNotDelivered is equal to 0 if the delivery has not been done yet, and equal to the difference between
    // quantity and quantity picked if the delivery is complete
    const numberOfProductsNotDelivered =
        product.quantityPicked || product.quantityPicked === 0 ? product.quantity - product.quantityPicked : 0;
    return (
        <LineItemContainer
            as="div"
            $borderBottom={borderBottom}
            $borderTop={borderTop}
            $paddingLeft={paddingLeft}
            $clickable={false}
        >
            <ProductDetails>
                <OptimizedImage
                    src={product.imageUrl}
                    height={60}
                    blurOnLoad={false}
                    // alt={product.name}
                />
                <ProductInfo>
                    <NameAndPriceLine>
                        <ProductName
                            $shouldBeGreyed={
                                shouldProductHaveBeenDelivered &&
                                !!numberOfProductsNotDelivered &&
                                numberOfProductsNotDelivered === product.quantity
                            }
                        >
                            {product.name}
                        </ProductName>
                        <ProductPrice>
                            {shouldProductHaveBeenDelivered && !!numberOfProductsNotDelivered && (
                                <CrossedValue>{formatPrice(product.unitPrice * product.quantity)}</CrossedValue>
                            )}
                            {shouldProductHaveBeenDelivered && numberOfProductsNotDelivered ? (
                                <UpdatedValue>
                                    &#8594;{' '}
                                    {formatPrice(
                                        roundPrice(
                                            product.unitPrice * product.quantity -
                                                product.unitPrice * numberOfProductsNotDelivered,
                                        ),
                                    )}
                                </UpdatedValue>
                            ) : (
                                formatPrice(product.unitPrice * product.quantity)
                            )}
                        </ProductPrice>
                    </NameAndPriceLine>
                    <WeightAndTextLine>
                        <ProductWeigthDisplay $hasMissingQuantities={numberOfProductsNotDelivered}>
                            {shouldProductHaveBeenDelivered && !!numberOfProductsNotDelivered && (
                                <CrossedValue>
                                    {getWeightDisplay({
                                        quantity: product.quantity,
                                        displayAsKilograms: product.displayKg,
                                        weight: product.weightWithoutPackaging,
                                    })}
                                </CrossedValue>
                            )}
                            {numberOfProductsNotDelivered ? (
                                <UpdatedValue>
                                    &#8594;{' '}
                                    {getWeightDisplay({
                                        quantity: shouldProductHaveBeenDelivered
                                            ? product.quantityPicked
                                            : product.quantity,
                                        displayAsKilograms: product.displayKg,
                                        weight: product.weightWithoutPackaging,
                                    })}
                                </UpdatedValue>
                            ) : (
                                <div>
                                    {getWeightDisplay({
                                        quantity: shouldProductHaveBeenDelivered
                                            ? product.quantityPicked
                                            : product.quantity,
                                        displayAsKilograms: product.displayKg,
                                        weight: product.weightWithoutPackaging,
                                    })}
                                </div>
                            )}
                        </ProductWeigthDisplay>
                        {!!numberOfProductsNotDelivered && (
                            <ProductPriceInfo>
                                {numberOfProductsNotDelivered === product.quantity
                                    ? '(produit épuisé)'
                                    : '(quantités insuffisantes)'}
                            </ProductPriceInfo>
                        )}
                    </WeightAndTextLine>
                </ProductInfo>
            </ProductDetails>
        </LineItemContainer>
    );
};

const UpdatedValue = styled.div`
    color: ${({ theme }) => theme.colors.red};
`;

const ProductDetails = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;
`;

const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const NameAndPriceLine = styled.div`
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const WeightAndTextLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0 0 5px;
`;

const ProductPrice = styled.div`
    display: flex;
`;

const ProductPriceInfo = styled.div`
    color: ${({ theme }) => theme.colors.red};
    text-align: right;
`;

const ProductName = styled.div`
    color: ${({ theme, $shouldBeGreyed }) => $shouldBeGreyed && theme.colors.pantinGrey};
    font-weight: 500;
`;

const ProductWeigthDisplay = styled.div`
    display: flex;
    color: ${({ theme, $hasMissingQuantities }) => $hasMissingQuantities && theme.colors.pantinGrey};
`;

const CrossedValue = styled.div`
    color: ${({ theme }) => theme.colors.pantinGrey};
    text-decoration-color: ${({ theme }) => theme.colors.pantinGrey};
    margin-right: 5px;
`;
