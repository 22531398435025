export const CREATED = 'Created'; // used for orders, totem, categories
export const ARCHIVED = 'Archived'; // used for totems, orders, products
export const INPROGRESS = 'InProgress'; // used for orders, deliveries
export const ABORTED = 'Aborted'; // used for deliveries
export const NOT_DELIVERED = 'NotDelivered'; // used for deliveries

// only used for products
export const REGULAR = 'Regular';

// only used for orders
export const PAUSED = 'Paused';
export const VALIDATED = 'Validated';
export const READY = 'Ready';

// only used for deliveries
export const DELIVERED = 'Delivered';

// only used for organization
export const TOTARGET = 'ToTarget';

// only used for totem
export const ROLLOUT = 'Rollout';

export const MODIFIABLE_ORDER_STATES = [CREATED, INPROGRESS, VALIDATED, PAUSED];
