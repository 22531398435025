import React, { useState } from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import { IconCircleX, IconMailForward, IconCircleCheck, IconUnlink } from '@tabler/icons-react';
import { User } from '@totem/roles';
import dayjs from 'dayjs';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { TeamPopupWindow } from 'pages/Settings/SettingsPage/SettingsTeam/TeamPopupWindow';
import { AddEmployeesPopupWindow } from 'pages/Settings/SettingsPage/SettingsTeam/AddEmployeesPopupWindow';
import { DetachEmployeeWarningPopup } from 'pages/Settings/SettingsPage/SettingsTeam/DetachEmployeeWarningPopup';
import { EmployeesDropdownButton } from 'pages/Settings/SettingsPage/SettingsTeam/EmployeesDropdownButton';

import { OutsideClickTracker } from 'components/OutsideClickTracker';

import { SettingsCardSubtitle, InvoicesTableStyle, EmployeesTitle } from 'styles/pages/settings.styles';
import {
    RESEND_WELCOMING_EMAIL_TO_USERS,
    TOGGLE_ORGANIZATION_PAYMENT_FOR_USER,
    TOGGLE_USER_GROUP_FOR_USER,
} from 'data/mutations';
import { Checkbox } from 'ui/Checkbox';
import { Tooltip, MultilineTooltip } from 'styles';
import { organizationCardUpdatedCacheHandler } from 'utils/cache/organizationCardUpdatedCacheHandler';

export const EmployeesTable = ({ users, refetchUsers, userGroups }) => {
    const apolloClient = useApolloClient();

    const [employeePopupOpen, setEmployeePopupOpen] = useState(false);
    const [addEmployeesPopupOpen, setAddEmployeesPopupOpen] = useState(false);
    const [warningPopupOpen, setWarningPopupOpen] = useState(false);
    const [userToDetach, setUserToDetach] = useState(null);
    const [resentEmailsAddresses, setResentEmailsAddresses] = useState([]);
    const usersLength = users.length;

    const [resendWelcomingEmailToUsers] = useMutation(RESEND_WELCOMING_EMAIL_TO_USERS);
    const [toggleOrganizationPaymentForUser] = useMutation(TOGGLE_ORGANIZATION_PAYMENT_FOR_USER);
    const [toggleUserGroupForUser] = useMutation(TOGGLE_USER_GROUP_FOR_USER);

    const handleDetachWarning = (userId) => {
        setUserToDetach(users.find((user) => user._id === userId));
        setWarningPopupOpen(true);
    };

    function toggleOrganizationPaymentCallback({ userId, isOrganizationPaymentEnabled }) {
        return async () => {
            const { data } = await toggleOrganizationPaymentForUser({
                variables: { userId, isOrganizationPaymentEnabled },
            });

            const {
                toggleOrganizationPaymentForUser: { errors, success, organizationCard },
            } = data;
            if (success && organizationCard) {
                organizationCardUpdatedCacheHandler({ apolloClient, organizationCard });
                toast.success("L'utilisateur·rice a bien été mis·e à jour.", {
                    duration: 4000,
                });
            } else if (errors) {
                errors.forEach((error) => toast.error(error));
            }
        };
    }

    function toggleUserGroupCallback({ userId, userGroupId, isUserGroupEnabled }) {
        return async () => {
            const { data } = await toggleUserGroupForUser({
                variables: { userId, userGroupId, isUserGroupEnabled },
            });

            const {
                toggleUserGroupForUser: { errors, success },
            } = data;
            if (success) {
                // organizationCardUpdatedCacheHandler({ apolloClient, organizationCard });
                toast.success("L'utilisateur·rice a bien été mis·e à jour.", {
                    duration: 4000,
                });
                // cache should be updated, but sometimes it's not
                refetchUsers();
            } else if (errors) {
                errors.forEach((error) => toast.error(error));
            }
        };
    }

    const handleResendMails = async (emailAddresses) => {
        const {
            data: { resendWelcomingEmailToUsers: response },
        } = await resendWelcomingEmailToUsers({
            variables: { emailAddresses },
        });
        setResentEmailsAddresses([...resentEmailsAddresses, ...response]);
    };

    const usersWithWithoutAnyVerifiedEmail = users.filter(
        ({ emails }) => !emails.find((email) => email.verified === true),
    );
    const allEmailsToResend = usersWithWithoutAnyVerifiedEmail
        .filter(({ emails }) => !resentEmailsAddresses.includes(emails[0].address))
        .map(({ emails }) => emails[0].address);

    return (
        <>
            <EmployeesTitle>
                <SettingsCardSubtitle $noMargin>Employés {!!usersLength && `(${usersLength})`}</SettingsCardSubtitle>
                <OutsideClickTracker
                    render={(open, setOpen) => (
                        <EmployeesDropdownButton
                            open={open}
                            setOpen={setOpen}
                            setEmployeePopupOpen={setEmployeePopupOpen}
                            setAddEmployeesPopupOpen={setAddEmployeesPopupOpen}
                        />
                    )}
                />
            </EmployeesTitle>
            <EmployeeSectionExplanation>
                Si vous disposez d'un{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://totem.co/stores/">
                    magasin
                </a>
                , et que vous souhaitez mettre en place des subventions pour vos collaborateurs, vous avez la
                possibilité d'ajouter ici leurs emails. Lorsqu'un utilisateur se créera un compte sur l'application
                mobile TOTEM avec un des emails renseignés, il bénéficiera automatiquement des subventions mises en
                place.
            </EmployeeSectionExplanation>
            {!!usersLength && (
                <InvoicesTableStyle $lessPadding style={{ align: 'left' }}>
                    <thead>
                        <tr>
                            <th>Nom complet</th>
                            <th>Adresse e-mail (compte créé ou non)</th>
                            <th>Badge pro</th>
                            {userGroups.map(({ _id, label }) => (
                                <th key={_id}>{label}</th>
                            ))}
                            <th>Détacher de mon entreprise</th>
                            <th>
                                <AlignedTextAndIcon>
                                    <MailSendIcon
                                        size="20px"
                                        onClick={() => handleResendMails(allEmailsToResend)}
                                        data-for="resendAllEmailsTooltip"
                                        data-tip="Renvoyer les invitations aux employés non-inscrits"
                                    />
                                    <MultilineTooltip
                                        id="resendAllEmailsTooltip"
                                        effect="solid"
                                        type="dark"
                                        place="bottom"
                                    />
                                </AlignedTextAndIcon>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(
                            (
                                {
                                    _id: userId,
                                    lastSeenAt,
                                    profile: { firstname, lastname },
                                    roles,
                                    emails,
                                    userGroupIds,
                                    wallet: { organizationCard },
                                },
                                index,
                            ) => {
                                const emailAddress = emails.find((email) => email.verified) || emails[0];
                                const displayResendEmailIcon = !resentEmailsAddresses.includes(emailAddress.address);
                                const isGlobalTeamMember = User.isGlobalTeamMember(roles);
                                const isNotAdmin = index > 0;
                                const hasOrganizationCard = !!organizationCard && organizationCard.state === 'Active';

                                return (
                                    <tr key={userId}>
                                        <td>
                                            {firstname} {lastname}
                                        </td>
                                        <td>
                                            <AlignedTextAndIcon>
                                                {emailAddress.address}
                                                {emailAddress.verified ? (
                                                    <GreenIconCircleCheck size={15} />
                                                ) : (
                                                    <RedIconCircleX size={15} />
                                                )}
                                            </AlignedTextAndIcon>
                                        </td>
                                        <td>
                                            <Checkbox
                                                readOnly
                                                checked={hasOrganizationCard}
                                                onChange={toggleOrganizationPaymentCallback({
                                                    userId,
                                                    isOrganizationPaymentEnabled: !hasOrganizationCard,
                                                })}
                                            />
                                        </td>
                                        {userGroups.map(({ _id }) => (
                                            <td key={_id}>
                                                <Checkbox
                                                    readOnly
                                                    checked={userGroupIds.includes(_id)}
                                                    onChange={toggleUserGroupCallback({
                                                        userId,
                                                        userGroupId: _id,
                                                        isUserGroupEnabled: !userGroupIds.includes(_id),
                                                    })}
                                                />
                                            </td>
                                        ))}
                                        {isNotAdmin ? (
                                            <td>
                                                <DetachIcon size="20px" onClick={() => handleDetachWarning(userId)} />
                                            </td>
                                        ) : null}
                                        {!isGlobalTeamMember ? (
                                            <td>
                                                <AlignedTextAndIcon>
                                                    {emailAddress.verified ? (
                                                        `En ligne ${dayjs(lastSeenAt).fromNow()}`
                                                    ) : displayResendEmailIcon ? (
                                                        <>
                                                            <MailSendIcon
                                                                size="20px"
                                                                onClick={() =>
                                                                    handleResendMails([emailAddress.address])
                                                                }
                                                                data-for="resendEmailTooltip"
                                                                data-tip="Renvoyer l'invitation"
                                                            />
                                                            <Tooltip
                                                                id="resendEmailTooltip"
                                                                effect="solid"
                                                                type="dark"
                                                                place="bottom"
                                                            />
                                                        </>
                                                    ) : (
                                                        <GreenIconCircleCheck size="20px" />
                                                    )}
                                                </AlignedTextAndIcon>
                                            </td>
                                        ) : null}
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </InvoicesTableStyle>
            )}
            {warningPopupOpen && (
                <DetachEmployeeWarningPopup setWarningPopupOpen={setWarningPopupOpen} userToDetach={userToDetach} />
            )}
            {employeePopupOpen && <TeamPopupWindow setPopupOpen={setEmployeePopupOpen} isEmployee={true} />}
            {addEmployeesPopupOpen && (
                <AddEmployeesPopupWindow setPopupOpen={setAddEmployeesPopupOpen} refetchUsers={refetchUsers} />
            )}
        </>
    );
};

const EmployeeSectionExplanation = styled.p`
    font-size: 1.2em;
    margin-bottom: 2em;
    max-width: 1000px;
    a {
        text-decoration: underline;
    }
`;

const AlignedTextAndIcon = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-left: 5px;
    }
`;

const MailSendIcon = styled(IconMailForward)`
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};
    transition: all 0.25s ease;

    &:hover {
        filter: brightness(85%);
    }
`;

const DetachIcon = styled(IconUnlink)`
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};
    transition: all 0.25s ease;

    &:hover {
        filter: brightness(85%);
    }
`;

const RedIconCircleX = styled(IconCircleX)`
    color: ${(props) => props.theme.colors.red};
`;
const GreenIconCircleCheck = styled(IconCircleCheck)`
    color: ${(props) => props.theme.colors.green};
`;
