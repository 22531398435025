import RenderAppropriate from '../../internals/RenderAppropriate';

import { CardTitle } from './CardTitle';
import { CardBody } from './CardBody';
import { CardFooter } from './CardFooter';

import { groupDisplayName } from '../../utils';

import { CardStyle } from '../../styles/display/card.styles';

const applyDisplayName = groupDisplayName('Card');

export const Card = ({ children, component: Component, ...rest }) =>
    RenderAppropriate(CardStyle, rest, children, Component);

Card.Title = applyDisplayName(CardTitle);
Card.Body = applyDisplayName(CardBody);
Card.Footer = applyDisplayName(CardFooter);

Card.Style = CardStyle;
