import styled from 'styled-components';
import { padding } from 'polished';

export const SvenStyle = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: inherit;
    ${(props) => padding(props.theme.variables.padding)};
`;

export const SvenImg = styled.img`
    margin: auto;
    ${(props) => padding(props.theme.variables.padding)};
`;
