import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import uniq from 'lodash/uniq';

import { OrderHistoryTable } from './OrderHistoryTable';

import { FORMAT } from 'constants/date-constants';
import { ARCHIVED, READY } from 'constants/states-app';

import { TotemButton } from 'ui/Button';

/**
 * Displays all orders to come and orders delivered today
 */
export const OrdersSchedule = ({
    baseUrl,
    defaultCategoryId,
    isOnboarding,
    ordersOfTotem,
    readyPunctualOrders = [],
}) => {
    // constants
    const INITIAL_NB_ORDERS_TO_SHOW = 5;

    // state
    const [nbOrderToShow, setNbOrderToShow] = useState(INITIAL_NB_ORDERS_TO_SHOW);

    // orders
    const { recentOrders } = ordersOfTotem;
    const currentOrders = recentOrders.filter((order) => order.state !== ARCHIVED);
    const readyOrders = recentOrders.filter(({ state }) => state === READY);
    const ordersToShow = uniq(
        [...currentOrders, ...readyOrders, ...readyPunctualOrders].filter((order) => order),
        (order) => order._id,
    ).sort((a, b) => (dayjs(a.date_delivery, FORMAT).isBefore(dayjs(b.date_delivery, FORMAT)) ? 1 : -1));

    return (
        <>
            {ordersToShow.length ? (
                <>
                    <OrderHistoryTable
                        ordersToShow={ordersToShow}
                        nbOrderToShow={nbOrderToShow}
                        ordersOfTotem={ordersOfTotem}
                        isOnboarding={isOnboarding}
                        defaultCategoryId={defaultCategoryId}
                        baseUrl={baseUrl}
                    />
                    {ordersToShow.length > nbOrderToShow && (
                        <TotemButton
                            $alignSelf="center"
                            $fontSize="1em"
                            onClick={() => setNbOrderToShow(nbOrderToShow + 5)}
                        >
                            Voir plus
                        </TotemButton>
                    )}
                </>
            ) : (
                <ScheduleText>
                    {isOnboarding
                        ? 'Vos commandes apparaîtront ici. Cliquez sur le bouton pour créer votre première commande !'
                        : "Vous n'avez aucune commande en cours !"}
                </ScheduleText>
            )}
        </>
    );
};

const ScheduleText = styled.div`
    font-size: 18px;
`;
