import gql from 'graphql-tag';

export const GET_EQUIPMENTS = gql`
    {
        equipments {
            _id
            name
            brand
            volume
            imageUrl
            description
            price
            categories {
                _id
                title
            }
            isAnnual
        }
    }
`;
