import gql from 'graphql-tag';

export const UPDATE_USER = gql`
    mutation UPDATE_USER($data: JSON!, $_id: String!) {
        updateUser(data: $data, _id: $_id) {
            profile {
                firstname
                lastname
                organizationId
                phone
                jobTitle
                note
                visited
                description
                state
                stateHistory {
                    createdAt
                    state
                }
                organizationId
                imageUrl
                fullname
                jobType
                buyingRole
                language
                salesforceId
            }
        }
    }
`;

export const FETCH_USER_TOKEN = gql`
    mutation FETCH_USER_TOKEN($spyeeId: String!) {
        fetchSpyeeToken(spyeeId: $spyeeId)
    }
`;

export const ADD_USER_TO_ORGANIZATION = gql`
    mutation ADD_USER_TO_ORGANIZATION($newUser: AddUserInput!, $shouldSendEmailToEmployee: Boolean!) {
        addUserToOrganization(newUser: $newUser, shouldSendEmailToEmployee: $shouldSendEmailToEmployee) {
            _id
        }
    }
`;

export const ADD_MANY_USERS_TO_ORGANIZATION = gql`
    mutation ADD_MANY_USERS_TO_ORGANIZATION($newUsers: [AddUserInput!]!, $shouldSendEmailToEmployee: Boolean!) {
        addManyUsersToOrganization(newUsers: $newUsers, shouldSendEmailToEmployee: $shouldSendEmailToEmployee)
    }
`;

export const RESEND_WELCOMING_EMAIL_TO_USERS = gql`
    mutation RESEND_WELCOMING_EMAIL_TO_USERS($emailAddresses: [String]!) {
        resendWelcomingEmailToUsers(emailAddresses: $emailAddresses)
    }
`;

export const DETACH_USER_FROM_ORGANIZATION = gql`
    mutation DETACH_USER_FROM_ORGANIZATION($userId: ID!) {
        detachUserFromOrganization(userId: $userId) {
            success
            errors
            user {
                _id
                profile {
                    organizationId
                }
            }
        }
    }
`;

export const TOGGLE_ORGANIZATION_PAYMENT_FOR_USER = gql`
    mutation TOGGLE_ORGANIZATION_PAYMENT_FOR_USER($userId: ID!, $isOrganizationPaymentEnabled: Boolean!) {
        toggleOrganizationPaymentForUser(userId: $userId, isOrganizationPaymentEnabled: $isOrganizationPaymentEnabled) {
            success
            errors
            organizationCard {
                _id
                state
                userId
            }
        }
    }
`;

export const TOGGLE_USER_GROUP_FOR_USER = gql`
    mutation TOGGLE_USER_GROUP_FOR_USER($userId: ID!, $userGroupId: ID!, $isUserGroupEnabled: Boolean!) {
        toggleUserGroupForUser(userId: $userId, userGroupId: $userGroupId, isUserGroupEnabled: $isUserGroupEnabled) {
            success
            errors
            user {
                _id
                userGroupIds
                __typename
            }
        }
    }
`;

export const UPDATE_TEAM_ROLE = gql`
    mutation UPDATE_TEAM_ROLE($role: String!, $userId: String!, $totemId: String!) {
        updateTeamRole(role: $role, userId: $userId, totemId: $totemId)
    }
`;

export const SET_PASSWORD = gql`
    mutation SET_PASSWORD($password: String!, $token: String!) {
        setUserPassword(password: $password, token: $token) {
            success
            error
            token
        }
    }
`;

export const AUTHENTICATE_USER = gql`
    mutation AUTHENTICATE_USER($token: String!) {
        authenticateUser(token: $token) {
            success
            error
            organizationName
        }
    }
`;

export const AUTHENTICATE_USER_RESET = gql`
    mutation AUTHENTICATE_USER_RESET($token: String!) {
        authenticateUserReset(token: $token) {
            success
            error
        }
    }
`;

export const AUTHENTICATE_EMPLOYEE_VALIDATION = gql`
    mutation AUTHENTICATE_EMPLOYEE_VALIDATION($token: String!) {
        authenticateEmployeeValidation(token: $token) {
            success
            error
        }
    }
`;

export const SEND_RESET_PASSWORD = gql`
    mutation SEND_RESET_PASSWORD($emailAddress: String!) {
        sendResetPassword(emailAddress: $emailAddress) {
            success
            error
            organizationName
        }
    }
`;

export const CHECK_PASSWORD = gql`
    mutation CHECK_PASSWORD($password: String!) {
        checkPassword(password: $password)
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation CHANGE_PASSWORD($password: String!) {
        changePassword(password: $password) {
            success
            error
        }
    }
`;

export const UPDATE_EMAIL = gql`
    mutation UPDATE_EMAIL($emailAddress: String!) {
        updateEmail(emailAddress: $emailAddress) {
            success
            error
        }
    }
`;
