import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { TotemButton } from 'ui/Button';
import { InlineError } from 'ui/InlineError';

import { TotemInput, TotemLabel } from 'styles';

import { validateEmail } from 'utils';
import { UPDATE_ORGANIZATION_BILLING_INFO } from 'data/mutations';
import { track, analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants } from 'tracking/segment';
import { ClipLoader } from 'react-spinners';

export const BillingInfoForm = ({ currentTotem, currentUser, organization, setIsModifyingBillingInfo }) => {
    const [updateOrganizationBillingInfo] = useMutation(UPDATE_ORGANIZATION_BILLING_INFO);
    const { billingInfo } = organization;
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            invoiceEmail: billingInfo?.invoiceEmail || currentUser?.emails?.[0].address,
            invoiceName: billingInfo?.invoiceName || organization?.name,
            invoiceAddress: billingInfo?.invoiceAddress || currentTotem?.address,
            invoiceCity: billingInfo?.invoiceCity || currentTotem?.city,
            invoiceZipcode: billingInfo?.invoiceZipcode || currentTotem?.zipcode,
            invoiceCountry: billingInfo?.invoiceCountry || currentTotem?.country,
            invoiceVAT: billingInfo?.invoiceVAT,
            invoicePO: billingInfo?.invoicePO,
        },
    });
    async function handleBillingInfoSubmit(organizationBillingInfo) {
        const options = {
            variables: {
                organizationId: organization._id,
                organizationBillingInfo,
            },
        };
        await updateOrganizationBillingInfo(options);
        track(analyticsEvents.VALIDATE_BILLING_INFO, {
            [analyticsPropertiesConstants.COMMON.IS_ONBOARDING]: true,
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
            [analyticsPropertiesConstants.ORDER.BILLING.HAS_PO_NUMBER]: !!organizationBillingInfo?.invoicePO,
            [analyticsPropertiesConstants.ORDER.BILLING.HAS_TVA_NUMBER]: !!organizationBillingInfo?.invoiceVAT,
        });
        setIsModifyingBillingInfo(false);
    }
    return (
        <BillingInfoFormContainer onSubmit={handleSubmit(handleBillingInfoSubmit)}>
            <TotemLabel>
                Email de réception des factures* <InlineError display={errors.invoiceEmail} />
                <TotemInput
                    placeholder="Entrez l'email de réception des factures"
                    type="email"
                    {...register('invoiceEmail', {
                        required: true,
                        validate: validateEmail('Votre mail n’est pas valide'),
                        setValueAs: (email) => email.trim(),
                    })}
                    data-test="payment-email"
                />
            </TotemLabel>
            <TotemLabel>
                Nom d'entreprise pour la facture* <InlineError display={errors.invoiceName} />
                <TotemInput
                    placeholder="Entrez le nom d'entreprise pour la facture"
                    {...register('invoiceName', {
                        required: true,
                        minLength: {
                            value: 3,
                            message: "Le nom de l'entreprise doit contenir au moins 3 caractères",
                        },
                    })}
                    data-test="payment-name"
                />
            </TotemLabel>
            <Line>
                <TotemLabel $halfWidth>
                    Numéro de PO <InlineError display={errors.invoicePO} />
                    <TotemInput
                        placeholder="Entrez le n° de PO"
                        {...register('invoicePO')}
                        data-test="payment-PO-number"
                    />
                </TotemLabel>
                <TotemLabel $halfWidth>
                    Numéro de TVA <InlineError display={errors.invoiceVAT} />
                    <TotemInput
                        placeholder="Entrez le n° de TVA"
                        {...register('invoiceVAT')}
                        data-test="payment-VAT-number"
                    />
                </TotemLabel>
            </Line>
            <TotemLabel>
                Adresse de facturation* <InlineError display={errors.invoiceAddress} />
                <TotemInput
                    placeholder="Adresse"
                    {...register('invoiceAddress', { required: true })}
                    data-test="payment-address"
                />
            </TotemLabel>
            <Line>
                <TotemLabel $halfWidth>
                    Ville* <InlineError display={errors.invoiceCity} />
                    <TotemInput
                        placeholder="Entrez la ville"
                        {...register('invoiceCity', { required: true })}
                        data-test="payment-city"
                    />
                </TotemLabel>
                <TotemLabel $halfWidth>
                    Code postal* <InlineError display={errors.invoiceZipcode} />
                    <TotemInput
                        placeholder="Entrez le code postal"
                        {...register('invoiceZipcode', { required: true })}
                        data-test="payment-zipcode"
                    />
                </TotemLabel>
            </Line>
            <TotemLabel>
                Pays* <InlineError display={errors.invoiceCountry} />
                <TotemInput
                    placeholder="Entrez le pays"
                    {...register('invoiceCountry', { required: true })}
                    data-test="payment-country"
                />
            </TotemLabel>
            <div>Rentrez les informations telles que vous souhaitez qu'elles apparaissent sur la facture.</div>
            <TotemButton
                disabled={isSubmitting}
                $margins={[1, 0, 0, 0]}
                $fontSize="1em"
                type="submit"
                data-test="validate-billing-info"
            >
                {isSubmitting ? <ClipLoader color="white" size="16px" /> : 'Valider les infos de facturation'}
            </TotemButton>
        </BillingInfoFormContainer>
    );
};

const BillingInfoFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
`;

const Line = styled.div`
    display: flex;
    justify-content: space-between;
`;
