import React from 'react';
import styled from 'styled-components';

import { TotemButton } from 'ui/Button';

import { PopupTitle } from 'styles/pages/settings.styles';

export const ChoosePunctualOrRecurringOrder = ({
    handleDuplicateOrder,
    hasSameDayPunctualOrder,
    sameDayModifiableRecurringOrder,
    setIsConfirmingReplace,
    translatedWeekday,
}) => {
    return (
        <>
            <PopupTitle>Voulez-vous vous faire livrer tous les {translatedWeekday}s ?</PopupTitle>
            <OrderTypeContainer>
                <TotemButton
                    $margins={[1, 1, 0.5, 1]}
                    data-test="duplicate-into-recurring-order-button"
                    onClick={() => {
                        if (sameDayModifiableRecurringOrder) {
                            setIsConfirmingReplace(true);
                        } else {
                            handleDuplicateOrder(false);
                        }
                    }}
                >
                    Oui
                </TotemButton>
                <Tip>
                    Votre commande sera automatiquement renouvelée et livrée toutes les semaines (elle reste modifiable
                    chaque semaine)
                </Tip>
                <TotemButton
                    $type="secondary"
                    $margins={[1, 1, 0.5, 1]}
                    onClick={() => handleDuplicateOrder(true)}
                    data-test="duplicate-into-punctual-order-button"
                >
                    Non
                </TotemButton>
                {!!hasSameDayPunctualOrder && (
                    <Tip color="red">Attention : vous avez déjà une commande ponctuelle pour ce jour.</Tip>
                )}
            </OrderTypeContainer>
        </>
    );
};

const OrderTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Tip = styled.div`
    color: ${(props) => (props.color === 'red' ? props.theme.colors.red : props.theme.colors.pantinGrey)};
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    max-width: 470px;
`;
