import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MoreHorizontalOutline } from '@styled-icons/evaicons-outline';

export const MeatBallsMenu = ({ dataTest, children }) => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    });

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    }
    // only show the meatballs menu if it has some elements to show
    if (
        !(
            children?.props?.label || // children is just one react element
            (Array.isArray(children) && children.some((v) => !!v))
        ) // children is an array (all elements can be `false` so we check this)
    )
        return null;

    return (
        <Container onClick={() => setOpen(!isOpen)}>
            <ThreeDots data-test={dataTest}>
                <MoreHorizontalOutline size={24} />
            </ThreeDots>
            {isOpen && <ListItems ref={ref}>{children}</ListItems>}
        </Container>
    );
};

const ThreeDots = styled.button`
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 6px;
    transition-duration: 0.2s;
    min-height: 35px;
    min-width: 35px;
    background-color: transparent;
    color: ${(props) => props.theme.colors.black};
    &:hover {
        background-color: ${(props) => props.theme.colors.culturedGrey};
        color: black;
    }
    cursor: pointer;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;

const ListItems = styled.div`
    z-index: 10;
    font-size: 1.2em;
    position: absolute;
    right: 0;
    top: 30px;
    border-radius: 4px;
    box-shadow:
        0 0 0 1px rgb(111 119 130 / 15%),
        0 5px 20px 0 rgb(21 27 38 / 8%);
    box-sizing: border-box;
    font-size: 14px;
    max-width: 500px;
    min-width: 200px;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.culturedGrey};
`;
