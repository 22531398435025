import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Flex } from 'deprecated-enkel';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { margin, padding, transparentize } from 'polished';

import { CardTagsStyle, RemoveIcon } from 'styles/components/card.styles';

import { GET_PRODUCT } from 'data/queries';
import { ARCHIVED } from 'constants/states-app';

import { ExtendedProductTabs } from 'pages/Totem/TotemProducts/ExtendedProduct/ExtendedProductTabs';
import { ExtendedProductHeader } from 'pages/Totem/TotemProducts/ExtendedProduct/ExtendedProductHeader';

import { OptimizedImage } from 'components/OptimizedImage';
import { Tag } from 'ui/Tag';

import { buildImgUrl } from 'utils/image/buildImgUrl';

import { checkIfProductIsAvailable } from 'utils';
import { useWindowDimensions } from 'utils/useWindowDimensions';

import { Tooltip } from 'styles';

import { Loading } from 'components/Loading';

export const TotemExtendedProduct = ({ currentCategory, order, lastOrder }) => {
    const { totemId, productId } = useParams();
    const { width } = useWindowDimensions();
    const [info, setInfo] = useState('information');

    const {
        loading: loadingProduct,
        error: errorProduct,
        data: dataProduct,
    } = useQuery(GET_PRODUCT, {
        variables: { productId, orderId: order._id },
    });

    if (loadingProduct) {
        return <Loading relative />;
    }

    if (errorProduct) {
        return JSON.stringify(errorProduct, null, 4);
    }

    const { product } = dataProduct;

    const recentlyOutOfSeason = (_id) => {
        return product.isPaused && lastOrder?.products.find((p) => p._id === _id);
    };

    const badges = [...product.preferences, ...product.diets];
    if (product.newUntil) {
        badges.push({
            name: 'new',
            imageUrl: 'img/new.svg',
        });
    }

    const availableStock = product.availableStock > 0;
    const isProductOutOfStock =
        product.state !== ARCHIVED &&
        !product.isPaused &&
        !product.isWithoutStock &&
        !product.allowNegativeStock &&
        !product.canBeSuppliedBeforeOrderDate &&
        !availableStock;

    const orderProductIds = order.products.map(({ _id }) => _id);
    const isAlreadyInOrder = orderProductIds.includes(product._id);

    return (
        <ProductBody>
            <ProductImageContainerStyle $flow="column" $justifyContent="space-between">
                <RemoveIconLink
                    to={`/totem/${totemId}/${currentCategory._id}/${order._id}`}
                    data-test="close-extended-view"
                >
                    <RemoveIcon size="30px" />
                </RemoveIconLink>
                <OverlayedImageContainer>
                    <OptimizedImage src={product.imageUrl} />
                    {!isAlreadyInOrder && (
                        <>
                            {((!checkIfProductIsAvailable(product) &&
                                !recentlyOutOfSeason(product._id, product.state)) ||
                                isProductOutOfStock) && (
                                <ImageOverlay>
                                    <ImageOverlayText>
                                        Revient vite
                                        <BottomLine />
                                    </ImageOverlayText>
                                </ImageOverlay>
                            )}
                            {!checkIfProductIsAvailable(product) && recentlyOutOfSeason(product._id, product.state) && (
                                <ImageOverlay>
                                    <ImageOverlayText>
                                        Fini, c’est hors saison
                                        <BottomLine />
                                    </ImageOverlayText>
                                </ImageOverlay>
                            )}
                        </>
                    )}
                </OverlayedImageContainer>
                {width < 1300 && (
                    <LabelsWrapper>
                        <ProductTagsStyle>
                            {product.tags.map(
                                ({ _id, name }) =>
                                    name && (
                                        <Tag extendedView key={_id}>
                                            {name}
                                        </Tag>
                                    ),
                            )}
                        </ProductTagsStyle>
                        {badges && badges.length > 0 && (
                            <>
                                <ProductBadgesStyle>
                                    {badges.map((badge) => (
                                        <>
                                            <OptimizedImage
                                                data-for={`badge_${badge._id}`}
                                                data-tip={badge.name}
                                                key={badge._id}
                                                src={buildImgUrl(badge.imageUrl)}
                                                fetchFormat="svg"
                                                quality={40}
                                                isLazy={false}
                                            />
                                            <Tooltip
                                                id={`badge_${badge._id}`}
                                                effect="solid"
                                                type="dark"
                                                place="bottom"
                                            />
                                        </>
                                    ))}
                                </ProductBadgesStyle>
                            </>
                        )}
                    </LabelsWrapper>
                )}
            </ProductImageContainerStyle>
            <ProductDetailsStyle>
                <ExtendedProductHeader
                    product={product}
                    order={order}
                    totemId={totemId}
                    currentCategory={currentCategory}
                />
                <ExtendedProductTabs product={product} info={info} setInfo={setInfo} />
            </ProductDetailsStyle>
        </ProductBody>
    );
};

const OverlayedImageContainer = styled.div`
    display: relative;
    height: 100%;
    object-fit: contain;
    img {
        height: 100%;
        object-fit: contain;
    }
`;

const ProductBody = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: 50%;
    width: 100%;
    background: ${(props) => props.theme.colors.white};

    @media (min-width: 1300px) {
        flex-flow: row;
        height: 100%;
    }
`;

const ProductImageContainerStyle = styled(Flex.Style)`
    width: 100%;
    position: relative;
    height: 100%;
    flex-shrink: 0;
    overflow: hidden;

    @media (min-width: 1300px) {
        width: 55%;

        &:before {
            display: none;
        }
    }
`;

const RemoveIconLink = styled(Link)`
    position: absolute;
    top: 15px;
    right: 25px;
    z-index: 1;
`;

const ProductDetailsStyle = styled.div`
    display: flex;
    flex-flow: column;
    align-self: stretch;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
`;

const ProductTagsStyle = styled(CardTagsStyle)`
    font-size: 1.35em;
    padding: 0;
    ${(props) =>
        margin(
            props.theme.variables.margin * 0.5,
            props.theme.variables.margin,
            props.theme.variables.margin * 0.5,
            props.theme.variables.margin,
        )};
`;

const LabelsWrapper = styled(Flex.Style)`
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    width: 100%;
    ${(props) => padding(0, props.theme.variables.padding, props.theme.variables.padding * 0.5)}
`;

const ProductBadgesStyle = styled.div`
    ${(props) => margin(0, 0, props.theme.variables.margin, props.theme.variables.margin)};

    > img {
        width: 35px;
        ${(props) => margin(0, props.theme.variables.margin * 1.5, 0, 0)};
    }
`;

const ImageOverlay = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: ${(props) => transparentize(0.5, props.theme.colors.black)};
`;

const ImageOverlayText = styled(Flex.Style)`
    flex-direction: column;
    align-items: center;
    font-size: 3em;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.white};
`;

const BottomLine = styled.div`
    ${(props) => padding(props.theme.variables.padding * 2, 0, 0, 0)};
    content: '';
    width: 60%;
    min-height: 100%;
    border-bottom: 4px solid ${(props) => props.theme.colors.white};
`;
