import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { AuthContext } from '@totem/auth';

import { AUTHENTICATE_USER_RESET } from 'data/mutations';

import { SetPasswordInner } from 'pages/SetPassword/SetPasswordInner';
import { Loading } from 'components/Loading';
import { Logo } from 'components/Logo';

import { LoginBottomHalfStyle, LoginStyle, LoginTopHalfStyle, LoginWrapperStyle } from 'styles/pages/login.styles';
import { TotemLoadingStyle } from 'styles/pages/totem.styles';

export const ResetPassword = ({ setSkip }) => {
    const { token } = useParams();
    const {
        authActions: { setPassword },
    } = useContext(AuthContext);
    const [verifyUser, setVerifyUser] = useState(null);
    const [authenticateUserReset] = useMutation(AUTHENTICATE_USER_RESET);

    useEffect(() => {
        const verifyToken = async () => {
            const result = await authenticateUserReset({ variables: { token } }).catch((e) => {
                if (e.message === 'GraphQL error: invalid token') {
                    setVerifyUser({ success: false, error: 'Veuillez vérifier votre lien' });
                } else if (e.message === 'GraphQL error: maxAge exceeded') {
                    setVerifyUser({
                        success: false,
                        error: 'Ce lien a expiré. Veuillez en demander un nouveau',
                    });
                } else {
                    setVerifyUser({
                        success: false,
                        error: 'Il y a eu un problème avec la réinitialisation de votre mot de passe.',
                    });
                }
            });
            if (result && !verifyUser) {
                const {
                    data: { authenticateUserReset },
                } = result;
                setVerifyUser(authenticateUserReset);
            }
        };
        if (!verifyUser) {
            verifyToken();
        }
    }, [verifyUser, token, authenticateUserReset]);

    if (!verifyUser) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <LoginBottomHalfStyle>
                <LoginWrapperStyle>
                    <SetPasswordInner
                        isResetPassword
                        verifyUser={verifyUser}
                        setSkip={setSkip}
                        setPassword={setPassword}
                        token={token}
                    />
                </LoginWrapperStyle>
            </LoginBottomHalfStyle>
        </LoginStyle>
    );
};
