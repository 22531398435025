import React, { useState } from 'react';
import styled from 'styled-components';

import { EmojiLabel } from 'components/EmojiLabel';

import { WEEKDAY_INFO } from 'constants/date-constants';

import { Checkbox } from 'ui/Checkbox';
import { TotemButton } from 'ui/Button';

import { Popup, PopupContent, PopupTitle, CloseContainer } from 'styles/pages/settings.styles';
import { RemoveIcon } from 'styles/components/card.styles';

import { StarFull } from 'assets/svg/StarFull';
import { StarEmpty } from 'assets/svg/StarEmpty';

import { TotemInput } from 'styles';
import { colors } from 'styles/theme';

export const RatingPopupContent = ({ handleClose, ratePreviousOrder, weekday }) => {
    const [starsStates, setStarsStates] = useState([0, 0, 0, 0, 0]); // 1 = star is full, 0 = star is empty
    const [starsHoverStates, setStarsHoverStates] = useState([0, 0, 0, 0, 0]); // 1 = star is full, 0 = star is empty
    const [isStarHovered, setIsStarHovered] = useState(false);
    const { name: deliveryDay } = WEEKDAY_INFO.find((day) => day.value === weekday) || {};
    const [formReasons, setFormReasons] = useState({
        delivery: false,
        products: false,
        quantity: false,
        equipments: false,
        productsMissing: false,
        webapp: false,
    });
    const [formFeedback, setFormFeedback] = useState('');
    const selectedStar = starsStates.reduce((a, b) => a + b, 0);
    const reasonsLabels = {
        delivery: 'Livraison',
        equipments: 'Équipements',
        products: 'Qualité des produits',
        quantity: 'Quantité',
        productsMissing: 'Produits manquants',
        webapp: 'Espace client',
    };
    const canSubmitForm = !Object.values(formReasons).includes(true);

    const setHoverStar = (hoveredIndex) => () => {
        setStarsHoverStates(starsHoverStates.map((star, index) => (index <= hoveredIndex ? 1 : 0)));
        setIsStarHovered(true);
    };
    const setSelectedStar = (selectedIndex) => () => {
        setStarsStates(starsStates.map((star, index) => (index <= selectedIndex ? 1 : 0)));
        if (selectedIndex === 4) {
            ratePreviousOrder({ appreciation: 5 });
        }
    };
    const handleReasonCheck = (reason) => () => {
        setFormReasons({ ...formReasons, [reason]: !formReasons[reason] });
    };
    return (
        <Popup style={{ $alignItems: 'center', $justifyContent: 'center' }}>
            <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween $width="520px" onClick={(e) => e.stopPropagation()}>
                <PopupTitle>
                    Avez-vous aimé votre commande <br />
                    de {deliveryDay} cette semaine ?
                </PopupTitle>
                <RatingForm>
                    <Stars>
                        {starsStates.map((star, index) => {
                            return (
                                <StarContainer
                                    key={index}
                                    onClick={setSelectedStar(index)}
                                    onMouseEnter={setHoverStar(index)}
                                    onMouseLeave={() => setIsStarHovered(false)}
                                >
                                    {(isStarHovered ? starsHoverStates[index] : star) ? (
                                        <StarFull fill={colors.yellow} />
                                    ) : (
                                        <StarEmpty fill={colors.yellow} />
                                    )}
                                </StarContainer>
                            );
                        })}
                    </Stars>
                    {!!selectedStar && selectedStar < 5 && (
                        <Form>
                            <FormItem>
                                <FormLabel>
                                    <EmojiLabel src="🤕" /> Pourquoi n’êtes-vous pas encore totalement satisfait ?
                                </FormLabel>
                                <Reasons>
                                    {Object.keys(formReasons).map((reason, index) => {
                                        return (
                                            <Reason key={index}>
                                                <Checkbox
                                                    checked={formReasons[reason]}
                                                    onChange={handleReasonCheck(reason)}
                                                />
                                                <ReasonLabel>{reasonsLabels[reason]}</ReasonLabel>
                                            </Reason>
                                        );
                                    })}
                                </Reasons>
                            </FormItem>
                            <FormItem>
                                <FormLabel>
                                    <EmojiLabel src="👩‍💻" />
                                    Dites-nous en plus !
                                </FormLabel>
                                <FeedbackInput
                                    placeholder="Vos remarques"
                                    name="feedback"
                                    onChange={({ target: { value } }) => setFormFeedback(value)}
                                    defaultValue={formFeedback}
                                />
                            </FormItem>
                            <SubmitButtonContainer>
                                <TotemButton
                                    disabled={canSubmitForm}
                                    onClick={() =>
                                        ratePreviousOrder({
                                            appreciation: selectedStar,
                                            feedback: formFeedback,
                                            badServicesObject: formReasons,
                                        })
                                    }
                                >
                                    Envoyer à la TOTEAM
                                </TotemButton>
                            </SubmitButtonContainer>
                        </Form>
                    )}
                </RatingForm>
                <CloseContainer>
                    <RemoveIcon size="25px" onClick={handleClose} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};

const RatingForm = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Stars = styled.div`
    display: flex;
    width: 50%;
    justify-content: space-around;
`;

const StarContainer = styled.div`
    width: 20%;
    cursor: pointer;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const FormItem = styled.div`
    margin-top: 20px;
    width: 100%;
`;

const FormLabel = styled.span`
    font-weight: 800;
    margin-top: 15px;
    color: ${(props) => props.theme.colors.black};
    font-size: 20px;
`;

const Reasons = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
`;

const Reason = styled.div`
    display: flex;
    align-items: center;
    width: 33%;
`;

const ReasonLabel = styled.span`
    margin-left: 10px;
    color: ${(props) => props.theme.colors.black};
    font-size: 15px;
`;

const FeedbackInput = styled(TotemInput)`
    margin-top: 10px;
`;

const SubmitButtonContainer = styled.div`
    padding: 15px;
`;
