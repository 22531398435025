import styled from 'styled-components';

export const CenterPanelStyle = styled.div`
    position: relative;
    flex-shrink: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: ${(props) => props.theme.colors.culturedGrey};

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

export const MobileAppButtons = styled.div`
    @media not screen, (min-width: 600px) {
        display: none;
    }
`;
