import React from 'react';
import { formatPrice } from '@totem/constants';
import styled from 'styled-components';

import { BasketDisplayLineItem } from './BasketDisplayLineItem';

import { TotemButton } from 'ui/Button';

import { DELIVERY_PRICE_WITHOUT_ONSITE_SETUP, ONSITE_SETUP_PRICE, SHORT_TIME_WINDOW_PRICE } from 'constants/order';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';
import { getTimeDifference } from 'utils';
import { MIN_FREE_INTERVAL_DURATION } from 'constants/deliveryTime';

export const BasketPriceDetail = ({ hasSubscription, order, setIsSubscriptionAsideOpen }) => {
    const { discount, products: productsFromOrder } = order;

    const isTimeWindowFreeOfCharge =
        getTimeDifference(order.deliveryTimeWindows?.[0].start, order.deliveryTimeWindows?.[0].stop) >=
        MIN_FREE_INTERVAL_DURATION;

    const deliveryPriceWithoutSubscription =
        DELIVERY_PRICE_WITHOUT_ONSITE_SETUP +
        (order.hasOnsiteSetup ? ONSITE_SETUP_PRICE : 0) +
        (isTimeWindowFreeOfCharge ? 0 : SHORT_TIME_WINDOW_PRICE);
    const deliveryPrice = hasSubscription ? 0 : deliveryPriceWithoutSubscription;
    const { calculatedPriceHT } = productsFromOrder.reduce(
        (acc, product) => {
            acc.calculatedPriceHT += product.unitPrice * product.quantity;
            return acc;
        },
        {
            calculatedPriceHT: deliveryPrice,
        },
    );
    const subTotalPriceHT = calculatedPriceHT - deliveryPrice;
    let discountTotalValue = 0;
    if (discount) {
        const { amount: discountAmount, type: discountType } = discount;
        if (discountType === 'Percentage') {
            discountTotalValue = calculatedPriceHT * discountAmount;
        }
        if (discountType === 'Fixed') {
            if (discount.amount > calculatedPriceHT) {
                discountTotalValue = calculatedPriceHT;
            } else {
                discountTotalValue = discountAmount;
            }
        }
    }
    const formattedSubTotalPriceHT = formatPrice(subTotalPriceHT);
    const formattedDiscountPrice = formatPrice(-discountTotalValue);
    const formattedDiscountEffect =
        discount?.type === 'Percentage' ? '(-' + discount?.amount * 100 + '%)' : '(-' + discount?.amount + '€)';
    const formattedTotalBeforeDiscount = formatPrice(calculatedPriceHT);
    const formattedTotalAfterDiscount = formatPrice(calculatedPriceHT - discountTotalValue);
    return (
        <PriceDetailContainer>
            <BasketDisplayLineItem label="Sous-total" displayedInfo={formattedSubTotalPriceHT} />
            {discount && (
                <BasketDisplayLineItem
                    label={'Promo : ' + discount.description + ' ' + formattedDiscountEffect}
                    displayedInfo={formattedDiscountPrice}
                />
            )}
            <DeliveryPriceContainer>
                <DeliveryPriceLineItem>
                    <span>Frais de livraison {order.hasOnsiteSetup ? ' et de mise en place' : ''}</span>
                    {hasSubscription ? (
                        <div data-test="basket-delivery-fee">
                            <span>
                                <strike>{formatPrice(deliveryPriceWithoutSubscription)}</strike>{' '}
                                {formatPrice(deliveryPrice)}
                            </span>
                        </div>
                    ) : (
                        <div data-test="basket-delivery-fee">{formatPrice(deliveryPrice)}</div>
                    )}
                </DeliveryPriceLineItem>
                {!hasSubscription && (!isTimeWindowFreeOfCharge || order.hasOnsiteSetup) ? (
                    <DetailListContainer>
                        <ListItemContent>
                            <div>• Livraison</div>
                            <div>{formatPrice(DELIVERY_PRICE_WITHOUT_ONSITE_SETUP)}</div>
                        </ListItemContent>

                        {!isTimeWindowFreeOfCharge ? (
                            <ListItemContent>
                                <div>• Plage horaire restreinte</div>
                                <div>{formatPrice(SHORT_TIME_WINDOW_PRICE)}</div>
                            </ListItemContent>
                        ) : null}
                        {order.hasOnsiteSetup ? (
                            <ListItemContent>
                                <div>• Mise en place</div>
                                <div>{formatPrice(ONSITE_SETUP_PRICE)}</div>
                            </ListItemContent>
                        ) : null}
                    </DetailListContainer>
                ) : null}
                {!hasSubscription ? (
                    <SubscriptionButtonContainer>
                        <TotemButton
                            $fontSize="0.75rem"
                            $margins={[0.5, 0, 0, 0]}
                            onClick={() => {
                                setIsSubscriptionAsideOpen(true);
                                track(analyticsEvents.OPEN_DELIVERY_SUBSCRIPTION_SECTION, {
                                    [analyticsPropertiesConstants.COMMON.SOURCE]:
                                        analyticsSourceConstants.CHECKOUT_BASKET,
                                });
                            }}
                        >
                            Ne payez {order.first_order ? 'pas' : 'plus'} la livraison
                        </TotemButton>
                    </SubscriptionButtonContainer>
                ) : null}
            </DeliveryPriceContainer>
            <BasketDisplayLineItem
                label="Total HT"
                displayedInfo={
                    discountTotalValue ? (
                        <span>
                            <strike>{formattedTotalBeforeDiscount}</strike> {formattedTotalAfterDiscount}
                        </span>
                    ) : (
                        formattedTotalAfterDiscount
                    )
                }
                borderBottom
            />
        </PriceDetailContainer>
    );
};

const PriceDetailContainer = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    width: 100%;
    border-width: 0 0 0.1rem;
`;

const DeliveryPriceContainer = styled.div`
    padding: 25px;
`;

const DeliveryPriceLineItem = styled.div`
    font-size: 1.1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const DetailListContainer = styled.div`
    color: ${(props) => props.theme.colors.pantinGrey};
`;

const ListItemContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const SubscriptionButtonContainer = styled.div``;
