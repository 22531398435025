import React from 'react';
import { useQuery } from '@apollo/client';
import { User } from '@totem/roles';

import { SETTINGS_PAGES } from 'constants/settings-pages';

import { GET_LATEST_TERMS_RECORD } from '../../data/queries/termsRecord.queries';

import { EmojiLabel } from 'components/EmojiLabel';

import { isClient } from 'utils';

import { SettingsNavInner, SettingsNavHeading, SettingsFooter, TermsAnchor } from 'styles/pages/settings.styles';

import { TotemNavStyle, TotemNavLink, TotemNavCategories } from 'styles/pages/totem.styles';
import { useLocation } from 'react-router-dom';

export const SettingsNav = () => {
    const location = useLocation();

    const user = User.get();
    const isToteam = !isClient();

    const { data: termsRecordData } = useQuery(GET_LATEST_TERMS_RECORD);

    const { pathname } = location;

    return (
        <TotemNavStyle>
            <SettingsNavHeading>Paramètres</SettingsNavHeading>
            <SettingsNavInner>
                <TotemNavCategories>
                    {SETTINGS_PAGES.map(({ key, title, roles }) => {
                        const hasAccess = isToteam || user.roles.global.some((role) => roles.includes(role));

                        return (
                            hasAccess && (
                                <TotemNavLink
                                    to={`/settings/${key}`}
                                    key={key}
                                    $isSelected={pathname.includes(key)}
                                    data-test={key}
                                >
                                    {title}
                                </TotemNavLink>
                            )
                        );
                    })}
                </TotemNavCategories>
                <SettingsFooter>
                    <span>
                        Made with <EmojiLabel src="💛" spacing={2} /> in Paris •
                        <TermsAnchor
                            href={
                                termsRecordData?.latestTermsRecord?.termsOfUseUrl &&
                                termsRecordData.latestTermsRecord.termsOfUseUrl
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Conditions générales
                        </TermsAnchor>
                        • TOTEM © {new Date().getFullYear()}
                    </span>
                </SettingsFooter>
            </SettingsNavInner>
        </TotemNavStyle>
    );
};
