import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/client';
import { Camera, Trash } from '@styled-icons/boxicons-regular';
import { ReloadCircle, Duplicate, PauseCircle } from '@styled-icons/ionicons-outline';
import { formatPrice } from '@totem/constants';

import { REACTIVATE_ORDER, DELETE_ORDER } from 'data/mutations';
import { GET_ORDERS_OF_TOTEM } from 'data/queries';

import { ARCHIVED, CREATED, INPROGRESS, PAUSED, READY, VALIDATED } from 'constants/states-app';

import { FormattedDeliveryDate } from 'pages/Totem/TotemDashboard/FormattedDeliveryDate';
import { MeatBallsMenu } from 'components/MeatBallsMenu';
import { MeatBallsListItem } from 'components/MeatBallsListItem';
import { OrderTableState } from 'pages/Totem/TotemDashboard/OrderTableState';
import { TotemDeliveryPicturesPopUp } from './TotemDeliveryPicturesPopUp';
import { DuplicateOrderPopUp } from 'pages/Order/OrderDuplicate/DuplicateOrderPopUp';
import { PauseOrderPopUp } from 'pages/Order/OrderPausing/PauseOrderPopUp';
import { TotemUserPicture } from 'pages/Totem/TotemDashboard/TotemUserPicture';

import { getUrlParameters } from 'utils';
import { getDeliveryHourRangeString } from 'utils/time';
import { PUNCTUAL, RECURRING } from 'constants/types';

import { PunctualIcon, RecurringIcon, MagnifyingGlassIcon } from 'styles/pages/totem.styles';
import { InvoicesTableStyle } from 'styles/pages/settings.styles';
import { DashboardOrderModifyButton } from 'pages/Totem/TotemDashboard/DashboardOrderModifyButton';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';
import { calculateOrderPrices } from 'pages/Order/OrderDetails/utils/calculateOrderPrices';
import { mergeProductsAndProductsRequested } from 'pages/Order/OrderDetails/utils/mergeProductsAndProductsRequested';

export const OrderHistoryTable = ({
    ordersToShow,
    nbOrderToShow = 20,
    ordersOfTotem,
    isOnboarding = false,
    defaultCategoryId,
    baseUrl,
}) => {
    const [isShowingPhotos, setShowPhotos] = useState(false);
    const [isDuplicatingOrder, setIsDuplicatingOrder] = useState(false);
    const [orderBeingDuplicated, setOrderBeingDuplicated] = useState(null);
    const [isPausingOrder, setIsPausingOrder] = useState(false);
    const [orderBeingPaused, setOrderBeingPaused] = useState(null);
    const [photos, setPhotos] = useState(null);
    // props
    const { totemId } = getUrlParameters(window.location.pathname);
    const { recentOrders } = ordersOfTotem;
    // hooks
    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    // queries and mutations
    const [reactivateOrder, { loading: reactivateLoading }] = useMutation(REACTIVATE_ORDER);
    const [deleteOrder] = useMutation(DELETE_ORDER);

    // functions
    const handleSeePhotos = (photos) => {
        setShowPhotos(true);
        setPhotos(photos);
        track(analyticsEvents.SEE_ORDER_PHOTOS);
    };

    const goToOrder =
        ({ orderId }) =>
        async () => {
            track(analyticsEvents.MODIFY_ORDER, {
                [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.DASHBOARD,
                [analyticsPropertiesConstants.COMMON.IS_ONBOARDING]: isOnboarding,
            });
            navigate(`${baseUrl}/${defaultCategoryId}/${orderId}`);
        };

    const handleReactivateOrder = async (orderId) => {
        if (reactivateLoading) return;

        const {
            data: { reactivateOrder: updatedOrder },
        } = await reactivateOrder({
            variables: { orderId },
        });

        const updatedOrders = recentOrders.map((orderFromCache) =>
            orderFromCache._id === orderId ? updatedOrder : orderFromCache,
        );

        apolloClient.writeQuery({
            query: GET_ORDERS_OF_TOTEM,
            variables: { totemId },
            data: {
                ordersOfTotem: { ...ordersOfTotem, recentOrders: updatedOrders },
            },
        });
        track(analyticsEvents.REACTIVATE_ORDER);
    };

    const handleDeleteOrder = (orderId) => async () => {
        await deleteOrder({ variables: { orderId } });
        const updatedOrders = [...recentOrders];
        const orderIds = updatedOrders.map((localOrder) => localOrder._id);
        const orderIndex = orderIds.indexOf(orderId);

        if (orderIndex >= 0) {
            updatedOrders.splice(orderIndex, 1);
        }

        apolloClient.writeQuery({
            query: GET_ORDERS_OF_TOTEM,
            variables: { totemId },
            data: {
                ordersOfTotem: { ...ordersOfTotem, recentOrders: updatedOrders },
            },
        });
        track(analyticsEvents.DELETE_ORDER);
    };

    const handleDuplicateOrder = (order) => {
        setIsDuplicatingOrder(true);
        setOrderBeingDuplicated(order);
        track(analyticsEvents.START_ORDER_DUPLICATION);
    };

    const handlePauseOrder = (order) => {
        setIsPausingOrder(true);
        setOrderBeingPaused(order);
        track(analyticsEvents.START_ORDER_PAUSING);
    };

    const getOrderPhotos = (order) => {
        const orderPhotos = [];

        if (order.delivery?.photos?.length) {
            orderPhotos.push(...order.delivery.photos);
        }

        if (order.delivery?.siteSetup?.freefoodSetup?.instructions?.length) {
            const photoInstruction = order.delivery.siteSetup.freefoodSetup.instructions.find(
                ({ __typename }) => __typename === 'PhotosInstruction',
            );

            if (photoInstruction.photos.length) {
                orderPhotos.push(...photoInstruction.photos);
            }
        }

        return orderPhotos;
    };

    return (
        <>
            <InvoicesTableStyle $spacing $marginMultiplier={[0, 0, 1.5, 0]}>
                <thead>
                    <tr>
                        <th>Utilisateur</th>
                        <th>Date et heures de livraison</th>
                        <th>Type de commande</th>
                        <th>Total HT</th>
                        <th>Statut</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {ordersToShow
                        .sort((order1) => {
                            if (order1?.delivery?.state === INPROGRESS) return -1;
                            else return 1;
                        })
                        .slice(0, nbOrderToShow)
                        .map((order) => {
                            const {
                                _id: orderId,
                                date_delivery,
                                deliveryTimeWindows,
                                isPunctual,
                                state,
                                first_order: firstOrder,
                                productsWithQuantityPicked,
                                productsRequestedButNotFullyDelivered,
                                stateHistory,
                                delivery,
                                user: {
                                    profile: { firstname, lastname, imageUrl },
                                },
                            } = order;

                            const orderPhotos = getOrderPhotos(order);
                            const stateRecordHasBeenValidated = stateHistory.some(({ state }) => state === VALIDATED);
                            const currentOrders = recentOrders.filter((order) => order.state !== ARCHIVED);
                            const isAdditionalWeekdayOnboardingOrder =
                                !isPunctual && firstOrder && currentOrders.length;

                            const productsWithQuantityRequested = mergeProductsAndProductsRequested({
                                products: productsWithQuantityPicked,
                                productRequestedWithQuantities: productsRequestedButNotFullyDelivered,
                                deliveryDate: order.date_delivery,
                            });

                            const { allProductPriceWithDelivery } = calculateOrderPrices({
                                products: productsWithQuantityRequested,
                                priceDelivery: order.prices.priceDelivery,
                            });

                            const orderTestType = isPunctual ? PUNCTUAL : `${RECURRING}-${state.toLowerCase()}`;
                            return (
                                <tr key={orderId}>
                                    <td width="15%">
                                        <TotemUserPicture
                                            firstName={firstname}
                                            lastName={lastname}
                                            imageUrl={imageUrl}
                                        />
                                    </td>
                                    <td width="20%">
                                        <FormattedDeliveryDate deliveryDate={date_delivery} />,
                                        <br />
                                        {getDeliveryHourRangeString(
                                            deliveryTimeWindows?.[0].start,
                                            deliveryTimeWindows?.[0].stop,
                                        )}
                                    </td>
                                    {isPunctual ? (
                                        <td width="20%">
                                            <PunctualIcon size="20px" /> Ponctuelle
                                        </td>
                                    ) : (
                                        <td width="20%">
                                            <RecurringIcon size="20px" /> Récurrente
                                        </td>
                                    )}
                                    <td width="15%">{formatPrice(allProductPriceWithDelivery)}</td>
                                    <td width="20%">
                                        {' '}
                                        <OrderTableState
                                            delivery={delivery}
                                            firstOrder={firstOrder}
                                            hasBeenValidated={stateRecordHasBeenValidated}
                                            isPunctual={isPunctual}
                                            state={state}
                                        />
                                    </td>
                                    <td width="25%">
                                        <LastColumn>
                                            <MeatBallsMenu dataTest="order-action-button">
                                                {[ARCHIVED, READY].includes(state) && (
                                                    <MeatBallsListItem
                                                        onClick={() => navigate(`/order/${orderId}`)}
                                                        icon={<MagnifyingGlassIcon color="secondary" size="14px" />}
                                                        label="Détails"
                                                    />
                                                )}
                                                {state === PAUSED && (
                                                    <MeatBallsListItem
                                                        onClick={() => handleReactivateOrder(orderId)}
                                                        icon={<ReloadCircle color="secondary" size="14px" />}
                                                        label="Réactiver ma commande"
                                                        loading={reactivateLoading}
                                                    />
                                                )}
                                                {!isPunctual && ![PAUSED, ARCHIVED, READY].includes(state) && (
                                                    <MeatBallsListItem
                                                        onClick={() => handlePauseOrder(order)}
                                                        icon={<PauseCircle color="secondary" size="14px" />}
                                                        label="Suspendre"
                                                    />
                                                )}
                                                {![ARCHIVED, READY].includes(state) &&
                                                    (isPunctual || isAdditionalWeekdayOnboardingOrder) &&
                                                    [CREATED, INPROGRESS].includes(state) &&
                                                    !stateRecordHasBeenValidated && (
                                                        <MeatBallsListItem
                                                            onClick={handleDeleteOrder(orderId)}
                                                            icon={<Trash color="secondary" size="14px" />}
                                                            label="Supprimer"
                                                        />
                                                    )}
                                                {orderPhotos.length ? (
                                                    <MeatBallsListItem
                                                        onClick={() => handleSeePhotos(orderPhotos)}
                                                        icon={<Camera color="secondary" size="14px" />}
                                                        label="Voir les photos"
                                                    />
                                                ) : null}
                                                <MeatBallsListItem
                                                    onClick={() => handleDuplicateOrder(order)}
                                                    icon={<Duplicate color="secondary" size="14px" />}
                                                    label="Dupliquer"
                                                    dataTest="duplicate-order-button"
                                                />
                                            </MeatBallsMenu>
                                            {![ARCHIVED, READY, PAUSED].includes(state) && (
                                                <DashboardOrderModifyButton
                                                    orderTestType={orderTestType}
                                                    onClick={goToOrder({
                                                        orderId,
                                                    })}
                                                    deliveryDate={date_delivery}
                                                    firstOrder={firstOrder}
                                                    hasBeenValidated={stateRecordHasBeenValidated}
                                                    isPunctual={isPunctual}
                                                    state={state}
                                                />
                                            )}
                                        </LastColumn>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </InvoicesTableStyle>
            {isShowingPhotos && <TotemDeliveryPicturesPopUp photos={photos} setShowPictures={setShowPhotos} />}
            {isDuplicatingOrder && (
                <DuplicateOrderPopUp
                    order={orderBeingDuplicated}
                    closeOrderPopup={() => setIsDuplicatingOrder(false)}
                    defaultCategoryId={defaultCategoryId}
                />
            )}
            {isPausingOrder && <PauseOrderPopUp order={orderBeingPaused} closePopup={() => setIsPausingOrder(false)} />}
        </>
    );
};

const LastColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
