import React from 'react';
import styled from 'styled-components';

import { MapContainer } from './MapContainer';
import { DashboardHeaderInfo } from './DashboardHeaderInfo';

export const DashboardHeader = ({ totem, openDeliveryInfoPopup }) => {
    return (
        <DashboardHeaderContainer>
            <>
                {!!(totem.latitude && totem.longitude) && (
                    <MapWrapper>
                        <>
                            <MapContainer
                                position={{
                                    lat: totem.latitude,
                                    lng: totem.longitude,
                                }}
                                zoom={11}
                                disableDefaultUI={true}
                            />
                            <MapOverlay />
                        </>
                    </MapWrapper>
                )}
                <DashboardHeaderInfo totem={totem} openDeliveryInfoPopup={openDeliveryInfoPopup} />
            </>
        </DashboardHeaderContainer>
    );
};

const DashboardHeaderContainer = styled.div`
    position: relative;
    width: 100%;
`;

const MapWrapper = styled.div`
    position: relative;
    height: 250px;
    overflow: hidden;
    pointer-events: none;
    flex: 1;
`;

const MapOverlay = styled.div`
    background: ${({ theme: { colors } }) => `radial-gradient(rgba(250, 250, 250, 0) 0%, ${colors.culturedGrey} 70%)`};
    box-sizing: border-box;
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
