import { GET_TOTEMS } from 'data/queries';

export function totemUpdatedCacheHandler({ apolloClient, currentTotem, totemUpdatedValues }) {
    const { totemsOfUser: totems } = apolloClient.readQuery({
        query: GET_TOTEMS,
    });
    const totemsToUpdate = [...totems];
    const totemIds = totems.map((totem) => totem._id);
    const elemIndex = totemIds.indexOf(currentTotem._id);
    const updatedTotem = { ...currentTotem, ...totemUpdatedValues };
    totemsToUpdate.splice(elemIndex, 1, updatedTotem);
    apolloClient.writeQuery({
        query: GET_TOTEMS,
        data: { totemsOfUser: totemsToUpdate },
    });
}
