import React from 'react';
import { useMutation } from '@apollo/client';
import { IconArrowLeft } from '@tabler/icons-react';
import { useForm, Controller } from 'react-hook-form';
import Toggle from 'react-toggle';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

import { AsideBody, AsideDescription, AsideForm, AsideHeader, AsideSubTitle } from 'pages/Totem/Aside/AsideComponents';

import { TotemLabel, TotemInput } from 'styles';

import { IconButton, TotemButton } from 'ui/Button';

import { UPDATE_ORDER_ONSITE_SETUP_INSTRUCTIONS } from 'data/mutations';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const OnsiteSetupContainer = ({ order, totem, setIsOnsiteSetupInstructionsOpen }) => {
    const [updateOrderOnsiteSetupInstructions, { loading }] = useMutation(UPDATE_ORDER_ONSITE_SETUP_INSTRUCTIONS);
    const {
        control,
        handleSubmit,
        register,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            onsiteSetupInstructions: order?.onsiteSetupInstructions || totem?.consigneDelivery,
            makeDefault: order?.onsiteSetupInstructions === totem?.consigneDelivery,
        },
    });
    const handleOnsiteSetupValidation = async ({ onsiteSetupInstructions, makeDefault }) => {
        await updateOrderOnsiteSetupInstructions({
            variables: {
                makeDefault,
                orderId: order._id,
                onsiteSetupInstructions,
            },
        });
        track(analyticsEvents.SET_ONSITE_SETUP_INSTRUCTIONS, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
        });
        setIsOnsiteSetupInstructionsOpen(false);
    };
    return (
        <>
            <AsideHeader>
                <IconButton
                    data-test="aside-return-button"
                    $white
                    $large
                    onClick={() => setIsOnsiteSetupInstructionsOpen(false)}
                >
                    <IconArrowLeft size={25} />
                </IconButton>
            </AsideHeader>
            <AsideBody>
                <AsideForm onSubmit={handleSubmit(handleOnsiteSetupValidation)}>
                    <AsideSubTitle>Consignes de mise en place</AsideSubTitle>
                    <AsideDescription>
                        Nos livreurs installent, dans le respect des mesures sanitaires, les produits commandés.
                        Indiquez nous où vous souhaitez que le café soit posé, comment les boissons doivent être
                        rangées, etc..
                    </AsideDescription>
                    <TotemInput
                        {...register('onsiteSetupInstructions', { required: 'ce champ est requis' })}
                        async // necessary because we use styled-component
                        as={TextareaAutosize}
                        rows={4}
                        $margins={[1, 2, 0, 2]}
                        placeholder="Entrez vos consignes de mise en place"
                        data-test="onsite-setup-instructions"
                    />
                    <LineItemToggleContainer>
                        <TotemLabel>
                            <span>Utiliser ces consignes par défaut (pour toutes les commandes à cette adresse)</span>
                            <Controller
                                control={control}
                                name="makeDefault"
                                render={({ field: { value, onChange } }) => {
                                    return <Toggle checked={value} onChange={onChange} />;
                                }}
                            />
                        </TotemLabel>
                    </LineItemToggleContainer>
                    <TotemButton
                        $margins={[2, 2]}
                        $fontSize="1rem"
                        disabled={!isValid || !isDirty || loading}
                        type="submit"
                        data-test="basket-onsite-setup-instructions-validation"
                    >
                        Modifier
                    </TotemButton>
                </AsideForm>
            </AsideBody>
        </>
    );
};

const LineItemToggleContainer = styled.div`
    padding: 1em 2em 0 2em;
    .react-toggle--checked .react-toggle-track {
        background-color: ${({ theme }) => theme.colors.green};
    }
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${({ theme }) => theme.colors.greenDarker};
    }
    span {
        margin-bottom: 0.5em;
    }
`;
