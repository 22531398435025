import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import Toggle from 'react-toggle';

import { ADD_USER_TO_ORGANIZATION } from 'data/mutations/user.mutations';

import { UserContext } from 'constants/user-context';

import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { validateEmail } from 'utils';

import { Popup, PopupContent, CloseContainer, PopupTitle } from 'styles/pages/settings.styles';
import { RemoveIcon } from 'styles/components/card.styles';
import { TotemInput } from 'styles';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

export const TeamPopupWindow = ({ setPopupOpen, isEmployee }) => {
    const {
        organization: { maxUsers },
    } = useContext(UserContext);
    const [addUserToOrganization, { loading }] = useMutation(ADD_USER_TO_ORGANIZATION);
    const [shouldSendEmailToEmployee, setShouldSentEmailToEmployee] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({});

    const handleAddUser = async (arg) => {
        const { email, firstname, lastname } = arg;
        try {
            const trimmedEmail = email.trim();
            const trimmedFirstname = firstname.trim();
            const trimmedLastname = lastname.trim();
            await addUserToOrganization({
                variables: {
                    newUser: {
                        emailAddress: trimmedEmail,
                        firstname: trimmedFirstname,
                        lastname: trimmedLastname,
                        isEmployee,
                    },
                    shouldSendEmailToEmployee,
                },
                refetchQueries: ['GET_USERS_OF_ORGANIZATION'],
            });
            track(analyticsEvents.ADD_USER_TO_ORG, {
                [analyticsPropertiesConstants.COMMON.IS_EMPLOYEE]: isEmployee,
            });
            setPopupOpen(false);
        } catch (error) {
            setError('email', { type: 'invalid', message: error.message.split(': ').pop() });
        }
    };

    return (
        <Popup $alignItems="center" $justifyContent="center" onClick={() => setPopupOpen(false)}>
            <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="455px" onClick={(e) => e.stopPropagation()}>
                {isEmployee ? (
                    <>
                        <PopupTitle>Ajouter un employé</PopupTitle>
                        <InfoText>
                            <p>
                                Vous pouvez attacher autant d'employés que vous voulez à votre organisation. Ces
                                derniers bénéficieront d'éventuelles subventions que mettriez en place.
                            </p>
                        </InfoText>
                    </>
                ) : (
                    <>
                        <PopupTitle>Ajouter un utilisateur</PopupTitle>
                        <InfoText>Vous pouvez ajouter jusqu'à {maxUsers} utilisateurs pour un compte TOTEM.</InfoText>
                    </>
                )}
                <FormContainer onSubmit={handleSubmit(handleAddUser)}>
                    <InputContainer style={{ position: 'relative' }}>
                        <InlineError top="3px" right="13px" display={errors.firstname} />
                        <TotemInput
                            autoFocus
                            marginLeft
                            sidePadding
                            placeholder={`Prénom du nouvel ${isEmployee ? 'employé' : 'utilisateur'}`}
                            width="300px"
                            minHeight="40px"
                            {...register('firstname', { required: true })}
                        />
                    </InputContainer>
                    <InputContainer style={{ position: 'relative' }}>
                        <InlineError top="3px" right="13px" display={errors.lastname} />
                        <TotemInput
                            marginLeft
                            sidePadding
                            placeholder={`Nom du nouvel ${isEmployee ? 'employé' : 'utilisateur'}`}
                            name="lastname"
                            width="300px"
                            minHeight="40px"
                            {...register('lastname', { required: true })}
                        />
                    </InputContainer>
                    <InputContainer style={{ position: 'relative' }}>
                        <InlineError top="3px" right="13px" display={errors.email} />
                        <TotemInput
                            marginLeft
                            sidePadding
                            placeholder={`Email du nouvel ${isEmployee ? 'employé' : 'utilisateur'}`}
                            width="300px"
                            minHeight="40px"
                            {...register('email', {
                                required: true,
                                validate: validateEmail("L'adresse mail n'est pas valide"),
                            })}
                        />
                    </InputContainer>
                    {isEmployee && (
                        <ShouldSendEmailToggle>
                            <Toggle
                                checked={shouldSendEmailToEmployee}
                                onChange={(e) => setShouldSentEmailToEmployee(e.target.checked)}
                            />
                            <CheckboxLabel>Envoyer un email à l'email renseigné</CheckboxLabel>
                        </ShouldSendEmailToggle>
                    )}
                    <TotemButton $margins={[2, 0, 0, 0]} type="submit" $isLoading={loading}>
                        {loading ? <ClipLoader color="white" size="20px" /> : 'Inviter'}
                    </TotemButton>
                </FormContainer>
                <CloseContainer>
                    <RemoveIcon size="25px" onClick={() => setPopupOpen(false)} />
                </CloseContainer>
            </PopupContent>
        </Popup>
    );
};

const InfoText = styled.p`
    margin: 10px 40px;
`;

const ShouldSendEmailToggle = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .react-toggle--checked .react-toggle-track {
        background-color: ${({ theme }) => theme.colors.green};
    }
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${({ theme }) => theme.colors.greenDarker};
    }
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    justify-self: flex-end;
    justify-content: center;
    align-items: center;
`;

const InputContainer = styled.div`
    position: relative;
    margin-top: 20px;
`;

const CheckboxLabel = styled.span`
    margin-left: 5px;
    font-weight: 500;
    font-size: 16px;
`;
