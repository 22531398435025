import React, { useState } from 'react';

import { TrashIcon } from 'styles/pages/settings.styles';
import { DETACH_PAYMENT_METHOD } from 'data/mutations/stripe.mutations';
import { useMutation } from '@apollo/client';
import { ClipLoader } from 'react-spinners';
import { colors } from 'styles/theme';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const IbanInfo = ({ paymentMethod, organizationId, numberOfCards }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [detachPaymentMethod] = useMutation(DETACH_PAYMENT_METHOD);

    function handleDeleteIban() {
        setIsDeleting(true);
        detachPaymentMethod({
            variables: {
                paymentMethodId: paymentMethod.id,
                organizationId,
            },
        });
        track(analyticsEvents.DELETE_PAYMENT_METHOD, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.SETTINGS,
        });
    }

    return (
        <>
            <td>Prélèvement bancaire</td>
            <td>
                {paymentMethod.sepa_debit.country} • • {paymentMethod.sepa_debit.bank_code} • • • • • •{' '}
                {paymentMethod.sepa_debit.last4}
            </td>
            <td>{paymentMethod.owner?.name}</td>
            <td />
            <td>
                {numberOfCards > 1 ? (
                    isDeleting ? (
                        <ClipLoader color={colors.black} size="16px" />
                    ) : (
                        <TrashIcon size="20px" onClick={handleDeleteIban} />
                    )
                ) : (
                    ''
                )}
            </td>
        </>
    );
};
