import gql from 'graphql-tag';

import { CARD_FRAGMENT } from 'data/fragments/stripe.fragments';

export const CREATE_SETUP_INTENT = gql`
    mutation CREATE_SETUP_INTENT($organizationId: ID!) {
        createSetupIntent(organizationId: $organizationId) {
            clientSecret
        }
    }
`;

export const SET_STRIPE_DEFAULT_PAYMENT_METHOD = gql`
    mutation SET_STRIPE_DEFAULT_PAYMENT_METHOD($organizationId: ID!, $userId: String!, $paymentMethodId: String!) {
        setStripeDefaultPaymentMethod(
            organizationId: $organizationId
            userId: $userId
            paymentMethodId: $paymentMethodId
        ) {
            _id
            billingInfo {
                defaultPaymentMethod
            }
            cards {
                ...CardFragment
            }
            paymentType
            stripeId
        }
    }
    ${CARD_FRAGMENT}
`;

export const DETACH_PAYMENT_METHOD = gql`
    mutation DETACH_PAYMENT_METHOD($organizationId: ID!, $paymentMethodId: String!) {
        detachPaymentMethod(organizationId: $organizationId, paymentMethodId: $paymentMethodId) {
            _id
            billingInfo {
                defaultPaymentMethod
            }
            cards {
                ...CardFragment
            }
            paymentType
            stripeId
        }
    }
    ${CARD_FRAGMENT}
`;
