import React from 'react';
import styled from 'styled-components';
import { padding } from 'polished';
import { Image } from 'cloudinary-react';

import { IMAGE_LOGO_DARK, IMAGE_LOGO_LIGHT } from 'data/images';

import { buildImgUrl } from 'utils/image/buildImgUrl';

export const Logo = ({ dark = false, width = 250, isLink = true, ...rest }) => {
    const logoImage = dark ? IMAGE_LOGO_DARK : IMAGE_LOGO_LIGHT;

    return (
        <>
            {isLink ? (
                <LogoAnchor href="/" {...rest}>
                    <Image src={buildImgUrl(logoImage)} alt="Totem Logo" width={width} />
                </LogoAnchor>
            ) : (
                <LogoContainer>
                    <Image src={buildImgUrl(logoImage)} alt="Totem Logo" width={width} />
                </LogoContainer>
            )}
        </>
    );
};

const LogoContainer = styled.div`
    display: ${(props) => (props.isInline ? 'inline-block' : 'block')};
    text-align: center;
    ${(props) => padding(props.theme.variables.padding * 2.7, props.theme.variables.padding * 1.5)};
`;

const LogoAnchor = styled.a`
    display: ${(props) => (props.isInline ? 'inline-block' : 'block')};
    text-align: center;
    ${(props) => padding(props.$noPadding ? 0 : props.theme.variables.padding * 1.5)};
    user-select: none;
`;
