import { FORMAT } from 'constants/date-constants';
import dayjs from 'dayjs';

export function mergeProductsAndProductsRequested({ products, productRequestedWithQuantities, deliveryDate }) {
    if (dayjs(deliveryDate, FORMAT).isBefore('2021-10-19')) {
        return [...products];
    }
    const updatedProducts = products.map((product) => {
        const requestedProduct = productRequestedWithQuantities.find((p) => p._id === product._id);

        if (requestedProduct) {
            const { quantityRequested } = requestedProduct;
            return {
                ...product,
                quantity: quantityRequested,
                portion: quantityRequested * (product.portion / product.quantity),
                price: quantityRequested * product.unitPrice,
            };
        } else {
            return product;
        }
    });
    const productsCompletelyRemovedFromProducts = productRequestedWithQuantities.filter(
        (product) => !products.some((p) => p._id === product._id),
    );
    productsCompletelyRemovedFromProducts.forEach(({ quantityRequested, ...product }) => {
        updatedProducts.push({
            ...product,
            price: product.unitPrice * quantityRequested,
            quantity: quantityRequested,
            quantityPicked: 0,
        });
    });
    return updatedProducts;
}
