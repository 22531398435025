import React from 'react';
import { Flex } from 'deprecated-enkel';
import styled from 'styled-components';
import { margin, padding, darken, lighten } from 'polished';

import { NutritionFacts } from '../../NutritionFacts';

import { Tag } from 'ui/Tag';
import { OptimizedImage } from 'components/OptimizedImage';

import { capitalize, getKeysWithTrueValues, decimals } from 'utils';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import { buildImgUrl } from 'utils/image/buildImgUrl';
import { roundPrice } from 'utils/orders';

import { Tooltip } from 'styles';
import { CardTagsStyle } from 'styles/components/card.styles';

export const ExtendedProductTabs = ({ product, info, setInfo }) => {
    const { width } = useWindowDimensions();
    const netWeight = product.weightWithoutPackaging
        ? Math.round(product.weightWithoutPackaging * 1000) + 'g'
        : product.volume;
    let productPrice = decimals(product.price, 2);

    if (product.displayKg) {
        productPrice = Number(productPrice / product.weightWithoutPackaging).toFixed(2);
    }

    const allergenList = {
        gluten: 'gluten',
        crustaceans: 'crustacées',
        eggs: 'oeufs',
        fish: 'poisson',
        peanuts: 'arachides',
        soybeans: 'soja',
        milk: 'lait',
        nuts: 'fruits à coque',
        celery: 'céleri',
        mustard: 'moutarde',
        sesame: 'sésame',
        sulphites: 'sulphites',
        lupin: 'lupin',
        molluscs: 'mollusques',
    };
    const allergensArray = getKeysWithTrueValues(product.allergen);
    const translatedAllergens = allergensArray.map((allergen) => allergenList[allergen]);
    const allergens = translatedAllergens.length ? capitalize(translatedAllergens.join(', ')) : 'Ø';
    const portion = product.displayKg ? product.portion / product.weightWithoutPackaging : product.portion;
    const portionPrice = roundPrice(product.price / product.portion);
    let ingredients = null;

    if (product.ingredients) {
        ingredients = capitalize(product.ingredients);
    }

    const badges = [...product.preferences, ...product.diets];
    if (product.newUntil) {
        badges.push({
            name: 'new',
            imageUrl: 'img/new.svg',
        });
    }

    const hasNutritionalValues = Object.keys(product.nutrition).some((key) => {
        if (product.nutrition[key] && ![product.nutrition.__typename, '0'].includes(product.nutrition[key])) {
            return true;
        }
        return false;
    });

    return (
        <>
            <Flex>
                <ProductDetailsTab onClick={() => setInfo('information')} active={info === 'information'}>
                    Information
                </ProductDetailsTab>
                {(product.ingredients || allergens !== 'Ø') && (
                    <ProductDetailsTab onClick={() => setInfo('ingredients')} active={info === 'ingredients'}>
                        Ingrédients et allergènes
                    </ProductDetailsTab>
                )}
                {hasNutritionalValues && (
                    <ProductDetailsTab onClick={() => setInfo('nutrition')} active={info === 'nutrition'}>
                        Valeurs nutritionnelles
                    </ProductDetailsTab>
                )}
                <ProductTabLine />
            </Flex>
            <TabStyle>
                {info === 'information' ? (
                    <>
                        <ProductInfoSubtitle>Description</ProductInfoSubtitle>
                        <ProductDescriptionStyle>{product.description}</ProductDescriptionStyle>
                        {width >= 1300 && (
                            <>
                                <ProductInfoSubtitle>Tags</ProductInfoSubtitle>
                                <ProductTagsStyle>
                                    {product.tags.map(
                                        ({ _id, name }) =>
                                            name && (
                                                <Tag extendedView key={_id}>
                                                    {name}
                                                </Tag>
                                            ),
                                    )}
                                    {product.isToBeArchived && (
                                        <Tag extendedView invertColor>
                                            Tout doit disparaître
                                        </Tag>
                                    )}
                                </ProductTagsStyle>
                                {badges && badges.length > 0 && (
                                    <>
                                        <ProductInfoSubtitle>Labels</ProductInfoSubtitle>
                                        <ProductBadgesStyle>
                                            {badges.map((badge) => (
                                                <React.Fragment key={badge._id}>
                                                    <OptimizedImage
                                                        data-for={`badge_${badge._id}`}
                                                        data-tip={badge.name}
                                                        key={badge._id}
                                                        src={buildImgUrl(badge.imageUrl)}
                                                    />
                                                    <Tooltip
                                                        id={`badge_${badge._id}`}
                                                        effect="solid"
                                                        type="dark"
                                                        place="bottom"
                                                    />
                                                </React.Fragment>
                                            ))}
                                        </ProductBadgesStyle>
                                    </>
                                )}
                            </>
                        )}
                        <ProductInfoSubtitle>Portion</ProductInfoSubtitle>
                        <ProductDescriptionStyle>
                            {product.displayKg ? `${Math.round(1000 / portion)}g` : `${portion} par pièce`}
                        </ProductDescriptionStyle>

                        <ProductInfoSubtitle>Colisage</ProductInfoSubtitle>
                        <ProductDescriptionStyle>
                            par {product.conditionningTotem} portion{product.conditionningTotem > 1 && 's'}
                        </ProductDescriptionStyle>

                        {netWeight && (
                            <>
                                <ProductInfoSubtitle>Poids net</ProductInfoSubtitle>
                                <ProductDescriptionStyle>{netWeight}</ProductDescriptionStyle>
                            </>
                        )}
                        {productPrice === portionPrice ? (
                            <>
                                <ProductInfoSubtitle>Prix</ProductInfoSubtitle>
                                <ProductDescriptionStyle bold>{productPrice}€</ProductDescriptionStyle>
                            </>
                        ) : (
                            <>
                                <ProductInfoSubtitle>Prix par portion</ProductInfoSubtitle>
                                <ProductDescriptionStyle bold>{portionPrice}€</ProductDescriptionStyle>
                                <ProductInfoSubtitle>
                                    Prix {product.displayKg ? 'au kilo' : 'à la pièce'}
                                </ProductInfoSubtitle>
                                <ProductDescriptionStyle bold>{productPrice}€</ProductDescriptionStyle>
                            </>
                        )}
                    </>
                ) : info === 'ingredients' ? (
                    <Flex $direction="row" $justifyContent="space-between">
                        <Flex $direction="column">
                            {product.ingredients && (
                                <>
                                    <ProductInfoSubtitle>Ingrédients</ProductInfoSubtitle>
                                    <ProductDescriptionStyle>{ingredients}</ProductDescriptionStyle>
                                </>
                            )}
                            <ProductInfoSubtitle>Allergènes</ProductInfoSubtitle>
                            <ProductDescriptionStyle>{allergens}</ProductDescriptionStyle>
                            {!!product?.allergen?.canContain && (
                                <>
                                    <ProductInfoSubtitle>Peut contenir des traces de</ProductInfoSubtitle>
                                    <ProductDescriptionStyle>{product.allergen.canContain}</ProductDescriptionStyle>
                                </>
                            )}
                        </Flex>
                    </Flex>
                ) : (
                    <NutritionFacts nutrition={product.nutrition} />
                )}
            </TabStyle>
        </>
    );
};

const TabStyle = styled.div`
    display: flex;
    flex-flow: column;
    ${(props) =>
        margin(
            props.theme.variables.margin,
            props.theme.variables.margin * 2,
            props.theme.variables.margin,
            props.theme.variables.margin,
        )};
    overflow: auto;
`;

export const ProductDetailsTab = styled.div`
    text-align: center;
    cursor: pointer;
    width: max-content;
    flex-shrink: 0;
    font-size: 1rem;
    color: ${(props) => darken(0.2, props.theme.colors.pantinGrey)};
    letter-spacing: 0.3px;
    ${(props) => padding(props.theme.variables.padding, props.theme.variables.padding * 1.75)};
    ${(props) => margin(props.theme.variables.margin, 0)};
    border-right: 1px solid ${(props) => props.theme.colors.borderGrey};
    border-top: 5px solid ${(props) => (props.active ? props.theme.colors.black : 'transparent')};
    background: ${(props) =>
        props.active ? props.theme.colors.white : lighten(0.05, props.theme.colors.culturedGrey)};
    transition: all 0.25s ease;
    ${(props) => props.last && 'border-top-right-radius: 3px'};

    &:hover {
        border-top-color: ${(props) => (props.active ? props.theme.colors.black : props.theme.colors.borderGrey)};
    }
`;

const ProductBadgesStyle = styled.div`
    ${(props) => margin(0, 0, props.theme.variables.margin, props.theme.variables.margin)};

    > img {
        width: 35px;
        ${(props) => margin(0, props.theme.variables.margin * 1.5, 0, 0)};
    }
`;

const ProductTabLine = styled.div`
    width: 100%;
    ${(props) => margin(props.theme.variables.margin, 0)};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderGrey};
    flex-shrink: 1;
`;

const ProductDescriptionStyle = styled.div`
    color: ${(props) => (props.bold ? props.theme.colors.black : props.theme.colors.pantinGrey)};
    ${(props) => props.bold && 'font-weight: 500'};
    font-size: 1.2em;
    letter-spacing: 0.4px;
    ${(props) => margin(0, 0, props.theme.variables.margin * 0.75, props.theme.variables.margin)};
`;

const ProductInfoSubtitle = styled.div`
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2px;

    ${(props) =>
        margin(
            props.theme.variables.margin * 0.25,
            0,
            props.theme.variables.margin * 0.75,
            props.theme.variables.margin,
        )};
    color: ${(props) => props.theme.colors.black};
`;

const ProductTagsStyle = styled(CardTagsStyle)`
    font-size: 1.35em;
    padding: 0;
    ${(props) =>
        margin(
            props.theme.variables.margin * 0.5,
            props.theme.variables.margin,
            props.theme.variables.margin * 0.5,
            props.theme.variables.margin,
        )};
`;
