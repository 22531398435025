import React, { useContext } from 'react';

import { UserContext } from 'constants/user-context';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

import { Tag } from 'ui/Tag';
import { AspectRatio, Flex } from 'deprecated-enkel';
import {
    Card,
    CardTitle,
    CardSubtitle,
    CardDescription,
    CardContent,
    CardActionButton,
    CardButtonGroup,
    CardTagsStyle,
    SuggestIcon,
    SuggestIconContainer,
} from 'styles/components/card.styles';

export const CardSuggestion = ({ currentCategory, setIsSuggestionPopupOpen }) => {
    const {
        user: {
            profile: { firstname },
        },
    } = useContext(UserContext);

    const handleOpenSuggestionPopup = () => () => {
        track(analyticsEvents.START_PRODUCT_SUGGESTION, {
            [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: currentCategory.title,
            [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: currentCategory._id,
        });
        setIsSuggestionPopupOpen(true);
    };

    return (
        <Card $noPadding>
            <AspectRatio
                size="100%"
                ratio="3:2"
                innerStyle={{
                    overflow: 'hidden',
                }}
            >
                <SuggestIconContainer onClick={handleOpenSuggestionPopup()}>
                    <SuggestIcon size="75px" />
                </SuggestIconContainer>
            </AspectRatio>
            <CardContent height="max-content" $paddingMultiplier={[0.5, 0.5, 0, 0.5]}>
                <CardTitle $vAlign="flex-start">Un seul produit vous manque...</CardTitle>
                <Flex $justifyContent="space-between" $alignItems="flex-start">
                    <CardSubtitle>Envie de nouveauté ? </CardSubtitle>
                </Flex>
            </CardContent>
            <CardTagsStyle>
                <Tag>Suggestions de {firstname}</Tag>
            </CardTagsStyle>
            <CardDescription>Dites-nous quels sont les produits que vous aimeriez retrouver ici !</CardDescription>
            <CardButtonGroup $borderRadius={[false, false, true, true]} $noBorder>
                <CardActionButton onClick={handleOpenSuggestionPopup()}>Suggérer</CardActionButton>
            </CardButtonGroup>
        </Card>
    );
};
