import { GET_ORDERS_OF_TOTEM } from 'data/queries';

export function orderUpdatedCacheHandler({ apolloClient, totemId, orderId, updatedOrder }) {
    const { ordersOfTotem } = apolloClient.readQuery({
        query: GET_ORDERS_OF_TOTEM,
        variables: { totemId },
    });

    const { recentOrders } = ordersOfTotem;
    const updatedOrders = [...recentOrders];
    const orderIds = updatedOrders.map((localOrder) => localOrder._id);
    const orderIndex = orderIds.indexOf(orderId);

    updatedOrders[orderIndex] = updatedOrder;

    apolloClient.writeQuery({
        query: GET_ORDERS_OF_TOTEM,
        variables: { totemId },
        data: { ordersOfTotem: { ...ordersOfTotem, recentOrders: updatedOrders } },
    });
}
