export const isProduction = window.__TOTENV__.ENV === 'prod';
export const isPreprod = window.__TOTENV__.ENV === 'preprod';
export const isDemo = window.__TOTENV__.ENV === 'demo';

const rawUrls = {
    prod: 'graphql.totem.co',
    preprod: 'graphql-test.totem.co',
    demo: 'graphql-demo.totem.co',
    default: 'localhost:4000',
};

export function formGraphQLUrl(isWS) {
    const protocol = isWS
        ? isProduction || isPreprod || isDemo
            ? 'wss://'
            : 'ws://'
        : isProduction || isPreprod || isDemo
          ? 'https://'
          : 'http://';

    if (isProduction) {
        return `${protocol}${rawUrls.prod}/graphql`;
    }

    if (isPreprod) {
        return `${protocol}${rawUrls.preprod}/graphql`;
    }

    if (isDemo) {
        return `${protocol}${rawUrls.demo}/graphql`;
    }

    return `${protocol}${rawUrls.default}/graphql`;
}
