import React, { useState, useContext, useEffect } from 'react';

import { Checkbox } from 'ui/Checkbox';

import { capitalize } from 'utils';

import {
    FiltersApply,
    FiltersCheckbox,
    FiltersColumn,
    FiltersSubColumn,
    FiltersDropdown,
    FiltersDropdownWrapper,
    FiltersTitle,
} from 'styles/pages/totem.styles';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

export const TotemFiltersDropdown = ({ context }) => {
    const {
        filter: { allFilterItems, filterTag, filterBrand, filterBadge, filterPrice },
        filterActions: { bulkSetSelectedFilters, toggleFilters },
    } = useContext(context);
    const [allFilters, setAllFilters] = useState(null);
    const [filters, setFilters] = useState(null);

    useEffect(() => {
        if (!allFilters) {
            setAllFilters(allFilterItems);
        }

        if (!filters) {
            setFilters({
                filterTag,
                filterBadge,
                filterPrice,
                filterBrand,
            });
        }
    }, [allFilters, filters, allFilterItems, filterTag, filterBrand, filterBadge, filterPrice]);

    const handleFilterSelect = (key, value) => () => {
        key = capitalize(key);
        const values = new Set([...filters[`filter${key}`]]);

        if (values.has(value)) {
            values.delete(value);
        } else {
            values.add(value);
        }

        setFilters({
            ...filters,
            [`filter${key}`]: [...values],
        });
    };

    const isFilterSelected = (filterId, key) => {
        key = capitalize(key);

        return filters[`filter${key}`] && filters[`filter${key}`].includes(filterId);
    };

    const applyFilters = () => {
        bulkSetSelectedFilters(filters);
        toggleFilters(false);
        const { filterBadge, filterBrand, filterPrice, filterTag } = filters;
        track(analyticsEvents.SET_PRODUCTS_FILTER, {
            [analyticsPropertiesConstants.PRODUCTS.FILTERS.HAS_BADGE_FILTER]: !!filterBadge.length,
            [analyticsPropertiesConstants.PRODUCTS.FILTERS.HAS_BRAND_FILTER]: !!filterBrand.length,
            [analyticsPropertiesConstants.PRODUCTS.FILTERS.HAS_PRICE_FILTER]: !!filterPrice.length,
            [analyticsPropertiesConstants.PRODUCTS.FILTERS.HAS_TAG_FILTER]: !!filterTag.length,
        });
    };

    const resetFilters = () => {
        const filtersReset = { filterBadge: [], filterTag: [], filterBrand: [], filterPrice: [] };

        setFilters(filtersReset);
        bulkSetSelectedFilters(filtersReset);
        toggleFilters(false);
    };

    const checkNestedArraysLength = (filtersBadges, filtersPrices, filterBrands, filtersTags) => {
        if (filtersBadges.length || filtersPrices.length || filterBrands.length || filtersTags.length) {
            return true;
        }
        return false;
    };

    const hasAtLeastOneFilterSelected =
        filters &&
        checkNestedArraysLength(filters.filterBadge, filters.filterPrice, filters.filterBrand, filters.filterTag);

    return (
        <FiltersDropdownWrapper>
            {allFilters && (
                <FiltersDropdown>
                    <FiltersColumn>
                        <FiltersTitle>Catégories</FiltersTitle>
                        <FiltersSubColumn>
                            {allFilters.tags.map((tag) => (
                                <FiltersCheckbox key={tag.value} onClick={handleFilterSelect('tag', tag.value)}>
                                    <Checkbox readOnly checked={isFilterSelected(tag.value, 'tag')} />
                                    <li>{tag.label}</li>
                                </FiltersCheckbox>
                            ))}
                        </FiltersSubColumn>
                    </FiltersColumn>
                    <FiltersColumn>
                        <FiltersTitle>Labels</FiltersTitle>
                        {allFilters.badges.map((badge) => (
                            <FiltersCheckbox key={badge.value} onClick={handleFilterSelect('badge', badge.value)}>
                                <Checkbox readOnly checked={isFilterSelected(badge.value, 'badge')} />
                                <li>{badge.label}</li>
                            </FiltersCheckbox>
                        ))}
                    </FiltersColumn>
                    <FiltersColumn>
                        <FiltersTitle>Marques</FiltersTitle>
                        <FiltersSubColumn>
                            {allFilters.brands.map((brand) => (
                                <FiltersCheckbox key={brand.value} onClick={handleFilterSelect('brand', brand.value)}>
                                    <Checkbox readOnly checked={isFilterSelected(brand.value, 'brand')} />
                                    <li>{brand.label}</li>
                                </FiltersCheckbox>
                            ))}
                        </FiltersSubColumn>
                    </FiltersColumn>
                </FiltersDropdown>
            )}
            {hasAtLeastOneFilterSelected && (
                <FiltersApply first onClick={resetFilters}>
                    Réinitialiser
                </FiltersApply>
            )}
            <FiltersApply onClick={applyFilters}>Appliquer</FiltersApply>
        </FiltersDropdownWrapper>
    );
};
