import React, { useContext, useState } from 'react';
import { useQuery, useMutation, useApolloClient, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { formatPrice } from '@totem/constants';
import { IconInfoCircle } from '@tabler/icons-react';

import { GET_PRODUCTS_STOCK, GET_LATEST_TERMS_RECORD } from 'data/queries';
import { CHECKOUT, DISCARD, UPDATE_ACCEPTED_TERMS, UPDATE_ORDER_WITH_STOCK_QUANTITIES, VALIDATE } from 'data/mutations';

import { colors } from 'styles/theme';
import { Tooltip } from 'styles';

import { BANK_TRANSFER } from 'constants/organization';
import { CREATED, INPROGRESS, VALIDATED } from 'constants/states-app';
import { PRODUCT_FLOW } from 'constants/product-constants';
import { UserContext } from 'constants/user-context';
import {
    ORDER_BAKERY_MIN_AMOUNT,
    ORDER_DRY_MIN_AMOUNT,
    ORDER_FRESH_MIN_AMOUNT,
    ORDER_FRUIT_MIN_AMOUNT,
    ORDER_MIN_AMOUNT,
} from 'constants/order';

import { TotemButton } from 'ui/Button';
import { StockWarningPopup } from 'pages/Totem/Aside/Basket/StockWarningPopup';
import { ProgressBar } from 'components/ProgressBar';

import { HandleNotification } from 'utils';
import { orderValidatedCacheHandler } from 'utils/cache/orderValidatedCacheHandler';
import { orderUpdatedCacheHandler } from 'utils/cache/orderUpdatedCacheHandler';
import { totemUpdatedCacheHandler } from 'utils/cache/totemUpdatedCacheHandler';
import { remainingTimeForValidation } from 'utils/time';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

import { TotemClosedDaysContext } from 'contexts/TotemClosedDaysContext';

export const BasketFooter = ({
    currentTotem,
    order,
    ordersOfTotem,
    setIsBillingAsideOpen,
    setIsDeliveryAccessInstructionsAsideOpen,
}) => {
    const navigate = useNavigate();

    const totemClosedDays = useContext(TotemClosedDaysContext);
    const { organization } = useContext(UserContext);

    const [isValidationLoading, setIsValidationLoading] = useState(false);
    const [getProductsStockCounter, setGetProductsStockCounter] = useState(0);

    const apolloClient = useApolloClient();

    const { data: termsRecordData, loading: areTermsRecordLoading } = useQuery(GET_LATEST_TERMS_RECORD);
    const [discard] = useMutation(DISCARD);
    const [updateOrderWithStockQuantities] = useMutation(UPDATE_ORDER_WITH_STOCK_QUANTITIES);
    const [updateAcceptedTerms] = useMutation(UPDATE_ACCEPTED_TERMS);
    const [checkout, { loading: isCheckoutLoading }] = useMutation(CHECKOUT);
    const [validate, { loading: isValidateLoading }] = useMutation(VALIDATE);

    async function handleStockAdjustment(productsFromOrderWithInsufficientQuantity, toastId) {
        const productsStockToUpdate = productsFromOrderWithInsufficientQuantity.map(({ productId, availableStock }) => {
            return { productId, availableStock };
        });
        const {
            data: { updateOrderWithStockQuantities: updatedOrder },
        } = await updateOrderWithStockQuantities({
            variables: {
                orderId: order._id,
                productsStockToUpdate,
            },
        });

        toast.remove(toastId);
        toast.success('Les quantités de produits ont été mises à jour', {
            duration: 2000,
        });
        orderUpdatedCacheHandler({ apolloClient, totemId: currentTotem._id, orderId: order._id, updatedOrder });
    }

    function handleProductsStock(data) {
        const { productsStock: productsStockData } = data;
        setGetProductsStockCounter(getProductsStockCounter + 1);
        const insufficientProducts = productsStockData.reduce((acc, stock) => {
            const { productId, availableStock, imageUrl, brand, volume, displayKg, weightWithoutPackaging } = stock;
            const orderProduct = order.products.find((product) => product._id === productId);
            const { name, quantity: requestedQuantity } = orderProduct;
            // stock online can be negative, but we want to avoid having negative quantities in the order
            const correctedStockOnline = availableStock < 0 ? 0 : availableStock;
            if (requestedQuantity > correctedStockOnline) {
                acc.push({
                    requestedQuantity,
                    productId,
                    name,
                    brand,
                    volume,
                    imageUrl,
                    availableStock: correctedStockOnline,
                    displayKg,
                    weightWithoutPackaging,
                });
            }
            return acc;
        }, []);
        if (!insufficientProducts.length) {
            hasConfirmedTotemOrders ? handleOrderValidation() : handleOrderCheckout();
        } else {
            toast.custom(
                (t) => (
                    <StockWarningPopup
                        productsWithInsufficientQuantity={insufficientProducts}
                        confirmStock={() => handleStockAdjustment(insufficientProducts, t.id)}
                        handleClose={() => {
                            toast.remove(t.id);
                        }}
                    />
                ),
                {
                    duration: Infinity,
                },
            );
        }
        setIsValidationLoading(false);
    }
    const [getProductsStock, { loading: isProductsStockLoading }] = useLazyQuery(GET_PRODUCTS_STOCK, {
        onError: (error) => HandleNotification(error),
        onCompleted: handleProductsStock,
        fetchPolicy: 'no-cache',
    });

    const { hasConfirmedTotemOrders } = ordersOfTotem;
    const isLoading = isValidateLoading || isProductsStockLoading || isCheckoutLoading || areTermsRecordLoading;
    const hasOrganizationAcceptedLastTerms =
        organization.acceptedTerms?.termsRecordId === termsRecordData?.latestTermsRecord._id;
    const orderCanBeDiscarded =
        !order.isPunctual &&
        (order.stateHistory.some(({ state }) => state === VALIDATED) || order.first_order === false) &&
        order.state === INPROGRESS;

    const orderMinAmount = currentTotem?.minimumOrderAmountInfo.minimumTotalAmount ?? ORDER_MIN_AMOUNT;

    const bakeryMinAmount = currentTotem?.minimumOrderAmountInfo.minimumBakeryAmount ?? ORDER_BAKERY_MIN_AMOUNT;
    const dryMinAmount = currentTotem?.minimumOrderAmountInfo.minimumDryAmount ?? ORDER_DRY_MIN_AMOUNT;
    const freshMinAmount = currentTotem?.minimumOrderAmountInfo.minimumFreshAmount ?? ORDER_FRESH_MIN_AMOUNT;
    const fruitMinAmount = currentTotem?.minimumOrderAmountInfo.minimumFruitAmount ?? ORDER_FRUIT_MIN_AMOUNT;

    const freshAmount = order.products
        .filter(({ flow }) => flow === PRODUCT_FLOW.FRESH)
        .reduce((acc, product) => acc + product.unitPrice * product.quantity, 0);
    const dryAmount = order.products
        .filter(({ flow }) => flow === PRODUCT_FLOW.DRY)
        .reduce((acc, product) => acc + product.unitPrice * product.quantity, 0);
    const fruitAmount = order.products
        .filter(({ flow }) => flow === PRODUCT_FLOW.FRUIT)
        .reduce((acc, product) => acc + product.unitPrice * product.quantity, 0);
    const bakeryAmount = order.products
        .filter(({ flow }) => flow === PRODUCT_FLOW.BAKERY)
        .reduce((acc, product) => acc + product.unitPrice * product.quantity, 0);

    const orderMinAmountsTooltipText =
        "Un minimum de commande est imposé par catégorie afin d'éviter le transport de caisses de livraison peu remplies. Aidez nous à limiter les émissions de CO2 en regroupant vos achats de produits fruits/épicerie/frais/matin.";

    const hasOrderMinAmounts =
        order.prices.price >= orderMinAmount &&
        (!freshAmount || freshAmount >= freshMinAmount) &&
        (!dryAmount || dryAmount >= dryMinAmount) &&
        (!fruitAmount || fruitAmount >= fruitMinAmount) &&
        (!bakeryAmount || bakeryAmount >= bakeryMinAmount);

    const { fullString: remainingTimeForValidationAsFullString, validationLimitDateIsPast } =
        remainingTimeForValidation({
            deliveryDate: order.date_delivery,
            firstOrder: order.first_order,
            hasBeenValidated: order.stateHistory.some(({ state }) => state === VALIDATED),
            isPunctual: order.isPunctual,
            state: order.state,
            totemClosedDays,
        });

    async function handleOrderValidation() {
        try {
            const {
                data: {
                    validate: { order: updatedOrder, organization: updatedOrganization },
                },
            } = await validate({
                variables: {
                    orderId: order._id,
                },
            });
            track(analyticsEvents.VALIDATE_ORDER, {
                [analyticsPropertiesConstants.ORDER.IS_PUNCTUAL]: order.isPunctual,
            });

            orderValidatedCacheHandler({
                apolloClient,
                orderId: order._id,
                totemId: currentTotem._id,
                updatedOrder,
                updatedOrganization,
            });

            toast.success(`Votre commande est validée et vous sera livrée le ${order.date_delivery}`, {
                duration: 4000,
            });
            console.log(currentTotem._id);
            console.log('should navigate to', `/totem/${currentTotem._id}`);
            navigate(`/totem/${currentTotem._id}`, { replace: true, relative: 'path' });
            setIsValidationLoading(false);
            return;
        } catch (error) {
            setIsValidationLoading(false);
            HandleNotification(error);
        }
    }

    async function handleOrderCheckout() {
        try {
            const {
                data: {
                    checkout: { order: updatedOrder, organization: updatedOrganization, totem: updatedTotem },
                },
            } = await checkout({
                variables: {
                    data: {
                        orderId: order._id,
                        oldState: order.state,
                    },
                },
            });
            orderValidatedCacheHandler({
                apolloClient,
                orderId: order._id,
                totemId: currentTotem._id,
                updatedOrder,
                updatedOrganization,
            });
            totemUpdatedCacheHandler({
                apolloClient,
                currentTotem,
                totemUpdatedValues: updatedTotem,
            });
            track(analyticsEvents.CONFIRM_ORDER, {
                [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.CHECKOUT_BASKET,
                [analyticsPropertiesConstants.ORDER.IS_PUNCTUAL]: order.isPunctual,
            });
            toast.success(`Votre commande est validée et vous sera livrée le ${order.date_delivery}`, {
                duration: 4000,
            });
            navigate(`/totem/${currentTotem._id}`, { relative: 'path' });
            setIsValidationLoading(false);
        } catch (error) {
            setIsValidationLoading(false);
            HandleNotification(error);
        }
    }

    async function handleNext() {
        try {
            if (!isValidationLoading) {
                setIsValidationLoading(true);
                const productIds = order.products.map((product) => product._id);
                // getProductStock => set insufficient products (if any) and show popup, if validated => handleProductsStock => validateProducts
                getProductsStock({
                    variables: {
                        productIds,
                        orderId: order._id,
                        getProductsStockCounter, //dirty fix to make lazyQuery fetch with same parameters twice
                    },
                });
            }
            // user is redirected to the dashboard page from the call to `getProductsStock` mutation
            if (!hasOrganizationAcceptedLastTerms) {
                await updateAcceptedTerms({
                    variables: {
                        hasAcceptedTerms: true,
                    },
                });
            }
            return;
        } catch (error) {
            HandleNotification(error);
        }
    }

    async function handleDiscardOrderChanges() {
        if (!order.isPunctual) {
            const {
                data: { discard: updatedOrder },
            } = await discard({
                variables: {
                    orderId: order._id,
                },
            });
            orderUpdatedCacheHandler({ apolloClient, totemId: currentTotem._id, orderId: order._id, updatedOrder });
            navigate(`/totem/${currentTotem._id}`, { relative: 'path' });
            return;
        }
    }

    const hasDefinedDeliveryInstructions = !!currentTotem.address && !!currentTotem.address_details;
    const { billingInfo } = organization;
    const hasDefinedBillingInfo = !!(
        billingInfo?.invoiceName &&
        billingInfo?.invoiceEmail &&
        billingInfo?.invoiceAddress &&
        billingInfo?.invoiceZipcode &&
        billingInfo?.invoiceCity &&
        billingInfo?.invoiceCountry
    );
    const hasAddedAPaymentMethod =
        !!organization.billingInfo?.defaultPaymentMethod || organization.paymentType === BANK_TRANSFER;
    return (
        <>
            <FooterContainer>
                {[CREATED, INPROGRESS].includes(order.state) && !validationLimitDateIsPast && (
                    <FooterInfoText>{remainingTimeForValidationAsFullString}</FooterInfoText>
                )}
                {order.state === VALIDATED && (
                    <FooterInfoText>
                        Votre commande est validée et vous sera livrée le {order.date_delivery}
                    </FooterInfoText>
                )}
                {hasDefinedDeliveryInstructions && hasAddedAPaymentMethod ? (
                    <>
                        {fruitAmount && fruitAmount < fruitMinAmount ? (
                            <MinAmountContainer>
                                <MinAmountTextContainer>
                                    <MinAmountText color={colors.lightRed}>
                                        Montant minimum <MinAmountTextBold>fruits</MinAmountTextBold> non atteint :{' '}
                                        {formatPrice(fruitAmount, { withoutSpace: true })}/
                                        {formatPrice(fruitMinAmount, { withoutSpace: true })}
                                    </MinAmountText>
                                    <TooltipIcon
                                        color={colors.pantinGrey}
                                        data-tip={orderMinAmountsTooltipText}
                                        data-for="tooltipMinAmountFruit"
                                        size={20}
                                    />
                                    <TooltipComponent
                                        id="tooltipMinAmountFruit"
                                        type="dark"
                                        effect="solid"
                                        place="right"
                                        multiline={true}
                                    />
                                </MinAmountTextContainer>
                                <ProgressBar max={fruitMinAmount} value={fruitAmount} $isErrorStyle />
                            </MinAmountContainer>
                        ) : null}
                        {dryAmount && dryAmount < dryMinAmount ? (
                            <MinAmountContainer>
                                <MinAmountTextContainer>
                                    <MinAmountText color={colors.lightRed}>
                                        Montant minimum <MinAmountTextBold>épicerie</MinAmountTextBold> non atteint :{' '}
                                        {formatPrice(dryAmount, { withoutSpace: true })}/
                                        {formatPrice(dryMinAmount, { withoutSpace: true })}
                                    </MinAmountText>
                                    <TooltipIcon
                                        color={colors.pantinGrey}
                                        data-tip={orderMinAmountsTooltipText}
                                        data-for="tooltipMinAmountDry"
                                        size={20}
                                    />
                                    <TooltipComponent
                                        id="tooltipMinAmountDry"
                                        type="dark"
                                        effect="solid"
                                        place="right"
                                        multiline={true}
                                    />
                                </MinAmountTextContainer>
                                <ProgressBar max={dryMinAmount} value={dryAmount} $isErrorStyle />
                            </MinAmountContainer>
                        ) : null}
                        {freshAmount && freshAmount < freshMinAmount ? (
                            <MinAmountContainer>
                                <MinAmountTextContainer>
                                    <MinAmountText color={colors.lightRed}>
                                        Montant minimum <MinAmountTextBold>produits frais</MinAmountTextBold> non
                                        atteint : {formatPrice(freshAmount, { withoutSpace: true })}/
                                        {formatPrice(freshMinAmount, { withoutSpace: true })}
                                    </MinAmountText>
                                    <TooltipIcon
                                        color={colors.pantinGrey}
                                        data-tip={orderMinAmountsTooltipText}
                                        data-for="tooltipMinAmountFresh"
                                        size={20}
                                    />
                                    <TooltipComponent
                                        id="tooltipMinAmountFresh"
                                        type="dark"
                                        effect="solid"
                                        place="right"
                                        multiline={true}
                                    />
                                </MinAmountTextContainer>
                                <ProgressBar max={freshMinAmount} value={freshAmount} $isErrorStyle />
                            </MinAmountContainer>
                        ) : null}
                        {bakeryAmount && bakeryAmount < bakeryMinAmount ? (
                            <MinAmountContainer>
                                <MinAmountTextContainer>
                                    <MinAmountText color={colors.lightRed}>
                                        Montant minimum <MinAmountTextBold>matin</MinAmountTextBold> non atteint :{' '}
                                        {formatPrice(bakeryAmount, { withoutSpace: true })}/
                                        {formatPrice(bakeryMinAmount, { withoutSpace: true })}
                                    </MinAmountText>
                                    <TooltipIcon
                                        color={colors.pantinGrey}
                                        data-tip={orderMinAmountsTooltipText}
                                        data-for="tooltipMinAmountBakery"
                                        size={20}
                                    />
                                    <TooltipComponent
                                        id="tooltipMinAmountBakery"
                                        type="dark"
                                        effect="solid"
                                        place="right"
                                        multiline={true}
                                    />
                                </MinAmountTextContainer>
                                <ProgressBar max={bakeryMinAmount} value={bakeryAmount} $isErrorStyle />
                            </MinAmountContainer>
                        ) : null}
                        {order.prices.price < orderMinAmount ? (
                            <MinAmountContainer>
                                <MinAmountText color={colors.lightRed}>
                                    Montant minimum <MinAmountTextBold>des produits</MinAmountTextBold> non atteint :{' '}
                                    {formatPrice(order.prices.price, { withoutSpace: true })}/
                                    {formatPrice(orderMinAmount, { withoutSpace: true })}
                                </MinAmountText>
                                <ProgressBar max={orderMinAmount} value={order.prices.price} $isErrorStyle />
                            </MinAmountContainer>
                        ) : null}
                    </>
                ) : null}
                {[CREATED, INPROGRESS].includes(order.state) &&
                    (!hasDefinedDeliveryInstructions ? (
                        <TotemButton
                            $alignSelf="stretch"
                            $margins={[1, 0, 0.5, 0]}
                            $fontSize="1em"
                            data-test="set-delivery-info"
                            onClick={() => setIsDeliveryAccessInstructionsAsideOpen(true)}
                        >
                            Définir les instructions de livraison
                        </TotemButton>
                    ) : !(hasAddedAPaymentMethod && hasDefinedBillingInfo) ? (
                        <TotemButton
                            $alignSelf="stretch"
                            $margins={[1, 0, 0.5, 0]}
                            $fontSize="1em"
                            data-test="add-payment-method"
                            onClick={() => setIsBillingAsideOpen(true)}
                        >
                            Ajouter les informations de facturation
                        </TotemButton>
                    ) : (
                        <>
                            {orderCanBeDiscarded && (
                                <TotemButton
                                    $alignSelf="stretch"
                                    $margins={[0.5, 0]}
                                    $type="secondary"
                                    $fontSize="1em"
                                    data-test="discard-order-changes"
                                    onClick={handleDiscardOrderChanges}
                                >
                                    {isLoading ? <ClipLoader color="white" size="16px" /> : 'Annuler les modifications'}
                                </TotemButton>
                            )}
                            <TotemButton
                                $alignSelf="stretch"
                                $margins={[1, 0, 0.5, 0]}
                                $fontSize="1em"
                                disabled={isLoading || !hasOrderMinAmounts}
                                data-test="validate-order"
                                onClick={handleNext}
                            >
                                {isLoading ? (
                                    <ClipLoader color="white" size="16px" />
                                ) : orderCanBeDiscarded ? (
                                    'Valider les modifications'
                                ) : (
                                    'Valider ma commande'
                                )}
                            </TotemButton>
                        </>
                    ))}
                {/* new terms validation */}
                {organization.billingInfo?.defaultPaymentMethod &&
                    [CREATED, INPROGRESS].includes(order.state) &&
                    !hasOrganizationAcceptedLastTerms && (
                        <CheckBoxLine>
                            <FooterInfoText>
                                En validant ma commande, j'accepte les{' '}
                                <a
                                    href={
                                        termsRecordData?.latestTermsRecord?.termsOfUseUrl &&
                                        termsRecordData.latestTermsRecord.termsOfUseUrl
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {hasConfirmedTotemOrders && 'nouvelles '}conditions générales de vente
                                </a>
                            </FooterInfoText>
                        </CheckBoxLine>
                    )}
            </FooterContainer>
        </>
    );
};

const FooterContainer = styled.footer`
    background: white;
    box-shadow: rgb(123 104 104 / 20%) 0px -4px 1rem;
    z-index: 2147483003;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FooterInfoText = styled.div`
    font-size: 0.9rem;
    text-align: center;
    padding: 0.5rem 0 0.5rem 0;
    color: ${({ color }) => color};
    a {
        text-decoration: underline;
    }
`;

const MinAmountContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

const MinAmountTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
`;

const MinAmountText = styled.div`
    font-size: 1rem;
    text-align: center;
    color: ${({ color }) => color};
`;

const MinAmountTextBold = styled.span`
    font-weight: 800;
`;

const TooltipIcon = styled(IconInfoCircle)`
    margin-left: 5px;
`;

const TooltipComponent = styled(Tooltip)`
    max-width: 50%;
`;

const CheckBoxLine = styled.div`
    display: flex;
    align-items: end;
`;
