import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Flex } from 'deprecated-enkel';
import { AuthContext } from '@totem/auth';

import { SET_LOGIN } from 'data/mutations';

import { client } from 'index';

import { TotemButton } from 'ui/Button';
import { InlineError } from 'ui/InlineError';

import { Logo } from 'components/Logo';

import { analyticsEvents, identify, track } from 'tracking/segment';

import { validateEmail, HandleNotification } from 'utils';

import { TotemLabel, TotemInput } from 'styles';
import {
    LoginBottomHalfStyle,
    LoginFormStyle,
    LoginStyle,
    LoginTopHalfStyle,
    LoginWrapperStyle,
    LoginText,
    BorderBottomLink,
    BorderBottomText,
    RecoverPasswordLink,
} from 'styles/pages/login.styles';
import { SignupTitleStyle } from 'styles/pages/signup.styles';

export const Login = () => {
    const {
        authActions: { login },
        auth: { loading },
    } = useContext(AuthContext);
    const [loginHandler] = useMutation(SET_LOGIN);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const loginMutationHandler = async (data) => {
        try {
            const {
                data: { signin },
                errors,
            } = await loginHandler(data);
            identify({
                createdAt: signin?.user?.createdAt / 1000,
                email: signin?.user?.username,
                id: signin?.user?._id,
                name: `${signin?.user?.profile.firstname} ${signin?.user?.profile.lastname}`,
            });
            track(analyticsEvents.LOG_IN);
            return {
                ...signin,
                errors,
            };
        } catch (error) {
            HandleNotification(error);
        }
    };

    window.TOTEM.auth.customLogin = loginMutationHandler;

    const handleLogin = async ({ email, password }) => {
        client.clearStore();
        const trimmedEmail = email && email.trim();
        await login({ variables: { email: trimmedEmail, password } });
    };

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <LoginBottomHalfStyle>
                <LoginWrapperStyle>
                    {loading ? (
                        <LoginText>Connexion à votre espace en cours...</LoginText>
                    ) : (
                        <>
                            <SignupTitleStyle>Heureux de vous revoir</SignupTitleStyle>
                            <LoginFormStyle onSubmit={handleSubmit(handleLogin)}>
                                <TotemLabel color="white">
                                    <span>Email Professionnel</span>
                                    <TotemInput
                                        placeholder="Entrez votre email pro"
                                        autoFocus
                                        data-test="login-username"
                                        {...register('email', {
                                            required: true,
                                            validate: validateEmail('Votre mail n’est pas valide'),
                                        })}
                                    />
                                    <InlineError display={errors.email} />
                                </TotemLabel>
                                <TotemLabel color="white">
                                    <TotemLabelLine>
                                        {' '}
                                        <span>Mot de passe</span>
                                        <RecoverPasswordLink to="/recover-password">
                                            Mot de passe oublié ?
                                        </RecoverPasswordLink>
                                    </TotemLabelLine>

                                    <TotemInput
                                        placeholder="Entrez votre mot de passe"
                                        type="password"
                                        data-test="login-password"
                                        {...register('password', { required: true })}
                                    />

                                    <InlineError display={errors.password} />
                                </TotemLabel>
                                <Flex $alignItems="center" $direction="column">
                                    <TotemButton $margins={[1, 0]} type="submit" data-test="login-submit">
                                        Se connecter
                                    </TotemButton>
                                    <BorderBottomText>
                                        Nouveau chez TOTEM ?{' '}
                                        <BorderBottomLink to="/signup">Créer un compte</BorderBottomLink>
                                    </BorderBottomText>
                                </Flex>
                            </LoginFormStyle>
                        </>
                    )}
                </LoginWrapperStyle>
            </LoginBottomHalfStyle>
        </LoginStyle>
    );
};

const TotemLabelLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
