import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Flex } from 'deprecated-enkel';
import { ClipLoader } from 'react-spinners';

import { SIGNUP } from 'data/mutations';

import { Logo } from 'components/Logo';
import { PasswordStrength } from 'components/PasswordStrength';

import { TotemButton } from 'ui/Button';
import { InlineError } from 'ui/InlineError';

import { HandleNotification, parseQueryStrings, validateEmail } from 'utils';

import {
    LoginTopHalfStyle,
    LoginBottomHalfStyle,
    BorderBottomLink,
    BorderBottomText,
    LoginStyle,
} from 'styles/pages/login.styles';
import { TotemLabel, TotemInput } from 'styles';
import { SignupWrapperStyle, SignupFormStyle, SignupTitleStyle } from 'styles/pages/signup.styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '@totem/auth';

import { analyticsEvents, analyticsPropertiesConstants, identify, track } from 'tracking/segment';

export const Signup = () => {
    const { search } = useLocation();
    const searchParams = parseQueryStrings(search);
    const {
        authActions: { signup },
    } = useContext(AuthContext);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        watch,
    } = useForm({
        defaultValues: { email: searchParams?.email || '' },
    });
    const dirtyPassword = watch('password', '');

    const [signupHandler] = useMutation(SIGNUP);

    const signupMutationHandler = async (data) => {
        try {
            const {
                data: { signup },
                errors,
            } = await signupHandler(data);
            identify({
                createdAt: signup?.user?.createdAt / 1000,
                email: signup?.user?.username,
                id: signup?.user?._id,
            });

            return {
                ...signup,
                errors,
            };
        } catch (error) {
            HandleNotification(error);
        }
    };

    window.TOTEM.auth.customSignup = signupMutationHandler;

    const handleSignup = async ({ email, password }) => {
        const mediumRegex = new RegExp(
            '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
        );

        if (!password.match(mediumRegex)) {
            const message = 'Essayez un mot de passe avec une majuscule et au moins 6 caractères';
            setError('password', { type: 'weakPassword', message });
            return;
        }

        const trimmedEmail = email.trim();

        const {
            gclid = '',
            userReferrerId = '',
            utm_term: utmTerm = '',
            utm_source: utmSource = '',
            utm_medium: utmMedium = '',
            utm_campaign: utmCampaign = '',
            utm_content: utmContent = '',
        } = searchParams;
        try {
            await signup({
                variables: {
                    data: {
                        password,
                        email: trimmedEmail,
                        gclid,
                        utmTerm,
                        utmSource,
                        utmMedium,
                        utmCampaign,
                        utmContent,
                        userReferrerId,
                    },
                },
            });
            track(analyticsEvents.SIGNUP, {
                [analyticsPropertiesConstants.SIGNUP.UTM_CAMPAIN]: utmCampaign,
                [analyticsPropertiesConstants.SIGNUP.UTM_CONTENT]: utmContent,
                [analyticsPropertiesConstants.SIGNUP.UTM_MEDIUM]: utmMedium,
                [analyticsPropertiesConstants.SIGNUP.UTM_SOURCE]: utmSource,
                [analyticsPropertiesConstants.SIGNUP.UTM_TERM]: utmTerm,
            });
            navigate('/signup/2');
        } catch (error) {
            window.Log.error(error);
            throw error;
        }
    };

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <LoginBottomHalfStyle>
                <SignupWrapperStyle $transparentize>
                    <SignupTitleStyle>S'inscrire</SignupTitleStyle>
                    <SignupFormStyle onSubmit={handleSubmit(handleSignup)}>
                        <TotemLabel color="white">
                            Email Professionnel <InlineError display={errors.email} />
                            <TotemInput
                                placeholder="Entrez votre email pro"
                                autoFocus
                                data-test="signup-username"
                                {...register('email', {
                                    required: true,
                                    validate: validateEmail('Votre mail n’est pas valide'),
                                })}
                            />
                        </TotemLabel>
                        <TotemLabel color="white">
                            Mot de passe <InlineError display={errors.password} />
                            <TotemInput
                                placeholder="Entrez votre mot de passe"
                                type="password"
                                data-test="signup-password"
                                {...register('password', {
                                    required: true,
                                })}
                            />
                            <PasswordStrength input={dirtyPassword} />
                        </TotemLabel>
                        <Flex $alignItems="center" $direction="column">
                            <TotemButton
                                disabled={isSubmitting}
                                $margins={[1, 0, 1, 0]}
                                type="submit"
                                data-test="signup-submit"
                            >
                                {isSubmitting ? <ClipLoader color="white" size="16px" /> : "S'inscrire"}
                            </TotemButton>
                            <BorderBottomText>
                                Vous avez déjà un compte ?{' '}
                                <BorderBottomLink to="/login">Connectez-vous</BorderBottomLink>
                            </BorderBottomText>
                        </Flex>
                    </SignupFormStyle>
                </SignupWrapperStyle>
            </LoginBottomHalfStyle>
        </LoginStyle>
    );
};
