import React, { useContext } from 'react';
import { IconEditCircle } from '@tabler/icons-react';

import { Tooltip } from 'styles';

import { IconButton } from 'ui/Button';

import { remainingTimeForValidation } from 'utils/time';
import { TotemClosedDaysContext } from 'contexts/TotemClosedDaysContext';

export const DashboardOrderModifyButton = ({
    orderTestType,
    onClick,
    deliveryDate,
    firstOrder,
    hasBeenValidated,
    isPunctual,
    state,
}) => {
    const totemClosedDays = useContext(TotemClosedDaysContext);

    const { fullString, validationLimitDateIsPast } = remainingTimeForValidation({
        deliveryDate,
        firstOrder,
        hasBeenValidated,
        isPunctual,
        state,
        totemClosedDays,
    });
    return (
        <>
            <IconButton
                data-test={`adjust-${orderTestType}-order-button`}
                data-tip={!validationLimitDateIsPast ? fullString : undefined}
                data-for="countDownToolTip"
                onClick={onClick}
            >
                <IconEditCircle stroke={2.5} />
            </IconButton>
            <Tooltip id="countDownToolTip" type="dark" effect="solid" place="left" />
        </>
    );
};
