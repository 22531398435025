import styled, { css } from 'styled-components';
import { margin, padding, darken } from 'polished';
import { animated } from 'react-spring';
import { Flex, Heading, Button } from 'deprecated-enkel';
import { Link } from 'react-router-dom';

import { ClockOutline, RepeatOutline } from 'styled-icons/evaicons-outline';
import { ArrowBackCircle } from 'styled-icons/ionicons-outline';
import { MagnifyingGlass } from 'styled-icons/entypo';

export const MagnifyingGlassIcon = styled(MagnifyingGlass)`
    color: ${({ color, theme: { colors } }) => colors[color || 'green']};
    margin-right: 5px;
`;

export const PunctualIcon = styled(ClockOutline)`
    color: ${({ color, theme: { colors } }) => colors[color || 'green']};
    margin-right: 5px;
`;

export const RecurringIcon = styled(RepeatOutline)`
    color: ${({ color, theme: { colors } }) => colors[color || 'green']};
    margin-right: 5px;
`;

export const TotemStyle = styled(animated.div)`
    display: flex;
    align-items: flex-start;
    height: 100%;
`;

export const TotemInactiveStyle = styled(Flex.Style)`
    height: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.black};
`;

export const TotemInactiveHeading = styled(Flex.Style)`
    align-items: center;
    font-size: 2.25rem;
    margin: 0;
    text-transform: uppercase;

    > h2 {
        ${(props) => margin(0, 0, 0, props.theme.variables.margin)};
    }
`;

export const TotemInactiveSpan = styled.span`
    font-size: 1.25rem;
    ${(props) => margin(0, 0, props.theme.variables.margin)};

    &:last-of-type {
        ${(props) => margin(0, 0, props.theme.variables.margin * 2)};
    }
`;

export const TotemNavStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: ${(props) => props.theme.variables.specificNavbarWidth}px;
    height: 100%;
    color: ${(props) => props.theme.colors.light};
    font-size: 1.1em;
    background: white;
    box-shadow: 0px 1px 20px 1px ${(props) => props.theme.colors.lightGrey};
    z-index: 2;
`;

export const TotemNavInner = styled(animated.div)`
    display: flex;
    flex-flow: column;
    flex: 1;
    overflow-y: auto;
`;

export const TotemNavItemPlaceholder = styled(Flex.Style)`
    flex-shrink: 0;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || 'flex-start'};
    height: ${(props) => props.height};
    ${(props) =>
        padding(
            props.theme.variables.padding * 1.3,
            props.theme.variables.padding,
            props.theme.variables.padding * 1.3,
            props.theme.variables.padding * 1.8,
        )};
    color: ${(props) => props.theme.colors.pantinGrey};
    text-decoration: none;
    user-select: none;
    cursor: default;
`;

export const TotemNavLink = styled(Link)`
    display: flex;
    ${(props) => props.$spaceBetween && 'justify-content: space-between'};
    flex-shrink: 0;
    align-items: center;
    ${(props) => props.$recap && 'justify-content: space-between'};
    height: ${(props) => props.height};
    ${(props) =>
        padding(
            props.theme.variables.padding * 1.3,
            props.theme.variables.padding,
            props.theme.variables.padding * 1.3,
            props.theme.variables.padding * 1.8,
        )};
    color: ${(props) =>
        props.$isSelected
            ? props.theme.colors.black
            : props.$isNotInOrder
              ? props.theme.colors.pantinGrey
              : props.theme.colors.black};
    ${(props) => props.$isSelected && `background: ${props.theme.colors.green}`};
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s ease;

    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }

    &:hover {
        color: ${(props) => props.theme.colors.black};
        background: ${(props) => props.theme.colors.green};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            &:hover {
                color: ${(props) => props.theme.colors.white};
                background-color: ${(props) => props.theme.colors.black};
            }
        `};

    > img {
        padding-bottom: 3px;
    }
`;

export const TotemNavCategoriesScrollable = styled.div`
    overflow-y: auto;
`;

export const TotemNavCategories = styled.div`
    display: flex;
    flex-flow: column;
    flex-shrink: 1;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const TotemNavFooter = styled.div`
    margin-top: auto;
    color: ${({ theme }) => theme.colors.black};
`;

export const TotemCategoryStyle = styled.div`
    position: relative;
    flex-shrink: 1;
    width: 100%;
    height: 100%;
`;

export const TotemCategoryInner = styled(animated.div)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    background: ${(props) => props.theme.colors.light};
`;

export const TotemProductsInnerTitleStyle = styled.h2`
    font-size: 1.35rem;
    font-weight: 500;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderGrey};
    color: ${(props) => props.theme.colors.black};
    ${(props) => padding(0, 0, props.theme.variables.padding, 0)};
    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};
`;

export const TotemProductsInnerStyle = styled(Flex.Style)`
    ${(props) => padding(props.theme.variables.padding)};
`;

export const TotemLoadingStyle = styled.div`
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.colors.culturedGrey};
`;

export const FiltersApply = styled(Button.Style)`
    position: absolute;
    bottom: 0;
    right: ${(props) => (props.first ? '80px' : 0)};
    font-weight: 800;
    font-size: 0.8rem;
    text-transform: uppercase;
    background-color: transparent;
    color: ${(props) => (props.first ? props.theme.colors.pantinGrey : props.theme.colors.black)};
    ${(props) => margin(props.theme.variables.margin * 0.5)};

    &:hover {
        background-color: transparent;
        color: ${(props) => props.theme.colors.black};
    }
`;

export const FiltersCheckbox = styled(Flex.Style)`
    cursor: pointer;
    ${(props) => margin(props.theme.variables.margin * 0.75, 0)};
`;

export const FiltersSubColumn = styled(Flex.Style)`
    max-height: 500px;
    flex-flow: column;
    overflow: auto;
    ${(props) => padding(0, props.theme.variables.padding * 3, 0, 0)};
`;

export const FiltersColumn = styled.ul`
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    ${(props) => padding(0, props.theme.variables.padding * 1.5)};

    li {
        width: max-content;
        ${(props) => margin(0, props.theme.variables.margin)};
    }
`;

export const FiltersDropdownWrapper = styled.div`
    height: max-content;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    ${(props) => padding(0, props.theme.variables.padding * 0.75)};
    z-index: 7;
`;

export const FiltersDropdown = styled(Flex.Style)`
    position: relative;
    color: ${(props) => props.theme.colors.black};
`;

export const FiltersTitle = styled.h2`
    font-size: 1.15rem;
    font-weight: 500;
`;

export const DashboardWrapper = styled(Flex.Style)`
    flex-direction: column;
    overflow-y: auto;
    flex-shrink: 1;
    width: 100%;
    min-height: 100%;
`;

export const TotemCard = styled(Flex.Style)`
    flex-direction: column;
    flex-shrink: 0;
    background: ${(props) => props.theme.colors.white};
    ${(props) => padding(props.theme.variables.padding * 3)};
    border-radius: ${(props) => props.theme.variables.borderRadius};

    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};
`;

export const DashboardTitle = styled.h1`
    font-size: 2.25em;
    color: ${(props) => props.theme.colors.black};
    ${(props) => padding(props.theme.variables.padding, 0)};
    font-weight: 800;

    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};

    &:not(:first-of-type) {
        ${(props) => padding(props.theme.variables.padding * 4, 0, props.theme.variables.padding * 2)};
    }
`;

export const DateTitle = styled(Heading.Two.Style)`
    text-transform: uppercase;
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    ${({ theme: { variables }, $margins }) =>
        $margins &&
        margin(
            variables.margin * $margins[0],
            variables.margin * $margins[1],
            variables.margin * ($margins[2] ?? $margins[0]),
            variables.margin * ($margins[3] ?? $margins[1]),
        )}
`;

export const ArrowCircleIcon = styled(ArrowBackCircle)`
    color: ${({ theme: { colors } }) => colors.black};
    cursor: pointer;
    transition: all 0.25s ease;
    &:hover {
        color: ${(props) => darken(0.1, props.theme.colors.black)};
    }
`;

export const AbsoluteContainer = styled.div`
    position: absolute;
    right: ${(props) => props.$right};
    left: ${(props) => props.$left};
    top: ${(props) => props.$top};
    bottom: ${(props) => props.$bottom};
`;
