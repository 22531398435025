import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';

import { CHECK_PASSWORD, CHANGE_PASSWORD } from 'data/mutations';

import { PasswordStrength } from 'components/PasswordStrength';
import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { handleTextInput } from 'utils';

import { TotemLabel, TotemInput } from 'styles';
import { SettingsCredentialsSubtitle, CredentialsSubtitleContainer } from 'styles/pages/settings.styles';

import { analyticsEvents, track } from 'tracking/segment';

export const ChangePassword = () => {
    const [passwordValues, setPasswordValues] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
    const [checkPassword, { loading: checkLoading }] = useMutation(CHECK_PASSWORD);
    const [changePassword, { loading: passwordLoading }] = useMutation(CHANGE_PASSWORD);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        setValue,
    } = useForm({ reValidateMode: 'onSubmit' });

    const isLoading = checkLoading || passwordLoading;

    const handleResetPassword = async () => {
        const { currentPassword, newPassword, confirmPassword } = passwordValues;
        const mediumRegex = new RegExp(
            '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
        );

        if (!newPassword.match(mediumRegex)) {
            const message = 'Essayez un mot de passe avec une majuscule ou chiffre et au moins 6 caractères';
            setError('newPassword', { type: 'weakPassword', message });
            return;
        }

        if (newPassword !== confirmPassword) {
            const message = 'Les mots de passe sont différents';
            setError('confirmPassword', { type: 'notMatch', message });
            return;
        }

        const {
            data: { checkPassword: passwordIsCorrect },
        } = await checkPassword({ variables: { password: currentPassword } });

        if (!passwordIsCorrect) {
            const message = 'Mot de passe incorrect';
            setError('currentPassword', { type: 'invalid', message });
            return;
        }
        const {
            data: { changePassword: response },
        } = await changePassword({
            variables: { password: newPassword },
        });

        if (response?.error) {
            setError('currentPassword', { type: 'invalid', message: response.error });
        } else if (response.success) {
            track(analyticsEvents.UPDATE_PASSWORD);
            setPasswordValues({ currentPassword: '', newPassword: '', confirmPassword: '' });
            setValue('currentPassword', '');
            setValue('newPassword', '');
            setValue('confirmPassword', '');
        }
    };

    const handlePasswordInputs = (e) => {
        clearErrors(e.target.name);
        handleTextInput(e, setPasswordValues, passwordValues);
    };

    const passwordFieldsFilled =
        passwordValues.currentPassword && passwordValues.newPassword && passwordValues.confirmPassword;

    return (
        <form onSubmit={handleSubmit(handleResetPassword)}>
            <CredentialsSubtitleContainer $topMargin>
                <SettingsCredentialsSubtitle>Changer le mot de passe</SettingsCredentialsSubtitle>
                <TotemButton $fontSize="1em" disabled={!passwordFieldsFilled} type="submit" $isLoading={isLoading}>
                    {isLoading ? <ClipLoader color="white" size="16px" /> : 'Mettre à jour'}
                </TotemButton>
            </CredentialsSubtitleContainer>
            <TotemLabel>
                Mot de passe actuel <InlineError display={errors.currentPassword} />
                <TotemInput
                    placeholder="••••••••"
                    type="password"
                    {...register('currentPassword', { required: true })}
                    onChange={handlePasswordInputs}
                />
            </TotemLabel>
            <TotemLabel>
                Nouveau mot de passe <InlineError display={errors.newPassword} />
                <TotemInput
                    placeholder="••••••••"
                    type="password"
                    {...register('newPassword', { required: true })}
                    onChange={handlePasswordInputs}
                />
                <PasswordStrength input={passwordValues.newPassword} />
            </TotemLabel>
            <TotemLabel>
                Confirmez le nouveau mot de passe <InlineError display={errors.confirmPassword} />
                <TotemInput
                    placeholder="••••••••"
                    type="password"
                    {...register('confirmPassword', { required: true })}
                    onChange={handlePasswordInputs}
                />
            </TotemLabel>
        </form>
    );
};
