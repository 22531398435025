import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { AuthContext } from '@totem/auth';

import { UserContext } from 'constants/user-context';
import { SIGNUP } from 'data/mutations';

import { GetOrganizationInfo } from 'pages/Signup/GetOrganizationInfo';
import { GetOfficeManagerInfo } from 'pages/Signup/GetOfficeManagerInfo';

import { Loading } from 'components/Loading';

import { HandleNotification } from 'utils';

import { TotemLoadingStyle } from 'styles/pages/totem.styles';

export const SignupRouter = () => {
    const { organization, user } = useContext(UserContext);
    const hasOrganizationPassedStep2 = !!organization.name;
    const hasOrganizationPassedStep3 =
        !!user.profile.firstname && !!user.profile.lastname && !!user.profile.jobTitle && !!user.profile.phone;
    const location = useLocation();
    const [signupHandler] = useMutation(SIGNUP);
    const {
        auth: { loading: authLoading },
    } = useContext(AuthContext);

    if (authLoading) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    const signupMutationHandler = async (data) => {
        try {
            const {
                data: { signup },
                errors,
            } = await signupHandler(data);
            return {
                ...signup,
                errors,
            };
        } catch (error) {
            HandleNotification(error);
        }
    };

    window.TOTEM.auth.customSignup = signupMutationHandler;

    const { pathname, search } = location;

    if (hasOrganizationPassedStep2 && hasOrganizationPassedStep3) {
        return <Navigate to={{ pathname: '/', search }} />;
    }

    const stepTwoRegex = /signup\/2((\/|\?).*)?$/g;
    const isStep2 = !!pathname.match(stepTwoRegex);

    const stepThreeRegex = /signup\/3((\/|\?).*)?$/g;
    const isStep3 = !!pathname.match(stepThreeRegex);

    if (!hasOrganizationPassedStep2 && !isStep2) {
        return <Navigate to={{ pathname: '/signup/2', search }} />;
    } else if (hasOrganizationPassedStep2 && !isStep3) {
        return <Navigate to={{ pathname: '/signup/3', search }} />;
    }

    return (
        <Routes>
            <Route path="/2" exact element={<GetOrganizationInfo />} />
            <Route path="/3" exact element={<GetOfficeManagerInfo />} />
        </Routes>
    );
};
