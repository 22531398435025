import gql from 'graphql-tag';
import { PRODUCT_FRAGMENT } from 'data/fragments/product.fragments';

export const GET_PRODUCTS = gql`
    query GET_PRODUCTS($categoryId: String, $orderId: String!, $productsIdsInLastOrder: [String]) {
        freefoodProducts(categoryId: $categoryId, orderId: $orderId, productsIdsInLastOrder: $productsIdsInLastOrder) {
            ...ProductFragment
            volume
            availableStock
            canBeSuppliedBeforeOrderDate
        }
    }
    ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT = gql`
    query GET_PRODUCT($productId: String!, $orderId: String!) {
        product(productId: $productId, orderId: $orderId) {
            ...ProductFragment
            allergen {
                gluten
                crustaceans
                eggs
                fish
                peanuts
                soybeans
                milk
                nuts
                celery
                mustard
                sesame
                sulphites
                lupin
                molluscs
                canContain
            }
            ingredients
            nutrition {
                caloriesKCal
                totalFat
                sturatedFat
                carbohydrates
                sugars
                fibers
                protein
                sodium
            }
            availableStock
            canBeSuppliedBeforeOrderDate
        }
    }
    ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCTS_STOCK = gql`
    query GET_PRODUCTS_STOCK($productIds: [String]!, $orderId: String!) {
        productsStock(productIds: $productIds, orderId: $orderId) {
            productId
            availableStock
            imageUrl
            brand
            volume
            displayKg
            weightWithoutPackaging
        }
    }
`;
