import React from 'react';
import styled from 'styled-components';

import { SET_PAYMENT_TYPE_AS_BANK_TRANSFER } from 'data/mutations/organization.mutations';

import { TotemButton } from 'ui/Button';
import { useMutation } from '@apollo/client';
import { ClipLoader } from 'react-spinners';

export const BankTransferForm = ({ organization, setIsBillingAsideOpen }) => {
    const [setPaymentTypeAsBankTransfer, { loading: isLoading }] = useMutation(SET_PAYMENT_TYPE_AS_BANK_TRANSFER);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setPaymentTypeAsBankTransfer({
            variables: {
                organizationId: organization._id,
            },
        });
        setIsBillingAsideOpen(false);
    };

    return (
        <BankTransferFormContainer onSubmit={handleSubmit}>
            <TotemButton
                disabled={isLoading}
                $margins={[2, 0, 0, 0]}
                $fontSize="1em"
                type="submit"
                data-test="choose-bank-transfer"
            >
                {isLoading ? <ClipLoader color="white" size="16px" /> : 'Toujours payer par virement'}
            </TotemButton>
        </BankTransferFormContainer>
    );
};

const BankTransferFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
