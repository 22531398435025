import React from 'react';
import styled from 'styled-components';

export const Checkbox = ({ checked, height = '16px', width = '16px', dataTest, disabled, ...props }) => (
    <CheckboxContainer>
        <HiddenCheckbox
            height={height}
            width={width}
            data-test={dataTest}
            checked={checked}
            disabled={disabled}
            {...props}
        />
        <StyledCheckbox height={height} width={width} checked={checked}>
            <Icon checked={checked} viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
            </Icon>
        </StyledCheckbox>
    </CheckboxContainer>
);

const CheckboxContainer = styled.div`
    display: inline-flex;
    vertical-align: middle;
    position: relative;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    height: ${({ height }) => height};
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: ${({ width }) => width};
    opacity: 0;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    z-index: 1;
`;

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
    display: inline-block;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    background: ${(props) => (props.checked ? props.theme.colors.black : props.theme.colors.culturedGrey)};
    border-radius: ${(props) => props.theme.variables.borderRadius};
    transition: all 150ms;

    ${Icon} {
        visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    }
`;
