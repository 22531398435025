import React from 'react';
import styled from 'styled-components';

import { LineItemContainer } from 'pages/Totem/Aside/Basket/LineItemContainer';

export const BasketDisplayLineItem = ({ additionalButton, borderBottom, displayedInfo, icon, label, dataTest }) => {
    return (
        <LineItemContainer as="div" $borderBottom={borderBottom}>
            <FlexLeftContainer>
                <LabelAndIcon>
                    {icon}
                    {label}
                </LabelAndIcon>
                {additionalButton}
            </FlexLeftContainer>
            <DisplayedInfo data-test={dataTest}>{displayedInfo}</DisplayedInfo>
        </LineItemContainer>
    );
};

const FlexLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const LabelAndIcon = styled.div`
    svg {
        margin-right: 1.6rem;
    }
    display: flex;
    align-items: center;
`;

const DisplayedInfo = styled.div``;
