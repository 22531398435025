import { isProduction, formGraphQLUrl } from 'utils/url';
import { TOKEN } from 'constants';

export const LinkConfig = {
    uri: formGraphQLUrl(false),
};

export const typePolicies = {
    RequestedOrderProduct: { keyFields: ['orderId', '_id'] },
    OrderProduct: { keyFields: ['orderId', '_id'] },
    BoxProduct: { keyFields: ['boxId', '_id'] },
    User: {
        fields: {
            profile: {
                merge(existing, incoming) {
                    return { ...existing, ...incoming };
                },
            },
        },
    },
    Order: {
        fields: {
            stateHistory: {
                merge: false,
            },
            products: {
                merge: false,
            },
            price: {
                merge: false,
            },
        },
    },
    Query: {
        fields: {
            archivedOrders: {
                keyArgs: ['totemId'],
                merge(existing = { archivedOrders: [] }, incoming, { readField }) {
                    const newOrders = [];
                    const existingOrderIds = existing.archivedOrders.map((order) => readField('_id', order));
                    incoming.archivedOrders.forEach((item) => {
                        if (!existingOrderIds.includes(readField('_id', item))) {
                            newOrders.push(item);
                        }
                    });
                    return {
                        archivedOrders: [...existing.archivedOrders, ...newOrders],
                        hasMore: incoming.hasMore,
                    };
                },
            },
            getStripeInvoices: {
                keyArgs: ['organizationId'],
                merge(existing = { invoices: [] }, incoming, { readField }) {
                    const newInvoices = [];
                    const existingInvoicesIds = existing.invoices.map((invoice) => readField('id', invoice));
                    incoming.invoices.forEach((item) => {
                        if (!existingInvoicesIds.includes(readField('_id', item))) {
                            newInvoices.push(item);
                        }
                    });
                    return {
                        invoices: [...existing.invoices, ...newInvoices],
                        hasMore: incoming.hasMore,
                    };
                },
            },
        },
    },
};

export const ClientConfig = {
    connectToDevTools: !isProduction,
};

export const WSConfig = {
    url: formGraphQLUrl(true),
    connectionParams: async () => ({
        token: (window.TOTEM.auth.authenticated && window.TOTEM.storage.get(TOKEN)) || '',
        webapporigin: true,
    }),
};
