import React from 'react';
import { getValueAndUnit } from 'polished';

import { AspectRatioContainer, AspectRatioWrapper, AspectRatioInner } from '../styles/display/aspect-ratio.styles';

import { getAspectRatio } from '../utils/getAspectRatio';

export const AspectRatio = ({ ratio = '16:9', size = '100', children, innerStyle, ...rest }) => {
    const [width, unit] = getValueAndUnit(size);
    const ratioSize = getAspectRatio(ratio);

    return (
        <AspectRatioContainer {...rest} width={width + (unit || '%')}>
            <AspectRatioWrapper height={ratioSize + '%'}>
                <AspectRatioInner style={innerStyle}>{children}</AspectRatioInner>
            </AspectRatioWrapper>
        </AspectRatioContainer>
    );
};
