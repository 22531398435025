import React from 'react';

import { OptimizedImage } from 'components/OptimizedImage';

import { PrivilegesTableStyle } from 'styles/pages/settings.styles';

import { buildImgUrl } from 'utils/image/buildImgUrl';

export const PrivilegesTable = () => {
    return (
        <PrivilegesTableStyle>
            <thead>
                <tr>
                    <th />
                    <th>Analyste</th>
                    <th>Éditeur</th>
                    <th>Admin</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Lire et Analyser</th>
                    <td>
                        <OptimizedImage width={20} src={buildImgUrl('img/icones/check.png')} />
                    </td>
                    <td>
                        <OptimizedImage width={20} src={buildImgUrl('img/icones/check.png')} />
                    </td>
                    <td>
                        <OptimizedImage width={20} src={buildImgUrl('img/icones/check.png')} />
                    </td>
                </tr>
                <tr>
                    <th>Modifier les commandes</th>
                    <td />
                    <td>
                        <OptimizedImage width={20} src={buildImgUrl('img/icones/check.png')} />
                    </td>
                    <td>
                        <OptimizedImage width={20} src={buildImgUrl('img/icones/check.png')} />
                    </td>
                </tr>
                <tr>
                    <th>Créer de nouvelles adresses</th>
                    <td />
                    <td />
                    <td>
                        <OptimizedImage width={20} src={buildImgUrl('img/icones/check.png')} />
                    </td>
                </tr>
                <tr>
                    <th>Modifier les informations de paiement</th>
                    <td />
                    <td />
                    <td>
                        <OptimizedImage width={20} src={buildImgUrl('img/icones/check.png')} />
                    </td>
                </tr>
                <tr>
                    <th>Ajouter des utilisateurs</th>
                    <td />
                    <td />
                    <td>
                        <OptimizedImage width={20} src={buildImgUrl('img/icones/check.png')} />
                    </td>
                </tr>
            </tbody>
        </PrivilegesTableStyle>
    );
};
