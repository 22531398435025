import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { OPEN_DOWN, ANCHOR_LEFT } from 'react-dates/constants';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';

import { FORMAT } from 'constants/date-constants';
import { TotemClosedDaysContext } from 'contexts/TotemClosedDaysContext';

import { CloseContainer, PopupContent, PopupTitle } from 'styles/pages/settings.styles';
import { AbsoluteContainer, ArrowCircleIcon } from 'styles/pages/totem.styles';
import { RemoveIcon } from 'styles/components/card.styles';
import { TotemLabel } from 'styles';
import { smallSelectStyle } from 'styles/components/reactSelect.styles';

import {
    MIN_FREE_INTERVAL_DURATION,
    MIN_SLOTS_IN_DELIVERY_WINDOW,
    MIN_SLOT_INTERVAL_DURATION,
    startDeliveryRange,
    stopDeliveryRange,
} from 'constants/deliveryTime';

import { InlineError } from 'ui/InlineError';
import { TotemButton } from 'ui/Button';

import { getTimeDifference } from 'utils';
import { getInitialVisibleMonth, isBankHoliday, isDateBeforeNext, isWeekEnd } from 'utils/datePicker';
import { getNextAvailableDeliveryDate } from 'utils/time';

import { analyticsEvents, track } from 'tracking/segment';
import { SHORT_TIME_WINDOW_PRICE } from 'constants/order';

export const DashboardDeliveryDateSelector = ({
    handleClose,
    selectedDate,
    setSelectedDate,
    selectedTimeWindow,
    setSelectedTimeWindow,
    onSubmit,
    onClickBackButton,
    shouldHideBackButton,
}) => {
    const totemClosedDays = useContext(TotemClosedDaysContext);
    const nextDeliveryDate = dayjs(getNextAvailableDeliveryDate(totemClosedDays));
    const [isDatePickerFocused, setIsDatePickerFocused] = useState(false);

    const startDeliveryOptions = startDeliveryRange.map((value) => ({ label: value, value }));
    const stopDeliveryOptions = stopDeliveryRange.map((value) => ({ label: value, value }));
    const defaultdeliveryTimeStart = startDeliveryOptions[2];
    const defaultdeliveryTimeStop = stopDeliveryOptions[6];

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        setValue,
        getValues,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            deliveryDate: selectedDate ? moment(selectedDate) : moment(dayjs(nextDeliveryDate).format()),
            deliveryTimeStart: selectedTimeWindow
                ? { label: selectedTimeWindow?.start, value: selectedTimeWindow?.start }
                : defaultdeliveryTimeStart,
            deliveryTimeStop: selectedTimeWindow
                ? { label: selectedTimeWindow?.stop, value: selectedTimeWindow?.stop }
                : defaultdeliveryTimeStop,
        },
    });

    const deliveryTimeStartValue = watch('deliveryTimeStart').value;
    const deliveryTimeStopValue = watch('deliveryTimeStop').value;
    const isTimeWindowFreeOfCharge =
        getTimeDifference(deliveryTimeStartValue, deliveryTimeStopValue) >= MIN_FREE_INTERVAL_DURATION;

    const handleDeliveryDateTimeValidation = ({
        deliveryDate,
        deliveryTimeStart: { value: deliveryTimeStartValue },
        deliveryTimeStop: { value: deliveryTimeStopValue },
    }) => {
        setSelectedDate(deliveryDate);
        setSelectedTimeWindow({
            start: deliveryTimeStartValue,
            stop: deliveryTimeStopValue,
        });
        track(analyticsEvents.SET_ORDER_DELIVERY_DATETIME);
        onSubmit();
    };
    return (
        <>
            <PopupContent $paddings={[2, 2, 4, 2]} $spaceBetween width="520px">
                {!shouldHideBackButton && (
                    <AbsoluteContainer $top="9px" $left="9px">
                        <ArrowCircleIcon onClick={onClickBackButton} size="32px" />
                    </AbsoluteContainer>
                )}
                <PopupTitle>Date et heures de livraison</PopupTitle>
                <DeliveryDateTimeForm onSubmit={handleSubmit(handleDeliveryDateTimeValidation)}>
                    <TotemLabel>
                        <span>Date de livraison</span>
                        <Controller
                            control={control}
                            rules={{
                                required: 'ce champ est requis',
                            }}
                            name="deliveryDate"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <SingleDatePicker
                                        id="order-date-picker"
                                        placeholder="Sélectionnez la date de livraison"
                                        date={value}
                                        required
                                        onDateChange={(date) => onChange(date)}
                                        isOutsideRange={(date) =>
                                            isDateBeforeNext(date._d, nextDeliveryDate) ||
                                            isWeekEnd(date._d) ||
                                            isBankHoliday({ totemClosedDays, date })
                                        }
                                        calendarInfoPosition="after"
                                        openDirection={OPEN_DOWN}
                                        anchorDirection={ANCHOR_LEFT}
                                        numberOfMonths={1}
                                        firstDayOfWeek={1}
                                        showDefaultInputIcon={true}
                                        hideKeyboardShortcutsPanel
                                        focused={isDatePickerFocused}
                                        onFocusChange={() => setIsDatePickerFocused(!isDatePickerFocused)}
                                        initialVisibleMonth={() =>
                                            getInitialVisibleMonth({ selectedDate, nextDeliveryDate })
                                        }
                                        displayFormat={() => `dddd  ${FORMAT}`}
                                    />
                                );
                            }}
                        />
                        <InlineError display={errors.deliveryDate} />
                    </TotemLabel>
                    <DeliveryTimeWindow>
                        <Controller
                            name="deliveryTimeStart"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <TotemLabel>
                                    <span>Livraison à partir de</span>
                                    <StyledSelect
                                        {...field}
                                        styles={smallSelectStyle}
                                        multi={false}
                                        onChange={(selectedOption) => {
                                            const { value: deliveryTimeStartValue } = selectedOption;
                                            const {
                                                deliveryTimeStop: { value: deliveryTimeStopValue },
                                            } = getValues();
                                            if (
                                                getTimeDifference(deliveryTimeStartValue, deliveryTimeStopValue) <=
                                                MIN_SLOT_INTERVAL_DURATION
                                            ) {
                                                setValue(
                                                    'deliveryTimeStop',
                                                    stopDeliveryOptions[
                                                        stopDeliveryOptions.findIndex(
                                                            (option) => option.value === selectedOption.value,
                                                        ) + MIN_SLOTS_IN_DELIVERY_WINDOW
                                                    ],
                                                );
                                            }
                                            field.onChange(selectedOption);
                                        }}
                                        classNamePrefix="delivery-time-start"
                                        placeholder="Livraison à partir de"
                                        options={startDeliveryOptions}
                                        noOptionsMessage={() => "Pas d'horaire trouvé"}
                                    />
                                    <InlineError display={errors.deliveryTimeStart} />
                                </TotemLabel>
                            )}
                        />
                        <Controller
                            name="deliveryTimeStop"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <TotemLabel>
                                    <span>Jusqu'à</span>
                                    <StyledSelect
                                        {...field}
                                        styles={smallSelectStyle}
                                        multi={false}
                                        onChange={(selectedOption) => {
                                            const { value: deliveryTimeStopValue } = selectedOption;
                                            const {
                                                deliveryTimeStart: { value: deliveryTimeStartValue },
                                            } = getValues();
                                            if (
                                                getTimeDifference(deliveryTimeStartValue, deliveryTimeStopValue) <=
                                                MIN_SLOT_INTERVAL_DURATION
                                            ) {
                                                setValue(
                                                    'deliveryTimeStart',
                                                    startDeliveryOptions[
                                                        startDeliveryOptions.findIndex(
                                                            (option) => option.value === selectedOption.value,
                                                        ) - MIN_SLOTS_IN_DELIVERY_WINDOW
                                                    ],
                                                );
                                            }
                                            field.onChange(selectedOption);
                                        }}
                                        placeholder="Plutôt avant"
                                        options={stopDeliveryOptions}
                                        noOptionsMessage={() => "Pas d'horaire trouvé"}
                                    />
                                    <InlineError display={errors.deliveryTimeStop} />
                                </TotemLabel>
                            )}
                        />
                    </DeliveryTimeWindow>
                    <Disclaimer>
                        En choisissant une plage horaire large ({MIN_FREE_INTERVAL_DURATION}h ou plus), vous nous aidez
                        à réduire les émissions liées à la livraison, grâce à des optimisations de transport (moins de
                        camionnettes sont nécessaires, avec des trajets plus courts).
                        <br />
                        <br /> Lorsque cela est nécessaire, vous avez la possibilité de définir un créneau de livraison
                        réduit (fenêtre de 1h minimum, avec un surcoût de {SHORT_TIME_WINDOW_PRICE}€).
                    </Disclaimer>
                    {!isTimeWindowFreeOfCharge ? (
                        <Warning>
                            <span role="img" aria-label="warning">
                                ⚠️
                            </span>{' '}
                            Un surcoût de {SHORT_TIME_WINDOW_PRICE}€ s’applique car la plage horaire est inférieure à{' '}
                            {MIN_FREE_INTERVAL_DURATION}h.
                        </Warning>
                    ) : null}
                    <TotemButton
                        $margins={[1, 0, 0, 0]}
                        type="submit"
                        disabled={!isValid}
                        data-test="validate-dashboard-delivery-date-button"
                    >
                        Continuer
                    </TotemButton>
                </DeliveryDateTimeForm>
            </PopupContent>
            <CloseContainer>
                <RemoveIcon size="25px" onClick={handleClose} />
            </CloseContainer>
        </>
    );
};

const DeliveryTimeWindow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > :first-child {
        margin-right: 1em;
    }
    & > :last-child {
        margin-left: 1em;
    }
    margin-top: 1em;
`;

const StyledSelect = styled(Select)`
    width: 14em;
    margin-bottom: 0.2em;
`;

const DeliveryDateTimeForm = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    .DateInput {
        width: 330px;
    }
`;

const Disclaimer = styled.div`
    margin-top: 15px;
    color: ${(props) => props.theme.colors.pantinGrey};
`;

const Warning = styled.div`
    margin-top: 15px;
    color: ${(props) => props.theme.colors.pantinGrey};
`;
