import React from 'react';
import styled from 'styled-components';

import { EmojiLabel } from 'components/EmojiLabel';

import { OptimizedImage } from 'components/OptimizedImage';

import { buildImgUrl } from 'utils/image/buildImgUrl';

export const UseDesktopWebApp = () => {
    return (
        <UseDesktopContainer>
            <Title>
                Oups <EmojiLabel src="😕" spacing={2} />
            </Title>
            <OptimizedImage height={100} src={buildImgUrl('img/illustrations/Totem_illustration-021.png')} />
            <SecondaryText>Cette section n'est malheureusement pas consultable sur mobile...</SecondaryText>
            <SecondaryText>
                Votre compte existe bien ! Connectez-vous sur votre ordinateur pour y accéder !
            </SecondaryText>
            <WebsiteAddress>
                <span>Rendez-vous vite sur :</span>
                <a target="_blank" rel="noopener noreferrer" href="https://app.totem.co/">
                    app.totem.co
                </a>
            </WebsiteAddress>
        </UseDesktopContainer>
    );
};

const UseDesktopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 85%;
    width: 100%;
    background: inherit;
`;

const Title = styled.h1`
    text-transform: uppercase;
    text-align: center;
    border: none;
    font-size: 48px;
    margin: 70px 0 0 0;
`;

const SecondaryText = styled.h4`
    text-align: center;
    padding: 0 20px;
    margin: 0;
    font-size: 20px;
`;

const WebsiteAddress = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    a {
        font-size: 26px;
        font-weight: 800;
        margin: 0 10px;
        text-decoration: underline;
    }
`;
