import gql from 'graphql-tag';
import { ORDER_FRAGMENT, REQUESTED_ORDER_PRODUCT_FRAGMENT } from 'data/fragments/order.fragments';

export const GET_MONTHLY_USED_BUDGET_FROM_DATE = gql`
    query GET_MONTHLY_USED_BUDGET_FROM_DATE($totemId: ID!, $date: GraphQLDate!) {
        getMonthlyUsedBudgetFromDateQuery(totemId: $totemId, date: $date)
    }
`;

export const GET_ARCHIVED_ORDERS = gql`
    query GET_ARCHIVED_ORDERS($offset: Int!, $totemId: String!) {
        archivedOrders(offset: $offset, totemId: $totemId) {
            hasMore
            archivedOrders {
                ...OrderFragment
                productsWithQuantityPicked {
                    ...RequestedOrderProductFragment
                }
                productsRequestedButNotFullyDelivered {
                    ...RequestedOrderProductFragment
                }
                user {
                    profile {
                        firstname
                        lastname
                        imageUrl
                    }
                }
                delivery {
                    _id
                    state
                    stateHistory {
                        state
                        createdAt
                    }
                    trackingUrl
                    photos {
                        type
                        urls
                    }
                    siteSetup {
                        freefoodSetup {
                            instructions {
                                ... on PhotosInstruction {
                                    photos {
                                        type
                                        urls
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${ORDER_FRAGMENT}
    ${REQUESTED_ORDER_PRODUCT_FRAGMENT}
`;

export const GET_ORDERS_OF_TOTEM_WITHIN_DATE_RANGE = gql`
    query GET_ORDERS_OF_TOTEM_WITHIN_DATE_RANGE(
        $totemId: ID!
        $startDate: GraphQLDate!
        $endDate: GraphQLDate!
        $isPunctual: Boolean!
    ) {
        ordersOfTotemWithinDateRange(
            totemId: $totemId
            startDate: $startDate
            endDate: $endDate
            isPunctual: $isPunctual
        ) {
            ...OrderFragment
        }
    }
    ${ORDER_FRAGMENT}
`;

export const GET_ORDERS_OF_TOTEM = gql`
    query GET_ORDERS_OF_TOTEM($totemId: String!) {
        ordersOfTotem(totemId: $totemId) {
            recentOrders {
                ...OrderFragment
                productsWithQuantityPicked {
                    ...RequestedOrderProductFragment
                }
                productsRequestedButNotFullyDelivered {
                    ...RequestedOrderProductFragment
                }
                user {
                    profile {
                        firstname
                        lastname
                        imageUrl
                    }
                }
                delivery {
                    _id
                    state
                    stateHistory {
                        state
                        createdAt
                    }
                    trackingUrl
                    photos {
                        type
                        urls
                    }
                }
            }
            hasConfirmedTotemOrders
        }
    }
    ${ORDER_FRAGMENT}
    ${REQUESTED_ORDER_PRODUCT_FRAGMENT}
`;

export const GET_READY_PUNCTUAL_ORDERS = gql`
    query GET_READY_PUNCTUAL_ORDERS($totemId: String!) {
        readyPunctualOrders(totemId: $totemId) {
            ...OrderFragment
            productsWithQuantityPicked {
                ...RequestedOrderProductFragment
            }
            productsRequestedButNotFullyDelivered {
                ...RequestedOrderProductFragment
            }
            user {
                profile {
                    firstname
                    lastname
                    imageUrl
                }
            }
            delivery {
                _id
                state
                stateHistory {
                    state
                    createdAt
                }
                trackingUrl
                photos {
                    type
                    urls
                }
            }
        }
    }
    ${REQUESTED_ORDER_PRODUCT_FRAGMENT}
    ${ORDER_FRAGMENT}
`;

export const GET_ORDER = gql`
    query GET_ORDER($orderId: ID!) {
        getOrder(orderId: $orderId) {
            ...OrderFragment
            productsWithQuantityPicked {
                ...RequestedOrderProductFragment
            }
            productsRequestedButNotFullyDelivered {
                ...RequestedOrderProductFragment
            }
        }
    }
    ${ORDER_FRAGMENT}
    ${REQUESTED_ORDER_PRODUCT_FRAGMENT}
`;

export const GET_TOTEM_CLOSED_DAYS = gql`
    query GET_TOTEM_CLOSED_DAYS {
        getTotemClosedDays
    }
`;
