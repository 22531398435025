import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { NEW_FORMAT, FORMAT } from 'constants/date-constants';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

/**
 * @param {String} time - In the format "HH:MM"
 */
export const getFrenchFormattedTime = (time) => {
    if (!time) {
        throw new Error('No time provided');
    }

    const [hours, minutes] = time.split(':');

    if (Number.isNaN(Number(hours)) || Number.isNaN(Number(minutes))) {
        throw new Error('Parameter is not a number!');
    }

    const stringHours = Number(hours).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

    const stringMinutes = Number(minutes).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

    return `${stringHours}h${stringMinutes}`;
};

export const getDeliveryHourRangeString = (startHour, stopHour) => {
    const formattedStartHour = getFrenchFormattedTime(startHour);
    const formattedStopHour = getFrenchFormattedTime(stopHour);

    if (startHour && stopHour) {
        return `entre ${formattedStartHour} et ${formattedStopHour}`;
    }

    return startHour ? `à partir de ${formattedStartHour}` : `avant ${formattedStopHour}`;
};

export function getDaysToPreviousWeekday(day) {
    switch (day) {
        case 0:
            return 2; // Sunday last weekday is Friday (j-2)
        case 1:
            return 3; // Monday last weekday is Friday (j-3)
        case 2: // Tuesday - Saturday last weekday is day before (j-1)
        case 3:
        case 4:
        case 5:
        case 6:
            return 1;
        default:
            throw new Error('Day input must be between 0-6');
    }
}

function getFormattedPreviousWeekday(date) {
    const dateWeekday = getDateWeekday(date);
    return date.subtract(getDaysToPreviousWeekday(dateWeekday), 'day').format(NEW_FORMAT);
}

function getDateWeekday(date) {
    const day = new Date(date);
    return day.getDay();
}

// Can modify until preparation date - 1 DAY (not weekday),
// Preparation date = order date - 1 weekday
// If -1 weekday preparation date is bank holiday, preparation = order date - 2 weekdays

export function remainingTimeForValidation({
    deliveryDate,
    firstOrder,
    hasBeenValidated,
    isPunctual,
    state,
    totemClosedDays,
}) {
    const dayJsDeliveryDate = dayjs(deliveryDate, FORMAT);
    const deliveryDateWeekday = getDateWeekday(dayJsDeliveryDate);

    let preparationFormattedDate = getFormattedPreviousWeekday(dayJsDeliveryDate);
    let preparationDelays = getDaysToPreviousWeekday(deliveryDateWeekday);

    while (totemClosedDays.includes(preparationFormattedDate)) {
        const preparationDate = dayjs(preparationFormattedDate, NEW_FORMAT);
        preparationFormattedDate = getFormattedPreviousWeekday(preparationDate);
        const preparationDateWeekday = getDateWeekday(preparationDate);
        preparationDelays += getDaysToPreviousWeekday(preparationDateWeekday);
    }

    const validationLimitDate = dayJsDeliveryDate
        .subtract(preparationDelays + 1, 'day') // 1 being modification deadline days before preparation
        .hour(23)
        .minute(59); // 2AM, 'daysToSubstract' before the delivery day
    const validationLimitDateIsPast = validationLimitDate.isBefore(dayjs());
    const timeToValidationDateAsString = validationLimitDate.locale('fr').fromNow(true);
    const isUnvalidatedFirstOrder = firstOrder && !hasBeenValidated;
    const statusInfo = {
        Created:
            isPunctual || isUnvalidatedFirstOrder
                ? `Plus que ${timeToValidationDateAsString} pour valider votre commande`
                : `Automatiquement renouvelée dans ${timeToValidationDateAsString}`,
        InProgress:
            isPunctual || isUnvalidatedFirstOrder
                ? `Plus que ${timeToValidationDateAsString} pour valider votre commande`
                : `Plus que ${timeToValidationDateAsString}  pour valider vos modifications`,
        Validated: `Plus que ${timeToValidationDateAsString} pour modifier cette commande`,
    };
    return {
        validationLimitDate,
        fullString: statusInfo[state],
        validationLimitDateIsPast,
    };
}

export function getDateAddFromDate({ daysToAdd, date }) {
    return dayjs.utc(date).add(daysToAdd, 'days').toDate();
}

export function getDaysToNextWeekday(day) {
    switch (day) {
        case 0: // Sunday to Thursday next weekday is day after (j+1)
        case 1:
        case 2:
        case 3:
        case 4:
            return 1;
        case 5:
            return 3; // On Friday, next weekday is Monday (j+3)
        case 6:
            return 2; // On Saturday, next weekday is Monday (j+2)
        default:
            throw new Error('Day input must be between 0-6');
    }
}

export function getFormattedDateAddFromDate({ date, daysToAdd, isNewFormat }) {
    return dayjs
        .utc(date)
        .add(daysToAdd, 'days')
        .format(isNewFormat ? NEW_FORMAT : FORMAT);
}

function getDaysToWorkedDayInXWorkedDays(numberOfWorkedDays, parameters, totemClosedDays) {
    const startDate = parameters?.from ?? new Date();
    const currentWeekDay = startDate.getDay();

    let days = 0;

    if (parameters?.shouldStartFromAWorkedDay) {
        // if we start from sunday or saterday it's not a weekday day so it's not worked.
        if ([0, 6].includes(currentWeekDay)) {
            days += getDaysToNextWeekday((currentWeekDay + days) % 7);
        }
        while (
            totemClosedDays.includes(
                getFormattedDateAddFromDate({ date: startDate, daysToAdd: days, isNewFormat: true }),
            )
        ) {
            days += getDaysToNextWeekday((currentWeekDay + days) % 7);
        }
    }

    for (let i = 0; i < numberOfWorkedDays; i++) {
        do {
            days += getDaysToNextWeekday((currentWeekDay + days) % 7);
        } while (
            totemClosedDays.includes(
                getFormattedDateAddFromDate({ date: startDate, daysToAdd: days, isNewFormat: true }),
            )
        );
    }

    return days;
}

export function getNextAvailableDeliveryDate(totemClosedDays) {
    return getDateAddFromDate({
        daysToAdd: getDaysToWorkedDayInXWorkedDays(2, { shouldStartFromAWorkedDay: true }, totemClosedDays),
        date: new Date(),
    });
}
