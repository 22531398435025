import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// /*eslint-disable import/first*/

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { createBrowserHistory } from 'history';
import { IntercomProvider } from 'react-use-intercom';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

import { page, load } from './tracking/segment';

import { initGlobals } from 'utils/init-globals';
import { createApolloClient } from 'data/connection/client.connection';
import { CustomThemeProvider } from 'deprecated-enkel';
import { globalStyles } from 'styles';
import { colors, variables } from 'styles/theme';

/**
 * Init global variables (including the global TOTEM), and all
 * libraries into it. This is vital for all of them to be accessible across the
 * application.
 */
initGlobals();

/**
 * Create a connection to our GraphQL endpoint using Apollo Client
 * to successfully parse all our queries and mutations
 */
const history = createBrowserHistory();
export const client = createApolloClient(history);

let prevPath = null;

// listen and notify Segment of client-side page updates
load();
history.listen((location) => {
    if (location.pathname !== prevPath) {
        prevPath = location.pathname;
        page();
    }
});

function E2EExcludedProviders({ children }) {
    if (!window.__TOTENV__.E2E) {
        return <IntercomProvider appId={window.__TOTENV__.INTERCOM_APP_ID}>{children}</IntercomProvider>;
    }
    return children;
}

const root = createRoot(document.getElementById('root'));

root.render(
    <E2EExcludedProviders>
        <BrowserRouter history={history}>
            <ApolloProvider client={client}>
                <CustomThemeProvider colors={colors} variables={variables} globalStyles={globalStyles}>
                    <App />
                </CustomThemeProvider>
            </ApolloProvider>
        </BrowserRouter>
    </E2EExcludedProviders>,
);

/**
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
