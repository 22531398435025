import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { DETACH_PAYMENT_METHOD } from 'data/mutations/stripe.mutations';

import { ClipLoader } from 'react-spinners';
import { capitalize } from 'utils';
import { TrashIcon } from 'styles/pages/settings.styles';
import { colors } from 'styles/theme';

import { analyticsEvents, analyticsPropertiesConstants, analyticsSourceConstants, track } from 'tracking/segment';

export const CardInfo = ({ paymentMethod, organizationId, numberOfCards }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [detachPaymentMethod] = useMutation(DETACH_PAYMENT_METHOD);

    function handleDeleteCard() {
        setIsDeleting(true);
        detachPaymentMethod({
            variables: {
                organizationId,
                paymentMethodId: paymentMethod.id,
            },
        });
        track(analyticsEvents.DELETE_PAYMENT_METHOD, {
            [analyticsPropertiesConstants.COMMON.SOURCE]: analyticsSourceConstants.SETTINGS,
        });
    }

    return (
        <>
            <td>{`Carte ${capitalize(paymentMethod.card?.brand || paymentMethod.brand)}`}</td>
            <td>• • • • • • • • • • • • {paymentMethod.card?.last4 || paymentMethod.last4}</td>
            <td>{paymentMethod.billing_details?.name || paymentMethod.name}</td>
            <td>{`${paymentMethod.card?.exp_month || paymentMethod.exp_month}/${
                paymentMethod.card?.exp_year || paymentMethod.exp_year
            }`}</td>
            <td>
                {numberOfCards > 1 ? (
                    isDeleting ? (
                        <ClipLoader color={colors.black} size="16px" />
                    ) : (
                        <TrashIcon size="20px" onClick={handleDeleteCard} />
                    )
                ) : (
                    ''
                )}
            </td>
        </>
    );
};
