import styled, { css } from 'styled-components';
import { margin, padding } from 'polished';
import React from 'react';

export const Tag = ({ children, extendedView, invertColor, onClick }) => {
    return (
        <TagStyle extendedView={extendedView} $invertColor={invertColor} onClick={onClick}>
            {children}
        </TagStyle>
    );
};

const TagStyle = styled.span`
    position: relative;
    display: inline-flex;
    align-items: center;
    width: max-content;
    height: 20px;
    color: ${(props) => (props.$invertColor ? '#FFF' : props.theme.colors.pantinGrey)};
    font-size: 0.8rem;
    background: ${({ $invertColor, theme: { colors } }) => ($invertColor ? colors.black : colors.culturedGrey)};
    border-radius: ${(props) => props.theme.variables.borderRadius};

    ${({ theme: { variables } }) => padding(variables.padding * 0.25, variables.padding * 0.75)};
    ${({ theme: { variables } }) => margin(variables.margin * 0.15, variables.margin * 0.5, variables.margin * 0.15, 0)}

    ${(props) =>
        props.extendedView &&
        css`
            font-size: 0.95rem;
            ${({ theme: { variables } }) => padding(variables.padding * 1.15, variables.padding * 1.5)};
            ${({ theme: { variables } }) => margin(0, variables.margin, variables.margin, 0)};
        `};
`;
