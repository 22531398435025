export const INTERNAL = 'INTERNAL';

export const AHEAD = 'AHEAD';
export const AHERA = 'AHERA';
export const AHEBI = 'AHEBI';
export const AHENA = 'AHENA';
export const AHEARCHIVED = 'AHEARCHIVED';
export const AHEBANNED = 'AHEBANNED';
export const AHEEA = 'AHEEA';
export const AHEEV = 'AHEEV';
export const AHEEP = 'AHEEP';
export const AHEOA = 'AHEOA';
export const AHESD = 'AHESD';
export const AHEEC = 'AHEEC';
export const AHESC = 'AHESC';
export const AHEVC = 'AHEVC';
export const AHELPL = 'AHELPL';
export const AHENC = 'AHENC';
export const AHECA = 'AHECA';
export const AHECC = 'AHECC';
export const AHECP = 'AHECP';
export const AHECJ = 'AHECJ';
export const AHECE = 'AHECE';
export const AHEVN = 'AHEVN';
export const AHELM = 'AHELM';
export const AHEAP = 'AHEAP';
export const AHEIY = 'AHEIY';
export const AHELE = 'AHELE';
export const AHEPD = 'AHEPD';
export const AHEPI = 'AHEPI';
export const AHEPAM = 'AHEPAM';
export const AHETP = 'AHETP';
export const AHEPT = 'AHEPT';
export const AHEPP = 'AHEPP';
export const AHEPC = 'AHEPC';
export const AHEPA = 'AHEPA';
export const AHEPS = 'AHEPS';
export const AHECT = 'AHECT';
export const AHECN = 'AHECN';
export const AHETP1 = 'AHETP1';
export const AHETP2 = 'AHETP2';
export const AHETP3 = 'AHETP3';
export const AHEDBNM = 'AHEDBNM';
export const AHEDB48 = 'AHEDB48';
export const TOTEM_DELETION = 'TOTEM_DELETION';
export const DOMAIN_ALREADY_TAKEN = 'DOMAIN_ALREADY_TAKEN';
