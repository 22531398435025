import gql from 'graphql-tag';

export const SET_LOGIN = gql`
    mutation SET_LOGIN($email: String!, $password: String!) {
        signin(email: $email, password: $password) {
            token
            user {
                _id
                username
                createdAt
                emails {
                    address
                    verified
                }
                profile {
                    firstname
                    lastname
                    organizationId
                    phone
                    jobTitle
                }
                organization {
                    _id
                    name
                    imageUrl
                    maxUsers
                    acceptedTerms {
                        areAccepted
                        termsRecordId
                        acceptanceDate
                    }
                    octobatId
                    stripeId
                    salesforceId
                    totalCoworkers
                    billingInfo {
                        defaultPaymentMethod
                        invoiceName
                        invoiceEmail
                        invoiceAddress
                        invoiceZipcode
                        invoiceCity
                        invoiceCountry
                        invoicePO
                        invoiceVAT
                    }
                    paymentTimeType
                    paymentTimeDays
                    paymentType
                    mandateSEPA
                    state
                }
            }
        }
    }
`;
