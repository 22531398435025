import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'deprecated-enkel';

import { TotemButton } from 'ui/Button';

import { PopupTitle } from 'styles/pages/settings.styles';

import { MODIFIABLE_ORDER_STATES } from 'constants/states-app';

export const PreventReplacingOrderByItself = ({
    defaultCategoryId,
    destinationTotemId,
    handleCancel,
    sameDayModifiableRecurringOrder,
}) => {
    const navigate = useNavigate();
    return (
        <>
            <PopupTitle>Vous ne pouvez pas remplacer une commande par elle même !</PopupTitle>
            {MODIFIABLE_ORDER_STATES.includes(sameDayModifiableRecurringOrder?.state) && (
                <TotemButton
                    $margins={[1, 0]}
                    data-test="no-modify"
                    onClick={() =>
                        navigate(
                            `/totem/${destinationTotemId}/${defaultCategoryId}/${sameDayModifiableRecurringOrder._id}`,
                            { relative: 'path' },
                        )
                    }
                >
                    <Flex>Modifier la commande existante</Flex>
                </TotemButton>
            )}
            <TotemButton $margins={[1, 0]} $type="secondary" onClick={() => handleCancel()} data-test="no-cancel">
                <Flex>Annuler la duplication de commande</Flex>
            </TotemButton>
        </>
    );
};
