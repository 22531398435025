import React, { useState, useRef, useEffect } from 'react';

import { stopPropagation } from '../utils/stopPropagation';
import { hasValue, getDefaultValue } from '../utils';

import {
    SelectStyle,
    SelectInputStyle,
    SelectMenuStyle,
    SelectMenuItemStyle,
    SelectInputWrapper,
    SelectIcon,
} from '../styles/form/select.styles';

export const Select = ({
    component: Component,
    inputComponent: InputComponent,
    menuComponent: MenuComponent,
    menuItemComponent: MenuItemComponent,
    options = [],
    optionRenderer,
    onChange,
    placeholder,
    searchable,
    name,
    closeOnSelect = true,
    value: propsValue,
    defaultValue,
    innerRef,
    autoFocus,
    showIcon = true,
    noPlaceholder = false,
    ...rest
}) => {
    const [value, setValue] = useState(getDefaultValue(defaultValue, options));
    const [currentScroll, setCurrentScroll] = useState(
        value ? options.findIndex((option) => option.value === value.value) : 0,
    );
    const [search, setSearch] = useState('');
    const [filteredOptions, setOptions] = useState(options);
    const [showMenu, shouldShowMenu] = useState(false);
    const thisRef = useRef < HTMLDivElement > null;
    const inputRef = useRef < HTMLInputElement > null;
    let optionRefs = [];

    const SelectComponent = Component || SelectStyle;
    const SelectInputComponent = InputComponent || SelectInputStyle;
    const SelectMenuComponent = MenuComponent || SelectMenuStyle;
    const SelectMenuItemComponent = MenuItemComponent || SelectMenuItemStyle;

    const defaultRenderer = (option) => <SelectMenuItemComponent>{option.label}</SelectMenuItemComponent>;

    const handleReset = (closeMenu, selectedValue) => {
        selectedValue && setValue(selectedValue);
        closeMenu && shouldShowMenu(false);
        const index = options.findIndex((option) => {
            if (selectedValue) {
                return option.value === selectedValue.value;
            } else if (value) {
                return option.value === value.value;
            } else if (defaultValue) {
                return option.value === defaultValue.value;
            }

            return false;
        });

        setCurrentScroll(index !== -1 ? index : 0);
        setSearch('');
        optionRefs = [];
        selectedValue && onChange && onChange(selectedValue);
    };

    const handleChange = (selectedValue) => (e) => {
        e.persist();
        stopPropagation(e);
        handleReset(closeOnSelect, selectedValue);
    };

    const handleFocus = (e) => {
        e.persist();
        stopPropagation(e);
        shouldShowMenu(true);
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleRootEscape = (e) => {
        if (showMenu && e.key === 'Escape') {
            handleReset(true);
        }
    };

    const handleRootClose = (e) => {
        if (showMenu && thisRef.current && !thisRef.current.contains(e.target)) {
            handleReset(true);
        }
    };

    const handleKeyDown = (e) => {
        e.persist();
        stopPropagation(e);

        if (showMenu) {
            const arrow = e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.key : false;

            if (filteredOptions) {
                let scrollValue = currentScroll;
                if (arrow) {
                    if (arrow === 'ArrowUp' && currentScroll > 0) {
                        scrollValue--;
                    }

                    if (arrow === 'ArrowDown' && currentScroll < filteredOptions.length - 1) {
                        scrollValue++;
                    }

                    setCurrentScroll(scrollValue);
                    optionRefs[scrollValue].scrollIntoView({
                        block: 'nearest',
                    });
                }

                if (e.key === 'Enter') {
                    handleChange(filteredOptions[scrollValue])(e);
                }

                return true;
            }
        }

        return false;
    };

    const handleMouseOver = (index) => () => {
        setCurrentScroll(index);
    };

    const handleRef = (ref) => {
        optionRefs.push(ref);
    };

    const handleSearch = (e) => setSearch(e.target.value);

    useEffect(() => {
        document.addEventListener('click', handleRootClose, true);
        document.addEventListener('keyup', handleRootEscape, true);
        return () => {
            document.removeEventListener('click', handleRootClose, true);
            document.removeEventListener('keyup', handleRootEscape, true);
        };
    }, [showMenu]);

    useEffect(() => {
        if (search) {
            const lowerCaseSearch = search.replace(/\s/g, '').toLowerCase();
            setOptions(
                options.filter(
                    ({ value, label }) =>
                        value.toString().toLowerCase().replace(/\s/g, '').includes(lowerCaseSearch) ||
                        label.toString().toLowerCase().replace(/\s/g, '').includes(lowerCaseSearch),
                ),
            );
            setCurrentScroll(0);
        } else {
            setOptions(options);
        }
    }, [search]);

    useEffect(() => {
        if (JSON.stringify(filteredOptions) !== JSON.stringify(options)) {
            setOptions(options);
            handleReset(false);
        }
    }, [filteredOptions, setOptions, options]);

    const renderer = optionRenderer || defaultRenderer;

    const getAppropriateValue = () => {
        const inputValues = {
            value: '',
            label: '',
        };

        if (propsValue && hasValue(propsValue)) {
            if (typeof propsValue === 'object') {
                inputValues.label = propsValue.label;
                inputValues.value = propsValue.value;
            } else {
                const currentValue = filteredOptions.find((opt) => opt.value === propsValue);
                inputValues.label = currentValue.label;
                inputValues.value = currentValue.label;
            }
        } else if (value && hasValue(value.value)) {
            inputValues.label = value.label;
            inputValues.value = value.value;
        } else if (defaultValue && hasValue(defaultValue.value)) {
            inputValues.label = defaultValue.label;
            inputValues.value = defaultValue.value;
        }

        if (showMenu && searchable && hasValue(search)) {
            inputValues.label = search;
        }

        return inputValues;
    };

    const { value: trueValue, label: trueLabel } = getAppropriateValue();

    return (
        <SelectComponent {...rest} className="select" innerRef={thisRef} onSelect={handleFocus}>
            <SelectInputWrapper>
                <SelectInputComponent
                    type="text"
                    readOnly={!searchable}
                    placeholder={noPlaceholder ? '' : placeholder || 'Select something..'}
                    ref={inputRef}
                    value={trueLabel}
                    menuIsOpen={showMenu}
                    onKeyDown={handleKeyDown}
                    onKeyUp={stopPropagation}
                    onKeyPress={stopPropagation}
                    onChange={handleSearch}
                    onFocus={handleFocus}
                    autoFocus={autoFocus}
                    tabIndex="0"
                />
                {showIcon && <SelectIcon invert={showMenu}>⌵</SelectIcon>}
            </SelectInputWrapper>
            <input type="hidden" value={trueValue} name={name} ref={innerRef} />
            {showMenu && options && (
                <SelectMenuComponent>
                    {filteredOptions.map((option, index) => {
                        const isSelected = trueValue === option.value;
                        const isHighlighted = currentScroll === index;

                        return React.cloneElement(renderer(option, index, isSelected, isHighlighted), {
                            key: `enkel-select-option-${index}`,
                            onClick: handleChange(option),
                            onMouseOver: handleMouseOver(index),
                            ref: handleRef,
                            option,
                            index,
                            isSelected,
                            isHighlighted,
                        });
                    })}
                </SelectMenuComponent>
            )}
        </SelectComponent>
    );
};

Select.Style = {
    Main: SelectStyle,
    Input: SelectInputStyle,
    InputWrapper: SelectInputWrapper,
    Menu: SelectMenuStyle,
    MenuItem: SelectMenuItemStyle,
};
