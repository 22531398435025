import React from 'react';
import styled from 'styled-components';

/**
 * @param {styled-icon} [icon] the icon is optional
 * @param {String} label the text label for the list item
 */
export const MeatBallsListItem = ({ onClick, icon, label, dataTest }) => {
    return (
        <Container onClick={onClick} data-test={dataTest}>
            {icon && icon}
            {label}
        </Container>
    );
};

const Container = styled.div`
    height: 36px;
    cursor: pointer;
    align-items: center;
    color: ${(props) => props.theme.colors.black};
    display: flex;
    padding: 0 8px;
    &:hover {
        background-color: ${(props) => props.theme.colors.pantinGrey};
    }
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
        color: ${(props) => props.theme.colors.black};
    }
`;
