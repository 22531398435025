import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

const BaseHeadingStyles = css`
    margin: ${({ $noMargin, theme: { variables } }) => ($noMargin ? 0 : variables.margin)} 0;
    padding: 0 0 ${({ $noPadding, theme: { variables } }) => ($noPadding ? 0 : variables.padding)};
    color: ${({ theme: { colors } }) => colors.dark};
    transition: all 0.5s ease;
`;

export const HeadingOneStyle = styled.h1`
    ${BaseHeadingStyles}
    color: ${({ theme: { colors } }) => colors.primary};
    font-size: 2rem;
    border-bottom: 1px dotted ${({ theme: { colors } }) => darken(0.25, colors.light)};
`;

export const HeadingTwoStyle = styled.h2`
    ${BaseHeadingStyles}
    color: ${({ theme: { colors } }) => colors.primary};
    font-size: 1.6rem;
`;

export const HeadingThreeStyle = styled.h3`
    ${BaseHeadingStyles}
    font-size: 1.4rem;
    border-bottom: 1px dotted ${({ theme: { colors } }) => lighten(0.75, colors.dark)};
`;

export const HeadingFourStyle = styled.h4`
    ${BaseHeadingStyles}
    font-size: 1.2rem;
`;

export const HeadingFiveStyle = styled.h5`
    ${BaseHeadingStyles}
    font-size: 1rem;
`;

export const HeadingSixStyle = styled.h6`
    ${BaseHeadingStyles}
    font-size: 0.8rem;
`;
