import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { GET_ARCHIVED_ORDERS } from 'data/queries';

import { FORMAT } from 'constants/date-constants';
import { ORDER_HISTORY_URI } from 'constants/routes-constants';

import { Loading } from 'components/Loading';
import { OrderHistoryTable } from './OrderHistoryTable';

import { TotemButton } from 'ui/Button';

/**
 * Displays 3 first ARCHIVED orders
 */
export const OrdersSmallHistory = ({ baseUrl, ordersOfTotem, totemId }) => {
    const {
        loading: archivedOrdersLoading,
        error: archivedOrdersError,
        data: archivedOrdersData,
    } = useQuery(GET_ARCHIVED_ORDERS, {
        variables: { offset: 0, totemId },
        skip: !totemId,
    });
    if (archivedOrdersError) {
        throw Error(archivedOrdersError);
    }
    if (archivedOrdersLoading || !totemId) {
        return <Loading relative={true} containerHeight="125px" imageSize={100} />;
    }
    const {
        archivedOrders: { archivedOrders },
    } = archivedOrdersData;

    const INITIAL_NB_ORDERS_TO_SHOW = 3;

    const ordersToShow = archivedOrders
        .filter((order) => order)
        .sort((a, b) => (dayjs(a.date_delivery, FORMAT).isBefore(dayjs(b.date_delivery, FORMAT)) ? -1 : 1));

    return (
        <>
            {ordersToShow.length ? (
                <>
                    <OrderHistoryTable
                        ordersToShow={ordersToShow}
                        nbOrderToShow={INITIAL_NB_ORDERS_TO_SHOW}
                        ordersOfTotem={ordersOfTotem}
                        baseUrl={baseUrl}
                    />
                    <TotemButton
                        as={Link}
                        $type="secondary"
                        $alignSelf="center"
                        $fontSize="1em"
                        to={`${baseUrl}/${ORDER_HISTORY_URI}`}
                        data-test="see-complete-order-history"
                    >
                        Voir l'historique complet
                    </TotemButton>
                </>
            ) : (
                <ScheduleText>Une fois complétées, vos commandes apparaîtront ici !</ScheduleText>
            )}
        </>
    );
};

const ScheduleText = styled.div`
    font-size: 18px;
`;
