import React from 'react';
import styled from 'styled-components';

export const AspectRatio = ({ ratio = '16:9', size = 100, children, innerStyle, style, ...rest }) => {
    let unit = 'px';
    let fullSize = `${size}${unit}`;

    if (typeof size === 'string') {
        fullSize = size;
        unit = size.split(/[0-9]/).pop();
        size = Number(size.replace(/[A-Za-z%]/g, ''));
    }

    const [xValue, yValue] = (function () {
        const [xAspect, yAspect] = ratio.split(/:|\//).map((o) => parseInt(o));

        let ratioValue = yAspect / xAspect;
        let sizeX = `${size}${unit}`,
            sizeY = `${ratioValue * 100}%`;

        if (xAspect < yAspect) {
            ratioValue = xAspect / yAspect;
            sizeY = sizeX;
            sizeX = `${ratioValue * 100}%`;
        }

        return [sizeX, sizeY];
    })(ratio);

    return (
        <AspectRatioContainer style={{ ...style }} {...rest} width={`${fullSize}`}>
            <AspectRatioWrapper x={xValue} y={yValue}>
                <AspectRatioInner style={innerStyle}>{children}</AspectRatioInner>
            </AspectRatioWrapper>
        </AspectRatioContainer>
    );
};

export const AspectRatioContainer = styled.div`
    width: ${(props) => props.width};
`;
export const AspectRatioWrapper = styled.div`
    position: relative;
    width: ${(props) => props.x};
    height: 0;
    padding-top: ${(props) => props.y};
`;

export const AspectRatioInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
