import { HandleNotification } from 'utils';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';

export function CardActions(data, handlers, refetchQueries) {
    const { order, product, currentCategory, debouncedFunction } = data;
    const { addProduct, removeProduct, moreProduct, lessProduct } = handlers;
    const mutationOptions = {
        variables: {
            orderId: order._id,
            productId: product._id,
        },
        refetchQueries,
    };

    const propagationHandler = (handler) => async (e) => {
        e.persist();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        try {
            debouncedFunction && debouncedFunction.cancel();
            await handler(e);
        } catch (error) {
            HandleNotification(error);
        }
    };

    const productInOrder = order.products.find((p) => p._id === product._id);
    const orderProductsInCategory = order.products.filter((p) => p.categoryId === currentCategory._id);

    return {
        handleAddProduct: propagationHandler(() => {
            track(analyticsEvents.ADD_PRODUCT, {
                [analyticsPropertiesConstants.PRODUCTS.ID]: product._id,
                [analyticsPropertiesConstants.PRODUCTS.NAME]: product.name,
                [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: currentCategory.title,
                [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: product.categoryId,
                [analyticsPropertiesConstants.PRODUCTS.BRAND]: product.brand,
            });
            return addProduct.handler(mutationOptions);
        }),

        handleRemoveProduct: propagationHandler(() => {
            track(analyticsEvents.REMOVE_PRODUCT, {
                [analyticsPropertiesConstants.PRODUCTS.ID]: product._id,
                [analyticsPropertiesConstants.PRODUCTS.NAME]: product.name,
                [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: currentCategory.title,
                [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: product.categoryId,
                [analyticsPropertiesConstants.PRODUCTS.BRAND]: product.brand,
            });
            return removeProduct.handler(mutationOptions);
        }),

        handleLessProduct: propagationHandler(() => {
            if (productInOrder?.quantity === 1) {
                // we are removing the product
                track(analyticsEvents.REMOVE_PRODUCT, {
                    [analyticsPropertiesConstants.PRODUCTS.ID]: product._id,
                    [analyticsPropertiesConstants.PRODUCTS.NAME]: product.name,
                    [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: currentCategory.title,
                    [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: product.categoryId,
                    [analyticsPropertiesConstants.PRODUCTS.BRAND]: product.brand,
                });
                return removeProduct.handler(mutationOptions);
            }
            track(analyticsEvents.MODIFY_PRODUCT_QUANTITY, {
                [analyticsPropertiesConstants.PRODUCTS.ID]: product._id,
                [analyticsPropertiesConstants.PRODUCTS.NAME]: product.name,
                [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: currentCategory.title,
                [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: product.categoryId,
                [analyticsPropertiesConstants.PRODUCTS.BRAND]: product.brand,
                [analyticsPropertiesConstants.PRODUCTS.BRAND]: product.brand,
            });
            return lessProduct.handler(mutationOptions);
        }),

        handleMoreProduct: propagationHandler(() => {
            track(analyticsEvents.MODIFY_PRODUCT_QUANTITY, {
                [analyticsPropertiesConstants.PRODUCTS.ID]: product._id,
                [analyticsPropertiesConstants.PRODUCTS.NAME]: product.name,
                [analyticsPropertiesConstants.PRODUCTS.CATEGORY]: currentCategory.title,
                [analyticsPropertiesConstants.PRODUCTS.CATEGORY_ID]: product.categoryId,
                [analyticsPropertiesConstants.PRODUCTS.BRAND]: product.brand,
            });
            return moreProduct.handler(mutationOptions);
        }),

        productsInCategory: orderProductsInCategory,
        mutationData: {
            addProduct: addProduct.data,
            removeProduct: removeProduct.data,
            moreProduct: moreProduct.data,
            lessProduct: lessProduct.data,
        },
    };
}
