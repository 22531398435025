import React from 'react';
import { User } from '@totem/roles';
import { Flex } from 'deprecated-enkel';
import styled from 'styled-components';

import { ExtendedProductInfoButtons } from 'pages/Totem/TotemProducts/ExtendedProduct/ExtendedProductInfoButtons';

import { margin } from 'polished';

import { checkIfProductIsAvailable } from 'utils';

export const ExtendedProductHeader = ({ product, totemId, order, currentCategory }) => {
    const isAnalyst = User.isAnalyst(totemId);

    const orderProductIds = order.products.map(({ _id }) => _id);
    const isAlreadyInOrder = orderProductIds.includes(product._id);

    return (
        <>
            <Flex $alignItems="flex-start" $justifyContent="space-between">
                <ProductTitleWrapper>
                    <ProductTitle>{product.name}</ProductTitle>
                    <ProductBrand>{product.brand}</ProductBrand>
                    {!isAlreadyInOrder && !checkIfProductIsAvailable(product) && (
                        <ProductUnavailableText>Quelques infos en attendant son retour</ProductUnavailableText>
                    )}
                </ProductTitleWrapper>
                {!isAnalyst && (
                    <ExtendedProductInfoButtons product={product} order={order} currentCategory={currentCategory} />
                )}
            </Flex>
        </>
    );
};

const ProductTitle = styled.h1`
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-size: 1.75rem;
    margin: 0;
`;

const ProductBrand = styled.div`
    color: ${(props) => props.theme.colors.pantinGrey};
    font-size: 1.3em;
    font-weight: 800;
    margin: 0;
`;

const ProductTitleWrapper = styled(Flex.Style)`
    padding: 20px;
    flex-flow: column;
    flex: 1;
    max-width: 470px;
`;

const ProductUnavailableText = styled.div`
    font-size: 0.9em;
    ${(props) => margin(props.theme.variables.margin * 0.2, 0, 0, props.theme.variables.margin * 2)};
    color: ${(props) => props.theme.colors.pantinGrey};
`;
