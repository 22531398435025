import { TOTEM_FRAGMENT } from 'data/fragments/totem.fragments';
import gql from 'graphql-tag';

export const GET_TOTEMS = gql`
    query GET_TOTEMS {
        totemsOfUser {
            ...TotemFragment
        }
    }
    ${TOTEM_FRAGMENT}
`;
