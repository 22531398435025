import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { margin } from 'polished';

import { GET_READY_PUNCTUAL_ORDERS } from 'data/queries';
import { UserContext } from 'constants/user-context';
import { TOTARGET } from 'constants/states-app';
import { EQUIPMENTS_URI } from 'constants/routes-constants';
import { ENV_PROD } from 'constants/environment-constants';

import { ModalContext } from 'contexts/ModalContext';

import { Loading } from 'components/Loading';
import { OrdersSchedule } from 'pages/Totem/TotemDashboard/OrdersSchedule';
import { OrdersSmallHistory } from 'pages/Totem/TotemDashboard/OrdersSmallHistory';
import { OrderSelectionPopup } from 'pages/Totem/TotemDashboard/OrderSelectionPopup';
import { DeliveryInfoPopup } from 'pages/Totem/TotemDashboard/DeliveryInfoPopup';
import { DashboardHeader } from 'pages/Totem/TotemDashboard/DashboardHeader';
import { BudgetCard } from 'pages/Totem/TotemDashboard/Budget/BudgetCard';

import { DashboardWrapper, TotemCard, DashboardTitle, TotemLoadingStyle } from 'styles/pages/totem.styles';

import { TotemButton } from 'ui/Button';

import { analyticsEvents, analyticsPropertiesConstants, track } from 'tracking/segment';
import { isClient } from 'utils';

export const TotemDashboard = ({ baseUrl, currentTotem, defaultCategoryId, ordersOfTotem }) => {
    const { organization } = useContext(UserContext);
    const { modal, isModalOpen } = useContext(ModalContext);
    const isOnboarding = organization.state === TOTARGET;
    const { _id: totemId } = currentTotem;
    const { hasConfirmedTotemOrders } = ordersOfTotem;
    const [orderPopupOpen, setOrderPopupOpen] = useState(false);
    const [isDeliveryInfoOpen, setDeliveryInfoOpen] = useState(false);

    const {
        data: readyPunctualOrdersData,
        loading: readyPunctualOrdersLoading,
        error: readyPunctualOrdersError,
    } = useQuery(GET_READY_PUNCTUAL_ORDERS, {
        variables: { totemId },
        skip: !hasConfirmedTotemOrders || !totemId,
    });
    if (readyPunctualOrdersLoading) {
        return (
            <TotemLoadingStyle>
                <Loading />
            </TotemLoadingStyle>
        );
    }

    if (readyPunctualOrdersError) {
        throw Error(readyPunctualOrdersError);
    }

    const { readyPunctualOrders } = readyPunctualOrdersData || [];

    const isToteam = !isClient();

    return (
        <DashboardWrapper>
            <DashboardHeader
                totem={currentTotem}
                openDeliveryInfoPopup={() => {
                    track(analyticsEvents.OPEN_TOTEM_DELIVERY_INFO);
                    setDeliveryInfoOpen(true);
                }}
            />
            <BudgetCard totemId={currentTotem._id} totemMonthlyBudget={currentTotem.monthlyBudget} />
            <TotemCard $marginMultiplier={[1.5, 3, 1.5, 3]}>
                {!isToteam || window.__TOTENV__.ENV !== ENV_PROD ? (
                    <>
                        <CardLine style={{ wrap: 'wrap' }}>
                            <DashboardTitle $marginMultiplier={[0, 1.5, 1, 0]}>Mes commandes en cours</DashboardTitle>
                            <TotemButton
                                $alignSelf="flex-start"
                                onClick={() => {
                                    track(analyticsEvents.START_ORDER_CREATION, {
                                        [analyticsPropertiesConstants.COMMON.IS_ONBOARDING]: !hasConfirmedTotemOrders,
                                    });
                                    setOrderPopupOpen(true);
                                }}
                                data-test="create-order-button"
                            >
                                Créer une commande
                            </TotemButton>
                        </CardLine>
                        <OrdersSchedule
                            baseUrl={baseUrl}
                            defaultCategoryId={defaultCategoryId}
                            isOnboarding={isOnboarding}
                            ordersOfTotem={ordersOfTotem}
                            readyPunctualOrders={readyPunctualOrders}
                        />
                        {!isOnboarding && hasConfirmedTotemOrders && (
                            <HistoryContainer>
                                <CardLine>
                                    <DashboardTitle $marginMultiplier={[2, 1.5, 1, 0]}>
                                        Mon historique de commandes
                                    </DashboardTitle>
                                </CardLine>
                                <OrdersSmallHistory
                                    totemId={totemId}
                                    baseUrl={baseUrl}
                                    defaultCategoryId={defaultCategoryId}
                                    ordersOfTotem={ordersOfTotem}
                                />
                            </HistoryContainer>
                        )}
                    </>
                ) : (
                    <DashboardText>Veuillez vous rendre sur tipi.totem.co pour créer des commandes</DashboardText>
                )}
            </TotemCard>
            <TotemCard $marginMultiplier={[1.5, 3, 1.5, 3]}>
                <CardLine>
                    <DashboardTitle $marginMultiplier={[0, 1.5, 0, 0]}>Mes équipements</DashboardTitle>
                    <TotemButton as={Link} $type="secondary" to={`${baseUrl}/${EQUIPMENTS_URI}`}>
                        Voir les équipements
                    </TotemButton>
                </CardLine>
                <DashboardText $marginMultiplier={[1.5, 0, 0, 0]}>
                    TOTEM vous propose en location ses meubles, machines à café, réfrigérateurs ...
                </DashboardText>
            </TotemCard>
            <OrderSelectionPopup
                orderPopupOpen={orderPopupOpen}
                closeOrderPopup={() => setOrderPopupOpen(false)}
                ordersOfTotem={ordersOfTotem}
                currentTotem={currentTotem}
                defaultCategoryId={defaultCategoryId}
            />
            {isDeliveryInfoOpen && (
                <DeliveryInfoPopup
                    currentTotem={currentTotem}
                    closeAddressPopup={() => setDeliveryInfoOpen(false)}
                    hasInstructions={true}
                />
            )}
            {isModalOpen && modal}
        </DashboardWrapper>
    );
};

const CardLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const DashboardText = styled.span`
    display: inline-flex;
    align-items: center;
    color: ${({ color, theme: { colors } }) => colors[color || 'secondary']};
    font-size: 1.2em;
    ${({ $bold }) => $bold && 'font-weight: 800'};
    ${({
        theme: {
            variables: { margin: marg },
        },
        $marginMultiplier = [],
    }) =>
        margin(
            marg * ($marginMultiplier[0] || 0),
            marg * ($marginMultiplier[1] || 0),
            marg * ($marginMultiplier[2] || 0),
            marg * ($marginMultiplier[3] || 0),
        )};
`;

const HistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
