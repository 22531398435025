import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { User } from '@totem/roles';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { UPDATE_USER } from 'data/mutations/user.mutations';
import { UserContext } from 'constants/user-context';

import { Logo } from 'components/Logo';

import { TotemButton } from 'ui/Button';
import { InlineError } from 'ui/InlineError';

import { validatePhone } from 'utils';

import { LoginStyle, LoginBottomHalfStyle, LoginTopHalfStyle, LoginWrapperStyle } from 'styles/pages/login.styles';
import { SignupForm, SignupTitleStyle, SignupOneRow } from 'styles/pages/signup.styles';
import { TotemLabel, TotemInput } from 'styles';

export const SetPasswordInfo = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const isGlobalEmployee = User.isGlobalEmployee();
    const [updateUser] = useMutation(UPDATE_USER);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstname: user?.profile?.firstname,
            lastname: user?.profile?.lastname,
            jobTitle: user?.profile?.jobTitle,
            phone: user?.profile?.phone,
        },
    });

    const handleFieldsSubmit = async (data) => {
        const profile = user.profile;
        const { firstname, lastname } = data;

        const updatedProfile = {
            ...profile,
            ...data,
            fullname: `${firstname} ${lastname}`,
        };

        try {
            await updateUser({
                variables: {
                    data: {
                        profile: {
                            ...updatedProfile,
                        },
                    },
                    _id: user._id,
                },
                refetchQueries: ['GET_USER'],
            });
            navigate('/', { relative: 'path' });
        } catch (error) {
            window.Log.error(error);
        }
    };

    return (
        <LoginStyle>
            <LoginTopHalfStyle>
                <Logo dark />
            </LoginTopHalfStyle>
            <LoginBottomHalfStyle>
                <LoginWrapperStyle>
                    <SignupTitleStyle>Faisons connaissance</SignupTitleStyle>

                    <SignupForm onSubmit={handleSubmit(handleFieldsSubmit)}>
                        <SignupOneRow>
                            <TotemLabel color="white" $marginMultiplier={[1, 0, 0, 0]}>
                                Prénom <InlineError display={errors.firstname} />
                                <TotemInput
                                    placeholder="Entrez votre prénom"
                                    type="text"
                                    autoFocus
                                    {...register('firstname', { required: true })}
                                    data-test="set-firstname"
                                />
                            </TotemLabel>
                            <TotemLabel color="white" $marginMultiplier={[1, 0, 0, 0]}>
                                Nom <InlineError display={errors.lastname} />
                                <TotemInput
                                    placeholder="Entrez votre nom"
                                    type="text"
                                    {...register('lastname', { required: true })}
                                    data-test="set-lastname"
                                />
                            </TotemLabel>
                        </SignupOneRow>
                        {!isGlobalEmployee && (
                            <SignupOneRow>
                                <TotemLabel color="white" $marginMultiplier={[0, 0.5, 1, 0]}>
                                    Rôle <InlineError display={errors.jobTitle} />
                                    <TotemInput
                                        placeholder="Entrez votre rôle dans l'entreprise"
                                        type="text"
                                        {...register('jobTitle', { required: true })}
                                        data-test="set-role"
                                    />
                                </TotemLabel>
                                <TotemLabel color="white" $marginMultiplier={[0, 0, 1, 0.5]}>
                                    Numéro de téléphone <InlineError display={errors.phone} />
                                    <TotemInput
                                        placeholder="Entrez votre numéro de téléphone"
                                        type="text"
                                        {...register('phone', {
                                            required: true,
                                            validate: validatePhone("Votre numéro de téléphone n'est pas valide"),
                                        })}
                                        data-test="set-phone-number"
                                    />
                                </TotemLabel>
                            </SignupOneRow>
                        )}
                        <TotemButton $margins={[0, 0.5]} type="submit" data-test="set-password-submit">
                            Suivant
                        </TotemButton>
                    </SignupForm>
                </LoginWrapperStyle>
            </LoginBottomHalfStyle>
        </LoginStyle>
    );
};
